import * as yup from 'yup';
import { validateCpf, validateCnpj } from '../utils';

yup.addMethod(yup.string, 'cpf', function (message) {
	return this.test('cpf', message, function (value) {
		return validateCpf(value);
	});
});

yup.addMethod(yup.string, 'cnpj', function (message) {
	return this.test('cnpj', message, function (value) {
		return validateCnpj(value);
	});
});
