import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';

import UIButton from 'components/UI/Button/Button';
import api from 'services/api';

import profile from 'assets/images/profile.png';

import { updatePicture, updateUser } from 'store/modules/user/actions';
import { setLoading } from 'store/modules/app/actions';
import { useDispatch } from 'react-redux';
import { store } from 'store';

export default function DropZone({
	userDefault,
	userId = 1,
}) {
	const dispatch = useDispatch();

	const [currentPicture, setCurrentPicture] = useState(profile);
	const user = store.getState().user;

	useEffect(() => {
		const key = (store.getState().user.profilePicture || {}).key;
		console.log(store.getState().user.profilePicture);
		if (String(key)
			.split('').reverse().join('')
			.substr(0, 4)
			.split('').reverse().join('') === "host") setCurrentPicture(key);
		else
			setCurrentPicture(profile);
	}, [user, dispatch]);

	const onDrop = useCallback(async (acceptedFiles) => {
		dispatch(setLoading(true));
		try {
			let formData = new FormData();
			formData.append('file', acceptedFiles[0]);
			formData.append('table', 'access');
			formData.append('tableId', userId);
			formData.append('category', 'profile');
			formData.append('ocr', true);

			const response = await api.post(`attachments`, formData);
			await dispatch(await updatePicture(response.data));
		} catch (error) {
			return null;
		} finally {
			dispatch(setLoading(false));
		}
	}, []);

	const { getRootProps, getInputProps } = useDropzone({ onDrop });

	return (
		<Box
			ml={4}
			mb={2}
			pt={1}
			display="flex"
			alignItems="center"
			justifyContent="space-between"
			flexDirection="column"
			height="100%"
			width="min-content"
		>
			<img
				src={currentPicture}
				alt="profile"
				width="150px"
			/>
			<Box {...getRootProps()}>
				<Box pt={2}>
					<UIButton
						variant="outlined"
						text="Alterar imagem"
						borderwidth="1px"
						fontSize="14px"
						width="180px"
					/>
				</Box>
				<input {...getInputProps()} accept="image/*" />
			</Box>
		</Box>
	)
}
