import produce from 'immer';

export const initialState = {
	products: [],
};

export default function processProduct(state = initialState, action) {
	return produce(state, (draft) => {
		switch (action.type) {
			case '@processProduct/SET_ALL':
				draft.products = action.payload;
				break;
			default:
				return state;
		}
	});
}
