import VerticalStageBar from 'components/StageBar/VerticalStageBar/VerticalStageBar';
import UISelect from 'components/UI/Select/Select';
import UploadArea from 'components/Upload/UploadArea/UploadArea';
import React, { useEffect, useState } from 'react';
import { ImFolderOpen } from 'react-icons/im';

import mock from 'assets/mock.json';
import NavBreadcrumbs from 'components/Navigation/Breadcrumbs/Breadcrumbs';
import { Box, Container, Divider, Grid } from '@material-ui/core';
import { PanelLeft, PanelRight } from './style';
import api from 'services/api';
import { store } from 'store';
import { useDispatch } from 'react-redux';
import { setLoading } from 'store/modules/app/actions';

export default function CreateProcess({ buttonAction }) {
	const { breadcumbs, documentTypes, stageBarItems } = mock;

	const dispatch = useDispatch();
	const distributor = store.getState().user;

	const [selectedDocumentType, setSelectedDocumentType] = useState(null);
	const [file, setFile] = useState();
	const [labelFile, setLabelFile] = useState('');
	const [msgErrorCombo, setMsgErrorCombo] = useState(null);

	const uploadAttachment = async () => {
		try {
			dispatch(setLoading(true));
			const process = await api.post(`process`, {
				distributorId: distributor.id,
				processDirect: false,
			});

			let formData = new FormData();
			formData.append('file', file);
			formData.append('table', 'process');
			formData.append('tableId', process.data.id);
			formData.append('category', selectedDocumentType);

			let config = {};
			if (selectedDocumentType === 'Edital') {
				formData.append('ocr', true);
				config.headers = { ocr: true, category: 'edital' };
			}
			await api.post(`attachments`, formData, config);

			dispatch({ type: '@process/SET_PROCESS', payload: process.data });

			buttonAction();
			dispatch(setLoading(false));
		} catch (error) {
			console.log(error);
			return null;
		}
	};

	//renderiza mensagem de erro no upload do arquivo
	useEffect(() => {
		if (selectedDocumentType === '') {
			setMsgErrorCombo('Selecione um tipo de documento');
		} else if (selectedDocumentType === false) {
			setMsgErrorCombo('');
		} else {
			setMsgErrorCombo('');
		}
	}, [selectedDocumentType]);

	return (
		<Container>
			<Box mb={5}>
				<Grid container direction="column">
					<div className="breads">
						<NavBreadcrumbs items={breadcumbs} />
					</div>
					<Grid container direction="row">
						<Grid item xs={12} md={5}>
							<PanelLeft>
								<div>
									<ImFolderOpen color="var(--primary)" size={34} />
									<div>
										<h1> Adicionar processo </h1>
										<span>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit.
											Sed fringilla molestie ultricies. Fusce aliquet in ante a
											iaculis. Mauris pharetra fermentum condimentum.
										</span>
									</div>
									<Divider />
									<div>
										<VerticalStageBar items={stageBarItems} current={0} />
									</div>
								</div>
							</PanelLeft>
						</Grid>

						<PanelRight item xs={12} md={7}>
							<Box pb={3}>
								<div className="input-type">
									<UISelect
										items={documentTypes}
										label="Documento"
										value={selectedDocumentType}
										onChange={(e) => setSelectedDocumentType(e.target.value)}
										msgError={msgErrorCombo}
										error={msgErrorCombo}
									/>
								</div>
							</Box>
							<UploadArea
								height="350px"
								labelText={labelFile}
								onChange={(param) => {
									setFile(param[0]);
									setLabelFile(
										`Arquivo selecionado: ${String(param[0].name).substr(
											0,
											30,
										)}.../pdf`,
									);
								}}
								buttonText="Avançar"
								buttonOnClick={() => {
									if (selectedDocumentType == null) {
										return setSelectedDocumentType('');
									}

									return uploadAttachment();
								}}
							/>
						</PanelRight>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
}
