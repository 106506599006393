import {
	Box,
	Divider,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Paper,
} from '@material-ui/core';

import MoreVertIcon from '@material-ui/icons/MoreVert';

import UITag from 'components/UI/Tag/index';

import { DarkBox, StyledSpan, StyledP, TinyInfo, StyledLine } from './style';

import { formatToBRL } from 'brazilian-values';

import React, { useEffect, useState } from 'react';
import UIText from 'components/UI/Text/Text';

export default function InformationCard({ process, type }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [amount, setAmount] = useState(0);
	const [currentBalance, setCurrentBalance] = useState(0);

	useEffect(() => {
		let hiredValue = 0,
			totalHiredValue = 0;
		process?.process_products?.forEach((products) => {
			hiredValue +=
				products.finalValue * (products.initialQuantity - products.quantity);
			totalHiredValue += products.finalValue * products.initialQuantity;
		});
		setAmount(hiredValue);
		setCurrentBalance(totalHiredValue);
	}, [process]);

	return (
		<Paper elevation={0}>
			<Box p={1} pl={2}>
				<Grid container justify="space-between" alignItems="center">
					<Grid item>
						<StyledSpan
							fontSize="16px"
							fontWeight="500"
							color="dark"
							margin="0 10px 0 0"
						>
							Informações do processo
						</StyledSpan>
						<UITag text="Publico" variant={process.segment} />
					</Grid>
					<Grid item>
						<StyledSpan color="grey">Codigo do cliente: </StyledSpan>
						<StyledSpan color="blue">
							#{process.distributorId}{' '}
						</StyledSpan> |{' '}
						<StyledSpan color="blue">
							{process.distributor && process.distributor.name
								? process.distributor.name
								: null}
						</StyledSpan>
						<IconButton
							aria-label="more"
							aria-controls="long-menu"
							aria-haspopup="true"
							onClick={(e) => setAnchorEl(e.currentTarget)}
							size="small"
						>
							{' '}
							<MoreVertIcon />
						</IconButton>
						{type !== 'distributor' ? (
							<Menu
								open={Boolean(anchorEl)}
								keepMounted
								anchorEl={anchorEl}
								onClose={() => setAnchorEl(null)}
							>
								<MenuItem>Editar informacoes</MenuItem>
							</Menu>
						) : null}
					</Grid>
				</Grid>
			</Box>
			<Divider />
			<Box p={2}>
				<Grid container direction="row">
					<Grid item xs={12} md={7}>
						<Grid container direction="row" justify="space-between">
							<Grid item xs={4}>
								<StyledSpan color="grey" fontWeight="500">
									Numero do pregao
								</StyledSpan>
								<StyledP fontSize="24px"> {process.tradingCode} </StyledP>
							</Grid>
							<Grid item xs={4}>
								<StyledSpan color="grey" fontWeight="500">
									Numero da PNE
								</StyledSpan>
								<StyledP fontSize="24px"> {process.PNE ?? '###'} </StyledP>
							</Grid>
							<Grid item xs={3}>
								<UITag
									fontWeight="500"
									text={process.institution ? process.institution.class : ''}
									variant={
										process.institution
											? (process.institution.class || '').toLowerCase()
											: false
									}
								/>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Box mb={2}>
								<StyledSpan color="grey" fontSize="16px">
									{process.institution ? process.institution.name : ''}
								</StyledSpan>
								<StyledLine />
							</Box>
						</Grid>
						<Grid item xs={12}>
							<StyledSpan color="blue" fontSize="16px">
								{process.city} - {process.uf} |{' '}
							</StyledSpan>
							<StyledSpan>
								{process.institution ? process.institution.serviceChannel : ''}
							</StyledSpan>
						</Grid>
						<Grid item xs={12}>
							<StyledSpan fontSize="12px"> Programa: </StyledSpan>
							<StyledSpan color="blue" fontSize="12px">
								{process.institution ? process.programName : ''}
							</StyledSpan>
							<StyledSpan fontSize="12px"> Cliente: </StyledSpan>
							<StyledSpan color="blue" fontSize="12px">
								{process.institution ? process.institution.social : ''}
							</StyledSpan>
						</Grid>
						<Grid container direction="row">
							<Grid item xs={12} lg={6}>
								<Box mt={2}>
									<StyledSpan color="grey" fontSize="12px" fontWeight="500">
										Valor contratado
									</StyledSpan>
									<br />
									<UITag
										text={formatToBRL(amount)}
										variant="success"
										fontSize="15px"
										fontWeight="500"
									/>
								</Box>
							</Grid>
							<Grid item xs={12} lg={6}>
								<Box mt={2}>
									<StyledSpan color="grey" fontSize="12px" fontWeight="500">
										Valor a faturar
									</StyledSpan>
									<br />
									<UITag
										text={formatToBRL(currentBalance)}
										variant="silver"
										fontSize="15px"
										fontWeight="500"
									/>
								</Box>
							</Grid>
						</Grid>
					</Grid>
					<Divider orientation="vertical" flexItem />
					<Grid item xs={12} md={4}>
						<Box pl={3}>
							<Grid container style={{ height: '100%' }}>
								<Grid item>
									<StyledSpan color="grey">Abertura da licitacao</StyledSpan>
									<UIText text="12 de novembro de 2020" fontSize="14px" />
								</Grid>
								<Grid item>
									<StyledSpan color="grey">Assinatura do contrato</StyledSpan>
									<UIText text="12 de novembro de 2020" fontSize="14px" />
								</Grid>
								<Grid item>
									<StyledSpan color="grey">Assinatura do contrato</StyledSpan>
									<UIText text="12 de novembro de 2020" fontSize="14px" />
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>
			<DarkBox p={2}>
				<Grid container>
					<TinyInfo xs-mt="1em" item xs={6} md={3}>
						<StyledSpan padding="2em 0px 0px 0px" fontSize="12px">
							{' '}
							Politica comercial{' '}
						</StyledSpan>
						<StyledP>
							{process.distributor ? 'Distribuidor' : 'Instituicao'}{' '}
						</StyledP>
					</TinyInfo>
					<TinyInfo xs-mt="1em" item xs={6} md={3}>
						<StyledSpan padding="2em 0px 0px 0px" fontSize="12px">
							{' '}
							Forma de participacao{' '}
						</StyledSpan>
						<StyledP>{process.processDirect ? 'Direto' : 'Indireto'} </StyledP>
					</TinyInfo>
					<TinyInfo xs-mt="1em" item xs={6} md={3}>
						<StyledSpan padding="2em 0px 0px 0px" fontSize="12px">
							{' '}
							Periodo de fornecimento{' '}
						</StyledSpan>
						<StyledP>{process.period} </StyledP>
					</TinyInfo>
					<TinyInfo xs-mt="1em" item xs={6} md={3}>
						<StyledSpan padding="2em 0px 0px 0px" fontSize="12px">
							{' '}
							Pode ser prorrogado{' '}
						</StyledSpan>
						<StyledP color="blue">{process.canExtend ? 'Sim' : 'Nao'} </StyledP>
					</TinyInfo>
					<TinyInfo item lg-mt="1em" xs={6} md={3}>
						<StyledSpan padding="2em 0px 0px 0px" fontSize="12px">
							{' '}
							Modalidade do pregao{' '}
						</StyledSpan>
						<StyledP>{process.competitionModality} </StyledP>
					</TinyInfo>
					<TinyInfo item lg-mt="1em" xs={6} md={3}>
						<StyledSpan padding="2em 0px 0px 0px" fontSize="12px">
							{' '}
							Numero do edital{' '}
						</StyledSpan>
						<StyledP>{process.tradingCode} </StyledP>
					</TinyInfo>
					<TinyInfo item lg-mt="1em" xs={6} md={3}>
						<StyledSpan padding="2em 0px 0px 0px" fontSize="12px">
							{' '}
							Consultor(a){' '}
						</StyledSpan>
						<StyledP>
							{process.employee && process.employee.name
								? process.employee.name
								: ''}
						</StyledP>
					</TinyInfo>
					<TinyInfo item lg-mt="1em" xs={6} md={3}>
						<StyledSpan padding="2em 0px 0px 0px" fontSize="12px">
							{' '}
							Meses prorrogados{' '}
						</StyledSpan>
						<StyledP>{process.extendMonths} </StyledP>
					</TinyInfo>
					<TinyInfo item lg-mt="1em" xs={6} md={3}>
						<StyledSpan padding="2em 0px 0px 0px" fontSize="12px">
							{' '}
							Cota{' '}
						</StyledSpan>
						<StyledP>{process.quota} </StyledP>
					</TinyInfo>
					<TinyInfo item lg-mt="1em" xs={6} md={3}>
						<StyledSpan padding="2em 0px 0px 0px" fontSize="12px">
							{' '}
							Regional{' '}
						</StyledSpan>
						<StyledP>{process.regional}</StyledP>
					</TinyInfo>
					<TinyInfo item lg-mt="1em" xs={6} md={3}>
						<StyledSpan padding="2em 0px 0px 0px" fontSize="12px">
							{' '}
							Municipio foco?{' '}
						</StyledSpan>
						<StyledP>{process.focusUf ? 'Sim' : 'Não'} </StyledP>
					</TinyInfo>
				</Grid>
			</DarkBox>
		</Paper>
	);
}
