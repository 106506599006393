import React, { useLayoutEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Route from './Route';
import history from 'services/history';

//routes import

import Login from 'screens/login';
import Dashboard from 'screens/Dashboard/index';

//process components
import CreateProcess from 'screens/Process/create/index';
import ProcessHistory from 'screens/Process/history';
import ResultProcess from 'screens/Process/result/index';
//end process

//admin components
import AllProcess from 'screens/Admin/process/index';
import Institutions from 'screens/Admin/institution';
import Users from 'screens/Admin/user';
import CreateUser from 'screens/Admin/user/create';
import CreateDistributor from 'screens/Admin/distributor/create';
import Prodcuts from 'screens/Admin/product';
import CreateProduct from 'screens/Admin/product/create';
//end admin

// pendencies compoenents
import Pendencies from 'screens/pendencies/index';
// end pendencies

// viewcomponents components
import ViewComponents from 'screens/ViewComponents/index';
// end viewcomponents
import CreateInstitution from 'screens/Admin/institution/create';
import Profile from 'screens/Profile';

//end routes import
function Routes() {
	useLayoutEffect(() => {
		const unlisten = history.listen(() => {
			window.scrollTo(0, 0);
		});
		return unlisten;
	}, []);

	return (
		<BrowserRouter history={history}>
			<Switch>
				<Route exact path="/login" component={Login} noLayout />
				<Route exact path="/" component={Dashboard} isPrivate />
				<Route
					exact
					path={["/processo/novo/:idProcess", "/processo/novo/"]}
					component={CreateProcess}
					noContainer
					isPrivate
				/>
				<Route
					exact
					path="/processo/:idProcess"
					component={ProcessHistory}
					isPrivate
				/>
				<Route
					exact
					path="/components"
					component={ViewComponents}
					noLayout
					isPrivate
				/>
				<Route
					exact
					path="/resultado/:idProcess"
					component={ResultProcess}
					isPrivate
				/>
				<Route exact path="/processo" component={AllProcess} isPrivate />
				<Route exact path="/instituicao" component={Institutions} isPrivate />
				<Route
					exact
					path="/instituicao/nova"
					component={CreateInstitution}
					isPrivate
				/>
				<Route
					exact
					path={['/instituicao/nova', '/instituicao/:institutionId']}
					component={CreateInstitution}
					isPrivate
				/>
				<Route exact path="/usuario" component={Users} isPrivate />
				<Route exact path={'/usuario/novo'} component={CreateUser} isPrivate />
				<Route
					exact
					path={'/distribuidor/novo'}
					component={CreateDistributor}
					isPrivate
				/>
				<Route exact path="/produto" component={Prodcuts} isPrivate />
				<Route
					exact
					path={['/produto/novo', '/produto/:productId']}
					component={CreateProduct}
					isPrivate
				/>
				<Route
					exact
					path={['/perfil', '/editar/:userId']}
					component={Profile}
					isPrivate
				/>
				<Route
					exact
					path="/pendencias"
					component={Pendencies}
					isPrivate
				/>
			</Switch>
		</BrowserRouter>
	);
}

export default Routes;
