import {
	AppBar,
	Toolbar,
	withStyles,
	Typography,
	Grid,
	styled,
} from '@material-ui/core';

export const StyledAppBar = withStyles({
	root: {
		height: '60px',
		boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.16)',
	},
})(AppBar);

export const StyledToolbar = withStyles({
	root: {
		minHeight: '60px',
		padding: 0,
	},
})(Toolbar);

export const StyledGrid = withStyles({
	root: {
		height: '100%',
	},
})(Grid);

export const Username = withStyles({
	root: {
		fontSize: '12px',
	},
})(Typography);

export const Profile = withStyles({
	root: {
		fontSize: '12px',
		fontWeight: 'normal',
		color: 'var(--primary)',
	},
})(Typography);
