import React, { useCallback, useEffect, useState } from 'react';
import {
	Box,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableHead,
} from '@material-ui/core';
import { useHistory } from 'react-router';

import HeaderScreens from 'screens/Admin/components/HeaderScreens';
import NavTabs from 'components/Navigation/Tabs/Tabs';
import Card from 'components/Cards';
import AddProductModal from '../components/AddProductModal';
import Modal from 'components/Modals';
import TableCell from 'components/UI/Table/TableCell';
import TableRow from 'components/UI/Table/TableRow';
import UIText from 'components/UI/Text/Text';
import RivalProductModal from '../components/RivalProductModal';
import UIPagination from 'components/UI/Pagination';

import { Divider } from './style';

import { TiPencil } from 'react-icons/ti';
import { BiTrash } from 'react-icons/bi';

import api from 'services/api';
import { useDispatch } from 'react-redux';
import { setLoading } from 'store/modules/app/actions';
import mock from './mock.json'

export default function Product() {
	const history = useHistory();
	const dispatch = useDispatch();

	const [paramsProducts, setParamsProducts] = useState({ disabled: 0 });
	const [products, setProducts] = useState({});
	const [allProducts, setAllProducts] = useState({});
	const [selectedProduct, setSelectedProduct] = useState({});

	const [paramsRivalProducts, setParamsRivalProducts] = useState({});
	const [rivalProducts, setRivalProducts] = useState([]);
	const [selectedRivalProduct, setSelectedRivalProduct] = useState({});

	const [searchValue, setSearchValue] = useState('');
	const [activeTab, setActiveTab] = useState(0);

	const [showAddProductModal, setShowProductModal] = useState(false);

	const [showDeleteProductModal, setShowDeleteProductModal] = useState(false);
	const [
		showDeleteProductSuccessModal,
		setShowDeleteProductSuccessModal,
	] = useState(false);

	const [showReactivateProductModal, setShowReactivateProductModal] = useState(
		false,
	);
	const [
		showReactivateProductSuccessModal,
		setShowReactivateProductSuccessModal,
	] = useState(false);

	const [showRivalProductModal, setShowRivalProductModal] = useState(false);
	const [
		showRivalProductSuccessModal,
		setShowRivalProductSuccessModal,
	] = useState(false);
	const [
		showEditRivalProductSuccessModal,
		setShowEditRivalProductSuccessModal,
	] = useState(false);
	const [
		showDeleteRivalProductModal,
		setShowDeleteRivalProductModal,
	] = useState(false);
	const [
		showDeleteRivalProductSuccessModal,
		setShowDeleteRivalProductSuccessModal,
	] = useState(false);

	const editProduct = useCallback(
		(id) => {
			history.push(`/produto/${id}`);
		},
		[history],
	);

	useEffect(() => {
		getProducts();
	}, [paramsProducts]);

	useEffect(() => {
		getRivalProducts();
	}, [paramsRivalProducts]);

	const getProducts = async () => {
		try {
			dispatch(setLoading(true));
			const response = await api.get(`products`, {
				params: {
					...paramsProducts,
					$limit: 12,
				},
			});

			setProducts({
				total: response.data.total,
				list: response.data.data.map((product) => {
					return {
						...product,
						onPencilClick: () => editProduct(product.id),
						onTrashClick: () => {
							setSelectedProduct(product);
							setShowDeleteProductModal(true);
						},
						menu: [
							{
								text: 'Reativar produto',
								onClick: () => {
									setSelectedProduct(product);
									setShowReactivateProductModal(true);
								},
							},
						],
					};
				}),
			});
			setAllProducts({
				total: response.data.total,
				list: response.data.data.map((product) => {
					return {
						...product,
						onPencilClick: () => editProduct(product.id),
						onTrashClick: () => {
							setSelectedProduct(product);
							setShowDeleteProductModal(true);
						},
						menu: [
							{
								text: 'Reativar produto',
								onClick: () => {
									setSelectedProduct(product);
									setShowReactivateProductModal(true);
								},
							},
						],
					};
				}),
			});
			dispatch(setLoading(false));
		} catch (error) {
			dispatch(setLoading(false));
			return error;
		}
	};

	const getRivalProducts = async () => {
		try {
			dispatch(setLoading(true));
			const response = await api.get(`rival-products`, {
				params: paramsRivalProducts,
			});

			setRivalProducts(response.data);
			dispatch(setLoading(false));
		} catch (error) {
			dispatch(setLoading(false));
			return error;
		}
	};

	const handleChangeTab = (e, newValue) => {
		switch (newValue) {
			case 0:
				setParamsProducts({
					businessUnity: null,
					disabled: 0,
				});
				break;
			case 1:
				setParamsProducts({
					businessUnity: 'Fórmulas infantis',
					disabled: 0,
				});
				break;
			case 2:
				setParamsProducts({
					businessUnity: 'Compostos lácteos',
					disabled: 0,
				});
				break;
			case 3:
				setParamsProducts({
					businessUnity: 'Mucilon',
					disabled: 0,
				});
				break;
			case 4:
				setParamsProducts({
					businessUnity: null,
					disabled: 0,
				});
				break;
			case 6:
				getRivalProducts();
				break;
			case 7:
				setParamsProducts({
					businessUnity: null,
					disabled: 1,
				});
				break;
			default:
				break;
		}

		setActiveTab(newValue);
	};

	const suspendOrReactivateProduct = async (disabled) => {
		try {
			await api.patch(`products/${selectedProduct.id}`, {
				disabled,
			});
			if (disabled) setShowDeleteProductSuccessModal(true);
			else setShowReactivateProductSuccessModal(true);
			getProducts();
		} catch (error) {
			throw error;
		}
	};

	const sendRivalProduct = async (rival) => {
		try {
			if (rival.id) {
				await api.patch(`rival-products/${rival.id}`, rival);
			} else {
				await api.post(`rival-products`, rival);
			}

			setSelectedRivalProduct({});
			setShowRivalProductSuccessModal(true);
			getRivalProducts();
		} catch (error) {
			throw error;
		}
	};

	const deleteRivalProduct = async () => {
		try {
			await api.delete(`rival-products/${selectedRivalProduct.id}`);

			setSelectedRivalProduct({});
			setShowDeleteRivalProductSuccessModal(true);
			getRivalProducts();
		} catch (error) {
			throw error;
		}
	};

	useEffect(() => {
		const objToArray = object => Object.keys(object || {}).map(key => object[key]);
		const recursive = data => {
			const response = [];
			const array = objToArray(data);
			const haveMore = array.filter(item => Object.keys(item || {}).length > 0 && typeof (item) != "string");
			if (haveMore.length > 0) {
				const arrayCopy = array;
				haveMore.map(item => {
					arrayCopy.splice(array.indexOf(item), 1);
					recursive(item).filter(item => item != null && item != undefined).map(result => response.push(result));
				});
				arrayCopy.filter(item => item != null && item != undefined).map(result => response.push(result));
			}
			else array.filter(item => item != null && item != undefined).map(item => response.push(item));
			return response;
		};

		if (searchValue) {
			const current = String(searchValue).toLocaleLowerCase();
			const newProcess = [...allProducts.list];
			const found = [];
			newProcess.map(process => {
				// all process
				const array = recursive(objToArray(process));
				const values = array.findIndex(item => String(item).toLowerCase().indexOf(current) > -1);
				if (values > -1) found.push(process);
			});
			if (found) setProducts({ list: found });
		} else setProducts({ list: allProducts.list });
	}, [searchValue]);

	return (
		<Box p={3}>
			<HeaderScreens
				titleScreen={activeTab === 6 ? 'Produtos Concorrentes' : 'Produtos'}
				dtUpdated="Adicionado em: 24/04/19 - 19:12"
				buttonText="Adicionar Produto"
				buttonOnClick={() => setShowProductModal(true)}
				currentBreadItem={mock.breadcrumbItems}
				searchPlaceholder="Pesquisar produtos..."
				searchValue={searchValue}
				searchOnChange={(e) => setSearchValue(e.target.value)}
			/>
			<NavTabs
				tabs={mock.stepsProcess}
				rightTabs={mock.rightTabs}
				activeTab={activeTab}
				onChange={handleChangeTab}
			/>
			{activeTab !== 6 ? (
				<Box py={2}>
					<Grid container spacing={2}>
						{(products.list || []).map((product) => (
							<Grid item xs={12} sm={6} md={4} lg={4} key={product.id}>
								<Card variant="product" informations={product} />
							</Grid>
						))}
					</Grid>
					<Box display="flex" justifyContent="flex-end" py={2}>
						<UIPagination
							count={Math.ceil(products.total / 12)}
							size="medium"
							onChange={(e, value) =>
								setParamsProducts((params) => {
									return {
										...params,
										$skip: (value - 1) * 12,
									};
								})
							}
						/>
					</Box>
				</Box>
			) : (
					<Box bgcolor="#fff" borderRadius="4px" marginTop="32px">
						<Box padding="16px" display="flex" justifyContent="space-between">
							<UIText
								text="Produtos concorrentes"
								color="#565656"
								fontWeight="500"
							/>
							<UIPagination
								count={Math.ceil(rivalProducts.total / 10)}
								size="medium"
								onChange={(e, value) =>
									setParamsRivalProducts((params) => {
										return {
											...params,
											$skip: (value - 1) * 10,
										};
									})
								}
							/>
						</Box>
						<Divider />
						<Box padding="16px">
							<Table>
								<TableHead>
									<TableRow>
										{mock.tableHeaders.map((header, index) => (
											<TableCell color={header.text} width={header.width}>
												{header.text}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{rivalProducts.data.map((row) => (
										<TableRow key={row.id}>
											<TableCell>
												<IconButton
													size="small"
													onClick={() => {
														setSelectedRivalProduct(row);
														setShowRivalProductModal(true);
													}}
												>
													<TiPencil color="var(--text-grey)" size={18} />
												</IconButton>
												<IconButton
													size="small"
													onClick={() => {
														setSelectedRivalProduct(row);
														setShowDeleteRivalProductModal(true);
													}}
												>
													<BiTrash color="var(--text-grey)" size={18} />
												</IconButton>
											</TableCell>
											<TableCell>{row.description}</TableCell>
											<TableCell>{row.company}</TableCell>
											<TableCell>{row.grammage || '-'}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Box>
				)}
			<AddProductModal
				show={showAddProductModal}
				onClose={() => setShowProductModal(false)}
				actionRival={() => {
					setActiveTab(6);
					setShowProductModal(false);
					setShowRivalProductModal(true);
				}}
			/>
			<Modal
				variant="error"
				open={showDeleteProductModal}
				onClose={() => setShowDeleteProductModal(false)}
				title="Deseja suspender o produto?"
				subtitle="Não será mais possível inserir o produto em novos processos"
				buttonCancelAction={() => setShowDeleteProductModal(false)}
				buttonConfirmAction={() => suspendOrReactivateProduct(true)}
			/>

			<Modal
				variant="success"
				open={showDeleteProductSuccessModal}
				onClose={() => {
					setShowDeleteProductModal(false);
					setShowDeleteProductSuccessModal(false);
				}}
				title="Suspensão efetuada com sucesso"
				buttonText="Voltar a tela inicial"
				buttonAction={() => {
					setShowDeleteProductModal(false);
					setShowDeleteProductSuccessModal(false);
				}}
			/>
			<Modal
				variant="error"
				open={showDeleteRivalProductModal}
				onClose={() => setShowDeleteProductModal(false)}
				title="Deseja excluir o produto?"
				subtitle="Não será mais possível inserir o produto em novos processos"
				buttonCancelAction={() => setShowDeleteProductModal(false)}
				buttonConfirmAction={() => deleteRivalProduct()}
			/>
			<Modal
				variant="success"
				open={showDeleteRivalProductSuccessModal}
				onClose={() => {
					setShowDeleteProductModal(false);
					setShowDeleteProductSuccessModal(false);
				}}
				title="Exclusão efetuada com sucesso"
				buttonText="Voltar a tela inicial"
				buttonAction={() => {
					setShowDeleteRivalProductModal(false);
					setShowDeleteRivalProductSuccessModal(false);
				}}
			/>

			<Modal
				variant="error"
				colorIcon="#C4C4C4"
				open={showReactivateProductModal}
				onClose={() => setShowReactivateProductModal(false)}
				title="Deseja reativar o produto ao sistema?"
				buttonConfirmText="Reativar"
				buttonCancelAction={() => setShowReactivateProductModal(false)}
				buttonConfirmAction={() => suspendOrReactivateProduct(false)}
			/>

			<Modal
				variant="success"
				open={showReactivateProductSuccessModal}
				onClose={() => {
					setShowReactivateProductModal(false);
					setShowReactivateProductSuccessModal(false);
				}}
				title="Produto reativado com sucesso"
				buttonText="Voltar a tela inicial"
				buttonAction={() => {
					setShowReactivateProductModal(false);
					setShowReactivateProductSuccessModal(false);
				}}
			/>
			<RivalProductModal
				show={showRivalProductModal}
				onClose={() => {
					setShowRivalProductModal(false);
					setSelectedRivalProduct({});
				}}
				onConfirm={(rival) => sendRivalProduct(rival)}
				product={selectedRivalProduct}
			/>
			<Modal
				variant="success"
				open={showEditRivalProductSuccessModal}
				onClose={() => {
					setShowEditRivalProductSuccessModal(false);
					setShowRivalProductModal(false);
				}}
				title="Alteração feita com sucesso"
				buttonText="Voltar aos produtos"
				buttonAction={() => {
					setShowEditRivalProductSuccessModal(false);
					setShowRivalProductModal(false);
				}}
			/>
			<Modal
				variant="success"
				open={showRivalProductSuccessModal}
				onClose={() => {
					setShowRivalProductSuccessModal(false);
					setShowRivalProductModal(false);
				}}
				title="Produto concorrente
				adicionado com sucesso"
				buttonText="Voltar aos produtos"
				buttonAction={() => {
					setShowRivalProductSuccessModal(false);
					setShowRivalProductModal(false);
				}}
			/>
		</Box>
	);
}
