import produce from 'immer';

export const initialState = {
	modalNewProcess: false,
	process: {
		institution: {},
		employee: {},
		distributor: {},
	},
	order : []
};

export default function process(state = initialState, action) {
	return produce(state, (draft) => {
		switch (action.type) {
			case '@process/MODAL_NEW_PROCESS':
				draft.modalNewProcess = action.payload.trigger;
				break;
			case '@process/SET_PROCESS':
				draft.process = action.payload;
				break;
			case '@process/UPDATE':
				draft.process = action.payload;
				break;
			case '@process/SET_ORDER':
				draft.order = action.payload;
				break;
			default:
				return state;
		}
	});
}
