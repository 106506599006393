import React from 'react';

import { Grid, Divider } from '@material-ui/core';

import {
	CardContainer,
	PaddingGrid,
} from 'components/Cards/ProductCard/styles';

import UITag from 'components/UI/Tag/index';
import UIText from 'components/UI/Text/Text';

import { MdMoreVert } from 'react-icons/md';

export default function DisabledProductCard({ informations }) {
	/*
		template de informations = {
			tag: {
				text,
				variant,
				width,
				height,
				size
			},
			productName,
			sku,
			price,
			gramar,
			utilCount,
			onMenuClick,
		}
	*/

	return (
		<CardContainer
			borderColor="var(--text-dark)"
			container
			direction="column"
			justify="space-around"
			alignItems="flex-start"
		>
			<PaddingGrid
				padding=".5rem"
				container
				direction="column"
				justify="center"
				alignItems="flex-start"
			>
				<Grid
					container
					direction="row"
					justify="space-between"
					alignItems="center"
				>
					<UITag {...informations.tag} variant="disabled" />
					<Grid item>
						<MdMoreVert
							color="var(--text-grey)"
							size={18}
							onClick={informations.onMenuClick}
						/>
					</Grid>
				</Grid>
				<PaddingGrid padding=".5rem" item xs={12}>
					<UIText
						text={informations.productName}
						fontWeight="500"
						fontSize="16px"
					/>
				</PaddingGrid>
				<Grid
					container
					direction="row"
					justify="space-between"
					alignItems="center"
				>
					<Grid item xs={4}>
						<UIText
							text="SKU"
							textcolor="var(--text-grey)"
							fontSize="12px"
							fontWeight="400"
							textalign="center"
						/>
						<UIText
							text={informations.sku}
							textcolor="var(--text-dark)"
							fontSize="12px"
							fontWeight="400"
							textalign="center"
						/>
					</Grid>
					<Grid xs={4} item>
						<UIText
							text="(R$)Preço lista"
							textcolor="var(--text-grey)"
							fontSize="12px"
							fontWeight="400"
							textalign="center"
						/>
						<UIText
							text={informations.price}
							textcolor="var(--text-dark)"
							fontSize="12px"
							fontWeight="400"
							textalign="center"
						/>
					</Grid>
					<Grid xs={4} item>
						<UIText
							text="Gramatura"
							textcolor="var(--text-grey)"
							fontSize="12px"
							fontWeight="400"
							textalign="center"
						/>
						<UIText
							text={informations.gramar}
							textcolor="var(--text-dark)"
							fontSize="12px"
							fontWeight="400"
							textalign="center"
						/>
					</Grid>
				</Grid>
			</PaddingGrid>
			<Grid
				container
				direction="row"
				justify="flex-start"
				// alignItems="center"
			>
				<Grid item xs={12}>
					<Divider />
				</Grid>
			</Grid>
			<PaddingGrid
				padding=".5rem"
				container
				alignItems="flex-start"
				justify="flex-start"
			>
				<UIText
					text="Utilizado em: &nbsp;"
					textcolor="var(--text-grey)"
					fontWeight="500"
					fontSize="12px"
				/>
				<UIText
					text={informations.utilCount}
					textcolor="var(--text-dark)"
					fontWeight="500"
					fontSize="12px"
				/>
			</PaddingGrid>
		</CardContainer>
	);
}
