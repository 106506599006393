export function setProcessProducts(products) {
	return {
		type: '@processProduct/SET_ALL',
		payload: products,
	};
}

export function getProcessProducts(payload) {
	return {
		type: '@processProduct/GET',
		payload: payload,
	};
}

export function createProcessProduct(data) {
	return {
		type: '@processProduct/CREATE',
		payload: data,
	};
}

export function updateProcessProduct(data) {
	return {
		type: '@processProduct/UPDATE',
		payload: data,
	};
}

export function removeProcessProduct(data) {
	return {
		type: '@processProduct/REMOVE',
		payload: data,
	};
}
