import React, { useEffect, useState } from 'react';

import { Divider, Grid } from '@material-ui/core';
import { store } from 'store';

import {
	Container,
	StyledP,
	StyledSpan,
	PendenciesTitle,
	PendenciesHeader,
	Pendencie,
	PendencieTableBody
} from './style';
import { useSelector } from 'react-redux';

export default function TablePendencies({ pendencies, height }) {

	return (
		<Container elevation={0} height={height}>
			<Grid container direction="column">
				<PendenciesTitle container direction="row" alignItems="center">
					<Grid item xs={3}>
						<StyledP> Pendecias </StyledP>
					</Grid>
					<Grid item xs={5}>
						<StyledSpan color="grey" fontSize="12px">
							{pendencies ? pendencies.length : null}
							{pendencies && pendencies.length > 1
								? ' Pendencias'
								: ' Pendencia'}{' '}
							encontradas
						</StyledSpan>
					</Grid>
				</PendenciesTitle>

				<Divider />

				<PendenciesHeader
					container
					direction="row"
					justify="space-between"
					alignItems="center"
				>
					<Grid item xs={4}>
						<StyledP color="grey"> Pendecias </StyledP>
					</Grid>
					<Grid item xs={4}>
						<StyledSpan color="grey"> Vencimento </StyledSpan>
					</Grid>
				</PendenciesHeader>
				<Divider />
				<PendencieTableBody>
					{(pendencies || []).map((pendencie, index) => {
						return (
							<div key={index}>
								<Pendencie
									container
									direction="row"
									justify="space-between"
									alignItems="center"
									status={pendencie.status}
								>
									<Grid item xs={7}>
										<StyledP>{pendencie.text}</StyledP>
									</Grid>
									<Grid item xs={4}>
										<StyledSpan>
											{new Date(pendencie.createdAt).toLocaleDateString()}
										</StyledSpan>
									</Grid>
								</Pendencie>
								<Divider />
							</div>
						);
					})}
				</PendencieTableBody>
			</Grid>
		</Container>
	);
}
