import { Box, Divider, Grid } from '@material-ui/core';
import styled from 'styled-components';

const colors = {
	grey: '#C4C4C4',
	blue: '#5082C9',
	dark: '#565656',
};

export const StyledSpan = styled.span`
	color: ${(props) =>
		props.color && props.color ? colors[props.color] : 'var(--text-dark)'};
	font-size: ${(props) => props.fontSize ?? '14px'};
	margin: ${(props) => props.margin ?? '0'};
	font-weight: ${(props) => props.fontWeight};
`;

export const DarkBox = styled(Box)`
	background-color: #eaeef7;
`;

export const StyledP = styled.p`
	font-size: ${(props) => props.fontSize ?? '14px'};
	margin: ${(props) => props.margin ?? '0'};
	color: ${(props) =>
		props.color && props.color ? colors[props.color] : 'var(--text-dark)'};
`;

export const TinyInfo = styled(Grid)`
	margin-top: ${(props) => props['lg-mt'] ?? '0'}!important;
	@media (max-width: 960px) {
		margin-top: ${(props) => props['xs-mt'] ?? '0'}!important;
	}
`;

export const StyledLine = styled(Divider)`
	height: 3px;
	width: 20px;
	background: var(--primary);
	margin-top: 3px;
`;
