import { withStyles } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';

export const TableRowStyled = withStyles({
	root: {
		border: (props) => props.border,
		borderTop: (props) => props.bordertop,
		borderBottom: (props) => props.borderbottom,
		borderLeft: (props) => props.borderleft,
		borderRight: (props) => props.borderright,
	},
})(TableRow);
