import React from 'react';

import PropTypes from 'prop-types';

import './styles.css';

export default function VerticalLine({ color, isVisible }) {
	return (
		<svg
			className={`VerticalLine ${isVisible}`}
			width="40"
			height="100"
			viewBox="0 0 40 120"
		>
			<line x1="16" y1="0" x2="16" y2="120" stroke={color} strokeWidth="2" />
		</svg>
	);
}

VerticalLine.propTypes = {
	color: PropTypes.string.isRequired,
	isVisible: PropTypes.string.isRequired,
};
