import React from 'react';
import { Cell, MenuIcon, Text, ItemMenu } from './style';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';

function TableCellMenu(props) {
	const {
		children,
		background,
		fontSize,
		fontWeight,
		lineheight,
		color,
		bordertop,
		borderbottom,
		borderleft,
		borderright,
		textdecorationline,
		cursor,
		onClick,
		height,
		padding,
		colorIcon,
		menuItems,
		row,
	} = props;

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Cell
				background={background}
				fontSize={fontSize}
				fontWeight={fontWeight}
				lineheight={lineheight}
				color={color}
				bordertop={bordertop}
				borderbottom={borderbottom}
				borderleft={borderleft}
				borderright={borderright}
				textdecorationline={textdecorationline}
				cursor={cursor}
				onClick={onClick}
				height={height}
				padding={padding}
			>
				<IconButton
					size="small"
					aria-controls="simple-menu"
					aria-haspopup="true"
					onClick={handleClick}
				>
					<MenuIcon color={colorIcon} />
				</IconButton>
				<Text>{children}</Text>
			</Cell>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{(menuItems || []).map((item, index) => (
					<ItemMenu
						key={index}
						onClick={() => item.onClick(row)}
						color={item.color}
					>
						{item.text}
					</ItemMenu>
				))}
			</Menu>
		</>
	);
}

export default TableCellMenu;

TableCellMenu.propTypes = {
	children: PropTypes.any,
	background: PropTypes.string,
	fontSize: PropTypes.string,
	fontWeight: PropTypes.string,
	lineheight: PropTypes.string,
	color: PropTypes.string,
	bordertop: PropTypes.string,
	borderbottom: PropTypes.string,
	borderleft: PropTypes.string,
	borderright: PropTypes.string,
	textdecorationline: PropTypes.string,
	cursor: PropTypes.string,
	onClick: PropTypes.func,
	height: PropTypes.string,
	padding: PropTypes.string,
	colorIcon: PropTypes.string,
	menuItems: PropTypes.arrayOf(
		PropTypes.shape({
			onClick: PropTypes.func,
			color: PropTypes.string,
			text: PropTypes.string,
		}),
	),
};

TableCellMenu.defaultProps = {
	background: '#FFFFFF',
	fontSize: '12px',
	fontWeight: '500',
	lineheight: '14px',
	color: 'var(--text-dark)',
	bordertop: 'none',
	borderbottom: 'none',
	borderleft: 'none',
	borderright: 'none',
	textdecorationline: 'none',
	cursor: 'auto',
	onClick: () => {},
	height: '36px',
	padding: '0px 16px',
	colorIcon: 'var(--text-grey)',
	menuItems: [{ onClick: () => {}, color: 'var(--text-dark)', text: '' }],
};
