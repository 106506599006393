import {
	Divider,
	InputBase,
	Paper,
	Select,
	withStyles,
} from '@material-ui/core';

export const StyledPaper = withStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
		height: '40px',
		padding: '0px 4px',
	},
})(Paper);

export const StyledInputBase = withStyles({
	root: {
		fontSize: '14px',
	},
})(InputBase);

export const StyledDivider = withStyles({
	root: {
		height: '40px',
		marginRight: '15px',
	},
})(Divider);

export const StyledSelect = withStyles({
	root: {
		fontSize: '12px',
	},
	icon: {
		width: '30px',
		height: '21px',
		color: 'var(--text-grey)',
	},
})(Select);
