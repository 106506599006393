import styled from 'styled-components';

import { Grid, Paper } from '@material-ui/core';

const colors = {
	grey: '#C4C4C4',
	blue: '#5082C9',
	red: '#C95050',
};

export const Container = styled(Paper)`
	overflow: auto;
	height: ${(props) => props.height || '231px'};

	margin-left: 2em;

	@media (max-width: 960px) {
		margin-left: 0px;
		margin-top: 2em;
	}

	::-webkit-scrollbar {
		width: 5px;
	}
	::-webkit-scrollbar-track {
		border-radius: 3px;
	}
	::-webkit-scrollbar-thumb {
		background: #c4c4c4;
		border-radius: 3px;
	}
`;

export const StyledSpan = styled.span`
	color: ${(props) => (props.color && props.color ? colors[props.color] : '')};
	font-size: ${(props) => props.fontSize ?? '14px'};
`;

export const StyledP = styled.p`
	font-size: ${(props) => props.fontSize ?? '14px'};
	margin: ${(props) => props.margin ?? '0'};
	color: ${(props) => (props.color && props.color ? colors[props.color] : '')};
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

export const PendenciesTitle = styled(Grid)`
	padding-left: 16px;
	padding-right: 16px;
	height: 46px;
`;

export const PendenciesHeader = styled(Grid)`
	padding-left: 16px;
	padding-right: 16px;
	background-color: rgba(196, 196, 196, 0.1);
	height: 46px;
`;

export const Pendencie = styled(Grid)`
	height: 46px;
	.MuiGrid-item {
		padding-left: 16px;
		padding-right: 16px;
	}
	color: ${(props) =>
		props.status === 'alert' || props.status === 'warning' ? colors.red : ''};
`;

export const PendencieTableBody = styled.div`
	display: flex;
	flex-direction: column-reverse;
`;
