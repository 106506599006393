import { withStyles, Paper } from '@material-ui/core';
import { PaginationItem } from '@material-ui/lab';

export const StyledPaper = withStyles({
	root: {
		borderLeft: '4px solid var(--primary)',
		minHeight: '120px',
		padding: '15px 30px',
	},
})(Paper);

export const StyledPaginationItem = withStyles({
	root: {
		fontSize: '12px',
		minWidth: '18px',
		height: '18px',
		margin: '0px',
		padding: '0px',
	},
})(PaginationItem);
