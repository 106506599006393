import { withStyles, Breadcrumbs } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
	color: ${(props) => props.color ?? 'inherit'};
	font-size: '14px';
	text-decoration: none;
`;
export const StyledBreadcrumbs = withStyles({
	root: {
		marginBottom: '20px',
		color: (props) => props.color || 'inherit',
		marginTop: '20px',
	},
})(Breadcrumbs);
