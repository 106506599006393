import { Box, Grid, Menu, withStyles } from '@material-ui/core';
import styled from 'styled-components';

export const StyledMenu = styled(Menu)`
.MuiMenu-paper{
	padding-right: 1rem;
	background: none;
	outline: none;
	overflow: hidden;
}
&:focus{
	border: none;
	outline: none;
}
.MuiGrid-root:focus{
	border: none;
	outline: none;
}
`;

export const MenuHeader = withStyles({
	root: {
		background: '#1D56A7',
		borderTopLeftRadius: '10px',
		borderTopRightRadius: '10px',
	}
})(Box);

export const TopIcon = withStyles({
	root: {
		width: 0,
		height: 0,
		borderLeft: '12px solid transparent',
		borderRight: '12px solid transparent',
		borderBottom: '12px solid #1D56A7',
	}
})(Box);

export const IconBackground = withStyles({
	root: {
		background: 'rgba(80,130,201,0.16)',
		borderRadius: '4px',
	}
})(Box);

export const VerticalDivider = withStyles({
	root: {
		width: '1px',
		display: 'flex',
		height: '55px',
		background: 'var(--text-grey)',
		borderLeft: ' 1px solid var(--text-grey)',
		borderRight: ' 1px solid var(--text-grey)',
	}
})(Box);

export const MenuItems = withStyles({
	root: {
		background: '#fff',
	}
})(Grid);

export const StyledMenuItem = withStyles({
	root: {
		cursor: 'pointer',
	}
})(Grid);

export const ScrollContainer = styled(Grid)`
	min-height: 100px;
	max-height: 200px;
	overflow-x: hidden;
	overflow-y: scroll;
	display: flex;
	flex-direction: column-reverse;
	::-webkit-scrollbar {
		width: 5px;
	}
	::-webkit-scrollbar-track {
		border-radius: 3px;
	}
	::-webkit-scrollbar-thumb {
		background: #c4c4c4;
		border-radius: 3px;
	}
`;

export const Ball = withStyles({
	root: {
		width: '10px',
		height: '10px',
		visibility: props => props.visible ? 'visible' : 'hidden',
		background: 'var(--error)',
		border: '2px solid #FFF',
		borderRadius: '15px',
		position: 'absolute',
		right: 10,
		top: 10,
	}
})(Box);
