import styled from 'styled-components';

export const UploadArea = styled.div`
	height: ${(props) => props.height};
	background: #fff;
	margin: 0;
	padding: 15px 0px;
	overflow: auto;

	::-webkit-scrollbar {
		width: 5px;
	}
	::-webkit-scrollbar-track {
		border-radius: 3px;
	}
	::-webkit-scrollbar-thumb {
		background: #c4c4c4;
		border-radius: 3px;
	}
`;
