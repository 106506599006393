import { Box, Dialog, IconButton } from '@material-ui/core'
import { IoMdCloseCircle } from 'react-icons/io';
import { useHistory } from 'react-router';

import { Title, Text, Card } from './style'

import Person from '../../../../assets/images/person.svg';
import Building from '../../../../assets/images/building.svg';

export default function AddUserModal({show, onClose}) {
  const history = useHistory();

  return (
    <Dialog open={show} onClose={onClose}>
      <Box
        css={{bgcolor: '#fff'}}
        boxShadow="0px 0px 8px rgba(0, 0, 0, 0.16)"
        textAlign="center"
        paddingTop="48px"
        paddingBottom="55px"
        paddingX="80px"
      >
        <Box position="absolute" right="24px" top="40px">
          <IconButton size="medium" onClick={onClose}>
            <IoMdCloseCircle
              color="var(--text-grey)"
              size={25}
            />
          </IconButton>
        </Box>
        <Title>
          Adicionar Usuário
        </Title>
        <Text>
          Escolha entre adicionar usuários internos ou distribuidores
        </Text>
        <Box display="flex" justifyContent="space-between" marginTop="22px">
          <Card
            title="Usuários internos"
            image={Person}
            onClick={() => history.push('/usuario/novo')}
          />
          <Card
            title="Distribuidores"
            image={Building}
            onClick={() => history.push('/distribuidor/novo')}
          />
        </Box>
      </Box>
    </Dialog>
  )
}

