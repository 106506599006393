export const breadcrumbItems = [
	{
		text: "Dashboard",
		href: "/"
	},
	{
		text: "Processos",
		href: "/processo"
	}
];
