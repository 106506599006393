import React, { useEffect, useState, forwardRef } from 'react';
import { Grid, Box } from '@material-ui/core';

import CardPreviewFile from 'components/File/PreviewFile';
import UIButton from 'components/UI/Button/Button';
import ModalFile from 'components/File/ModalFile/index';
import ValidateInfosResult from '../components/Card/ValidateInfosResult';

import api from 'services/api';
import { useHistory, useParams } from 'react-router';
import CardOCRFields from '../components/Card/OCRFields';
import MaterialTable from 'material-table';
import { CsvToHtmlTable } from 'react-csv-to-table';
import { CsvTable } from './style';
import Axios from 'axios';
import {
	AddBox,
	ArrowDownward,
	Check,
	ChevronLeft,
	ChevronRight,
	Clear,
	DeleteOutline,
	Edit,
	FilterList,
	FirstPage,
	LastPage,
	Remove,
	SaveAlt,
	Search,
	ViewColumn,
} from '@material-ui/icons';

export default function Result() {
	const tableIcons = {
		Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
		Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
		Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
		Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
		DetailPanel: forwardRef((props, ref) => (
			<ChevronRight {...props} ref={ref} />
		)),
		Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
		Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
		Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
		FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
		LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
		NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
		PreviousPage: forwardRef((props, ref) => (
			<ChevronLeft {...props} ref={ref} />
		)),
		ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
		Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
		SortArrow: forwardRef((props, ref) => (
			<ArrowDownward {...props} ref={ref} />
		)),
		ThirdStateCheck: forwardRef((props, ref) => (
			<Remove {...props} ref={ref} />
		)),
		ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
	};

	const { idProcess } = useParams();
	const history = useHistory();

	const [noticeURL, setNoticeURL] = useState('');
	const [ocr, setOcr] = useState('');
	const [submit, setSubmit] = useState(false);
	const [headers, setHeaders] = useState([]);
	const [dataRows, setDataRows] = useState([]);
	const [csv, setCsv] = useState([]);
	const [tryAgain, setTryAgain] = useState(false);
	const [process, setProcess] = useState({});

	useEffect(() => {
		async function getProcess() {
			const response = await api.get(`process/${idProcess}`);

			if (response.status === 200) {
				setProcess(response.data);
			}
		}

		async function getNoticeURL() {
			try {
				const response = await api.get(`attachments`, {
					params: {
						category: 'Ata',
						tableId: idProcess,
						table: 'process',
					},
				});

				setNoticeURL(response.data.data[0].key);
				getOcr(response.data.data[0].id);
			} catch (error) {
				return null;
			}
		}

		getProcess();
		getNoticeURL();
	}, [noticeURL]);

	useEffect(() => {
		tryGetCsvData();
	}, [ocr]);

	useEffect(() => {
		if (csv && csv[0] && csv.length === ocr.csvs.length) {
			transformCsvData();
		}
	}, [csv, tryAgain]);

	const tryGetCsvData = async () => {
		if (ocr && ocr.csvs && ocr.csvs[0]) {
			let responses = [];

			ocr.csvs.map(async (url, index) => {
				const data = await Axios.get(url);
				responses.push(data.data);
				setCsv(responses);
			});
		}
	};

	const transformCsvData = () => {
		console.log('comecou');
		let tempCsvData = [];
		let maxIndex = 0;
		let materialHeaders = [];
		let maxLengthArray;

		csv.map((data) => {
			let rows = data.split('\n');

			let splitContent = [];
			rows.map((row) => {
				splitContent = row.split(' ,');
				if (splitContent.length > maxIndex) {
					maxIndex = splitContent.length;
					maxLengthArray = splitContent;
				}
			});

			let tableData = [];
			rows.map((row, index) => {
				let tempData = {};
				splitContent = row.split(' ,');
				if (splitContent.length > 0) {
					splitContent.map((content, column) => {
						tempData[`Coluna ${column}`] = content;
					});
					tableData.push(tempData);
					tempCsvData.push(tempData);
				}
			});

			return materialHeaders;
		});
		for (let i = 0; i < maxIndex; i++) {
			materialHeaders.push({
				title: maxLengthArray[i],
				field: `Coluna ${i}`,
				type: 'string',
				width: 50,
			});
		}
		setHeaders(materialHeaders);
		setDataRows(tempCsvData);
	};

	const getOcr = async (attachmentId) => {
		try {
			const response = await api.get(`ocr/${attachmentId}/products`);
			if (response.status === 200) setOcr(response.data);
		} catch (e) {
			setOcr({ status: false });
		}
	};

	const [modalFileController, setModalFileController] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);

	return (
		<Box pt={3}>
			<Grid container spacing={3}>
				<Grid item xs={12} md={4}>
					<CardPreviewFile
						urlFile={noticeURL}
						onZoomClick={() => setModalFileController(!modalFileController)}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<CardOCRFields />
				</Grid>
				<Grid item xs={12} md={4}>
					<ValidateInfosResult submit={submit} process={process} />
				</Grid>
				<Grid item xs={12}>
					{/* <CsvTable>
						<CsvToHtmlTable csvDelimiter=" ," data={csv} />
					</CsvTable> */}
					<UIButton
						onClick={() => setTryAgain(!tryAgain)}
						text="forcar carregamento da tabela"
					/>
					<MaterialTable icons={tableIcons} columns={headers} data={dataRows} />
				</Grid>
			</Grid>
			<Grid container justify="flex-end" spacing={3}>
				<Grid item xs={12} md={4} lg={2}>
					<Box pt={3}>
						<UIButton
							text="Voltar"
							fullWidth
							variant="outlined"
							color="secondary"
							textcolor="var(--text-grey)"
							onClick={() => history.push(`/processo/${idProcess}`)}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={4} lg={2}>
					<Box pt={3}>
						<UIButton
							text="Concluir"
							fullWidth
							onClick={() => setSubmit(true)}
						/>
					</Box>
				</Grid>
			</Grid>
			<ModalFile
				urlFile={noticeURL}
				isVisible={modalFileController}
				onClose={() => setModalFileController(false)}
				currentPage={currentPage}
			/>
		</Box>
	);
}
