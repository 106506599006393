import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid } from '@material-ui/core';

import CardNoticeFields from 'screens/Process/components/Card/NoticeFields';
import CardOCRFields from 'screens/Process/components/Card/OCRFields';
import UIButton from 'components/UI/Button/Button';
import CardPreviewFile from 'components/File/PreviewFile';
import ModalFile from 'components/File/ModalFile';

import api from 'services/api';

export default function NoticeAnalysis() {
	const dispatch = useDispatch();

	const processDefault = useSelector((state) => state.process.process);

	const [processForm, setProcessForm] = useState(processDefault);
	const [noticeURL, setNoticeURL] = useState('');
	const [ocr, setOcr] = useState({});
	const [submit, setSubmit] = useState(false);

	useEffect(() => {
		if (processForm.id) {
			getNoticeURL();
		}
	}, []);

	const getNoticeURL = async () => {
		try {
			const response = await api.get(`attachments`, {
				params: {
					category: 'Edital',
					tableId: processForm.id,
					table: 'process',
				},
			});
			if (response.status === 200) {
				const { key, id } = response.data.data[0];
				setNoticeURL(key);
				getOcr(id);
			}
		} catch (error) {
			return null;
		}
	};
	const getOcr = async (attachmentId) => {
		try {
			const response = await api.get(`ocr/${attachmentId}`);
			if (response.status === 200) setOcr(response.data);
		} catch (e) {
			setOcr({status : false })
		}

	};

	const eventGoBack = () => {
		const data = {
			id: processForm.id,
			step: 'proposal_summary',
		};
		updateProcess(data);
	};

	const updateProcess = async (process) => {
		dispatch({ type: '@process/UPDATE', payload: process });
	};

	const [modalFileController, setModalFileController] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);

	return (
		<Box pt={3}>
			<Grid container spacing={3}>
				<Grid item xs={12} md={4} id="file">
					<CardPreviewFile
						urlFile={noticeURL}
						onZoomClick={() => setModalFileController(!modalFileController)}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<CardOCRFields ocr={ocr} />
				</Grid>
				<Grid item xs={12} md={4}>
					<CardNoticeFields
						onChangeProcess={(process) => setProcessForm(process)}
						process={processForm}
						submit={submit}
					/>
				</Grid>
			</Grid>
			<Grid container justify="flex-end" spacing={3}>
				<Grid item xs={12} md={4} lg={2}>
					<Box pt={3}>
						<UIButton
							text="Voltar"
							fullWidth
							variant="outlined"
							color="secondary"
							textcolor="var(--text-grey)"
							onClick={() => eventGoBack()}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={4} lg={2}>
					<Box pt={3}>
						<UIButton
							text="Avançar"
							fullWidth
							onClick={() => setSubmit(true)}
						/>
					</Box>
				</Grid>
			</Grid>
			<ModalFile
				urlFile={noticeURL}
				isVisible={modalFileController}
				onClose={() => setModalFileController(false)}
				currentPage={currentPage}
			/>
		</Box>
	);
}
