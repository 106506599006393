export const serviceChannelOptions = [
	{ text: 'Hospital', value: 'Hospital' },
	{ text: 'Hospital maternidade', value: 'Hospital maternidade' },
	{ text: 'Programa social', value: 'Programa social' },
	{ text: 'Creche', value: 'Creche' },
];

export const yesNoOptions = [
	{ label: 'Sim', value: true },
	{ label: 'Não', value: false },
];

export const formParticipationOptions = [
	{ label: 'Direta', value: true },
	{ label: 'Indireta', value: false },
];
