import React from 'react';

import {
	FormControl,
	FormHelperText,
	Radio,
	RadioGroup,
} from '@material-ui/core';

import { StyledFormControlLabel, StyledInputLabel } from './style';

function UIRadio({
	label,
	value,
	name,
	onChange,
	items,
	disabled,
	color,
	error,
	fontSize,
	sizelabel,
	helperText,
}) {
	return (
		<FormControl fullWidth disabled={disabled} error={error ? true : false}>
			<StyledInputLabel shrink color={color} fontSize={fontSize}>
				{label}
			</StyledInputLabel>
			<RadioGroup row value={value} onChange={onChange}>
				{items.map((item, i) => (
					<StyledFormControlLabel
						value={item.value}
						control={<Radio color="primary" size="small" />}
						label={item.label}
						key={`radio-${i}`}
						name={name}
						sizelabel={sizelabel}
					/>
				))}
			</RadioGroup>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
}

export default UIRadio;
