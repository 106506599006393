import { Box, Dialog, IconButton } from '@material-ui/core';
import { IoMdCloseCircle } from 'react-icons/io';

import { Title, Text, Card } from './style';

import Persons from 'assets/images/persons.svg';
import Building from 'assets/images/building.svg';
import GenerateReportModal from '../GenerateReportModal';
import { useState } from 'react';

export default function DefineReportModal({ show, onClose }) {
	const [generateReport, setGenerateReport] = useState(false);
	const [typeReport, setTypeReport] = useState('');

	const handleGenerateReport = (type) => {
		setGenerateReport(!generateReport);
		setTypeReport(type);
	};

	return (
		<>
			<Dialog open={show} onClose={onClose}>
				<Box
					css={{ bgcolor: '#fff' }}
					boxShadow="0px 0px 8px rgba(0, 0, 0, 0.16)"
					textAlign="center"
					paddingTop="30px"
					paddingBottom="50px"
					paddingX="80px"
				>
					<Box position="absolute" right="24px" top="40px">
						<IconButton size="medium" onClick={onClose}>
							<IoMdCloseCircle color="var(--text-grey)" size={25} />
						</IconButton>
					</Box>
					<Title>Definir relatório</Title>
					<Text>Escolha o tipo de relatório que deseja gerar</Text>
					<Box
						display="flex"
						justifyContent="space-between"
						marginTop="22px"
						width="380px"
					>
						<Card
							title="Performance de vendas"
							image={Persons}
							onClick={() => handleGenerateReport('order')}
						/>
						<Card
							title="Controle PNE"
							image={Building}
							onClick={() => handleGenerateReport('pne control')}
						/>
					</Box>
				</Box>
			</Dialog>
			<GenerateReportModal
				show={generateReport}
				onClose={() => {
					onClose();
					handleGenerateReport();
				}}
				type={typeReport}
			/>
		</>
	);
}
