import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import logo from 'assets/images/logoNestle.svg';

import {
	Button,
	Checkbox,
	Container,
	FormControlLabel,
	Grid,
	Paper,
	TextField,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import { FormContainer, ForgotPassword } from './style';

import { store } from 'store';
import { useDispatch } from 'react-redux';
import { updatePicture } from 'store/modules/user/actions';

export default function Login() {
	const dispatch = useDispatch();
	const { access_token } = store.getState().auth;
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	useEffect(() => {
		dispatch(updatePicture(null));
	}, []);

	if (access_token) {
		return <Redirect to="/" />;
	}

	function handleSubmit(event) {
		event.preventDefault();
		dispatch({
			type: '@auth/LOGIN',
			payload: {
				email,
				password,
			},
		});
	}

	return (
		<Container maxWidth="xl">
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
				style={{ height: '100vh' }}
			>
				<Paper elevation={0} style={{ width: '406px', height: '460px' }}>
					<FormContainer onSubmit={(e) => handleSubmit(e)}>
						<Grid
							container
							direction="column"
							justify="center"
							style={{ width: '358px', margin: '24px' }}
						>
							<h1> Vendas Institucionais </h1>
							<p>
								{' '}
								Bem-vindo a Plataforma de Análise <br /> de processos{' '}
							</p>
							<TextField
								label="Email"
								name="email"
								type="email"
								onChange={(e) => setEmail(e.target.value)}
								value={email}
								required
							/>
							<TextField
								label="password"
								name="password"
								type="password"
								onChange={(e) => setPassword(e.target.value)}
								required
							/>
							<ForgotPassword
								container
								direction="row"
								justify="space-between"
								alignItems="center"
							>
								<FormControlLabel
									control={<Checkbox name="stay" color="primary" />}
									label="Mantenha-me conectado"
								/>
								<Link to="/esqueceu">Esqueceu a senha?</Link>
							</ForgotPassword>
							<Button variant="contained" color="primary" type="submit">
								Entrar
							</Button>
							<img src={logo} alt="" />
						</Grid>
					</FormContainer>
				</Paper>
			</Grid>
		</Container>
	);
}
