import React, { useEffect, useState } from 'react';
import Modal from 'components/Modals';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { Error, Specification, StyledP, StyledSpan } from './style';

import UITextarea from 'components/UI/Textarea/Textarea';
import UIInput from 'components/UI/Input/Input';

import Accordion from 'components/Process/Accordion';

import MainModal from 'components/Modals/MainModal';

import UISelect from 'components/UI/Select/Select';
import UploadArea from 'components/Upload/UploadArea/UploadArea';
import api from 'services/api';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';

import E2O from '../E2O';
import { isInteger } from 'formik';

/**
 * Order Modal
 * @param {boolean} consultant - Is consultant or distributor
 * @param {boolean} show - Boolean show modal
 * @param {string} [width=normal] - normal, medium, small
 * @param {string} title - Modal Title
 * @param {string} buttonText - If have this prop will add a button before close icon.
 * @param {function} titleButtonClick - Title button function
 * @param {component} children - HTML
 * @param {String} confirmButton - If exists will show the button if the params passe
 * @param {String} confirmVariant - If existe will change the button variant
 * @param {String} cancelButton - If exists will show the button if the params passed
 * @param {function} onClose - Close function
 * @param {function} onConfirm - Confirm function
 * @param {function} onCancel - Cancel function
 * @param {string} 	bottonButtonMd - Bottom Button MD size
 */
export default function Order({ consultant, show, ...rest }) {
	const dispatch = useDispatch();

	const { idProcess } = useParams();

	const [productsProcess, setProductsProcess] = useState([]);
	const [orderItens, setOrderItens] = useState([]);
	const [e2o, setE2o] = useState(false);
	const [totalOrder, setTotalOrder] = useState();
	const [successModal, setSuccessModal] = useState(false);

	//ugly code, need refactor urgently!!
	useEffect(() => {
		async function getProductProcess() {
			try {
				if (consultant) {
					const response = await api.get(`order`, {
						params: {
							processId: idProcess,
							$limit: 1,
							'$sort[createdAt]': -1,
							status: 'Processando',
						},
					});
					setTotalOrder({
						total: response.data.data[0].total,
						id: response.data.data[0].id,
					});
					const result = response.data.data[0].order_items.map(
						(processProduct) => {
							return {
								itemNumber: processProduct.process_product.itemNumber,
								id: processProduct.process_product.id,
								processProduct: {
									name: processProduct.process_product.product.description,
									sku: processProduct.process_product.product.sku,
									value: processProduct.process_product.finalValue ?? processProduct.process_product.nfPrice,
								},
								amount: processProduct.process_product.finalValue ?
									processProduct.process_product.finalValue *
									processProduct.process_product.initialQuantity :
									processProduct.process_product.nfPrice *
									processProduct.process_product.initialQuantity,
								currentBalance: processProduct.process_product.finalValue ?
									processProduct.process_product.finalValue *
									(processProduct.process_product.initialQuantity -
										processProduct.process_product.quantity) :
									processProduct.process_product.nfPrice *
									(processProduct.process_product.initialQuantity -
										processProduct.process_product.quantity),
								volume: processProduct.quantity,
								unity: processProduct.process_product.unity,
								description: processProduct.process_product.specification,
								finalValue: processProduct.process_product.finalValue ?? processProduct.process_product.nfPrice,
								quantity: processProduct.quantity,
								// error: i % 3 === 0 ? true : false, //need create the function to change this value, we need know when we have the errors messages
							};
						},
					);
					setProductsProcess(result);
				} else {
					const response = await api.get(`process-products`, {
						params: {
							processId: idProcess,
							winner: 1,
						},
					});

					const result = response.data.data.map((processProduct) => {
						return {
							itemNumber: processProduct.itemNumber,
							id: processProduct.id,
							processProduct: {
								name: processProduct.product.description,
								sku: processProduct.product.sku,
								value: processProduct.finalValue ?? processProduct.nfPrice,
							},
							amount: processProduct.finalValue ?
								processProduct.finalValue * processProduct.initialQuantity :
								processProduct.nfPrice * processProduct.initialQuantity,
							currentBalance: processProduct.finalValue ?
								processProduct.finalValue *
								(processProduct.initialQuantity - processProduct.quantity) :
								processProduct.nfPrice *
								(processProduct.initialQuantity - processProduct.quantity),
							volume: processProduct.quantity,
							unity: processProduct.unity,
							description: processProduct.specification,
							// error: i % 3 === 0 ? true : false, //need create the function to change this value, we need know when we have the errors messages
						};
					});
					setProductsProcess(result);
				}
			} catch (error) {
				return null;
			}
		}

		getProductProcess();
	}, [idProcess, show]);

	//need refactor
	function handleQuantity(e) {
		const { name } = e.target;
		let { id, value } = e.target;
		id = +id.split(name)[1];
		value = Number(value);
		const item = orderItens.find((item) => {
			return item.processProductId === +id;
		});
		if (item) {
			if (value === '') {
				const newOrderItem = orderItens.filter((item) => {
					return item.processProductId !== id;
				});
				setOrderItens(newOrderItem);
			} else {
				const newOrderItem = orderItens.map((item) => {
					if (item.processProductId === id) {
						return {
							processProductId: id,
							quantity: value,
						};
					}
					return item;
				});
				setOrderItens(newOrderItem);
			}
		} else {
			const newOrderItem = orderItens;
			newOrderItem.push({
				processProductId: id,
				quantity: value,
			});
			setOrderItens(newOrderItem);
		}
		const productProcess = productsProcess.find((product) => {
			return product.id === id;
		});
		if (consultant) {
			if (+value > productProcess.quantity) {
				setProductsProcess(
					productsProcess.map((product) => {
						if (product.id === id) {
							product.error = true;
						}
						return product;
					}),
				);
			} else {
				setProductsProcess(
					productsProcess.map((product) => {
						if (product.id === id) {
							product.error = false;
						}
						return product;
					}),
				);
			}
		} else {
			if (+value > productProcess.volume) {
				setProductsProcess(
					productsProcess.map((product) => {
						if (product.id === id) {
							product.error = true;
						}
						return product;
					}),
				);
			} else {
				setProductsProcess(
					productsProcess.map((product) => {
						if (product.id === id) {
							product.error = false;
						}
						return product;
					}),
				);
			}
		}
	}

	const handleChange = (e) => {
		const { name } = e.target;

		if (name === 'quantity')
			// need be removed, refactor handle quantity, the last function;
			return 0;

		let { id, value } = e.target;
		id = id.split(name)[1];
		id = Number(id);
		value = Number(value);

		const item = orderItens.find((item) => {
			return item.processProductId === +id;
		});

		if (item) {
			const newOrderItem = orderItens.map((item) => {
				if (item.processProductId === id) {
					return {
						...item,
						[name]: value,
					};
				}
				return item;
			});
			setOrderItens(newOrderItem);
		}
	};

	const makeOrder = async () => {
		const data = {
			processId: idProcess,
			status: 'Processando',
			order_items: orderItens,
		};

		// if ( !consultant ) <-- will need this if in future
		const response = await api.post(`order`, data);

		if (response.status === 201) {
			setSuccessModal(true);
			setOrderItens([]);
			rest.onClose();
		} else {
			alert('erro :(');
		}
	};

	const openE2O = () => {
		dispatch({ type: '@process/SET_ORDER', payload: orderItens });
		setE2o(true);
	};

	return (
		<>
			<MainModal
				onConfirm={
					consultant
						? () => {
							openE2O();
							rest.onClose();
						}
						: makeOrder
				}
				show={show}
				confirmDisabled={consultant ? false : orderItens.length === 0}
				onCancel={() => rest.onClose()}
				{...rest}
			>
				{productsProcess.map((item) => {
					if (item.volume > 0 || consultant) {
						return (
							<Accordion order item={item} key={item.itemNumber}>
								<Box mx={-4}>
									<Specification>
										<Grid>
											<UITextarea
												value={item.description}
												disabled
												label="Especificação"
												colorLabel="var(--text-grey)"
											/>
										</Grid>
									</Specification>
								</Box>
								<Grid>
									<Box mt={2}>
										<StyledP> Informações do item </StyledP>
									</Box>
									<Box mt={1}>
										<form>
											<Grid
												container
												direction="row"
												justify="center"
												spacing={2}
											>
												<Grid item md={consultant ? 4 : 12} xs={12}>
													{/* ORDER
														 WILL NEED TO REMOVE THIS DISABLED OPTION,
														 CONSULTANT CAN MAKE MORE THAN ONE EMAIL TO ORDER E2O
													*/}
													<UIInput
														name="quantity"
														type="number"
														id={`quantity${item.id}`}
														label="Quantidade solicitada"
														onChange={
															/*consultant ? () => false :*/ handleQuantity
														}
													// disabled={consultant ? true : false} // <-- IN FUTURE WE WILL REMOVE THIS.
													/>
												</Grid>

												{consultant ? (
													<>
														<Grid item md={4} xs={12}>
															<UIInput
																name="lote"
																id={`lote${item.id}`}
																label="Lote especifico"
																value={item.lote}
																onChange={(e) => handleChange(e)}
															// disabled
															/>
														</Grid>
														<Grid item md={4} xs={12}>
															<UIInput
																name="value"
																label="(R$) Valor por unidade"
																value={item.finalValue}
																disabled
															/>
														</Grid>
													</>
												) : null}
												{item.error ? (
													<Grid item xs={12}>
														<Error>
															<StyledSpan fontSize="12px" color="red">
																Valor maior que o estabelecido no contrato
															</StyledSpan>
															<Grid item xs={12}>
																<StyledP
																	fontSize="12px"
																	overflow="none"
																	textOverflow="none"
																	whiteSpace="initial"
																>
																	A quantidade solicitada excede o valor do
																	estabelecido no contrato. Verifique os valor
																	novamente, ou entre em contato com um
																	consultor para maiores informações
																</StyledP>
															</Grid>
														</Error>
													</Grid>
												) : null}
											</Grid>
										</form>
									</Box>
								</Grid>
							</Accordion>
						);
					} else {
						return null;
					}
				})}
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Box mx={-4} mt={2}>
							<Divider />
						</Box>
					</Grid>
					<Grid item>
						<Typography variant="h4">Anexo do pedido</Typography>
					</Grid>
					<Grid item xs={12}>
						<UISelect
							label="Documento"
							items={[
								{
									text: 'Solicitação de compras',
									value: 'Solicitação de compras',
								},
								{
									text: 'Autorização de fornecimento',
									value: 'Autorização de fornecimento',
								},
							]}
						/>
					</Grid>
					<Grid item xs={12}>
						<UploadArea height="240px" onChange={() => { }} />
					</Grid>
				</Grid>
			</MainModal>
			<E2O
				show={true}
				confirmButton="Enviar"
				confirmVariant="contained"
				cancelButton="Cancelar"
				bottonButtonMd
				width="small"
				title="Informações E2O"
				show={e2o}
				onClose={() => setE2o(false)}
				totalOrder={totalOrder}
			/>
			<Modal
				variant="success"
				title="Pedido enviado com sucesso"
				buttonText="Voltar a home"
				buttonAction={() => {
					setSuccessModal(false);
				}}
				open={successModal}
			/>
		</>
	);
}
