export function setModalNewProcess(trigger) {
	return { type: '@process/MODAL_NEW_PROCESS', payload: { trigger } };
}

export function getProcess(payload) {
	return {
		type: '@process/GET_PROCESS',
		payload: payload,
	};
}

export function setProcess(process) {
	return {
		type: '@process/SET_PROCESS',
		payload: process,
	};
}
