import { Input, InputLabel, withStyles } from '@material-ui/core';

export const StyledInput = withStyles({
	root: {
		width: (props) => props.width,
	},
	underline: {
		'&:before': {
			borderBottom: '1px solid var(--text-grey)',
		},
	},
})(Input);

export const StyledInputLabel = withStyles({
	root: {
		color: (props) => props.color || 'var(--primary)',
		fontWeight: '400',
		fontSize: (props) => props.fontSize || '20px',
		// width: (props) => props.labelwidth || 'max-content',
	},
})(InputLabel);
