import produce from 'immer';

export const initialState = {
	loading: false,
	refresh: false,
};

export default function app(state = initialState, action) {
	return produce(state, (draft) => {
		switch (action.type) {
			case '@app/LOADING':
				draft.loading = action.payload;
				break;
			case '@app/REFRESH':
				draft.refresh = !draft.refresh;
				break;
			default:
				return state;
		}
	});
}
