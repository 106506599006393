import React, { useEffect, useState } from 'react';
import Modal from 'components/Modals';
import { Box, Grid } from '@material-ui/core';

import { StyledP } from './style';
import UITextarea from 'components/UI/Textarea/Textarea';
import UIInput from 'components/UI/Input/Input';

import UIDate from 'components/UI/Date/Date';

import MainModal from 'components/Modals/MainModal';

import UISelect from 'components/UI/Select/Select';

import api from 'services/api';
import { useParams } from 'react-router';
import { store } from 'store';

/**
 * @param {boolean} show - Boolean show modal
 * @param {string} [width=normal] - normal, medium, small
 * @param {string} title - Modal Title
 * @param {string} buttonText - If have this prop will add a button before close icon.
 * @param {function} titleButtonClick - Title button function
 * @param {component} children - HTML
 * @param {String} confirmButton - If exists will show the button if the params passe
 * @param {String} confirmVariant - If existe will change the button variant
 * @param {String} cancelButton - If exists will show the button if the params passed
 * @param {function} onClose - Close function
 * @param {function} onConfirm - Confirm function
 * @param {function} onCancel - Cancel function
 * @param {string} 	bottonButtonMd - Bottom Button MD size
 */
export default function E2O({ totalOrder, ...rest }) {
	const { name } = store.getState().user;
	const { order } = store.getState().process;
	const { idProcess } = useParams();
	const [process, setProcess] = useState();
	const [formData, setFormData] = useState({
		organization: 'BRN4-06',
		language: 'PT',
		currency: 'BRL',
	});
	const [successModal, setSuccessModal] = useState(false);

	useEffect(() => {
		async function getProcess() {
			const response = await api.get(`process/${idProcess}`, {});
			setFormData({
				...formData,
				employeeId: response.data.employeeId,
				distributorId: response.data.distributorId,
				orderId: totalOrder?.id,
				processId: idProcess,
				date: new Date().toISOString(),
				order
			});

			setProcess(response.data);
		}

		getProcess();
	}, [totalOrder]);

	const handleForm = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};
	const handleSuccessModal = () => {
		setSuccessModal(!successModal);
	};

	const submitForm = async () => {
		const response = await api.post(`e2o`, formData);
		await api.patch(`order/${totalOrder?.id}`, {
			status: 'Aprovado',
			approvedDate: new Date().toISOString(),
			deliveryDate: formData.date,
		});
		if (response.status === 201) {
			rest.onClose();
			handleSuccessModal();
			setFormData({
				organization: 'BRN4-06',
				language: 'PT',
				currency: 'BRL',
			});
		} else {
			alert('erro :(');
		}
	};

	return (
		<>
			<MainModal
				{...rest}
				onCancel={() => rest.onClose()}
				onConfirm={submitForm}
				width={100}
			>
				<Box pb={2}>
					<StyledP fontWeight="500"> Informações do processo </StyledP>
				</Box>
				<Grid container spacing={2}>
					<Grid item md={6} xs={12}>
						<UIInput
							label="Organização de vendas"
							value={formData?.organization}
							disabled
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<UISelect
							label="Tipo de Ordem"
							items={[
								{
									text: 'ZRR - Return Request',
									value: 'ZRR - Return Request',
								},
								{
									text: 'OR- Standard Order',
									value: 'OR- Standard Order',
								},
							]}
							name={'orderType'}
							onChange={handleForm}
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<UIInput label="Idioma" value={formData?.language} disabled />
					</Grid>
					<Grid item md={6} xs={12}>
						<UIInput label="Consultor" value={name} disabled />
					</Grid>
					<Grid item xs={12}>
						<Box mt={2} mb={2}>
							<StyledP fontWeight="500"> Dados gerais dos itens </StyledP>
						</Box>
					</Grid>
					<Grid item md={6} xs={12}>
						<UIInput label="Sold" value={process?.distributor.sold} disabled />
					</Grid>
					<Grid item md={6} xs={12}>
						<UIInput
							label="Recebedor"
							onChange={handleForm}
							name={'receiver'}
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<UIInput label="PNE" value={process?.pne} disabled />
					</Grid>
					<Grid item md={6} xs={12}>
						<UIDate
							shrink={true}
							fullWidth={true}
							name={'date'}
							label="Data de remessa"
							selectedDate={formData?.date}
							onChange={(date) =>
								setFormData({ ...formData, date: new Date(date).toISOString() })
							}
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<UIInput label="Moeda" value={formData?.currency} disabled />
					</Grid>
					<Grid item md={6} xs={12}>
						<UIInput
							label="Planta faturadora"
							onChange={handleForm}
							name={'billingPlant'}
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<UIInput
							label="Cliente"
							value={process?.distributor.name}
							disabled
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<UIInput
							label="SAP consultor"
							value={process?.employee.sap}
							disabled
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<UIInput
							label="(R$)Valor total da nota"
							value={totalOrder?.total}
							disabled
						/>
					</Grid>
					<Grid item xs={12}>
						<UITextarea
							label="Comentário"
							placeholder="Insira o texto..."
							onChange={handleForm}
							name={'comment'}
							rows={3}
						/>
					</Grid>
				</Grid>
			</MainModal>
			<Modal
				variant="success"
				title="Documento enviado por email com suceso"
				buttonText="Voltar a tela inicial"
				buttonAction={() => {
					setSuccessModal(false);
					rest.onClose();
				}}
				open={successModal}
			/>
		</>
	);
}
