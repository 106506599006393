import { Box, Divider, Typography } from '@material-ui/core';
import React from 'react';

import UploadFile from '../UploadFile/UploadFile';
import { UploadArea } from './style';

export default function UploadList({
	titleList,
	itemsList,
	iconDelete,
	iconTitle,
	deleteAttachment,
	height,
}) {
	return (
		<UploadArea height={height}>
			<Box pl={2} pb={1}>
				{iconTitle}
			</Box>
			<Box pl={2} pb={2}>
				<Typography variant="h4">{titleList}</Typography>
			</Box>

			<Box pb={1}>
				<Divider />
			</Box>

			{itemsList.map((item, index) => (
				<UploadFile
					key={index}
					category={item.category}
					name={item.name}
					size={item.size}
					showBars={item.showBars}
					success={item.success}
					onDelete={deleteAttachment ?
						() => deleteAttachment(item.id)
						:
						null
					}
					iconDelete={iconDelete}
					url={item.url}
					referencia={`${item.id}buttonFile`}
					id={item.id}
				/>
			))}
		</UploadArea>
	);
}
