import React from 'react';

import { Box, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { FaCheckCircle } from 'react-icons/fa';

import UIButton from 'components/UI/Button/Button';

import { StyledDialog } from './styles';
import { useDispatch } from 'react-redux';
import { setRefresh } from 'store/modules/app/actions';

export default function SuccessModal({
	onClose,
	open = true,
	title,
	buttonAction,
	buttonText,
	widthTitle,
	subtitle,
}) {
	const dispatch = useDispatch();
	return (
		<StyledDialog onClose={onClose} open={open} maxWidth="xs" fullWidth>
			<Box align="center">
				<Box align="center">
					<FaCheckCircle color="var(--success)" size={100} onClick={onClose} />
				</Box>
				<DialogTitle style={{ width: widthTitle || '230px' }}>
					<Typography variant="h1" align="center">
						{title}
					</Typography>
					{subtitle}
				</DialogTitle>
				<DialogContent>
					<Box display="flex" justifyContent="space-around">
						<UIButton
							text={buttonText}
							onClick={() => {
								buttonAction();
								dispatch(setRefresh());
							}}
							texttransform="inherit"
						/>
					</Box>
				</DialogContent>
			</Box>
		</StyledDialog>
	);
}
