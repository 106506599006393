import { Box, Divider, Grid, Typography, IconButton } from '@material-ui/core';
import UIButton from 'components/UI/Button/Button';
import React from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import { MContainer, MBody, MHeader, MFooter } from './style';

/**
 *
 * @param {boolean} show - Boolean show modal
 * @param {string} [width=normal] - normal, medium, small
 * @param {string} title - Modal Title
 * @param {string} buttonText - If have this prop will add a button before close icon.
 * @param {function} titleButtonClick - Title button function
 * @param {component} children - HTML
 * @param {String} confirmButton - If exists will show the button if the params passe
 * @param {String} confirmVariant - If existe will change the button variant
 * @param {String} cancelButton - If exists will show the button if the params passed
 * @param {function} onClose - Close function
 * @param {function} onConfirm - Confirm function
 * @param {function} onCancel - Cancel function
 * @param {string} 	bottonButtonMd - Bottom Button MD size
 */

export default function MainModal({
	show,
	onClose,
	width,
	title,
	buttonText,
	titleButtonClick,
	confirmButton,
	confirmDisabled,
	cancelButton,
	children,
	confirmVariant,
	onConfirm,
	onCancel,
	bottonButtonMd,
}) {
	return (
		<>
			<ModalContainer show={show} width={width} onClose={onClose}>
				<ModalHeader
					title={title}
					buttonText={buttonText}
					titleButtonClick={titleButtonClick}
					onClose={onClose}
				/>
				<ModalBody>{children}</ModalBody>
				<ModalFooter
					confirmButton={confirmButton}
					cancelButton={cancelButton}
					confirmVariant={confirmVariant}
					confirmDisabled={confirmDisabled}
					onConfirm={onConfirm}
					onCancel={onCancel}
					bottonButtonMd={bottonButtonMd}
				/>
			</ModalContainer>
		</>
	);
}

/**
 *
 * @param {string} [width=normal] - normal, medium, small
 */
export function ModalContainer({ show, onClose, children, ...rest }) {
	return (
		<MContainer open={show} onClose={onClose}>
			<MBody {...rest}>{children}</MBody>
		</MContainer>
	);
}

/**
 *
 * @param {string} title - Modal Title
 * @param {string} buttonText - If have this prop will add a button before close icon.
 * @param {function} titleButtonClick - Title button function
 */
export function ModalHeader({ title, buttonText, titleButtonClick, onClose }) {
	return (
		<>
			<MHeader>
				<Typography variant="h4"> {title} </Typography>
				<Grid item>
					<Box display="flex" alignItems="center">
						{buttonText ? (
							<UIButton
								text={buttonText}
								variant="outlined"
								borderwidth="1px"
								width="140px"
								height="30px"
								fontSize="14px"
								onClick={titleButtonClick}
							/>
						) : null}
						<IconButton style={{margin: 0}} size="small" onClick={onClose}>
							<IoMdCloseCircle
								color="var(--text-grey)"
								size={25}
							/>
						</IconButton>
					</Box>
				</Grid>
			</MHeader>
			<Divider />
		</>
	);
}
/**
 *
 * @param {component} children - HTML
 */
export function ModalBody({ children }) {
	return <Box p={2}>{children}</Box>;
}
/**
 *
 * @param {String} confirmButton - If exists will show the button if the params passed
 * @param {String} cancelButton - If exists will show the button if the params passed
 * @param {String} variantCancelButton - If exists will show the button if the params passed
 * @param {String} variantConfirmButton - If exists will show the button if the params passed
 * @param {String} onConfirm - If exists will show the button if the params passed
 * @param {String} onCancel - If exists will show the button if the params passed
 * @param {string} 	bottonButtonMd - Bottom Button MD size
 */
export function ModalFooter({
	confirmButton,
	confirmVariant,
	confirmDisabled,
	cancelButton,
	onConfirm,
	onCancel,
	bottonButtonMd,
}) {
	return (
		<MFooter>
			<Box ml={2} mr={2}>
				<Grid container spacing={2} direction="row" justify="flex-end">
					{cancelButton ? (
						<Grid item md={bottonButtonMd ?? 0}>
							<UIButton
								text={cancelButton}
								variant="outlined"
								onClick={onCancel}
								width="100%"
							/>
						</Grid>
					) : null}
					{confirmButton ? (
						<Grid item md={bottonButtonMd ?? 0}>
							<UIButton
								text={confirmButton}
								variant={confirmVariant ?? 'outlined'}
								onClick={onConfirm}
								width="100%"
								disabled={confirmDisabled}
							/>
						</Grid>
					) : null}
				</Grid>
			</Box>
		</MFooter>
	);
}
