import styled from 'styled-components';
import {Grid} from '@material-ui/core';

export const FormContainer = styled.form`
  h1 {
    color: #5181C2;
    margin: 0;
  }
  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 135%;
  }
  .MuiCheckbox-root {
    margin-right : -3px ;
  }
  button {
    width: 358px;
    height: 40px;
    text-transform: none!important;
  }

  img {
    margin-top: 30px;
  }

`

export const ForgotPassword = styled(Grid)`

  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  .MuiTypography-body1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }
  a {
    margin-top: 23px;
    margin-bottom: 23px;
    align-self: end;
    justify-content: end;
    color: #5082C9;
  }
`
