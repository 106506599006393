import React, { useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';

import { Message, StyledGrid, StyledPaper } from './style.js';
import iconPencilNotebook from 'assets/images/pencilNotebook.svg';

export default function NotificationCard({ title, msg, bold }) {
	const [text, setText] = useState();

	const bolder = () => {
		const splitText = msg.split(bold);

		if (bold)
			setText(
				<>
					{splitText[0]} <u style={{ fontWeight: '500' }}>{bold}</u>{' '}
					{splitText[1]}
				</>,
			);
	};

	useEffect(bolder, [bold, msg]);

	return (
		<StyledPaper elevation={0}>
			<StyledGrid container alignItems="center">
				<Grid item>
					<Box pl={3} pt={2} pb={2}>
						<img src={iconPencilNotebook} alt="" />
					</Box>
				</Grid>
				<Grid item>
					<Box pl={3} pr={2} pt={2} pb={2}>
						<Typography variant="h4" color="inherit">
							{title ?? null}
						</Typography>
						<Message variant="h5" color="inherit">
							{text ?? null}
						</Message>
					</Box>
				</Grid>
			</StyledGrid>
		</StyledPaper>
	);
}
