import React, { useEffect, useState } from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Grid,
	Typography,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import Scrollbar from 'react-scrollbar';

import UIDate from 'components/UI/Date/Date';
import UIText from 'components/UI/Text/Text';
import UIInput from 'components/UI/Input/Input';
import UISelect from 'components/UI/Select/Select';
import UIRadio from 'components/UI/Radio/Radio';
import { UIAutocomplete } from 'components/UI/Autocomplete';
import Modal from 'components/Modals';

import { StyledCard, useStyles, StyledDivider } from './style';

import { MdExpandMore } from 'react-icons/md';

import api from 'services/api';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { parseToNumber } from 'brazilian-values';
import { maskValue, moneyMask } from 'services/mask';
import mock from 'assets/mock.json';

const validationSchema = yup.object().shape({
	startPeriod: yup.string().required('Selecione a data'),
});

function ValidateInfosResult({ submit, process }) {
	const history = useHistory();
	const classes = useStyles();

	const { idProcess } = useParams();

	const [products, setProducts] = useState([]);
	const [openSuccessModal, setOpenSuccessModal] = useState(false);

	const [productsList, setProductsList] = useState([]);
	const [rivalCompanies, setRivalCompanies] = useState([]);
	const [rivalProductList, setRivalProductList] = useState([]);

	const [refresh, setRefresh] = useState(false);

	//send products result
	useEffect(() => {
		if (submit) sendResult();
	}, [submit]);

	useEffect(() => {
		async function getProcessProducts() {
			const response = await api.get(`process-products`, {
				params: {
					processId: idProcess,
					approve: 1,
				},
			});

			setProducts(response.data.data);
		}

		const getRivalProducts = async () => {
			const response = await api.get(`rival-products`, {
				params: {
					$limit: -1,
				},
			});

			if (response.status === 200) {
				setRivalProductList(response.data);
				setRivalCompanies([
					...new Set(response.data.map((item) => item.company)),
				]);
			}
		};

		async function getAllProducts() {
			const response = await api.get(`products`, {
				params: { $limit: -1 },
			});
			if (response.status === 200) {
				setProductsList(response.data);
			}
		}

		getAllProducts();
		getProcessProducts();
		getRivalProducts({});
	}, [idProcess]);

	const { values, errors, touched, handleBlur, handleChange } = useFormik({
		validationSchema,
		initialValues: {
			startPeriod: process.startPeriod,
		},
		async onSubmit(fields) {},
	});

	const sendResult = async () => {
		try {
			await api.patch(
				`process-products`,
				products.map((product) => {
					return {
						...product,
						initialValue: parseToNumber(`${product.initialValue}`),
						finalValue: parseToNumber(`${product.finalValue}`),
						rivalInitialValue: parseToNumber(`${product.rivalInitialValue}`),
						rivalFinalValue: parseToNumber(`${product.rivalFinalValue}`),
					};
				}),
			);
			await api.patch(`process/${idProcess}`, {
				...values,
				status: 'awaiting order',
			});
			setOpenSuccessModal(true);
		} catch (error) {
			return null;
		}
	};

	const handleChangeProducts = (e, id) => {
		const newProducts = products;
		const { name, value } = e.target;
		newProducts.forEach((product) => {
			if (product.id === id) {
				if (
					[
						'initialValue',
						'finalValue',
						'rivalInitialValue',
						'rivalFinalValue',
					].includes(name)
				) {
					product[name] = maskValue('money', value || '');
				} else {
					product[name] = value;
				}

				if (name === 'product') product.productId = value.id;
				if (name === 'rival_product') product.rivalProductId = value.id;
				if (name === 'company') product.rival_product = { company: value };
			}
		});

		setProducts(newProducts);
		setRefresh(!refresh);
	};

	return (
		<StyledCard>
			<Grid container justify="space-between">
				<Grid item xs={12}>
					<Box pt={3} pl={2}>
						<Typography variant="h4">Validação oficial do processo</Typography>
					</Box>
					<StyledDivider />
				</Grid>
				<Grid item xs={12}>
					<Scrollbar className={classes.scrollbar} autoHide={false}>
						<Box p={2}>
							<Grid container>
								<Grid item xs={12}>
									<Box pb={3}>
										<UIDate
											shrink={true}
											fullWidth={true}
											customizated
											label="Data da assinatura"
											fontsize="1rem"
											textcolor="var(--primary)"
											selectedDate={values.startPeriod}
											onChange={handleChange('startPeriod')}
										/>
									</Box>
								</Grid>
								<Grid item xs={12}>
									{/* <UIDate
										shrink={true}
										fullWidth={true}
										customizated
										label="Término de fornecimento"
										fontsize="1rem"
										textcolor="var(--primary)"
										selectedDate={new Date()}
									/> */}
								</Grid>
							</Grid>
						</Box>
						<Box pb={2} pt={2}>
							{products.map((item) => (
								<Box pb={2} pt={2}>
									<Accordion key={`processItem${item.id}`}>
										<AccordionSummary
											expandIcon={<MdExpandMore />}
											aria-controls="panel1a-content"
											id="panel1a-header"
										>
											<UIText
												text={`Item ${item.itemNumber} - ${
													item.product ? item.product.description : ''
												}`}
												fontSize="16px"
												fontWeight="500"
											/>
										</AccordionSummary>
										<AccordionDetails>
											<Grid container spacing={3}>
												<Grid item xs={6}>
													<UIInput
														label="Quantidade"
														type="text"
														disabled
														value={item.quantity}
														name="quantity"
													/>
												</Grid>
												<Grid item xs={6}>
													<UIInput
														label="Unidade"
														type="text"
														disabled
														value={item.unity}
														name="unity"
													/>
												</Grid>
												<Grid item xs={6}>
													<UIInput
														label="SKU"
														type="text"
														disabled
														value={(item.product || {}).sku}
														name="sku"
													/>
												</Grid>
												<Grid item xs={6}>
													<UIInput
														label="(R$) Preço"
														type="text"
														disabled
														value={item.finalValue}
														name="value"
													/>
												</Grid>
												<Grid item xs={12}>
													<Box pb={3}>
														<Box pb={3}>
															<UIText
																text="Resultado do item"
																fontSize="16px"
																fontWeight="500"
																textcolor="var(--text-dark)"
															/>
														</Box>
														<Box pb={1}>
															<UIRadio
																label="O item foi ganho?"
																items={mock.yesNo}
																value={item.winner ? 'sim' : 'não'}
																onChange={(e) =>
																	handleChangeProducts(e, item.id)
																}
																onBlur={handleBlur('winner')}
																error={touched.winner && errors.winner}
															/>
														</Box>
														<Box pb={1}>
															<Grid item xs={12}>
																<UIAutocomplete
																	label="Produto"
																	options={productsList}
																	getOptionLabel={(option) =>
																		option.description
																	}
																	getOptionSelected={(option) =>
																		option.id === item.productId
																	}
																	value={item.product}
																	onChange={(_, value) =>
																		handleChangeProducts(
																			{
																				target: {
																					name: 'product',
																					value,
																				},
																			},
																			item.id,
																		)
																	}
																/>
															</Grid>
														</Box>
														<Box pb={1}>
															<Grid item xs={12}>
																<UIInput
																	label="(R$) Valor inicial"
																	value={item.initialValue}
																	name="initialValue"
																	onChange={(e) =>
																		handleChangeProducts(e, item.id)
																	}
																/>
															</Grid>
														</Box>
														<Box pb={1}>
															<Grid item xs={12}>
																<UIInput
																	label="(R$) Valor final"
																	value={item.finalValue}
																	name="finalValue"
																	onChange={(e) =>
																		handleChangeProducts(e, item.id)
																	}
																/>
															</Grid>
														</Box>
														<Box pb={3}>
															<Grid item xs={12}>
																<UIText
																	text="Grade de preço"
																	fontSize="16px"
																	fontWeight="500"
																	textcolor="var(--text-dark)"
																/>
															</Grid>
														</Box>
														<Box pb={3}>
															<Grid item xs={12}>
																<UIAutocomplete
																	label="Segundo colocado"
																	options={rivalCompanies}
																	value={item.rival_product.company}
																	onChange={(_, value) =>
																		handleChangeProducts(
																			{
																				target: {
																					name: 'company',
																					value: value,
																				},
																			},
																			item.id,
																		)
																	}
																/>
															</Grid>
														</Box>
														<Box pb={1}>
															<Grid item xs={12}>
																<UIAutocomplete
																	label="Produto"
																	options={rivalProductList.filter(
																		({ company }) =>
																			company === item.rival_product.company,
																	)}
																	getOptionLabel={(option) =>
																		option.description
																	}
																	getOptionSelected={(option) =>
																		option.id === item.rivalProductId
																	}
																	value={item.rival_product}
																	onChange={(_, value) =>
																		handleChangeProducts(
																			{
																				target: {
																					name: 'rival_product',
																					value,
																				},
																			},
																			item.id,
																		)
																	}
																/>
															</Grid>
														</Box>
														<Box pb={1}>
															<Grid item xs={12}>
																<UIInput
																	label="(R$) Valor inicial"
																	value={item.rivalInitialValue}
																	name="rivalInitialValue"
																	onChange={(e) =>
																		handleChangeProducts(e, item.id)
																	}
																/>
															</Grid>
														</Box>
														<Box pb={1}>
															<Grid item xs={12}>
																<UIInput
																	label="(R$) Valor final"
																	value={item.rivalFinalValue}
																	name="rivalFinalValue"
																	onChange={(e) =>
																		handleChangeProducts(e, item.id)
																	}
																/>
															</Grid>
														</Box>
														<Box pb={1}>
															<Grid item xs={12}>
																<UISelect
																	items={mock.justificationItems}
																	label="Justificativa"
																	disabled={item.winner}
																	value={item.justify}
																	name="justify"
																	onChange={(e) =>
																		handleChangeProducts(e, item.id)
																	}
																/>
															</Grid>
														</Box>
														<Box pb={1}>
															<Grid item xs={12}>
																<UIInput
																	label="Observação"
																	placeholder="Insira o texto aqui..."
																	value={item.observation}
																	name="observation"
																	onChange={(e) =>
																		handleChangeProducts(e, item.id)
																	}
																/>
															</Grid>
														</Box>
													</Box>
												</Grid>
											</Grid>
										</AccordionDetails>
									</Accordion>
								</Box>
							))}
						</Box>
					</Scrollbar>
				</Grid>
			</Grid>
			<Modal
				variant="success"
				open={openSuccessModal}
				title="Resultado adicionado com sucesso"
				subtitle="PNE: 123"
				buttonText="Voltar a tela inicial"
				buttonAction={() => history.push(`/processo/${idProcess}`)}
				onClose={() => setOpenSuccessModal(false)}
			/>
		</StyledCard>
	);
}

export default ValidateInfosResult;

function formatDate(date) {
	const year = new Date(date).getFullYear();
	const month = new Date(date).getMonth();
	const day = new Date(date).getDay();
	return `${day}/${month}/${year}`;
}
