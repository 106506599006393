import { Grid, withStyles } from '@material-ui/core';
import styled from 'styled-components';

export const ScrollContainer = styled(Grid)`
	min-height: 0px;
	max-height: 400px;
	overflow-x: hidden;
	overflow-y: auto;
	::-webkit-scrollbar {
		width: 5px;
	}
	::-webkit-scrollbar-track {
		border-radius: 3px;
	}
	::-webkit-scrollbar-thumb {
		background: #c4c4c4;
		border-radius: 3px;
	}
`;

export const StyledBox = withStyles({
	root: {
		background: '#F8F4F4',
		padding: '1rem',
		marginTop: '1rem',
	}
})(Grid);
