import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';

import UIInput from 'components/UI/Input/Input';
import UISelect from 'components/UI/Select/Select';

import { useFormik } from 'formik';
import * as yup from 'yup';

import mock from 'assets/mock.json';

const validationSchema = yup.object().shape({
	name: yup.string().required('Digite o nome'),
	lastName: yup.string().required('Digite o sobrenome'),
	email: yup.string().required('Digite o email').email('Email inválido'),
	regional: yup.string().required('Selecione a regional'),
	sap: yup.string().required('Digite o SAP'),
});

export default function Consultant({ user }) {

	const {
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
	} = useFormik({
		validationSchema,
		initialValues: {
			...user.employee,
			email: user.email,
		} || {
			email: '',
			name: '',
			lastName: '',
			regional: '',
			sap: ''
		},
		onSubmit(fields) { },
	});

	return (
		<Box pt={3} pb={3}>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<UIInput
						value={values.name}
						name="name"
						label="Nome"
						error={touched.name && errors.name}
						onChange={handleChange('name')}
						onBlur={handleBlur('name')}
						disabled
					/>
				</Grid>
				<Grid item xs={6}>
					<UIInput
						value={values.lastName}
						name="lastName"
						label="Sobrenome"
						error={touched.lastName && errors.lastName}
						onChange={handleChange('lastName')}
						onBlur={handleBlur('lastName')}
						disabled
					/>
				</Grid>
				<Grid item xs={6}>
					<UIInput
						value={values.email}
						name="email"
						label="Email"
						error={touched.email && errors.email}
						onChange={handleChange('email')}
						onBlur={handleBlur('email')}
						disabled
					/>
				</Grid>
				<Grid item xs={6}>
					<UIInput
						value={values.regional}
						name="regional"
						label="Regional"
						error={touched.regional && errors.regional}
						onChange={handleChange('regional')}
						onBlur={handleBlur('regional')}
						disabled
					/>
				</Grid>
				<Grid item xs={6}>
					<UIInput
						value={values.sap}
						name="SAP"
						label="SAP"
						error={touched.sap && errors.sap}
						onChange={handleChange('sap')}
						onBlur={handleBlur('sap')}
						disabled
					/>
				</Grid>
			</Grid>
		</Box>
	)
}
