import React from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { IoMdArrowDropdown } from 'react-icons/io';
import UIText from '../Text/Text';

function UIDate({
	formatDate = 'dd/MM/yyyy',
	fullWidth,
	shrink,
	views,
	label,
	selectedDate,
	onChange,
	disabled,
	customizated,
	fontsize,
	textcolor
}) {
	const isValidDate = (date) => {
		if (new Date(date).getTime()) return true;
		return false;
	};
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} >
			<KeyboardDatePicker
				views={views}
				fullWidth={fullWidth}
				KeyboardButtonProps={{ style: { padding: '12px 4px' } }}
				keyboardIcon={<IoMdArrowDropdown color="var(--text-dark)" size={18} />}
				value={selectedDate}
				format={formatDate}
				label={
					customizated ?
						<UIText
							text={label}
							textcolor={textcolor}
							fontSize={fontsize}
						/>
						:
						label
				}
				disableToolbar
				variant="inline"
				InputLabelProps={{
					shrink: shrink,
					style: {
						fontSize: '19px',
						color: disabled ? 'var(--text-grey)' : 'var(--primary)',
					},
				}}
				onChange={(date) => (isValidDate(date) ? onChange(date) : '')}
				disabled={disabled}
			/>
		</MuiPickersUtilsProvider>
	);
}

export default UIDate;
