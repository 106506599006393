import React, { useState, useEffect } from 'react';
import {
	Avatar,
	Container,
	Grid,
	makeStyles,
	Menu,
	MenuItem,
	Typography,
} from '@material-ui/core';

import UIButton from 'components/UI/Button/Button';
import Modal from 'components/Modals/index';
import ModalNewProcess from 'screens/Process/components/Modal/NewProcess';
import { setModalNewProcess } from 'store/modules/process/actions';
import Notifications from 'components/Navigation/Notifications/Notifications';
import { useHistory } from 'react-router';

import {
	Profile,
	StyledAppBar,
	StyledGrid,
	StyledToolbar,
	Username,
	IconProfile,
} from './style';

import logo from 'assets/images/vendasInstitucionais.png';
import profile from 'assets/images/profile.png';
import { IoMdArrowDropdown } from 'react-icons/io';

import { store } from 'store';
import client from 'services/socket';
import { useDispatch, useSelector } from 'react-redux';

import lang from 'lang/lang.json';
import mock from './mock.json';

export default function Navbar() {
	const history = useHistory();
	const dispatch = useDispatch();
	const {profilePicture} = store.getState().user
	console.log(profilePicture)
	const [picture, setPicture] = useState();
	const user = store.getState().user;

	useEffect(() => {
		dispatch({
			type: '@user/GET_PENDENCIES',
		});
		dispatch({
			type: '@user/GET_PICTURE',
			userId: user.id,
		});
	}, []);
	useEffect(() => {
		if (profilePicture) {
			setPicture(profilePicture);
		};
	}, [user, dispatch]);

	useEffect(() => {
		client.service('pendencies').on('created', (res) => {
			dispatch({
				type: '@user/PUT_PENDENCIES',
				payload: res,
			});
		});
	}, []);

	const [anchorEl, setAnchorEl] = useState(null);
	const [modalLogout, setModalLogout] = useState(false);
	const closeModalLogout = () => {
		setModalLogout(false);
	};

	const logout = () => {
		dispatch({ type: '@auth/LOGOUT' });
	};
	const handleOpenModalNewProcess = () => {
		dispatch(setModalNewProcess(true));
	};

	const handleCloseModalNewProcess = () => {
		dispatch(setModalNewProcess(false));
	};

	const useStyles = makeStyles({
		root: {
			borderRadius: '20px',
		},
	});
	const classes = useStyles();

	return (
		<>
			<StyledAppBar position="relative" color="inherit">
				<StyledToolbar>
					<Container>
						<StyledGrid container alignItems="center" justify="space-between">
							<Grid item xs={3} md={4}>
								<img
									src={logo}
									alt="vendas institucionais"
									onClick={() => history.push('/')}
								/>
							</Grid>
							{mock[user.type || ''].map((item) => (
								<Grid item>
									<Typography
										variant="h5"
										onClick={() => history.push(item.link)}
									>
										{item.text}
									</Typography>
								</Grid>
							))}
							<Grid item>
								<UIButton
									onClick={handleOpenModalNewProcess}
									variant="outlined"
									text="+ adicionar processo"
									height="30px"
									fontSize="14px"
								/>
							</Grid>
							<Grid
								spacing={2}
								item
								container
								alignItems="center"
								justify="flex-end"
								style={{ width: 'auto' }}
							>
								<Grid item>
									<Notifications />
								</Grid>
								<Grid
									spacing={2}
									item
									container
									alignItems="center"
									justify="flex-end"
									style={{ width: 'auto' }}
								>
									<Grid item>
										<Username variant="h6">{user.name}</Username>
										<Profile align="right">{lang[user.type]}</Profile>
									</Grid>
									{profilePicture ? (
										<img
											className={classes.root}
											src={profilePicture}
											alt="profile"
											width="40px"
										/>
									) : (
										<Avatar />
									)}

									<IoMdArrowDropdown
										size={20}
										color="var(--text-grey)"
										onClick={(e) => setAnchorEl(e.currentTarget)}
									/>
									<Menu
										id="simple-menu"
										anchorEl={anchorEl}
										keepMounted
										open={Boolean(anchorEl)}
										onClose={() => setAnchorEl(null)}
									>
										<MenuItem>
											<Typography variant="h5">Gerar relatório</Typography>
										</MenuItem>
										<MenuItem
											onClick={() => {
												setAnchorEl(null);
												history.push('/perfil');
											}}
										>
											<Typography variant="h5">Perfil</Typography>
										</MenuItem>
										<MenuItem>
											<Typography variant="h5">Configurações</Typography>
										</MenuItem>
										<MenuItem onClick={() => setModalLogout(true)}>
											<Typography variant="h5" color="error">
												Sair
											</Typography>
										</MenuItem>
									</Menu>
								</Grid>
							</Grid>
						</StyledGrid>
					</Container>
				</StyledToolbar>
			</StyledAppBar>
			<ModalNewProcess onClose={handleCloseModalNewProcess} />
			<Modal
				variant="error"
				onClose={closeModalLogout}
				open={modalLogout}
				title="Deseja sair do sistema?"
				buttonCancelAction={closeModalLogout}
				buttonCancelText="Cancelar"
				buttonConfirmAction={logout}
				buttonConfirmText="Sair"
				subtitle="Será nescessário fazer login novamente"
			/>
		</>
	);
}
