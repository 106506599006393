import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export const StyledButton = withStyles({
	root: {
		textTransform: (props) => props.texttransform || 'capitalize',
		fontSize: (props) => props.fontSize || '18px',
		borderWidth: (props) => props.borderwidth || '2px',
		borderColor: (props) => props.textcolor || 'var(--primary)',
		borderStyle: 'solid',
		borderRadius: '4px',
		color: (props) => props.textcolor,
		lineHeight: 'normal',
		padding: '6px 25px',
		boxShadow: 'inherit',
		width: (props) => props.width,
		height: (props) => props.height,

		'&:hover': {
			borderWidth: (props) => props.borderwidth || '2px',
			boxShadow: 'inherit',
		},
	},
})(Button);
