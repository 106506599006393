import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import InputMask from 'react-input-mask';
import NavBreadcrumbs from 'components/Navigation/Breadcrumbs/Breadcrumbs';
import UIButton from 'components/UI/Button/Button';
import UIInput from 'components/UI/Input/Input';
import UISelect from 'components/UI/Select/Select';
import Modal from 'components/Modals/index.js';
import DropZone from './components/DropZone/index';
// import { fields } from './fields.js';

import { useFormik } from 'formik';
import * as yup from 'yup';
import api from 'services/api';
import { store } from 'store';
import { useDispatch } from 'react-redux';
import { setLoading } from 'store/modules/app/actions.js';

import Consultant from './components/Consultant/index.js';
import Distributor from './components/Distributor';
import Admin from './components/Admin';
import { updateUser } from 'store/modules/user/actions';

const validationSchema = yup.object().shape({
	password: yup.string().optional('Insira a senha'),
	confirmPassword: yup.string().optional('Confirme a senha'),
});

export default function Profile() {
	const [user, setUser] = useState({});

	const history = useHistory();
	const dispatch = useDispatch();

	const [successModal, setSuccessModal] = useState(false);
	const [submitForm, setSubmitForm] = useState(null);

	const handleSuccessModal = () => {
		setSuccessModal(!successModal);
	};

	useEffect(() => {
		const getUser = async () => {
			dispatch(setLoading(true));
			try {
				const user = store.getState().user;
				const { id, type } = user;
				let person = {};
				if (type === 'distributor') {
					const response = await api.get(`access?${type}[id]=${id}`);
					person = response.data.data[0];
				} else {
					const response = await api.get(`access/${user.accessId}`, {
						params: {
							$limit: -1,
						}
					});
					person = response.data;
				}
				setUser({
					...person,
					id,
					type,
					accessId: person.id,
				});
			} catch (error) {
				return null;
			} finally {
				dispatch(setLoading(false));
			}
		};

		// if (!user)
		getUser();
	}, []);

	const sendForm = evt => setSubmitForm(evt);

	const {
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
		handleSubmit,
	} = useFormik({
		validationSchema,
		initialValues: {
			password: '',
			confirmPassword: '',
		},
		validate: fields => {
			if (fields.password != fields.confirmPassword) {
				errors.password = 'Senhas não correspondem';
				errors.confirmPassword = 'Senhas não correspondem';
			} else if (fields.password === fields.confirmPassword) {
				delete errors.password;
				delete errors.confirmPassword;
			} else if (fields.password === "" && fields.confirmPassword === "") {
				delete errors.password;
				delete errors.confirmPassword;
			}
		},
		onSubmit(fields) {
			const update = async (data) => {
				dispatch(setLoading(true));
				try {
					if (!submitForm) return false;
					if (data.type != 'distributor') {
						data.employee = {
							...data.employee,
							email: data.email,
						};
					}
					if (data.email === user.email) {
						if (data.employee) delete data.employee.email;
						delete data.email;
					}
					await api.patch(`access/${data.id}`, data);

					if (data.type === 'distributor')
						await api.patch(`distributor/${data.distributorId}`, data);
					else await api.patch(`employee/${data.employeeId}`, data);

					dispatch(updateUser({
						data,
						type: store.getState().user.type,
					}));

					return true;
				} catch (error) {
					return false;
				} finally {
					dispatch(setLoading(false));
				};
			};
			const dataPrepare = async () => {
				let state;
				let data = {
					...user,
				}
				if (fields.password && fields.confirmPassword) {
					data = {
						...data,
						password: fields.password,
					}
				}
				if (submitForm) {
					data = {
						...data,
						...submitForm.values,
						email: submitForm.values.email,
					};
				}
				if (data.password || submitForm) {
					state = await update(data);
				}
				if (state === undefined || state === true) {
					handleSuccessModal();
				}
			};

			if (fields.password != fields.confirmPassword) {
				errors.password = 'Senhas não correspondem';
				errors.confirmPassword = 'Senhas não correspondem';
			}
			else {
				if (fields.password === fields.confirmPassword) {
					delete errors.password;
					delete errors.confirmPassword;
				}
				dataPrepare();
			}
		},
	});

	return (
		<Box>
			<NavBreadcrumbs items={breadcrumbs} />
			<Typography variant="h1">Editar usuário</Typography>
			<Box mx={6} my={3} p={2} css={{ background: '#fff' }}>
				<Grid container>
					<Grid item xs={12}>
						<Typography variant="h4">Informações do usuário</Typography>
					</Grid>
					<Grid item xs={12}>
						<Box mx={-2}>
							<Divider />
						</Box>
					</Grid>
					<Grid item xs={3}>
						<Box mb={2}>
							<DropZone
								userDefault={user}
								userId={user.type === 'distributor' ? user.id : user.employeeId}
							/>
						</Box>
					</Grid>
					<Grid item xs={9}>
						{
							user && (user.type === 'consultant' || user.type === 'financier') ?
								<Consultant user={user} />
								:
								user && user.type === 'distributor' ?
									<Distributor user={user} />
									:
									user && user.type === 'admin' ?
										<Admin user={user} submit={sendForm} />
										:
										<div />
						}
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid container justify="flex-end">
						<Grid item xs={9}>
							<form
								onSubmit={(e) => {
									e.preventDefault();
									handleSubmit();
								}}
							>
								<Box mr={5} ml={1} my={2}>
									<Grid container spacing={2} justify="flex-end">
										<Grid item xs={6}>
											<UIInput
												type="password"
												label="Nova senha"
												value={values.password}
												onChange={handleChange('password')}
												onBlur={handleBlur('password')}
												error={touched.password && errors.password}
											/>
										</Grid>
										<Grid item xs={6}>
											<UIInput
												type="password"
												label="Confirmar senha"
												value={values.confirmPassword}
												onChange={handleChange('confirmPassword')}
												onBlur={handleBlur('confirmPassword')}
												error={touched.confirmPassword && errors.confirmPassword}
											/>
										</Grid>
										<Grid item xs={4}>
											<UIButton type="submit" text="Confirmar" fullWidth />
										</Grid>
									</Grid>
								</Box>
							</form>
						</Grid>
					</Grid>
				</Grid>
			</Box>
			<Modal
				variant="success"
				title="Perfil alterado com sucesso"
				buttonText="Voltar para a tela inicial"
				buttonAction={() => history.push(`/`)}
				open={successModal}
				onClose={() => handleSuccessModal()}
			/>
		</Box>
	);
}

const breadcrumbs = [{ text: 'Dashboard' }, { text: 'Usuários' }];
