import React from 'react';

import { Box, DialogContent, Grid, Typography } from '@material-ui/core';

import UIButton from 'components/UI/Button/Button';

import { StyledDialog } from './styles';
import { IoMdCloseCircle } from 'react-icons/io';
import UIText from 'components/UI/Text/Text';

export default function ErrorModal({
	onClose,
	open,
	title,
	buttonCancelAction,
	buttonCancelText = 'Cancelar',
	buttonConfirmAction,
	buttonConfirmText = 'Confirmar',
	colorIcon = 'var(--error)',
	subtitle,
}) {
	return (
		<StyledDialog onClose={onClose} open={open} maxWidth="xs" fullWidth>
			<Box align="center">
				<Box align="center">
					<IoMdCloseCircle color={colorIcon} size={120} onClick={onClose} />
				</Box>
				<Typography variant="h1" align="center">
					{title}
				</Typography>
				<Box pt={1} pl={4} pr={4}>
					<UIText fontSize="14px" text={subtitle} />
				</Box>
				<DialogContent>
					<Box pl={2} pr={2} pt={1}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<UIButton
									text={buttonCancelText}
									onClick={buttonCancelAction}
									texttransform="inherit"
									fullWidth
								/>
							</Grid>
							<Grid item xs={6}>
								<UIButton
									text={buttonConfirmText}
									onClick={buttonConfirmAction}
									texttransform="inherit"
									variant="outlined"
									fullWidth
								/>
							</Grid>
						</Grid>
					</Box>
				</DialogContent>
			</Box>
		</StyledDialog>
	);
}
