import {
	Input,
	InputLabel,
	Card,
	Divider,
	Box,
	withStyles,
	Typography,
	TextField,
	Grid,
} from '@material-ui/core';

import styled from 'styled-components';

export const SectionBody = styled.div`
	width: 100%;
	max-height: 400px;
	padding: 5px 20px;
	overflow: auto;

	overflow-x: hidden;
	::-webkit-scrollbar {
		width: 5px;
	}
	::-webkit-scrollbar-track {
		border-radius: 3px;
	}
	::-webkit-scrollbar-thumb {
		background: #c4c4c4;
		border-radius: 3px;
	}
`;

export const StyledCard = withStyles({
	root: {
		boxShadow: 'inherit',
		borderRadius: '4px',
		padding: '15px 0px',
	},
})(Card);

export const StyledDivider = withStyles({
	root: {
		margin: '15px -15px',
	},
})(Divider);

export const StyledInput = withStyles({
	root: {
		border: '1px dashed var(--text-grey)',
		borderRadius: '4px',
		padding: '0px 5px',
		fontSize: '12px',
		color: 'var(--text-dark)',
	},
})(Input);

export const StyledInputLabel = withStyles({
	root: {
		fontWeight: '400',
		fontSize: '14px',
		marginBottom: '7px',
		marginTop: '10px',
	},
})(InputLabel);

export const StyledTextField = withStyles({
	root: {
		'& .MuiInput-underline:before': {
			borderBottom: '1px solid var(--text-grey)',
		},
		'& .MuiFormLabel-root': {
			color: 'var(--primary)',
			fontSize: '20px',
		},
	},
})(TextField);

export const Confirm = withStyles({
	root: {
		fontSize: '12px',
		color: 'var(--primary)',
		marginLeft: 'auto',
		marginTop: '5px',
		marginBottom: '-5px',
		width: 'min-content',
	},
})(Typography);

export const BlockCard = withStyles({
	root: {
		minHeight: '70px',
		background: 'rgba(201, 80, 80, 0.16)',
		borderLeft: '3px solid var(--error)',
	},
})(Box);
