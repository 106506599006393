import React from 'react';

import { Grid } from '@material-ui/core';

import UIText from 'components/UI/Text/Text';

import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import { CardContainer } from './styles';

export default function SmallCard({ informations }) {
	/*
		template de informations = {
			borderColor,
			title,
			number,
			negative,
			percentage,
		}
	*/

	return (
		<CardContainer container color={informations.borderColor}>
			<Grid item xs={12}>
				<UIText
					fontSize="12px"
					fontWeight="400"
					text={informations.title}
					gutterBottom
				/>
			</Grid>
			<Grid
				container
				direction="row"
				justify="space-between"
				alignItems="center"
			>
				<UIText
					text={informations.number}
					fontSize="24px"
					fontWeight="500"
					textcolor="var(--text-dark)"
				/>
				{informations.percentage ?
					<Grid item xs={4}>
						<Grid
							container
							direction="row"
							justify="space-between"
							alignItems="center"
						>
							<UIText
								textcolor={informations.negative ? 'var(--error)' : 'var(--success)'}
								fontSize="10px"
								text={informations.percentage + '%'}
							/>
							{
								informations.negative ? (
									<BsArrowDown size={12} color={'var(--error)'} />
								) : (
										<BsArrowUp size={12} color={'var(--success)'} />
									)
							}
						</Grid>
					</Grid>
				: null
				}

			</Grid>
		</CardContainer>
	);
}
