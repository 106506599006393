import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';

import NotificationCard from 'components/Dashboard/NotificationCard';
import UIButton from 'components/UI/Button/Button';
import UISearch from 'components/UI/Search/Search';
import Card from 'components/Cards/index';
import TableOpenProcess from 'components/Process/TableOpenProcess';
import TablePendencies from 'components/Pendencies/TablePendencies';
import DefineReportModal from './components/DefineReportModal';
import UIText from 'components/UI/Text/Text';

import api from 'services/api';
import { store } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { setModalNewProcess } from 'store/modules/process/actions';

import { formatToNumber } from 'brazilian-values';

import mock from './mock.json';

export default function ScreensDashboard() {
	const history = useHistory();
	const dispatch = useDispatch();

	const { id, type } = store.getState().user;

	useEffect(() => {
		dispatch({
			type: '@user/GET_PICTURE',
			userId: id,
		});
	}, []);

	const [openProcess, setOpenProcess] = useState({});
	const [paramsProcess, setParamsProcess] = useState({});
	const [process, setProcess] = useState([]);
	const [allProcess, setAllProcess] = useState([]);
	const [defineReport, setDefineReport] = useState(false);
	const [dashboard, setDashboard] = useState('');
	const [search, setSearch] = useState({
		input: '',
		select: '',
	});

	const pendencies = useSelector((state) => state.user.pendencies);

	useEffect(() => {
		async function getOpenProcesses() {
			try {
				const response = await api.get(`process`, {
					params:
						type === 'consultant'
							? {
								employeeId: id,
								status: 'const recording',
								'$sort[createdAt]': -1,
							}
							: type === 'financier'
								? {
									status: 'awaiting financier',
									'$sort[createdAt]': -1,
								}
								: {},
				});

				setOpenProcess(response.data);
			} catch (error) {
				return null;
			}
		}
		getDashboard();
		async function getProcesses() {
			let param;
			if (type === 'distributor') {
				param = { distributorId: id };
			} else if (type === 'consultant') {
				param = { employeeId: id };
			} else {
			}
			const response = await api.get(
				`process`,
				{
					params: {
						...param,
						...paramsProcess,
						'$sort[createdAt]': -1,
					},
				},
			);
			if (response.status === 200) {
				setAllProcess(response.data || []);
				setProcess((response.data || {}).data || []);
			}
		}
		getProcesses();

		if (['consultant', 'financier'].includes(type)) getOpenProcesses();
	}, [id, type, paramsProcess]);

	const handleDefineReport = () => {
		setDefineReport(!defineReport);
	};

	const getDashboard = async() => {
		const dashboard = await api.get('/dashboard');
		setDashboard(dashboard.data);
	}

	const handleChangeSearch = evt => {
		setSearch(search => {
			return {
				...search,
				[evt.name]: evt.value,
			};
		});
	}

	useEffect(() => {
		const objToArray = object => Object.keys(object || {}).map(key => object[key]);
		const recursive = data => {
			const response = [];
			const array = objToArray(data);
			const haveMore = array.filter(item => Object.keys(item || {}).length > 0 && typeof (item) != "string");
			if (haveMore.length > 0) {
				const arrayCopy = array;
				haveMore.map(item => {
					arrayCopy.splice(array.indexOf(item), 1);
					recursive(item).filter(item => item != null && item != undefined).map(result => response.push(result));
				});
				arrayCopy.filter(item => item != null && item != undefined).map(result => response.push(result));
			}
			else array.filter(item => item != null && item != undefined).map(item => response.push(item));
			return response;
		};
		const searchBy = condition => {
			return allProcess.data.filter(condition);
		};

		if (search.input) {
			const current = String(search.input).toLocaleLowerCase();
			const newProcess = [...allProcess.data];
			const found = [];
			newProcess.map(process => {
				// all process
				const array = recursive(objToArray(process));
				const values = array.findIndex(item => String(item).toLowerCase().indexOf(current) > -1);
				if (values > -1) found.push(process);
			});
			if (found) setProcess(found);
		} else if (search.select) {
			const current = String(search.select).toLocaleLowerCase();
			let filtered = [];
			switch (current) {
				case 'ganho total': {
					filtered = searchBy(item => item.status === 'total win');
					break;
				}
				case 'ganho parcial': {
					filtered = searchBy(item => item.status === 'parcial win');
					break;
				}
				case 'perda': {
					filtered = searchBy(item => item.status === 'lost');
					break;
				}
				case 'suspensas': {
					filtered = searchBy(item => item.status === 'suspended' || item.status === 'suspending');
					break;
				} case 'pendencia distribuidor': {
					filtered = searchBy(item => item.status === 'dist recording' || item.status === 'awaiting distributor result');
					break;
				} case 'pendencia consultor': {
					filtered = searchBy(item => item.status === 'const recording' || item.status === 'awaiting consultant result');
					break;
				} case 'pendencia financeiro': {
					filtered = searchBy(item => item.status === 'awaiting financier' || item.status === 'waiting to confirm consultant values');
					break;
				}
				default: filtered = allProcess.data;
			}
			setProcess(filtered);
		} else {
			setProcess(allProcess.data || []);
		}
	}, [search]);

	return (
		<>
			<Box pt={3}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						{pendencies ? (
							<NotificationCard
								msg="A ata do processo de São José dos Campos que expirou a 20 dias ainda não foi anexada"
								title="A Ata do processo não foi entregue"
								bold="São José dos Campos"
							/>
						) : null}
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="h4">Etapas das processo</Typography>
					</Grid>
					<Grid item xs={6} md={3}>
						<Typography variant="h4">Ganhos e perdas</Typography>
					</Grid>
					<Grid item xs={6} md={3}>
						<Typography variant="h6" align="right">
							Período referente aos últimos 365 dias
						</Typography>
					</Grid>
					<Grid item xs={12} md={4} lg={2}>
						<Card
							variant="small"
							informations={{
								borderColor: 'var(--primary)',
								title: 'Quantidade de processos identificados',
								number: dashboard.Process ?? 0,
							}}
						/>
					</Grid>
					<Grid item xs={12} md={4} lg={2}>
						<Card
							variant="small"
							informations={{
								borderColor: 'var(--pink)',
								title: 'Processos em andamento',
								number: dashboard.InProgressProcess ?? 0,
								percentage: dashboard.PercentProgress ?? 0,
								negative: false,
							}}
						/>
					</Grid>
					<Grid item xs={12} md={4} lg={2}>
						<Card
							variant="small"
							informations={{
								borderColor: 'var(--orange)',
								title: 'Valor em andamentos	dos processos (R$)',
								number: dashboard.Total ? formatToNumber(dashboard.Total) : 0,
								percentage: dashboard.PercentBalance ? Math.round(dashboard.PercentBalance) : 0,
							}}
						/>
					</Grid>
					<Grid item xs={12} md={4} lg={2}>
						<Card
							variant="small"
							informations={{
								borderColor: 'var(--primary)',
								title: 'Ganhos',
								number: dashboard.InProgressProcess ?? 0,
							}}
						/>
					</Grid>
					<Grid item xs={12} md={4} lg={2}>
						<Card
							variant="small"
							informations={{
								borderColor: 'var(--pink)',
								title: 'Perdidos',
								number: dashboard.PartialLost ?? 0,
							}}
						/>
					</Grid>
					<Grid item xs={12} md={4} lg={2}>
						<Card
							variant="small"
							informations={{
								borderColor: 'var(--primary)',
								title: 'Saldo a faturar R$mil (processos vigentes)',
								number: '24.456',
								percentage: dashboard.BillingBalance ?? 0,
								negative: true,
							}}
						/>
					</Grid>
					<Grid item xs={12} md={9}>
						<UISearch
							selectOptions={mock.options}
							onChangeSelect={evt => handleChangeSearch({ name: 'select', value: evt.target.value })}
							onChangeInput={evt => handleChangeSearch({ name: 'input', value: evt.target.value })}
							valueInput={search.input}
							valueSelect={search.select || ''}
						/>
					</Grid>
					<Grid item xs={12} md={3}>
						{['financier', 'admin'].includes(type) && (
							<>
								<UIButton
									text={'Exportar relatório'}
									fullWidth
									onClick={handleDefineReport}
								/>
								<DefineReportModal
									show={defineReport}
									onClose={handleDefineReport}
								/>
							</>
						)}
						{['consultant', 'surpervisor', 'distributor'].includes(type) && (
							<UIButton
								text={'+ Adicionar processo'}
								fullWidth
								onClick={() => dispatch(setModalNewProcess(true))}
							/>
						)}
					</Grid>
					{type !== 'admin' ? (
						<>
							<Grid item xs={12}>
								<Grid container spacing={3} justify="space-between">
									<Grid item xs={6}>
										<Box display="flex" flexDirection="row">
											<Typography variant="h4">
												Ações a serem executadas
											</Typography>
											<Box ml={1}>
												<Typography variant="h6">
													{process.length} processos em aberto
												</Typography>
											</Box>
										</Box>
									</Grid>
									<Grid item>
										<UIText
											text="Ver todas"
											textcolor="var(--primary)"
											fontSize="12px"
											onClick={() => history.push('/pendencias')}
											cursor="pointer"
										/>
									</Grid>
								</Grid>
							</Grid>
							{(openProcess.data || []).slice(0, 4).map((process) => (
								<Grid
									onClick={() =>
										process.status === 'const recording'
											? history.push(`processo/novo/${process.id}`)
											: history.push(`processo/${process.id}`)
									}
									item
									key={process.id}
								>
									<Card
										variant="action"
										informations={{
											...process,
											title: 'Nutrilac Alimentos',
											bolderTitle:
												process.status === 'const recording'
													? 'Adicionar processo ao sistema'
													: process.status === 'awaiting financier'
														? 'Faturar pedido do processo'
														: null,
											city: `${process.city}-${process.uf}`,
											typeInstitution: (process.institution || {}).type,
										}}
									/>
								</Grid>
							))}
							<Grid item xs={12}>
								<Grid container>
									<Grid item xs={12} md={8}>
										<TableOpenProcess
											items={process || []}
											totalPages={Math.ceil(process.total / 10)}
											onChangePaginate={(_, value) =>
												setParamsProcess((params) => {
													return {
														...params,
														$skip: (value - 1) * 10,
													};
												})
											}
										/>
									</Grid>
									<Grid item xs={12} md={4}>
										<TablePendencies pendencies={pendencies} height="300px" />
									</Grid>
								</Grid>
							</Grid>
						</>
					) : (
							<Grid item xs={12}>
								<Grid container>
									<Grid item xs={12}>
										<TableOpenProcess
											items={process || []}
											totalPages={Math.ceil(process.total / 10)}
											onChangePaginate={(_, value) =>
												setParamsProcess((params) => {
													return {
														...params,
														$skip: (value - 1) * 10,
													};
												})
											}
										/>
									</Grid>
								</Grid>
							</Grid>
						)}
				</Grid>
			</Box>
		</>
	);
}
