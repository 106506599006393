import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import InputMask from 'react-input-mask';

import UIInput from 'components/UI/Input/Input';
import UISelect from 'components/UI/Select/Select';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { maskValue } from 'services/mask';
import { states } from 'utils/index.js';

const validationSchema = yup.object().shape({
	name: yup.string().required('Digite o nome'),
	socialName: yup.string().required('Digite o sobrenome'),
	email: yup.string().required('Digite o email').email('Email inválido'),
	telephone: yup.string().required('Digite o telefone'),
	contact: yup.string().required('Digite o nome do contato'),
	cnpj: yup.string().required('Digite o CNPJ').cnpj('CNPJ inválido'),
	uf: yup.string().required('Selecione a UF'),
	city: yup.string().required('Selecione o município'),
});

export default function Distributor({ user }) {

	const {
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
	} = useFormik({
		validationSchema,
		initialValues: {
			...user.distributor,
			email: user.email,
		} || {
			email: '',
			name: '',
			socialName: '',
			telephone: '',
			contact: '',
			cnpj: '',
		},
		onSubmit(fields) { },
	});

	return (
		<Box pt={3} pb={3}>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<UIInput
						value={values.name}
						name="name"
						label="Nome"
						error={touched.name && errors.name}
						onChange={handleChange('name')}
						onBlur={handleBlur('name')}
						disabled
					/>
				</Grid>
				<Grid item xs={6}>
					<UIInput
						value={values.socialName}
						name="socialName"
						label="Razão social"
						error={touched.socialName && errors.socialName}
						onChange={handleChange('socialName')}
						onBlur={handleBlur('socialName')}
						disabled
					/>
				</Grid>
				<Grid item xs={6}>
					<UIInput
						value={values.email}
						name="email"
						label="Email"
						error={touched.email && errors.email}
						onChange={handleChange('email')}
						onBlur={handleBlur('email')}
						disabled
					/>
				</Grid>
				<Grid item xs={6}>
					<InputMask
						value={maskValue('tel', values.telephone)}
						onChange={handleChange('telephone')}
						onBlur={handleBlur('telephone')}
						mask={['99.999.999/9999-99', '99.999.999/9999-99']}
						maskChar=" "
						disabled
					>
						{() => (
							<UIInput
								error={touched.telephone && errors.telephone}
								value={maskValue('tel', values.telephone)}
								name="telephone"
								label="Telefone"
								disabled
							/>
						)}
					</InputMask>
				</Grid>
				<Grid item xs={6}>
					<UIInput
						value={values.contact}
						name="contact"
						label="Contato"
						error={touched.contact && errors.contact}
						onChange={handleChange('contact')}
						onBlur={handleBlur('contact')}
						disabled
					/>
				</Grid>
				<Grid item xs={6}>
					<UIInput
						name="cnpj"
						onBlur={handleBlur('cnpj')}
						onChange={handleChange('cnpj')}
						label="CNPJ"
						error={touched.cnpj && errors.cnpj}
						disabled
						value={values.cnpj}
					/>
				</Grid>
				<Grid item xs={6}>
					<UIInput
						value={values.uf}
						name="uf"
						label="UF"
						error={touched.uf && errors.uf}
						onChange={handleChange('uf')}
						onBlur={handleBlur('uf')}
						disabled
					/>
				</Grid>
				<Grid item xs={6}>
					<UIInput
						value={values.city}
						name="city"
						label="Município"
						error={touched.city && errors.city}
						onChange={handleChange('city')}
						onBlur={handleBlur('city')}
						disabled
					/>
				</Grid>
			</Grid>
		</Box>
	)
}
