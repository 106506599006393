import { withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';

export const Cell = withStyles({
	root: {
		background: (props) => props.background,
		fontSize: (props) => props.fontSize,
		fontWeight: (props) => props.fontWeight,
		lineHeight: (props) => props.lineheight,
		color: (props) => props.color,
		borderTop: (props) => props.bordertop,
		borderBottom: (props) => props.borderbottom,
		borderLeft: (props) => props.borderleft,
		borderRight: (props) => props.borderright,
		textDecorationLine: (props) => props.textdecorationline,
		cursor: (props) => props.cursor,
		height: (props) => props.height,
		padding: (props) => props.padding,
		display: 'flex',
		alignItems: 'center',
	},
})(TableCell);

export const MenuIcon = withStyles({
	root: {
		color: (props) => props.color,
	},
})(MoreVertIcon);

export const Text = withStyles({
	root: {
		backgroundColor: 'rgba(80, 130, 201, 0.16)',
		width: '20px',
		height: '20px',
		borderRadius: '50%',
		textAlign: 'center',
		lineHeight: '20px',
		fontSize: '12px',
		color: 'var(--primary)',
	},
})(Typography);

export const ItemMenu = withStyles({
	root: {
		color: (props) => props.color,
		fontWeight: '500',
		fontSize: '14px',
	},
})(MenuItem);
