import api from 'services/api';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { setProcess } from './actions';
import { setLoading } from '../app/actions';

export function* findProcess({ payload }) {
	const { id } = payload;

	yield put(setLoading(true));

	const response = yield call(api.get, `process/${id}`);

	if (response.status === 200) {
		yield put(setLoading(false));
		yield put(setProcess(response.data));
	} else {
		return null;
	}
}

export function* update({ payload }) {
	const { id } = payload;
	yield put(setLoading(true));
	const response = yield call(() => api.patch(`/process/${id}`, payload));
	yield put(setProcess(response.data));
	yield put(setLoading(false));
}

export default all([
	takeLatest('@process/UPDATE', update),
	takeLatest('@process/GET_PROCESS', findProcess),
]);
