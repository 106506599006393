import React from 'react';
import { TableRowStyled } from './style';
import PropTypes from 'prop-types';

function TableRow(props) {
	const {
		children,
		border,
		bordertop,
		borderbottom,
		borderleft,
		borderright,
		onClick,
	} = props;

	return (
		<TableRowStyled
			border={border}
			bordertop={bordertop}
			borderbottom={borderbottom}
			borderleft={borderleft}
			borderright={borderright}
			onClick={onClick}
		>
			{children}
		</TableRowStyled>
	);
}

export default TableRow;

TableRow.propTypes = {
	border: PropTypes.string,
	bordertop: PropTypes.string,
	borderbottom: PropTypes.string,
	borderleft: PropTypes.string,
	borderright: PropTypes.string,
	onClick: PropTypes.func,
};

TableRow.defaultProps = {
	border: 'none',
	bordertop: 'none',
	borderbottom: 'none',
	borderleft: 'none',
	borderright: 'none',
	onClick: () => {}
};
