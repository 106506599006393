import produce from 'immer';


const INITIAL_STATE = {
	access_token: null
};

export default function auth(state = INITIAL_STATE, action) {
	return produce(state, (draft) => {
		switch (action.type) {
			case '@auth/LOGIN': {
				break;
			}
			case '@auth/LOGIN_SUCCESS': {
				draft.access_token = action.payload.token;
				document.location.href = '/';
				break;
			}
			case '@auth/LOGOUT': {
				draft.access_token = null;
				draft.user = null;
				document.location.href = '/login';
				break;
			}
			default:
				return state;
		}
	});
}
