import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Divider,
	Grid,
} from '@material-ui/core';
import { formatToNumber } from 'brazilian-values';
import UITag from 'components/UI/Tag';
import React from 'react';
import { StyledP, StyledSpan } from './style';

/**
 * @param {Object} item - Product
 * @param {children} item - Form body ?
 */
export default function ProductsAccordion({
	children,
	order,
	result,
	item,
	confirmValues,
	tag,
	...rest
}) {
	return (
		<Accordion elevation={0} key={item.itemNumber}>
			<Box mx={-2}>
				<AccordionSummary>
					<Grid container direction="row" spacing={2}>
						{tag && <UITag {...tag} />}
						{confirmValues ? (
							<>
								<Grid item xs={12}>
									<StyledP fontSize="20px" fontWeight="500">
										{(item.processProduct || {}).name}
									</StyledP>
								</Grid>
								<Grid item xs={2}>
									<StyledSpan color="grey">item: </StyledSpan>
									<StyledSpan color="blue"> {item.itemNumber} </StyledSpan>
								</Grid>
								<Grid item xs={3}>
									<StyledSpan color="grey">SKU: </StyledSpan>
									<StyledSpan color="blue">
										{(item.processProduct || {}).sku}
									</StyledSpan>
								</Grid>
								<Grid item xs={3}>
									<StyledSpan color="grey">Quant./Prod.: </StyledSpan>
									<StyledSpan color="blue">
										{formatToNumber(item.initialQuantity || 0)}
									</StyledSpan>
								</Grid>
								<Grid item xs={3}>
									<StyledSpan color="grey">(R$)Preço lista: </StyledSpan>
									<StyledSpan color="blue">
										{formatToNumber(item.priceList || 0)}
									</StyledSpan>
								</Grid>
								<Grid item xs={4}>
									<StyledSpan color="grey">(R$)Preço proposto: </StyledSpan>
									<StyledSpan color="blue">
										{formatToNumber(item.nfPrice || 0)}
									</StyledSpan>
								</Grid>
								<Grid item xs={3}>
									<StyledSpan color="grey">Markup: </StyledSpan>
									<StyledSpan color="blue">{item.markup}%</StyledSpan>
								</Grid>
								<Grid item xs={4}>
									<StyledSpan color="grey">(R$)Preço instituição: </StyledSpan>
									<StyledSpan color="blue">
										{formatToNumber(item.institutionValue || 0)}
									</StyledSpan>
								</Grid>
							</>
						) : (
							<>
								<Grid item xs={12}>
									<StyledP fontSize="20px" fontWeight="500">
										{(item.processProduct || {}).name}
									</StyledP>
								</Grid>
								<Grid item xs={2}>
									<StyledSpan color="grey">item: </StyledSpan>
									<StyledSpan color="blue"> {item.itemNumber} </StyledSpan>
								</Grid>
								<Grid item xs={2}>
									<StyledSpan color="grey">SKU: </StyledSpan>
									<StyledSpan color="blue">
										{(item.processProduct || {}).sku}
									</StyledSpan>
								</Grid>
								<Grid item xs={4}>
									<StyledSpan color="grey">(R$) Valor do item: </StyledSpan>
									<StyledSpan color="blue">
										{' '}
										{(item.processProduct || {}).value}{' '}
									</StyledSpan>
								</Grid>
							</>
						)}
						{item.discountOrder ? (
							<Grid item xs={2}>
								<UITag
									variant={(item.discountOrder || {}).status}
									text={(item.discountOrder || {}).status}
								></UITag>
							</Grid>
						) : null}
					</Grid>
				</AccordionSummary>
			</Box>
			<Divider />
			<AccordionDetails>
				<Grid container direction="row">
					{order ? (
						<>
							<Grid item xs={3}>
								<StyledSpan color="grey" fontSize="12px">
									(R$) Valor contratado
								</StyledSpan>
								<UITag variant="bgGrey" text={`R$ ${item.amount}`} />
							</Grid>
							<Grid item xs={3}>
								<StyledSpan color="grey" fontSize="12px">
									(R$) Saldo faturado
								</StyledSpan>
								<UITag variant="orange" text={`R$ ${item.currentBalance}`} />
							</Grid>
							<Grid item xs={3}>
								<StyledSpan color="grey" fontSize="12px">
									(KG) Saldo volume{' '}
								</StyledSpan>
								<StyledP
									color="orange"
									fontSize="12px"
									margin="5px 0px 0px 0px "
								>
									{' '}
									{`${item.volume}`}{' '}
								</StyledP>
							</Grid>{' '}
						</>
					) : null}
					{result ? (
						<Grid item xs={3}>
							<StyledSpan color="grey" fontSize="12px">
								(R$) Valor estipulado
							</StyledSpan>
							<UITag variant="bgGrey" text={`R$ ${item.value}`} />
						</Grid>
					) : null}
					<Grid item xs={12}>
						{/* <Box mt={4}>
							<StyledP>Informacoes do pedido</StyledP>
						</Box> */}
						{children}
					</Grid>
				</Grid>
			</AccordionDetails>
			<Divider />
		</Accordion>
	);
}
