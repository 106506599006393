import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { Autocomplete, createFilterOptions } from '@material-ui/lab';

import UIRadio from 'components/UI/Radio/Radio';
import UIInput from 'components/UI/Input/Input';
import UITextarea from 'components/UI/Textarea/Textarea';
import UIButton from 'components/UI/Button/Button';
import UIText from 'components/UI/Text/Text';
import api from 'services/api';

import { optionsYesNo } from './selectOptions';

import {
	StyledCard,
	SectionBody,
	StyledDivider,
	StyledTextField,
	BlockCard,
} from './style';
import { parseToNumber } from 'brazilian-values';
import { moneyMask, percentMask } from 'services/mask';
import { useParams } from 'react-router';

import { AiFillCloseCircle } from 'react-icons/ai';
import { getProcessProducts } from 'store/modules/processProduct/actions';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'store/modules/app/actions';

function CardScopeFields({ addItem }) {
	const dispatch = useDispatch();
	const { idProcess } = useParams();

	const [item, setItem] = useState({
		approve: true,
		discount: false,
	});
	const [productsList, setProductsList] = useState([]);
	const [productSelected, setProductSelected] = useState();
	const [inputError, setInputError] = useState({
		itemNumber: '',
		specification: '',
		priceList: '',
		initialQuantity: '',
		productId: '',
	});
	const [negateDiscount, setNegateDiscount] = useState('');

	useEffect(() => {
		getProducts();
	}, []);

	useEffect(() => {
		const nf = (
			parseToNumber(item.priceList || '') *
			(1 - parseToNumber((item.discountValue || '').replace('%', '')) / 100)
		).toFixed(2);

		const institution = (
			nf *
			(1 + parseToNumber((item.markup || '').replace('%', '')) / 100)
		).toFixed(2);

		setItem((item) => {
			return {
				...item,
				nfPrice: nf,
				institutionValue: institution,
			};
		});

		if (
			item.discount &&
			parseFloat(
				String(item.discountValue).replace('%', '').replace(',', '.'),
			) > 10
		)
			setNegateDiscount(<NegateDiscountCard />);
		else setNegateDiscount('');
	}, [item.priceList, item.discountValue, item.markup]);

	const getProducts = async () => {
		try {
			const response = await api.get(`products`);

			const products = response.data.data.map((item) => {
				return {
					title: item.sku,
					product: item.description,
					value: item.id,
				};
			});

			setProductsList(products);
		} catch (error) {
			return null;
		}
	};

	const newProduct = async () => {
		if (!validateFields()) {
			const data = {
				...item,
				priceList: parseToNumber(item.priceList || ''),
				discountValue: parseToNumber(
					(item.discountValue || '').replace('%', ''),
				),
				unity: 'UNI',
				markup: parseToNumber((item.markup || '').replace('%', '')),
				initialQuantity: parseToNumber(item.initialQuantity || ''),
				quantity: parseToNumber(item.initialQuantity || ''),
				processId: idProcess,
			};

			try {
				dispatch(setLoading(true));

				const response = await api.post(`/process-products/`, data);

				if (item.discount)
					await api.post(`discount-order`, {
						status: 'requested',
						discountValue: parseToNumber(
							(item.discountValue || '').replace('%', ''),
						),
						processProductId: response.data.id,
					});

				dispatch(getProcessProducts({ processId: idProcess }));
			} catch (error) {
				throw error;
			}

			dispatch(setLoading(false));

			setItem({ approve: true, discount: false });
			setProductSelected(null);
		}
	};

	const validateInputErrors = () => {
		const inputErrorKeys = Object.keys(inputError);

		return inputErrorKeys.forEach((inputKey) => {
			if (item[inputKey]) {
				setInputError((inputError) => {
					return {
						...inputError,
						[inputKey]: '',
					};
				});
			}
		});
	};

	const validateFields = () => {
		const inputErrorKeys = Object.keys(inputError);
		return inputErrorKeys.some((inputKey) => {
			if (!item[inputKey]) {
				setInputError((inputError) => {
					return {
						...inputError,
						[inputKey]: 'Preenchimento obrigatório',
					};
				});
				return true;
			}
			return false;
		});
	};

	const filterOptions = createFilterOptions({
		matchFrom: 'start',
		stringify: (option) => option.title,
	});

	const handleChangeItem = (event) => {
		setItem((item) => {
			return {
				...item,
				[event.target.name]: event.target.value,
			};
		});
		validateInputErrors();
	};

	function NegateDiscountCard() {
		return (
			<BlockCard pt={2} mb={2}>
				<Grid container justify="center" alignItems="center">
					<Grid item xs={3}>
						<Grid container justify="center" alignItems="center">
							<AiFillCloseCircle color="var(--error)" size={42} />
						</Grid>
					</Grid>
					<Grid item xs={9}>
						<Grid container>
							<Grid item xs={12}>
								<UIText
									text="Desconto excede política pré-aprovada"
									fontWeight="500"
									fontSize="12px"
									textcolor="var(--error)"
								/>
							</Grid>
							<Grid item xs={12}>
								<UIText
									text="Será nescessário a aprovação do financiero para prosseguir com esse valor"
									fontWeight="400"
									fontSize="12px"
									textcolor="var(--error)"
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</BlockCard>
		);
	}

	return (
		<StyledCard>
			<Grid container justify="space-between">
				<Grid item xs={12}>
					<Box pl={2} pr={2}>
						<Typography variant="h4">Validação oficial do processo</Typography>
					</Box>
					<StyledDivider />
				</Grid>
				<SectionBody pl={2} pr={2}>
					<Grid item xs={12}>
						<Box pb={2}>
							<UIInput
								label="Item"
								name="itemNumber"
								onChange={handleChangeItem}
								value={item.itemNumber || ''}
								helperText={inputError.itemNumber}
								error={inputError.itemNumber}
								type="number"
							/>
						</Box>
						<Box pb={2}>
							<UITextarea
								label="Especificação"
								name="specification"
								rows={3}
								onChange={handleChangeItem}
								value={item.specification || ''}
								helperText={inputError.specification}
								error={inputError.specification}
							/>
						</Box>
						<Box pb={2}>
							<UIInput
								label="Quantidade"
								name="initialQuantity"
								onChange={handleChangeItem}
								value={item.initialQuantity || ''}
								helperText={inputError.initialQuantity}
								error={inputError.initialQuantity}
								type="number"
								onKeyDown={(evt) => {
									if (evt.key === 'e') return evt.preventDefault();
								}}
							/>
						</Box>
						<Box pb={2}>
							<UIInput label="Unidade" name="unity" value="UNI" disabled />
						</Box>
						<Box pb={2}>
							<UIRadio
								label="Produto pode ser atendido?"
								items={optionsYesNo}
								name="approve"
								onChange={(e) =>
									setItem((item) => {
										return {
											...item,
											approve: e.target.value === 'sim' ? true : false,
										};
									})
								}
								value={item.approve ? 'sim' : 'não'}
								helperText={inputError.approve}
								error={inputError.approve}
							/>
						</Box>
						<Box pb={2}>
							<UITextarea
								label="Justificativa"
								rows={3}
								name="justify"
								onChange={handleChangeItem}
								value={item.justify || ''}
								disabled={item.approve}
							/>
						</Box>
						<Box pb={2}>
							<Autocomplete
								options={productsList}
								getOptionLabel={(option) => option.title || ''}
								filterOptions={filterOptions}
								value={productSelected ? productSelected : ''}
								onChange={(event, newValue) => {
									setItem((process) => {
										return {
											...process,
											productId: (newValue || {}).value || null,
											sku: (newValue || {}).title,
											productName: (newValue || {}).product,
										};
									});
									setProductSelected(newValue || {});
									validateInputErrors();
								}}
								renderInput={(params) => (
									<StyledTextField
										{...params}
										fullWidth
										InputLabelProps={{ shrink: true }}
										label="SKU"
										helperText={inputError.productId}
										error={inputError.productId ? true : false}
									/>
								)}
							/>
						</Box>
						<Box pb={2}>
							<UIInput
								label="Produto"
								value={productSelected ? productSelected.product : ''}
								disabled
							/>
						</Box>
						<Box pb={2}>
							<UIInput
								label="(R$)Preço lista"
								onChange={(e) =>
									handleChangeItem({
										target: {
											name: 'priceList',
											value: moneyMask(e.target.value),
										},
									})
								}
								value={item.priceList || ''}
								error={inputError.priceList}
							/>
						</Box>
						<Box pb={2}>
							<UIRadio
								label="Solicitar desconto?"
								items={optionsYesNo}
								name="discount"
								onChange={(e) =>
									setItem((item) => {
										return {
											...item,
											discount: e.target.value === 'sim' ? true : false,
										};
									})
								}
								value={item.discount ? 'sim' : 'não'}
							/>
						</Box>
						<Box pb={2}>
							<UIInput
								label="Desconto proposto"
								name="discountValue"
								onChange={(e) =>
									handleChangeItem({
										target: {
											name: 'discountValue',
											value: percentMask(e.target.value),
										},
									})
								}
								value={item.discount ? item.discountValue || '' : ''}
								disabled={!item.discount}
								helperText={item.discount ? inputError.discountValue : ''}
								error={item.discount ? inputError.discountValue : false}
							/>
						</Box>
						{negateDiscount}
						<Box pb={2}>
							<UIInput
								label="(R$)Preço NF Nestlé"
								name="nfPrice"
								value={item.nfPrice || 0}
								type="number"
								disabled={true}
							/>
						</Box>
						<Box pb={2}>
							<UIInput
								label="Markup"
								name="markup"
								onChange={(e) =>
									handleChangeItem({
										target: {
											name: 'markup',
											value: percentMask(e.target.value),
										},
									})
								}
								value={item.markup || ''}
								helperText={item.markup ? inputError.markup : ''}
								error={item.markup ? inputError.markup : false}
							/>
						</Box>
						<Box pb={2}>
							<UIInput
								type="number"
								label="(R$)Preço instituição"
								name="finalValue"
								value={item.institutionValue}
								disabled={true}
							/>
						</Box>
						{/* <Box pb={2}>
								<NegateDiscountCard />
							</Box> */}
						{/* <Box pb={2}>
								<UITextarea
									label="Justificativa"
									rows={3}
									onChange={handleChangeItem}
									value={item.justify || ''}
								/>
							</Box> */}
						<UIButton
							text="Adicionar produto"
							fullWidth
							onClick={() => newProduct()}
						/>
					</Grid>
				</SectionBody>
			</Grid>
		</StyledCard>
	);
}

export default CardScopeFields;
