import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useFormik } from 'formik';

import MainModal from 'components/Modals/MainModal';
import UIAutocomplete from 'components/UI/Autocomplete';
import { Box, Grid } from '@material-ui/core';
import UIInput from 'components/UI/Input/Input';
import UIButton from 'components/UI/Button/Button';
import api from 'services/api';
import { createFilterOptions } from '@material-ui/lab';

export default function SelectSubstituteUserModal({
	show,
	onClose,
	onConfirm,
	showPeriod,
	user,
}) {
	const [consultants, setConsultants] = useState([]);

	const validationSchema = useMemo(
		() =>
			yup.object().shape({
				sap: yup.mixed().required('Selecione o código SAP'),
				period: showPeriod
					? yup.string().required('Escolha um período').nullable()
					: undefined,
			}),
		[showPeriod],
	);

	const {
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
		handleSubmit,
		setFieldValue,
		resetForm,
	} = useFormik({
		initialValues: {
			sap: null,
			period: null,
		},
		validationSchema,
		onSubmit({ sap, period }) {
			onConfirm({ accessId: sap.value, oldAccessId: user.id, period });
		},
	});

	useEffect(() => {
		if (!show) {
			resetForm({ sap: null, period: '' });
		}
	}, [show, resetForm]);

	useEffect(() => {
		async function getConsultants() {
			try {
				const response = await api.get(
					`access?employeeId[$nin]=null&employeeId[$nin]=${user.id}`,
					{
						params: {
							'employee[accessLevel]': (user.employee || {}).accessLevel,
						},
					},
				);
				setConsultants(
					response.data.data.map(({ employee, id }) => {
						return {
							title: (employee || {}).sap,
							value: id,
							name: (employee || {}).name,
						};
					}),
				);
			} catch (error) {
				throw error;
			}
		}

		getConsultants();
	}, [user]);

	return (
		<MainModal
			show={show}
			title="Definir usuário substituto"
			width="small"
			onClose={onClose}
		>
			<form
				onSubmit={(e) => {
					handleSubmit(e);
					e.preventDefault();
				}}
			>
				<Box marginTop="16px">
					<Grid container spacing={3}>
						{showPeriod && (
							<Grid item md={12}>
								<UIInput
									value={values.period}
									label="Dias (Período)"
									type="number"
									inputProps={{ min: '1' }}
									error={touched.period && errors.period}
									onChange={handleChange('period')}
									onBlur={handleBlur('period')}
								/>
							</Grid>
						)}
						<Grid item md={6}>
							<UIAutocomplete
								value={values.sap}
								options={consultants}
								label="SAP"
								onChange={(_, newValue) => setFieldValue('sap', newValue)}
								error={touched.sap && errors.sap}
								onBlur={handleBlur('sap')}
								filter
							/>
						</Grid>
						<Grid item md={6}>
							<UIInput
								value={(values.sap || {}).name}
								label="Usuário"
								disabled
							/>
						</Grid>
					</Grid>
				</Box>
				<Box display="flex" justifyContent="flex-end" marginTop="46px">
					<UIButton text="Voltar" variant="outlined" onClick={onClose} />
					<Box width="8px" />
					<UIButton text="Confirmar" type="submit" />
				</Box>
			</form>
		</MainModal>
	);
}

SelectSubstituteUserModal.propTypes = {
	show: PropTypes.bool,
	onClose: PropTypes.func,
	onConfirm: PropTypes.func,
	showPeriod: PropTypes.bool,
};

const saps = [{ value: '123456', title: '1231321' }];
