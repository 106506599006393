import React from 'react';
import { TableContainerStyled, StyledScrollContaier } from './style';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import PropTypes from 'prop-types';

function TableSkeleton(props) {
	const { head, body, hideScrollbars, verticalScroll, horizontalScroll } = props;
	return (
		<TableContainerStyled>
			<StyledScrollContaier
				hideScrollbars={hideScrollbars}
				vertical={verticalScroll}
				horizontal={horizontalScroll}
			>
				<Table>
					<TableHead>{head}</TableHead>
					<TableBody>{body}</TableBody>
				</Table>
			</StyledScrollContaier>
		</TableContainerStyled>
	);
}

export default TableSkeleton;

TableSkeleton.propTypes = {
	hideScrollbars: PropTypes.bool,
	verticalScroll: PropTypes.bool,
	horizontalScroll: PropTypes.bool,
}

TableSkeleton.defaultProps = {
	hideScrollbars: true,
	verticalScroll: false,
	horizontalScroll: false,
}
