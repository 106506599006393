import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import { applyMiddleware, createStore } from 'redux';

import persistReducer from './persistReducer';

import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
	persistReducer(rootReducer),
	applyMiddleware(sagaMiddleware),
);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);


export { store, persistor };
