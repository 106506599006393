import { takeLatest, all, call, put } from 'redux-saga/effects';
import api from 'services/api';
import { setEmployee } from '../user/actions';
import { setDistributor } from '../user/actions';
import { login_success } from './actions';

import io from 'socket.io-client';
import client from 'services/socket';
import feathers from 'feathers-client';

export function* login({ payload }) {
	try {
		const { email, password } = payload;
		const response = yield call(api.post, '/authentication', {
			email,
			password,
			strategy: 'local',
		});

		if (response.status === 201) {
			const access_token = response.data.accessToken;

			if (response.data.access.employee)
				yield put(setEmployee(response.data.access));
			else yield put(setDistributor(response.data.access));

			yield put(login_success(access_token));
		}
	} catch (error) {
		return error;
	}
}

export async function setToken({ payload }) {
	if (!payload) return;
	const { access_token } = payload.auth;
	if (access_token) {
		api.defaults.headers.Authorization = `Bearer ${access_token}`;
		const socket = io('http://localhost:3000/', { path : "/ws/" });
		client.configure(feathers.socketio(socket));
		client.configure(feathers.hooks());
		await socket.emit(
			'create',
			'authentication',
			{
				strategy: 'jwt',
				accessToken: access_token,
			},
			function (error, authResult) {
			},
		);

	}
}

export default all([
	takeLatest('persist/REHYDRATE', setToken),
	takeLatest('@auth/LOGIN', login),
]);
