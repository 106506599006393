import api from 'services/api';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { getProcessProducts, setProcessProducts } from './actions';
import { setLoading } from '../app/actions';

export function* getAll({ payload }) {
	const { processId } = payload;

	yield put(setLoading(true));

	const response = yield call(api.get, `process-products`, {
		params: {
			processId,
		},
	});
	if (response.data) {
		yield put(setProcessProducts(response.data.data));
		yield put(setLoading(false));
	} else {
		return null;
	}
}

export function* create({ payload }) {
	try {
		yield put(setLoading(true));
		yield call(() => api.post(`/process-products/`, payload));
		yield put(getProcessProducts(payload));
	} catch (error) {
		return null;
	}

	yield put(setLoading(false));
}

export function* update({ payload }) {
	try {
		const { id } = payload;
		yield put(setLoading(true));
		yield call(() => api.patch(`/process-products/${id}`, payload));
		yield put(getProcessProducts(payload));
	} catch (error) {
		return error;
	}

	yield put(setLoading(false));
}

export function* remove({ payload }) {
	try {
		const { id } = payload;
		yield put(setLoading(true));
		yield call(() => api.delete(`/process-products/${id}`));
		yield put(getProcessProducts(payload));
	} catch (error) {
		return error;
	}
	yield put(setLoading(false));
}

export default all([
	takeLatest('@processProduct/CREATE', create),
	takeLatest('@processProduct/GET', getAll),
	takeLatest('@processProduct/UPDATE', update),
	takeLatest('@processProduct/REMOVE', remove),
]);
