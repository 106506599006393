import { FormControlLabel, InputLabel, withStyles } from '@material-ui/core';

export const StyledInputLabel = withStyles({
	root: {
		color: (props) => props.color || 'var(--primary)',
		fontSize: (props) => props.fontSize || '20px',
	},
})(InputLabel);

export const StyledFormControlLabel = withStyles({
	root: {
		marginTop: '17px',
	},
	label: {
		fontSize: (props) => props.sizelabel || '14px',
	},
})(FormControlLabel);
