import React, { useEffect, useState } from 'react';
import Modal from 'components/Modals';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { formatToNumber, parseToNumber } from 'brazilian-values';
import { useHistory, useParams } from 'react-router';

import { Specification, StyledDivider } from './style';
import UITextarea from 'components/UI/Textarea/Textarea';
import UIInput from 'components/UI/Input/Input';
import UIButton from 'components/UI/Button/Button';
import UIDate from 'components/UI/Date/Date';
import lang from 'lang/lang.json';
import Accordion from 'components/Process/Accordion';
import MainModal from 'components/Modals/MainModal';
import UIText from 'components/UI/Text/Text';
import UISelect from 'components/UI/Select/Select';
import UploadArea from 'components/Upload/UploadArea/UploadArea';
import UITag from 'components/UI/Tag';
import UIRadio from 'components/UI/Radio/Radio';
import SuccessModal from 'components/Modals/SuccessModal';

import documentImageColor from 'assets/images/documentImageColor.svg';

import api from 'services/api';
import { store } from 'store';
import { maskValue, percentMask } from 'services/mask';
import * as yup from 'yup';
import { useFormik } from 'formik';
import mock from './mock.json';
import globalMock from 'assets/mock.json';

export function RenewProcess({ show, onClose }) {
	const { type } = store.getState().user;

	const history = useHistory();

	const [openModalSuccess, setOpenModalSuccess] = useState(false);

	const validationSchema = yup.object().shape({
		period: yup.number().required('Preencha o periodo a ser prorrogado'),
		justify: yup.string().optional('Preencha o campo'),
		observation: yup.string().optional('Preencha o campo'),
	});

	const {
		values,
		errors,
		touched,
		handleBlur,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: {
			period: '',
			justify: '',
			observation: '',
		},
		validationSchema,
	});

	const evtNext = () => {
		setOpenModalSuccess(false);
		// fazer alguma coisa
		history.push('/');
	};

	return (
		<>
			<MainModal
				show={show}
				onClose={onClose}
				title="Renovar processo"
				buttonText="Ver processo"
				cancelButton="Reprovar renovação"
				confirmButton="Autorizar renovação"
				confirmVariant="contained"
				bottonButtonMd={6}
				onConfirm={() => setOpenModalSuccess(true)}
				onCancel={onClose}
			>
				<Box pt={1} pb={3}>
					<Grid
						container
						spacing={2}
						justify="space-between"
						alignItems="center"
					>
						<Grid item xs={12}>
							<Typography variant="h5">
								Faça download do documento para validar as informações
							</Typography>
						</Grid>
						<Grid item xs={1}>
							<img src={documentImageColor} alt="" />
						</Grid>
						<Grid item xs={6}>
							<UIText text="Solicitação de cancelamento" fontSize="20px" />
							<Grid container spacing={1}>
								<Grid item>
									<Typography variant="h5">Nome do arquivo.pdf </Typography>
								</Grid>
								<Grid item>
									<Divider orientation="vertical" />
								</Grid>
								<Grid item>
									<Typography variant="h5" color="secondary">
										45 mb
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={3}>
							<UIButton text="Baixar" variant="outlined" fullWidth />
						</Grid>
					</Grid>
				</Box>
				<Box mx={-2} mb={4}>
					<Divider />
				</Box>
				<form>
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<Typography variant="h4">Período</Typography>
						</Grid>
						<Grid item xs={6}>
							<UISelect
								label="Meses a ser prorrogado"
								items={globalMock.optionsMonth}
							/>
						</Grid>
						<Grid item xs={12}>
							<Box mx={-1}>
								<Specification>
									<Box px={1}>
										<UITextarea
											label="Justificativa"
											colorLabel="var(--text-grey)"
											rows={3}
										/>
									</Box>
								</Specification>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<UIInput
								label="Observação"
								placeholder="Insira o texto aqui..."
							/>
						</Grid>
						<Grid item xs={12}>
							<Box mx={-2} mb={4}>
								<Divider />
							</Box>
						</Grid>
					</Grid>
				</form>
			</MainModal>
			<SuccessModal
				open={openModalSuccess}
				title="Renovação efetuada com sucesso"
				buttonText="Voltar a tela inicial"
				onClose={evtNext}
				buttonAction={evtNext}
			/>
		</>
	);
}

export function InformDiscount({ show, onClose, process }) {
	const { idProcess } = useParams();

	const [products, setProducts] = useState([]);

	const [labelFile, setLabelFile] = useState('');
	const [itemsConfigs, setItemsConfigs] = useState();
	const [selectedDocumentType, setSelectedDocumentType] = useState();
	const [msgErrorCombo, setMsgErrorCombo] = useState(null);
	const [successModal, setSuccessModal] = useState(false);

	const [refresh, setRefresh] = useState(false);

	useEffect(() => {
		async function getProcessProducts() {
			try {
				const response = await api.get(`process-products`, {
					params: {
						processId: idProcess,
						discount: 1,
					},
				});

				setProducts(
					response.data.data
						.filter(
							({ discount_orders }) => discount_orders[0].status !== 'approved',
						)
						.map((product) => {
							return {
								...product,
								status: 'approved',
							};
						}),
				);
			} catch (error) {
				return error;
			}
		}

		getProcessProducts();
	}, [idProcess]);

	//renderiza mensagem de erro no upload do arquivo
	useEffect(() => {
		if (selectedDocumentType === '') {
			setMsgErrorCombo('Selecione um tipo de documento');
		} else {
			setMsgErrorCombo('');
		}
	}, [selectedDocumentType]);

	const uploadAttachment = async () => {
		try {
			let formData = new FormData();
			formData.append('file', itemsConfigs.file);
			formData.append('table', 'process');
			formData.append('tableId', idProcess);
			formData.append('category', selectedDocumentType);

			await api.post(`attachments`, formData);
		} catch (error) {
			return null;
		}
	};

	const handleSuccessModal = () => {
		setSuccessModal(!successModal);
	};

	const handleChange = (e, id) => {
		const newProducts = products;

		newProducts.forEach((product) => {
			if (product.id === id) {
				if (e.target.name === 'discountValue') {
					product[e.target.name] = maskValue('percent', (e.target.value || ''));
					product.discount_orders[0][e.target.name] = maskValue('percent', (e.target.value || ''));
				} else {
					product[e.target.name] = e.target.value;
					product.discount_orders[0][e.target.name] = e.target.value;
				}
			}
		});

		setProducts(newProducts);
		setRefresh(!refresh);
	};

	const sendForm = async () => {
		const orders = products.map((product) => {
			return {
				...product.discount_orders[0],
				discountValue: parseToNumber(
					(`${product.discountValue}` || '').replace('%', ''),
				),
			};
		});

		try {
			if (orders.some((order) => order.status === 'denied'))
				await api.patch(`process/${idProcess}`, {
					status: 'waiting to confirm consultant values',
				});
			else
				await api.patch(`process/${idProcess}`, {
					status: 'awaiting distributor result',
				});

			await api.patch(
				`process-products`,
				products.map((product) => {
					return {
						...product,
						discountValue: parseToNumber(
							(`${product.discountValue}` || '').replace('%', ''),
						),
					};
				}),
			);

			await api.patch(`discount-order`, orders);
			handleSuccessModal();
		} catch (error) { }
	};

	return (
		<>
			<MainModal
				show={show}
				title="Confirmar valores"
				buttonText="Ver processo"
				titleButtonClick={onClose}
				onCancel={onClose}
				onClose={onClose}
				cancelButton="Voltar"
				onConfirm={() => sendForm()}
				confirmButton="Confirmar valores"
				confirmVariant="contained"
				bottonButtonMd={6}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h4">Informações do processo</Typography>
					</Grid>
					<Grid item xs={12}>
						<Box style={{ background: '#F8F4F4' }} mx={-2} py={3} px={2}>
							<Grid container justify="space-between" spacing={2}>
								<Grid item md={4}>
									<Grid container direction="column" spacing={1}>
										<Grid item>
											<UITag
												text={(process.institution || {}).segment || ''}
												variant={(process.institution || {}).segment || ''}
											/>
										</Grid>
										<Grid item>
											<Typography variant="h2">
												{(process.institution || {}).name || ''}
											</Typography>
										</Grid>
										<Grid item>
											<UIText
												fontSize="16px"
												textcolor="var(--primary)"
												text={`
												${(process.institution || {}).city || ''}
												 -
												${(process.institution || {}).uf || ''}`}
											/>
										</Grid>
										<Grid item>
											<StyledDivider />
										</Grid>
										<Grid item>
											<UIText fontSize="12px" text="Último processo" />
											<Typography variant="h5" color="primary">
												<u>20/09/2019 - 19:34</u>
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									<Divider orientation="vertical" />
								</Grid>
								<Grid item md={6}>
									<Grid container spacing={1}>
										<Grid item xs={6}>
											<UIText fontSize="12px" text="Política comercial" />
											<Typography variant="h5">Distribuidor</Typography>
										</Grid>
										<Grid item xs={6}>
											<UIText fontSize="12px" text="Número do pregão" />
											<Typography variant="h5">
												{process.tradingCode || ''}
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<UIText fontSize="12px" text="Classificação" />
											<Typography variant="h5">
												{process.class || 'Não se aplica'}
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<UIText fontSize="12px" text="(R$) Valor total NF" />
											<Typography variant="h5">
												{formatToNumber(
													products.reduce(
														(acc, product) => acc + product.nfPrice,
														0,
													) || '',
												)}
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<UIText fontSize="12px" text="Código do cliente" />
											<Typography variant="h5">
												{(process.institution || {}).code || ''}
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<UIText fontSize="12px" text="Abertura da licitação" />
											<Typography variant="h5">
												{process.tradingDate
													? new Date(process.tradingDate).toLocaleDateString()
													: ''}
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<UIText fontSize="12px" text="Município foco" />
											<Typography variant="h5">
												{process.focus ? 'Sim' : 'Não'}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<UITextarea label="Observações" rows={3} disabled={true} />
					</Grid>
					<Grid item xs={12}>
						<Box mx={-2} py={3}>
							<Divider />
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h4">Itens do processo</Typography>
					</Grid>
					<Grid item xs={12}>
						{(products || []).map((product) => (
							<Accordion
								confirmValues
								item={{
									...product,
									processProduct: {
										...product.product,
										name: product.product.description,
									},
								}}
								tag={{
									text: lang[product.discount_orders[0].status],
									variant: product.discount_orders[0].status,
								}}
							>
								<Box mx={-2}>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<Typography variant="h4">Análise financeira</Typography>
										</Grid>
										<Grid item xs={12}>
											<UIRadio
												items={[
													{
														value: 'approved',
														label: 'Sim',
													},
													{
														value: 'denied',
														label: 'Não',
													},
												]}
												label="Desconto aprovado?"
												fontSize="18px"
												sizelabel="13px"
												name="status"
												onChange={(e) => handleChange(e, product.id)}
												value={product.status}
											/>
										</Grid>
										<Grid item xs={6}>
											<UIInput
												label="(R$) Valor do último processo"
												fontSize="18px"
												disabled
												value={product.priceList}
											/>
										</Grid>
										<Grid item xs={6}>
											<UIInput
												name="discountValue"
												label="(R$) Valor desconto"
												fontSize="18px"
												value={product.discountValue}
												onChange={(e) => handleChange(e, product.id)}
												disabled={
													product.discount_orders[0].status !== 'denied'
												}
											/>
										</Grid>
										{/* <Grid item xs={3}>
											<UIRadio
												items={mock.yesNo}
												label="Recolher ST"
												fontSize="18px"
												sizelabel="13px"
											/>
										</Grid> */}
									</Grid>
								</Box>
							</Accordion>
						))}
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h4">Anexo da decisão(opcional)</Typography>
						<Box pt={3}>
							<UISelect
								items={mock.docummentTypes}
								label="Documento"
								value={selectedDocumentType || ''}
								onChange={(e) => setSelectedDocumentType(e.target.value)}
								msgError={msgErrorCombo}
								error={msgErrorCombo ? true : false}
							/>
						</Box>
						<Box pt={3}>
							<UploadArea
								height="265px"
								labelText={labelFile}
								onChange={(param) => {
									const file = param[0];
									setItemsConfigs({
										file,
									});
									setLabelFile(
										`Arquivo selecionado: ${String(file.name).substr(
											0,
											30,
										)}.../pdf`,
									);
								}}
								buttonOnClick={() => {
									if (selectedDocumentType == null) {
										return setSelectedDocumentType('');
									}
									uploadAttachment();
								}}
							/>
						</Box>
					</Grid>
				</Grid>
			</MainModal>
			<Modal
				variant="success"
				title="Valores registrados com sucesso"
				buttonText="Voltar para o processo"
				buttonAction={() => {
					handleSuccessModal();
					onClose();
				}}
				open={successModal}
			/>
		</>
	);
}
