import React from 'react';

import { Box, Divider, Grid } from '@material-ui/core';
import UIText from 'components/UI/Text/Text';

import { StyledGrid } from './style';
import { AiFillCloseCircle } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';

import documentImageColor from 'assets/images/documentImageColor.svg';

import UploadProgressBar from '../UploadProgressBar/UploadProgressBar';

export default function UploadFile({
	name,
	size,
	showBars,
	success,
	onDelete,
	category,
	iconDelete,
	url,
}) {
	const onDownload = () => {
		const link = document.createElement('a');
		link.href = url;
		link.target = '_blank';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<>
			<StyledGrid container alignItems="center" justify="space-between">
				<Grid item xs={3} sm={2} md={2}>
					<img src={documentImageColor} alt="Icone de Documento" width={38} />
				</Grid>
				<Grid item xs={7} sm={8} md={8}>
					<UIText text={category} fontSize="14px" />
					<Grid container spacing={1}>
						<Grid item>
							<UIText text={name} fontSize="12px" fontWeight="500" />
						</Grid>
						<Grid item>|</Grid>
						<Grid item>
							<UIText
								text={size || ' '}
								fontSize="12px"
								fontWeight="500"
								textcolor="var(--text-grey)"
							/>
						</Grid>
					</Grid>
					<UploadProgressBar invisible={showBars} success={success} />
				</Grid>
				<Grid item xs={1}>
					<FiDownload
						color="var(--primary)"
						size={22}
						onClick={(e) => onDownload()}
					/>
				</Grid>
				<Grid item xs={1}>
					{onDelete ? (
						<AiFillCloseCircle
							color="var(--text-grey2)"
							size={22}
							onClick={(e) => onDelete()}
						/>
					)
						:
						''
					}
				</Grid>
				<Grid item xs={12}>
					<Box mt={1}>
						<Divider />
					</Box>
				</Grid>
			</StyledGrid>
		</>
	);
}
