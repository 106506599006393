import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';

import UploadArea from 'components/Upload/UploadArea/UploadArea';
import UploadList from 'components/Upload/UploadList/UploadList';
import UISelect from 'components/UI/Select/Select';
import UISearch from 'components/UI/Search/Search';
import UIButton from 'components/UI/Button/Button';
import Modal from 'components/Modals';

import { ImFolderOpen } from 'react-icons/im';

import api from 'services/api';
import { useDispatch, useSelector } from 'react-redux';
import { docummentTypes } from './mock.json';

export default function Attachments() {
	const history = useHistory();
	const { idProcess } = useParams();

	const products = useSelector((state) => state.processProduct.products);

	const dispatch = useDispatch();

	const [selectedFilterOption, setSelectedFilterOption] = useState();
	const [openModalConfirm, setOpenModalConfirm] = useState(false);
	const [openModalSuccess, setOpenModalSuccess] = useState(false);

	const [attachmentsDefault, setAttachmentsDefault] = useState([]);
	const [attachments, setAttachments] = useState([]);
	const [labelFile, setLabelFile] = useState('');
	const [itemsConfigs, setItemsConfigs] = useState();
	const [selectedDocumentType, setSelectedDocumentType] = useState();
	const [msgErrorCombo, setMsgErrorCombo] = useState(null);
	const [refresh, setRefresh] = useState(false);

	//buscar informações do processo e anexos
	useEffect(() => {
		async function getAttachments() {
			try {
				const response = await api.get(`attachments`, {
					params: {
						table: 'process',
						tableId: idProcess,
					},
				});

				const result = response.data.data.map((att) => {
					return {
						...att,
						url: att.key,
						success: true,
					};
				});

				setAttachmentsDefault(result);
			} catch (error) {
				return null;
			}
		}

		getAttachments();
	}, [idProcess]);

	//adiciona os anexos do processo no novo array para upload
	useEffect(() => {
		setAttachments((att) => {
			return [...att, ...attachmentsDefault];
		});
	}, [attachmentsDefault]);

	//renderiza mensagem de erro no upload do arquivo
	useEffect(() => {
		if (selectedDocumentType === '') {
			setMsgErrorCombo('Selecione um tipo de documento');
		} else {
			setMsgErrorCombo('');
		}
	}, [selectedDocumentType]);

	const uploadAttachment = async (data) => {
		try {
			let formData = new FormData();
			formData.append('file', data.file);
			formData.append('table', 'process');
			formData.append('tableId', idProcess);
			formData.append('category', selectedDocumentType);

			const response = await api.post(`attachments`, formData);

			return {
				...itemsConfigs,
				id: response.data.id,
				url: response.data.key,
				category: response.data.category,
			};
		} catch (error) {
			return null;
		}
	};

	const addAttachment = async () => {
		if (itemsConfigs) {
			if (
				!msgErrorCombo &&
				selectedDocumentType !== '' &&
				selectedDocumentType !== null
			) {
				const file = await uploadAttachment(itemsConfigs);
				const newItems = attachments;
				newItems.push(file);
				setAttachments(newItems);
				setSelectedDocumentType(null);
				setItemsConfigs(null);
				setLabelFile('');
			}
		}
	};

	const deleteAttachment = async (id) => {
		try {
			await api.delete(`attachments/${id}`);

			const files = attachments;

			files.splice(
				files.findIndex((file) => file.id === id),
				1,
			);

			setAttachments(files);
			setRefresh(!refresh);
		} catch (error) {
			return null;
		}
	};

	const eventGoBack = () => {
		const data = {
			id: idProcess,
			step: 'scope_registration',
		};
		updateProcess(data);
	};

	const submitForm = async () => {
		const status = products.some((product) => product.discount)
			? 'awaiting financier'
			: 'awaiting distributor result';

		const data = {
			id: idProcess,
			step: 'finished',
			status,
		};

		try {
			await api.patch(`process/${idProcess}`, data);
			setOpenModalSuccess(true);
		} catch (error) {
			return null;
		}
	};

	const updateProcess = async (process) => {
		dispatch({ type: '@process/UPDATE', payload: process });
	};

	const closeModalConfirm = () => {
		setOpenModalConfirm(false);
	};

	const closeModalSuccess = () => {
		setOpenModalSuccess(false);
	};

	return (
		<>
			<Box pt={3}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={5}>
						<UploadList
							height="450px"
							itemsList={attachments}
							iconTitle={
								<ImFolderOpen
									color="var(--primary)"
									size={30}
									style={{
										background: '#F6F9FC',
										padding: '10px',
										borderRadius: '100%',
									}}
								/>
							}
							titleList="Documentos anexados"
							deleteAttachment={deleteAttachment}
						/>
					</Grid>
					<Grid item xs={12} md={7}>
						<Box pb={3}>
							<UISearch
								label="testes"
								valueSelect={selectedFilterOption || ''}
								onChangeSelect={(e) => {
									setSelectedFilterOption(e.target.value);
								}}
								placeholder="Condições para o Recebimento da Autorização de Fornecimento"
							/>
						</Box>
						<Paper elevation={0}>
							<Box p={2} ml={1} mr={1}>
								<UISelect
									items={docummentTypes}
									label="Documento"
									value={selectedDocumentType || ''}
									onChange={(e) => setSelectedDocumentType(e.target.value)}
									msgError={msgErrorCombo}
									error={msgErrorCombo ? true : false}
								/>
							</Box>
							<Box ml={3} mr={3}>
								<UploadArea
									height="265px"
									labelText={labelFile}
									onChange={(param) => {
										const file = param[0];
										setItemsConfigs({
											name: file.name,
											size: String(file.size),
											showBars: true,
											success: param.success,
											file,
										});
										setLabelFile(
											`Arquivo selecionado: ${String(file.name).substr(
												0,
												30,
											)}.../pdf`,
										);
									}}
									buttonOnClick={() => {
										if (selectedDocumentType == null) {
											return setSelectedDocumentType('');
										}

										return addAttachment();
									}}
								/>
							</Box>
						</Paper>
					</Grid>
				</Grid>
				<Grid container justify="flex-end" spacing={3}>
					<Grid item xs={12} md={4} lg={2}>
						<Box pt={3}>
							<UIButton
								text="Voltar"
								fullWidth
								variant="outlined"
								color="secondary"
								textcolor="var(--text-grey)"
								onClick={() => eventGoBack()}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} lg={2}>
						<Box pt={3}>
							<UIButton
								text="Concluir"
								fullWidth
								onClick={() => setOpenModalConfirm(true)}
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>
			<Modal
				title="Confirmar processo"
				subtitle="Deseja adicionar o processo ao sistema?"
				variant="confirm"
				open={openModalConfirm}
				onClose={closeModalConfirm}
				buttonAcceptAction={() => submitForm()}
				buttonCancelAction={closeModalConfirm}
			/>
			<Modal
				variant="success"
				title="Resultado adicionado com sucesso"
				buttonText="Voltar a tela inicial"
				open={openModalSuccess}
				onClose={closeModalSuccess}
				buttonAction={() => history.push('/')}
			/>
		</>
	);
}
