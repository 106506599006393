import { Input, InputLabel, withStyles } from '@material-ui/core';

export const StyledInput = withStyles({
  root: {
    '&:before': {
      borderBottom: '1px solid var(--text-grey)',
    },
  },
})(Input);

export const StyledInputLabel = withStyles({
  root: {
    fontSize: '19px',
    color: (props) => props.color || 'var(--primary)',
  },
})(InputLabel);
