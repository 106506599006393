import React, { useEffect, useState } from 'react';
import { createFilterOptions } from '@material-ui/lab';
import { Box, Grid } from '@material-ui/core';

import MainModal from 'components/Modals/MainModal';
import UIInput from 'components/UI/Input/Input';
import UIAutocomplete from 'components/UI/Autocomplete';
import UIButton from 'components/UI/Button/Button';

import api from 'services/api';
import * as yup from 'yup';
import { useFormik } from 'formik';

import PropTypes from 'prop-types';

const validationSchema = yup.object().shape({
	description: yup.string().required('Digite o nome do produto'),
	company: yup.mixed().required('Selecione a empresa'),
	grammage: yup
		.string()
		.required('Digite a gramatura')
		.min(1, 'Digite um valor maior que 0'),
});

export default function RivalProductModal({
	show,
	product,
	onClose,
	onConfirm,
}) {
	const [companies, setCompanies] = useState([]);

	const {
		values,
		errors,
		touched,
		handleBlur,
		handleChange,
		setFieldValue,
		handleSubmit,
		resetForm,
	} = useFormik({
		initialValues: product || {
			description: '',
			company: null,
			grammage: '',
		},
		enableReinitialize: true,
		validationSchema,
		onSubmit(values) {
			if (onConfirm) {
				onConfirm(values);
			}
		},
	});

	useEffect(() => {
		if (!show) {
			resetForm({ description: '', company: null, grammage: '' });
		}
	}, [show, resetForm]);

	useEffect(() => {
		async function getCompanies() {
			try {
				const response = await api.get(`rival-products`, {
					params: {
						$distinct: 'company',
						$limit: -1,
					},
				});

				setCompanies(
					response.data.map(({ company }) => {
						return {
							title: company,
							value: company,
						};
					}),
				);
			} catch (error) {
				throw error;
			}
		}

		getCompanies();
	}, []);

	useEffect(() => {
		if (product) {
			// TODO: set form fields values
		}
	}, [product]);

	return (
		<MainModal
			show={show}
			title="Editar produto concorrente"
			width="small"
			onClose={onClose}
		>
			<form
				onSubmit={(e) => {
					handleSubmit(e);
					e.preventDefault();
				}}
			>
				<Box marginBottom="28px">
					<UIInput
						value={values.description}
						label="Produto"
						error={touched.description && errors.description}
						onChange={handleChange('description')}
						onBlur={handleBlur('description')}
					/>
				</Box>
				<Grid container spacing={3}>
					<Grid item xs="6">
						<UIAutocomplete
							value={{
								title: values.company,
								value: values.company,
							}}
							label="Empresa"
							options={companies}
							error={touched.company && errors.company}
							onChange={(_, newValue) =>
								setFieldValue('company', newValue.value)
							}
							onBlur={handleBlur('company')}
							filter
						/>
					</Grid>
					<Grid item xs="6">
						<UIInput
							value={values.grammage}
							label="Gramatura"
							error={touched.grammage && errors.grammage}
							onChange={handleChange('grammage')}
							onBlur={handleBlur('grammage')}
						/>
					</Grid>
				</Grid>
				<Box display="flex" justifyContent="flex-end" marginTop="43px">
					<UIButton variant="outlined" text="Voltar" onClick={onClose} />
					<Box width="8px" />
					<UIButton type="submit" text="Confirmar" />
				</Box>
			</form>
		</MainModal>
	);
}

RivalProductModal.propTypes = {
	show: PropTypes.bool,
	onClose: PropTypes.func,
	onConfirm: PropTypes.func,
	product: PropTypes.object,
};
