import React from 'react';

import PropTypes from 'prop-types';

import './styles.css';

export default function TextItem({
  title,
  description,
  variant,
}) {
  return (
    <div className={`TextItem ${variant}`}>
      <div className="TextItem-titulo">
        {title}
      </div>
      <div className="TextItem-descripition">
        {description}
      </div>
    </div>
  );
}

TextItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
};
