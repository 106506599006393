import {
	Input,
	InputLabel,
	Card,
	Divider,
	makeStyles,
	withStyles,
	Typography,
} from '@material-ui/core';

export const useStyles = makeStyles({
	scrollbar: {
		maxHeight: '400px',
	},
});

export const StyledCard = withStyles({
	root: {
		boxShadow: 'inherit',
		borderRadius: '4px',
		height: '100%',
	},
})(Card);

export const StyledDivider = withStyles({
	root: {
		margin: '15px -15px',
	},
})(Divider);

export const StyledInput = withStyles({
	root: {
		border: '1px dashed var(--text-grey)',
		borderRadius: '4px',
		padding: '0px 5px',
		fontSize: '12px',
		color: 'var(--text-dark)',
	},
})(Input);

export const StyledInputLabel = withStyles({
	root: {
		fontWeight: '400',
		fontSize: '14px',
		marginBottom: '7px',
		marginTop: '10px',
	},
})(InputLabel);

export const Confirm = withStyles({
	root: {
		fontSize: '12px',
		color: 'var(--primary)',
		marginLeft: 'auto',
		marginTop: '5px',
		marginBottom: '-5px',
		width: 'min-content',
	},
})(Typography);
