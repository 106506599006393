import React, { useState } from 'react';

import { Grid, Divider, Menu, MenuItem, Typography } from '@material-ui/core';

import { CardContainer } from './styles';
import UITag from 'components/UI/Tag/index';
import UIText from 'components/UI/Text/Text';

import { MdMoreVert } from 'react-icons/md';

export default function DisabledContactCard({ informations }) {
	/*
		template de informations = {
			tag: {
				text,
				variant,
				width,
				height,
				size
			},
			instituition,
			email,
			local,
			onMenuClick,
		}
	*/

	const [anchorEl, setAnchorEl] = useState(false);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(!anchorEl);
	};

	return (
		<CardContainer
			borderColor="#787b7e"
			container
			direction="row"
			justify="flex-start"
			alignItems="space-between"
		>
			<Grid container spacig={3}>
				<Grid container justify="space-between" alignItems="center">
					<UITag {...informations.tag} variant="disabled" />
					<Grid item>
						<MdMoreVert
							color="var(--text-grey)"
							size={25}
							onClick={(e) => {
								informations.onMenuClick();
								handleClick(e);
							}}
						/>
						<Menu
							id="simple-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							{informations.menuItems.map((item) => (
								<MenuItem
									onClick={() => {
										item.onClick();
										handleClose();
									}}
								>
									<Typography variant="h5">{item.text}</Typography>
								</MenuItem>
							))}
						</Menu>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<UIText text={informations.name} fontSize="16px" fontWeight="500" />
				</Grid>
				<Grid item xs={12}>
					<UIText text={informations.email} fontSize="12px" fontWeight="400" />
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid container justify="flex-start" alignItems="center">
				<UIText
					text="Local: "
					fontSize="12px"
					fontWeight="400"
					textcolor="var(--text-grey)"
				/>
				<UIText
					text={`${informations.city}, ${informations.uf}`}
					fontSize="12px"
					fontWeight="500"
					textcolor="black"
				/>
			</Grid>
		</CardContainer>
	);
}

/*







*/
