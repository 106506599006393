import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import faker from 'faker';

import UIText from 'components/UI/Text/Text';
import UIInput from 'components/UI/Input/Input';
import UIButton from 'components/UI/Button/Button';
import HeaderScreens from 'screens/Admin/components/HeaderScreens';
import Modal from 'components/Modals';

import { Divider } from '../../distributor/create/style';
import { useHistory, useParams } from 'react-router';
import UISelect from 'components/UI/Select/Select';
import api from 'services/api';

const PADDING = '16px';

const validationSchema = yup.object().shape({
	name: yup.string().required('Digite o nome'),
	lastName: yup.string().required('Digite o sobrename'),
	email: yup.string().required('Digite o email').email('Email inválido'),
	sap: yup.string().required('Digite o número do SAP'),
	regional: yup.string().required('Selecione uma região'),
	accessLevel: yup.string().required('Selecione um nível de acesso'),
});

export default function CreateUser() {
	const history = useHistory();
	const { userId } = useParams();

	const [showSuccessModal, setShowSuccessModal] = useState(false);

	const {
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
		handleSubmit,
	} = useFormik({
		initialValues: {
			name: '',
			lastName: '',
			password: 'dev',
			email: '',
			accessLevel: '',
			regional: '',
			sap: '',
		},
		validationSchema,
		async onSubmit(fields) {
			try {
				let data = {
					...fields,
					password: `${fields.name}-${fields.sap}`,
					employee: fields,
				};

				await api.post(`access`, data);
				setShowSuccessModal(true);
			} catch (error) {
				return error;
			}
		},
	});

	useEffect(() => {
		if (userId) {
			// TODO: fetch user data
		}
	}, [userId]);

	return (
		<Box p={3}>
			<HeaderScreens
				titleScreen="Usuários"
				currentBreadItem={{ text: 'Usuários', href: '/usuario' }}
			/>
			<Box css={{ bgcolor: '#fff' }} borderRadius="4px" marginTop="20px">
				<Box padding={PADDING}>
					<UIText
						text="Informações do usuário"
						color="#565656"
						fontWeight="500"
					/>
				</Box>
				<Divider />
				<Box padding={PADDING}>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						<Grid container spacing={3}>
							<Grid item md={4}>
								<UIInput
									value={values.name}
									label="Nome"
									error={touched.name && errors.name}
									onChange={handleChange('name')}
									onBlur={handleBlur('name')}
								/>
							</Grid>
							<Grid item md={4}>
								<UIInput
									value={values.lastName}
									label="Sobrenome"
									error={touched.lastName && errors.lastName}
									onChange={handleChange('lastName')}
									onBlur={handleBlur('lastName')}
								/>
							</Grid>
							<Grid item md={4}>
								<UIInput
									value={values.email}
									label="Email"
									error={touched.email && errors.email}
									onChange={handleChange('email')}
									onBlur={handleBlur('email')}
								/>
							</Grid>
							<Grid item md={4}>
								<UISelect
									value={values.accessLevel}
									error={touched.accessLevel && errors.accessLevel}
									label="Nível de acesso"
									items={accessLevels}
									onBlur={handleBlur('accessLevel')}
									onChange={handleChange('accessLevel')}
								/>
							</Grid>
							<Grid item md={4}>
								<UISelect
									value={values.regional}
									label="Regional"
									error={touched.regional && errors.regional}
									items={regions}
									onBlur={handleBlur('regional')}
									onChange={handleChange('regional')}
								/>
							</Grid>
							<Grid item md={4}>
								<UIInput
									value={values.sap}
									label="SAP"
									error={touched.sap && errors.sap}
									onChange={handleChange('sap')}
									onBlur={handleBlur('sap')}
								/>
							</Grid>
						</Grid>
					</form>
					<Box display="flex" justifyContent="flex-end" marginTop="8px">
						<UIButton
							text={userId ? 'Atualizar usuário' : 'Criar usuário'}
							onClick={() => handleSubmit()}
						/>
					</Box>
				</Box>

				<Divider />
				{/* <Box padding={PADDING}>
					<UIText
						text="Selecionar usuários sob supervisão direta"
						color="#565656"
						fontWeight="500"
					/>
					<Table>
						<TableHead>
							<TableRow>
								{headers.map((header, index) => (
									<TableCell color={header.text} width={header.width}>
										{header.text}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row) => (
								<TableRow key={row.name}>
									<TableCell>
										<UICheckbox checked={row.isSelected} />
									</TableCell>
									<TableCell>{row.name}</TableCell>
									<TableCell>{row.email}</TableCell>
									<TableCell>{row.sap}</TableCell>
									<TableCell>{row.region}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>

				</Box> */}
			</Box>
			<Modal
				variant="success"
				open={showSuccessModal}
				title={
					userId
						? 'Usuário atualizado com sucesso'
						: 'Usuário adicionado com sucesso'
				}
				buttonText="Volta a tela inicial"
				buttonAction={() => history.push('/usuario')}
				onClose={() => setShowSuccessModal(false)}
			/>
		</Box>
	);
}

const accessLevels = [
	{ text: 'Consultor', value: 'consultant' },
	{ text: 'Supervisor', value: 'supervisor' },
	{ text: 'Gerente', value: 'manager' },
	{ text: 'Financeiro', value: 'financier' },
	{ text: 'Administrador', value: 'admin' },
];

const regions = [
	{ text: 'SSP', value: 'SSP' },
	{ text: 'CNN', value: 'CNN' },
];

const headers = [
	{ text: '', width: '16px' },
	{ text: 'Nome', color: '#C4C4C4' },
	{ text: 'Email', color: '#C4C4C4' },
	{ text: 'SAP', color: '#C4C4C4' },
	{ text: 'Regional', color: '#C4C4C4' },
];

const rows = Array(15)
	.fill(0)
	.map((_, index) => ({
		isSelected: index % 3 === 0,
		name: faker.name.findName(),
		email: faker.internet.email(),
		sap: faker.finance.amount(0, 90000),
		region: faker.address.state(),
	}));
