import styled from 'styled-components';
import { CsvToHtmlTable } from 'react-csv-to-table';

export const CsvTable = styled.div`
	table {
		--bs-table-bg: transparent;
		--bs-table-striped-color: #212529;
		--bs-table-striped-bg: rgba(0, 0, 0, 0.05);
		--bs-table-active-color: #212529;
		--bs-table-active-bg: rgba(0, 0, 0, 0.1);
		--bs-table-hover-color: #212529;
		--bs-table-hover-bg: rgba(0, 0, 0, 0.075);
		width: 100%;
		margin-bottom: 1rem;
		color: #212529;
		vertical-align: top;
		border-color: #dee2e6;
	}


	tbody {
		vertical-align: inherit;
	}
	thead {
		vertical-align: bottom;
	}

	tbody > tr:nth-of-type(odd) {
		--bs-table-accent-bg: var(--bs-table-striped-bg);
		background-color: grey;
	}

	:not(:last-child) > :last-child > * {
		border-bottom-color: currentColor;
	}
`;
