import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Banner = styled(Grid)`
	background: var(--primary);
	width: 100%;
	color: #ffffff;
	.MuiBreadcrumbs-root {
		padding-top: 1em;
		padding-bottom: 10px;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		display: flex;
		align-items: center;
	}
`;
export const BannerLeft = styled(Grid)`
	padding: 1rem 2rem;
	h3 {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
	}
	span {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 135%;
	}
	margin-bottom: 24px;
`;

export const BannerHead = styled.div`
	height: 40%;
	padding: 1.5rem 1.5rem;

	background: #eaeef6 80%;
	border-radius: 4px 4px 0px 0px;

	display: flex;
	justify-content: space-around;
	align-items: flex-start;
`;

export const DateArea = styled(Grid)`
	> div {
		background-color: #fff;
	}
`;
export const ButtonArea = styled(Grid)`
	padding-left: 1.5em;
	padding-right: 1.5em;
	padding-bottom: 1em;
`;
