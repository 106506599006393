import { Box, Grid, Typography } from '@material-ui/core';
import MainModal from 'components/Modals/MainModal';
import UIDate from 'components/UI/Date/Date';
import UISelect from 'components/UI/Select/Select';
import UploadArea from 'components/Upload/UploadArea/UploadArea';
import UploadList from 'components/Upload/UploadList/UploadList';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import api from 'services/api';
import { setLoading } from 'store/modules/app/actions';

export default function GenerateReportModal({ show, onClose, type }) {
	const [form, setForm] = useState({});
	const dispatch = useDispatch();
	const [selectedAtt, setSelectedAtt] = useState({});
	const [attachments, setAttachments] = useState([]);
	const [errorFile, setErrorFile] = useState(null);

	const [category, setCategory] = useState('');
	const [errorCategory, setErrorCategory] = useState(null);

	const handleChange = (e) => {
		setForm((form) => {
			return {
				...form,
				[e.target.name]: e.target.value,
			};
		});
	};

	const addAtt = () => {
		if (!category) return setErrorCategory('Selecione o tipo do documento');
		if (!selectedAtt.file) return setErrorFile('Selecione o arquivo');

		setAttachments((att) => {
			return [
				...att,
				{
					...selectedAtt,
					name: selectedAtt.file.name,
					size: selectedAtt.file.size,
					success: true,
				},
			];
		});

		setCategory('');
		setErrorCategory(null);
		setSelectedAtt({});
		setErrorFile(null);
	};

	const generateReport = async () => {
		if (!attachments.some((att) => att.category === 'Base BW'))
			return setErrorFile('Adicione o arquivo Base BW');
		if (!attachments.some((att) => att.category === 'Tabela DE PARA SKU'))
			return setErrorFile('Adicione todos o arquivo Tabela DE PARA SKU');
		if (!attachments.some((att) => att.category === 'Tabela OTR – Base SAP'))
			return setErrorFile('Adicione todos o arquivo Tabela OTR – Base SAP');

		function findKey(category) {
			switch (category) {
				case 'Base BW':
					return 'baseBwFilename';
				case 'Tabela DE PARA SKU':
					return 'deParaFilename';
				case 'Tabela OTR – Base SAP':
					return 'baseOtrFilename';
				default:
					return;
			}
		}

		try {
			let formData = new FormData();

			attachments.forEach((att) => {
				formData.append('file', att.file);
				formData.append(findKey(att.category), att.name);
			});

			formData.append('type', type);
			formData.append('fromDate', form.fromDate);
			formData.append('toDate', form.toDate);
			dispatch(setLoading(true));
			const response = await api.post(`report`, formData);
			// const url = window.URL.createObjectURL(new Blob([response.data]));
			// const link = document.createElement('a');
			// link.href = url;
			// link.setAttribute('download', 'file.xlsx'); //or any other extension
			// document.body.appendChild(link);
			// link.click();
			const link = document.createElement('a');
			link.href = response.data.url;
			link.target = '_blank';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			console.log(response.data.url);
		} catch (error) {
		} finally {
			dispatch(setLoading(false));
		}
	};

	return (
		<MainModal
			show={show}
			onClose={onClose}
			title="Gerar relatório"
			confirmButton="Gerar controle PNE"
			onConfirm={() => generateReport()}
			confirmVariant="contained"
		>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<UISelect
						items={documents}
						value={category}
						label="Documento"
						onChange={(e) => setCategory(e.target.value)}
						error={!category && errorCategory ? errorCategory : null}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h2" color="primary">
						Insira o documento no campo abaixo
					</Typography>
					<Typography variant="h6" color="error">
						{!selectedAtt.file && errorFile ? errorFile : null}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<UploadArea
						buttonText="Adicionar documento"
						height="265px"
						onChange={(param) => {
							setSelectedAtt({
								file: param[0],
								category,
							});
						}}
						buttonOnClick={() => addAtt()}
						labelText={(selectedAtt.file || {}).name}
					/>
				</Grid>
				<Grid item xs={12}>
					<Box mx={-2}>
						<UploadList titleList="Documentos" itemsList={attachments} />
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h4">Definir período do relatório</Typography>
				</Grid>
				<Grid item>
					<UIDate
						views={['month', 'year']}
						formatDate="MM/yy"
						onChange={(date) =>
							handleChange({
								target: {
									value: date,
									name: 'fromDate',
								},
							})
						}
						label="Período inicial"
						value={form.fromDate}
						name="fromDate"
					/>
				</Grid>
				<Grid item>
					<UIDate
						views={['month', 'year']}
						formatDate="MM/yy"
						onChange={(date) =>
							handleChange({
								target: {
									value: date,
									name: 'toDate',
								},
							})
						}
						label="Período final"
						value={form.toDate}
						name="toDate"
					/>
				</Grid>
			</Grid>
		</MainModal>
	);
}

const documents = [
	{ text: 'Base BW', value: 'Base BW' },
	{
		text: 'Tabela DE PARA SKU',
		value: 'Tabela DE PARA SKU',
	},
	{ text: ' Tabela OTR – Base SAP', value: 'Tabela OTR – Base SAP' },
];
