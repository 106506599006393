import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { store } from 'store';

import mock from './mock.json';
import api from 'services/api';
import lang from 'lang/lang.json';

import NavBreadcrumbs from 'components/Navigation/Breadcrumbs/Breadcrumbs';
import MainModal from 'components/Modals/MainModal/index';
import Order from 'screens/Process/components/Card/OrderExecution';
import ObservationCard from 'screens/Process/components/Card/Observation';
import UploadList from '../../../components/Upload/UploadList/UploadList';
import UploadArea from 'components/Upload/UploadArea/UploadArea';
import UISelect from 'components/UI/Select/Select';
import NotificationCard from 'components/Dashboard/NotificationCard';
import ProcessTitle from 'screens/Process/components/ProcessTitle';
import Information from 'screens/Process/components/Card/Information';
import TablePendencies from 'components/Pendencies/TablePendencies';
import BottomButtons from 'screens/Process/components/BottomButtons';
import TableItemsProcess from 'screens/Process/components/Table/ProcessOrders';
import Modal from 'components/Modals';

import clsx from 'clsx';

import { Box, Divider, Grid, Paper, Step, Typography } from '@material-ui/core';

import {
	StyleStepper,
	StyledStepLabel,
	QontoConnector,
	useQontoStepIconStyles,
	Text,
} from './style';
import { getProcess } from 'store/modules/process/actions';
import { useDispatch, useSelector } from 'react-redux';

/**
 * @returns Process Page
 */

export default function ProcessHistory() {
	const dispatch = useDispatch();
	const { idProcess } = useParams();
	const { type } = store.getState().user;
	const refreshApp = useSelector((state) => state.app.refresh);

	const [alert] = useState(true);
	const { process } = useSelector((state) => state.process);
	const [processProducts, setProcessProducts] = useState([]);
	const [comments, setComments] = useState();

	const [attachments, setAttachments] = useState([]);
	const [selectedAttachment, setSelectedAttachment] = useState({});
	const [labelFile, setLabelFile] = useState('');
	const [itemsConfigs, setItemsConfigs] = useState();
	const [selectedDocumentType, setSelectedDocumentType] = useState(null);
	const [msgErrorCombo, setMsgErrorCombo] = useState(null);
	const [refresh, setRefresh] = useState(false);

	const [successModal, setSuccessModal] = useState(false);
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

	const [showOrder, setShowOrder] = useState(false);
	const [showMakeOrder, setShowMakeOrder] = useState(false);
	const [showInvoice, setShowInvoice] = useState(false);

	const [uploadItems, setUploadItems] = useState('');
	const [pendencies, setPendencies] = useState([]);

	const [log, setLog] = useState('');

	//buscar informações do processo e anexos
	async function getProductProcess() {
		const response = await api.get(`/process-products?processId=${idProcess}`, {
			params: {
				$limit: '-1',
			},
		});
		if (response.status === 200) {
			setProcessProducts(response.data);
			const productEmpty = response.data.find((value) => value.quantity > 0);
			if (productEmpty) setShowMakeOrder(true);
			else setShowMakeOrder(false);
		}
	}

	const getAttachments = async () => {
		try {
			const response = await api.get(`attachments`, {
				params: {
					table: 'process',
					tableId: idProcess,
				},
			});

			const result = response.data.data.map((att) => {
				return {
					...att,
					url: att.key,
					success: true,
				};
			});

			setLog(response.data.data);

			setAttachments(result);
		} catch (error) {
			return null;
		}
	};

	async function getLastOrder() {
		try {
			const response = await api.get(`order`, {
				params: {
					processId: idProcess,
					$limit: 1,
					'$sort[createdAt]': -1,
				},
			});
			const order = response.data.data[0];
			const orderDate = new Date(order?.approvedDate);
			const deliveryDate = orderDate.setDate(orderDate.getDate() + 5);

			if (
				(order?.status === 'Aprovado' && new Date() > deliveryDate) ||
				response.data.data.length === 0
			)
				setShowOrder(true);
			else setShowOrder(false);

			if (order.status === 'Processando') setShowInvoice(true);
			else setShowInvoice(false);
		} catch (error) {
			return null;
		}
	}

	useEffect(() => {
		dispatch(getProcess({ id: idProcess }));
		getLastOrder();
		getProductProcess();
		getAttachments();
	}, [idProcess, dispatch, refreshApp]);

	const globalPendencies = useSelector((state) => state.user.pendencies);
	useEffect(() => {
		const getPendencies = async () => {
			const result = await api.get('/pendencies', {
				params: { processId: idProcess, $limit: 50 },
			});

			if (result.status === 200) setPendencies(result.data.data);
			return;
		};
		getPendencies();
	}, [globalPendencies]);

	//renderiza mensagem de erro no upload do arquivo
	useEffect(() => {
		if (selectedDocumentType === '') {
			setMsgErrorCombo('Selecione um tipo de documento');
		} else if (selectedDocumentType === false) {
			setMsgErrorCombo('');
		} else {
			setMsgErrorCombo('');
		}
	}, [selectedDocumentType]);

	const uploadAttachment = async (data) => {
		try {
			let formData = new FormData();
			formData.append('file', data.file);
			formData.append('table', 'process');
			formData.append('tableId', idProcess);
			formData.append('category', selectedDocumentType);

			const response = await api.post(`attachments`, formData);
			if (response.data)
				return {
					...itemsConfigs,
					id: response.data.id,
					url: response.data.key,
					category: response.data.category,
				};
		} catch (error) {
			return null;
		}
	};

	const addAttachment = async () => {
		if (itemsConfigs) {
			if (
				!msgErrorCombo &&
				selectedDocumentType !== '' &&
				selectedDocumentType !== null
			) {
				const file = await uploadAttachment(itemsConfigs);
				setSuccessModal(true);
				const newItems = attachments;
				newItems.push(file);
				setAttachments(newItems);
				setSelectedDocumentType(null);
				setItemsConfigs(null);
				setLabelFile('');
			}
		}
	};

	const deleteAttachment = async () => {
		try {
			await api.delete(`attachments/${selectedAttachment.id}`);

			const files = attachments;

			files.splice(
				files.findIndex((file) => file.id === selectedAttachment.id),
				1,
			);

			setAttachments(files);
			setConfirmDeleteModal(false);
			setRefresh(!refresh);
		} catch (error) {
			return null;
		}
	};

	const breadcrumb = [
		{
			text: 'Dashboard',
			href: '/',
		},
		{
			text: 'Historico Processo',
			href: '',
		},
	];

	function QontoStepIcon(props) {
		const classes = useQontoStepIconStyles();
		const { active, completed, icon } = props;

		return (
			<div
				className={clsx(classes.root, {
					[classes.active]: active,
				})}
			>
				{completed ? (
					<div className={classes.completed}>{icon}</div>
				) : (
						<div>{icon}</div>
					)}
			</div>
		);
	}

	useEffect(() => {
		if (type === 'consultant')
			setUploadItems(
				<UploadList
					height="470px"
					itemsList={attachments}
					titleList="Documentos anexados"
					deleteAttachment={(id) => {
						setSelectedAttachment({ id });
						setConfirmDeleteModal(true);
					}}
				/>,
			);
		else
			setUploadItems(
				<UploadList
					height="470px"
					itemsList={attachments}
					titleList="Documentos anexados"
				/>,
			);
	}, [attachments, type]);

	return (
		<Grid container spacing={3} direction="column">
			<Grid item xs={12}>
				<NavBreadcrumbs items={breadcrumb} />
			</Grid>
			<Grid item xs={12}>
				{alert ? (
					<NotificationCard
						msg="A aplicacao esta em desenvolvimento, algumas funcionalidades nao estao operantes e estao presentes apenas para layout"
						title="Aplicacao em desenvolvimento"
						bold="apenas para layout"
					/>
				) : null}
			</Grid>
			{/* Title and buttons */}
			<Grid item xs={12}>
				{type === 'consultant' ? (
					<ProcessTitle // consultor
						invoice={showInvoice}
						generate
						renewConsultant
						status={(process || {}).status}
					/>
				) : type === 'distributor' ? (
					<ProcessTitle // distribuidor
						renewDistributor
						order={showMakeOrder}
						status={(process || {}).status}
					/>
				) : type === 'financier' ? (
					<ProcessTitle // financeiro
						discount
						status={(process || {}).status}
					/>
				) : null}
			</Grid>
			<Grid item xs={12}>
				{/* Steps */}
				{/* 1- cadastro, 2-Analise, 3-Financeiro, 4- Aguard. Licitacao, 5- Resultado, 6- Conclusao */}
				<StyleStepper
					alternativeLabel
					activeStep={lang.process_steps[(process || {}).status]}
					connector={<QontoConnector />}
				>
					{mock.steps.map((step, index) => {
						return (
							<Step key={index}>
								<StyledStepLabel StepIconComponent={QontoStepIcon}>
									{step.title}
								</StyledStepLabel>
							</Step>
						);
					})}
				</StyleStepper>
			</Grid>
			<Grid item>
				<Grid container direction="row">
					<Grid item md={6} lg={7}>
						<Information process={process} type={type} />
					</Grid>
					<Grid item xs={12} md={6} lg={5}>
						<TablePendencies pendencies={pendencies} />
						<Order />
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<ObservationCard list={comments || []} processId={process.id} />
			</Grid>
			<Grid item>
				<Grid container spacing={3}>
					<Grid item xs={12} md={5}>
						{uploadItems}
						<Modal
							open={confirmDeleteModal}
							onClose={() => setConfirmDeleteModal(!confirmDeleteModal)}
							variant="error"
							title="Deseja apagar o documento?"
							subtitle="Condições para o recebimento da autorização de fornecimento"
							buttonConfirmText="Apagar"
							buttonText="Voltar para a licitação"
							buttonCancelAction={() =>
								setConfirmDeleteModal(!confirmDeleteModal)
							}
							buttonConfirmAction={deleteAttachment}
						/>
					</Grid>
					<Grid item xs={12} md={7}>
						<Paper elevation={0} style={{ height: '100%' }}>
							<Box p={2}>
								<Typography variant="h4">Adicionar documentos</Typography>
							</Box>
							<Divider />
							<Box p={2} ml={1} mr={1}>
								<UISelect
									items={mock.docummentTypes}
									label="Documento"
									colorIcon="var(--text-grey)"
									value={selectedDocumentType}
									onChange={(e) => setSelectedDocumentType(e.target.value)}
									msgError={msgErrorCombo}
									error={msgErrorCombo}
								/>
							</Box>
							<Box ml={3} mr={3}>
								<UploadArea
									height="300px"
									labelText={labelFile}
									onChange={(param) => {
										const file = param[0];
										setItemsConfigs({
											name: file.name,
											size: String(file.size),
											showBars: true,
											success: param.success,
											file,
										});
										setLabelFile(
											`Arquivo selecionado: ${String(file.name).substr(
												0,
												30,
											)}.../pdf`,
										);
									}}
									buttonOnClick={() => {
										if (selectedDocumentType == null) {
											return setSelectedDocumentType('');
										}

										return addAttachment();
									}}
								/>
								<Modal
									open={successModal}
									onClose={() => setSuccessModal(!successModal)}
									variant="success"
									title="Documento anexado com sucesso"
									buttonText="Voltar para a licitação"
									buttonAction={() => setSuccessModal(!successModal)}
								/>
							</Box>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<TableItemsProcess items={processProducts} process={process} />
			</Grid>
			{/* End Process Data */}
			{/* Bottom Buttons */}
			<Grid item>
				<BottomButtons status={(process || {}).status} process={process} />
			</Grid>
			{/* End bottom Buttons */}
			{/* process history */}
			<Grid container direction="column">
				<Grid item xs={12}>
					<Box pb={2} pt={2}>
						Historico do processo
					</Box>
				</Grid>
				{/* logs  */}
				{(log || []).map((item, index) => {
					let logUser = { name: '', type: '' };
					if (
						item.access &&
						item.access.distributor &&
						item.access.distributor.name
					) {
						logUser.name = item.access.distributor.name;
						logUser.type = 'distribuidor';
					} else if (
						item.access &&
						item.access.employee &&
						item.access.employee.name
					) {
						logUser.name = item.access.employee.name;
						logUser.type = item.access.employee.accessLevel;
					}

					return (
						<div key={`log${index}`}>
							<Grid item>
								{`Anexo de ${item.category} adicionado no dia ${new Date(
									item.createdAt,
								).toLocaleDateString('pt-BR', { dateStyle: 'long' })} - pelo ${(lang[logUser.type] ? lang[logUser.type] : logUser.type)
									} ${logUser.name} `}
							</Grid>
							<Box mt={2} mb={2}>
								<Divider />
							</Box>
						</div>
					);
				})}
			</Grid>
			<MainModal
				show={false}
				title="Solicitar pedido"
				buttonText="Ver processos"
				confirmButton="Confirmar"
				cancelButton="voltar"
			>
				Teste
			</MainModal>

			{/* End process history */}
		</Grid>
	);
}
