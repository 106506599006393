import { withStyles } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import styled from 'styled-components';
import ScrollContainer from 'react-indiana-drag-scroll';

export const TableContainerStyled = withStyles({
  root: {
    borderRadius: '0px',
    backgroundColor: '#FFFFFF',
  },
})(TableContainer);

export const StyledScrollContaier = styled(ScrollContainer)`
	::-webkit-scrollbar-track {
		background-color: #F4F4F4;
	}
	::-webkit-scrollbar {
		height: 4px;
		background: #E5E5E5;
	}
	::-webkit-scrollbar-thumb {
		background: #C4C4C4;
		border-radius: 0px 0px 4px 4px;
	}
	::-webkit-scrollbar-track-piece {
		background: #E5E5E5;
	}
`;
