import React, { useEffect, useState } from 'react';

import { Grid, Divider, IconButton, Menu, MenuItem } from '@material-ui/core';

import {
	CardContainer,
	PaddingGrid,
} from 'components/Cards/ProductCard/styles';

import UITag from 'components/UI/Tag/index';
import UIText from 'components/UI/Text/Text';

import { TiPencil } from 'react-icons/ti';
import { BiTrash } from 'react-icons/bi';
import { MdMoreVert } from 'react-icons/md';
import api from 'services/api';
import { formatToBRL } from 'brazilian-values';

const colors = {
	Mucilon: { variant: 'orange', borderColor: 'var(--orange)' },
	Papinhas: { variant: 'pink', borderColor: 'var(--pink)' },
	'Fórmulas infantis': { variant: 'blue', borderColor: 'var(--primary)' },
	'FORMULAS INFANTIS': { variant: 'blue', borderColor: 'var(--primary)' },
	'Compostos lácteos': { variant: 'success', borderColor: 'var(--success)' },
};

export default function ProductCard({ informations }) {
	/*
		template de informations = {
			borderColor,
			tag: {
				text,
				variant,
				width,
				height,
			},
			active,
			menu: [
				{ text, onClick }
			]
			productName,
			sku,
			price,
			gramar,
			utilCount,
			onPencilClick,
			onTrashClick,
		}
	*/

	const [anchorEl, setAnchorEl] = useState(null);

	const [process, setProcess] = useState();
	const [price, setPrice] = useState();

	useEffect(() => {
		async function getProcess() {
			try {
				const process = await api.get(`process-products`, {
					params: {
						productId: informations.id,
					},
				});

				setProcess(process.data.total);
			} catch (error) {
				throw error;
			}
		}

		async function getPrice() {
			try {
				const response = await api.get(`product-prices`, {
					productId: informations.id,
				});

				setPrice(response.data.data[0].price);
			} catch (error) {
				throw error;
			}
		}

		getPrice();
		getProcess();
	}, [informations]);

	return (
		<CardContainer
			borderColor={
				informations.disabled === 1
					? '#86898c'
					: (colors[informations.businessUnity] || {}).borderColor ||
					'var(--primary)'
			}
			container
			direction="column"
			justify="space-around"
			alignItems="flex-start"
		>
			<PaddingGrid
				padding=".5rem"
				container
				direction="column"
				justify="center"
				alignItems="flex-start"
			>
				<Grid
					container
					direction="row"
					justify="space-between"
					alignItems="center"
				>
					<Grid item>
						<UITag
							{...informations.tag}
							variant={
								informations.disabled === 1
									? 'disabled'
									: (colors[informations.businessUnity] || {}).variant || 'blue'
							}
							text={informations.businessUnity}
						/>
					</Grid>
					<Grid item>
						{informations.disabled === 0 && (
							<>
								<IconButton size="small" onClick={informations.onPencilClick}>
									<TiPencil color="var(--text-grey)" size={18} />
								</IconButton>
								<IconButton size="small" onClick={informations.onTrashClick}>
									<BiTrash color="var(--text-grey)" size={18} />
								</IconButton>
							</>
						)}
						{informations.menu &&
							informations.menu.length > 0 &&
							informations.disabled === 1 && (
								<>
									<IconButton
										size="small"
										onClick={(e) => setAnchorEl(e.currentTarget)}
									>
										<MdMoreVert color="var(--text-grey)" size={18} />
									</IconButton>
									<Menu
										anchorEl={anchorEl}
										keepMounted
										open={Boolean(anchorEl)}
										onClose={() => setAnchorEl(null)}
										anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
									>
										{(informations.menu || []).map((item) => (
											<MenuItem
												onClick={() => {
													item.onClick && item.onClick();
													setAnchorEl(null);
												}}
											>
												{item.text}
											</MenuItem>
										))}
									</Menu>
								</>
							)}
					</Grid>
				</Grid>
				<PaddingGrid padding=".5rem" item xs={12}>
					<UIText
						text={informations.description}
						fontWeight="500"
						fontSize="16px"
						textOverflow="ellipsis"
						overflow="hidden"
						whiteSpace="nowrap"
					/>
				</PaddingGrid>
				<Grid
					container
					direction="row"
					justify="space-between"
					alignItems="center"
				>
					<Grid item xs={4}>
						<UIText
							text="SKU"
							textcolor="var(--text-grey)"
							fontSize="12px"
							fontWeight="400"
							textalign="center"
						/>
						<UIText
							text={informations.sku}
							textcolor="var(--text-dark)"
							fontSize="12px"
							fontWeight="400"
							textalign="center"
						/>
					</Grid>
					<Grid xs={4} item>
						<UIText
							text="(R$)Preço lista"
							textcolor="var(--text-grey)"
							fontSize="12px"
							fontWeight="400"
							textalign="center"
						/>
						<UIText
							text={formatToBRL(price || 0)}
							textcolor="var(--text-dark)"
							fontSize="12px"
							fontWeight="400"
							textalign="center"
						/>
					</Grid>
					<Grid xs={4} item>
						<UIText
							text="Gramatura"
							textcolor="var(--text-grey)"
							fontSize="12px"
							fontWeight="400"
							textalign="center"
						/>
						<UIText
							text={informations.grammage || '-'}
							textcolor="var(--text-dark)"
							fontSize="12px"
							fontWeight="400"
							textalign="center"
						/>
					</Grid>
				</Grid>
			</PaddingGrid>
			<Grid
				container
				direction="row"
				justify="flex-start"
			// alignItems="center"
			>
				<Grid item xs={12}>
					<Divider />
				</Grid>
			</Grid>

			<PaddingGrid
				padding=".5rem"
				container
				alignItems="flex-start"
				justify="flex-start"
			>
				<UIText
					text="Utilizado em: &nbsp;"
					textcolor="var(--text-grey)"
					fontWeight="500"
					fontSize="12px"
				/>
				<UIText
					text={process}
					textcolor="var(--text-dark)"
					fontWeight="500"
					fontSize="12px"
				/>
			</PaddingGrid>
		</CardContainer>
	);
}
