import { takeLatest, all, call, put } from 'redux-saga/effects';
import api from 'services/api';
import { setPendencies, updatePicture, updateUser } from './actions';
import { store } from 'store';

export function* getPendencies({ }) {
	try {
		const state = store.getState().user;
		let query = { $limit: 50 };
		//distribuidor
		if (state.type === 'distributor')
			query = { ...query, distributorId: state.id };
		else query = { ...query, employeeId: state.id }; // funcionario da nestle

		const pendencies = yield call(api.get, '/pendencies', {
			params: { ...query },
		});
		if (pendencies.status === 200) yield put(setPendencies(pendencies.data));
	} catch (e) {
		console.log(e);
	}
}

export function* getPicture({ userId }) {
	try {
		const response = yield call(api.get, 'attachments', {
			params: {
				table: 'access',
				tableId: userId,
				$limit: -1,
			}
		});
		if (response.data) {
			const filtered = response.data.filter(item => item.accessId === userId);
			yield put(updatePicture(filtered.pop()));
		}
	} catch (error) {
		console.log(error);
		return null;
	}
}

export default all([
	takeLatest('@user/GET_PENDENCIES', getPendencies),
	takeLatest('@user/GET_PICTURE', getPicture),
]);
