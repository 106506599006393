import React, { useEffect, useState } from 'react';

import { Box, Divider, Grid, Typography } from '@material-ui/core';
import UIButton from 'components/UI/Button/Button';

import {
	Container,
	OrderHeader,
	StyledSpan,
	StyledP,
	Specification,
} from './style';
import UITag from 'components/UI/Tag/index';
import { useParams } from 'react-router';
import { formatToBRL } from 'brazilian-values';
import api from 'services/api';

import Accordion from 'components/Process/Accordion';
import MainModal from 'components/Modals/MainModal';
import UIInput from 'components/UI/Input/Input';
import UITextarea from 'components/UI/Textarea/Textarea';
import UploadList from 'components/Upload/UploadList/UploadList';

export default function Order() {
	const { idProcess } = useParams();
	const [orderInExecution, setOrderInExecution] = useState();
	const [orderItens, setOrderItens] = useState([]);
	const [showOrderItens, setShowOrderItens] = useState(false);

	useEffect(() => {
		async function getOrderInExecution() {
			try {
				const response = await api.get(`order`, {
					params: {
						processId: idProcess,
						$limit: 1,
						'$sort[createdAt]': -1,
					},
				});
				//need refactor in accordion, same problem in -> ../../ProcessTitle/modals.js
				const result = response.data.data[0].order_items.map((processProduct) => {
					return {
						itemNumber: processProduct.process_product.itemNumber,
						id: processProduct.process_product.id,
						processProduct: {
							name: processProduct.process_product.product.description,
							sku: processProduct.process_product.product.sku,
							value: processProduct.process_product.finalValue,
						},
						amount:
							processProduct.process_product.finalValue *
							processProduct.process_product.initialQuantity,
						currentBalance:
							processProduct.process_product.finalValue *
							(processProduct.process_product.initialQuantity -
								processProduct.process_product.quantity),
						volume: processProduct.process_product.quantity,
						unity: processProduct.process_product.unity,
						description: processProduct.process_product.specification,
						finalValue: processProduct.process_product.finalValue,
						quantity: processProduct.quantity,
					};
				});
				setOrderItens(result);
				setOrderInExecution(response.data.data[0]);
			} catch (error) {
				return null;
			}
		}

		getOrderInExecution();
	}, [idProcess]);

	return (
		<>
			<Container elevation={0}>
				<OrderHeader
					container
					direction="row"
					justify="space-between"
					alignItems="center"
				>
					<Grid item>Pedido em execução</Grid>
					<Grid item>
						<UIButton
							variant="outlined"
							onClick={() => setShowOrderItens(true)}
							text="Ver informacoes"
						/>
					</Grid>
				</OrderHeader>
				<Divider />
				<Grid container direction="row">
					<Grid item xs={12} md={6}>
						<Box pt={2}>
							<UITag
								text={`Pedido ${
									orderInExecution?.status
										? orderInExecution.status
										: 'Nenhum pedido ainda'
								}`}
								variant={orderInExecution?.status ? 'success' : 'error'}
							/>
							<StyledP fontSize="24px">
								{' '}
								{orderInExecution?.total
									? formatToBRL(orderInExecution?.total)
									: ''}{' '}
							</StyledP>
							<StyledSpan color="grey"> Itens solicitados:</StyledSpan>
							<StyledSpan color="blue">
								{' '}
								{orderInExecution?.order_items.length
									? `${orderInExecution?.order_items.length} itens`
									: ' - '}
							</StyledSpan>

							<StyledP margin="1em 0 0 0" color="grey">
								Pedido Solicitado
							</StyledP>
							<StyledP margin="0.5em 0 0 0">
								{orderInExecution?.createdAt
									? new Date(orderInExecution?.createdAt).toLocaleDateString(
											'pt-br',
											{
												year: 'numeric',
												month: 'long',
												day: 'numeric',
											},
									  )
									: ' - '}
							</StyledP>
						</Box>
					</Grid>
					<Box pt={1}>
						<Divider orientation="vertical" variant="middle" />
					</Box>
					<Grid item xs={12} md={5}>
						<Box pt={5}>
							{/* <Box>
								<StyledSpan color="grey">Data de entrega</StyledSpan>
								<StyledP color="blue">
									{orderInExecution?.deliveryDate
										? new Date(
												orderInExecution?.deliveryDate,
										  ).toLocaleDateString('pt-br', {
												year: 'numeric',
												month: 'long',
												day: 'numeric',
										  })
										: ' - '}
								</StyledP>
							</Box> */}
							<Box pt={2}>
								<StyledSpan color="grey">Pedido Aprovado</StyledSpan>
								<StyledP>
									{orderInExecution?.approvedDate
										? new Date(
												orderInExecution?.approvedDate,
										  ).toLocaleDateString('pt-br', {
												year: 'numeric',
												month: 'long',
												day: 'numeric',
										  })
										: ' - '}
								</StyledP>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Container>

			<OrderItens
				show={showOrderItens}
				orderItens={orderItens}
				cancelButton="Voltar"
				onClose={() => setShowOrderItens(false)}
			/>
		</>
	);
}

/**
 * @param {boolean} consultant - Is consultant or distributor
 * @param {boolean} show - Boolean show modal
 * @param {string} [width=normal] - normal, medium, small
 * @param {string} title - Modal Title
 * @param {string} buttonText - If have this prop will add a button before close icon.
 * @param {function} titleButtonClick - Title button function
 * @param {component} children - HTML
 * @param {String} confirmButton - If exists will show the button if the params passe
 * @param {String} confirmVariant - If existe will change the button variant
 * @param {String} cancelButton - If exists will show the button if the params passed
 * @param {function} onClose - Close function
 * @param {function} onConfirm - Confirm function
 * @param {function} onCancel - Cancel function
 * @param {string} 	bottonButtonMd - Bottom Button MD size
 */
export function OrderItens({ orderItens, ...rest }) {
	return (
		<>
			<MainModal
				onCancel={() => rest.onClose()}
				show
				title="Informações do pedido"
				bottonButtonMd={6}
				{...rest}
			>
				{orderItens.map((item) => {
					return (
						<Accordion order item={item} key={item.itemNumber}>
							<Box mx={-4}>
								<Specification>
									<Grid>
										<UITextarea
											value={item.description}
											disabled
											label="Especificação"
											colorLabel="var(--text-grey)"
										/>
									</Grid>
								</Specification>
							</Box>
							<Grid>
								<Box mt={2}>
									<StyledP> Informações do item </StyledP>
								</Box>
								<Box mt={1}>
									<form>
										<Grid container direction="row" spacing={2}>
											<Grid item xs={12}>
												<UIInput
													type="number"
													id={item.id}
													label="Quantidade solicitada"
													value={item.quantity}
													disabled
												/>
											</Grid>
										</Grid>
									</form>
								</Box>
							</Grid>
						</Accordion>
					);
				})}
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Box mx={-4} mt={2}>
							<Divider />
						</Box>
					</Grid>
					<Grid item>
						<Typography variant="h4">Anexo do pedido</Typography>
					</Grid>
					<Grid item xs={12}>
						<UploadList className="Uploads-List" itemsList={[]} />
					</Grid>
				</Grid>
			</MainModal>
		</>
	);
}
