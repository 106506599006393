import { Card, makeStyles, withStyles } from '@material-ui/core';
import { PaginationItem } from '@material-ui/lab';

export const useStyles = makeStyles({
	scrollbar: {
		maxHeight: '400px',
	},
});

export const StyledCard = withStyles({
	root: {
		boxShadow: 'inherit',
		borderRadius: '4px',
		padding: '15px',
	},
})(Card);

export const StyledPaginationItem = withStyles({
	root: {
		fontSize: '12px',
		minWidth: '18px',
		height: '18px',
		margin: '0px',
		padding: '0px',
	},
})(PaginationItem);
