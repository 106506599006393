import { Grid, withStyles } from '@material-ui/core';

export const CardContainer = withStyles({
	root: {
		minHeight: '146px',
		minWidth: '262px',
		borderLeft: '5px solid #000',
		borderColor: (props) => props.borderColor || '',
		borderRadius: '3px',
		background: '#FFF',
		padding: '.9rem',
		marginBottom: '.3rem',
	}
})(Grid);
