import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';

import UIInput from 'components/UI/Input/Input';
import UIRadio from 'components/UI/Radio/Radio';
import UIButton from 'components/UI/Button/Button';
import UIAutocomplete from 'components/UI/Autocomplete';
import { StyledTextField } from 'screens/Process/components/DistributorCreating/Fields/DocumentSolicitation/styles';

import { Title, StyledGrid } from './style';

import { useDispatch, useSelector } from 'react-redux';
import {
	getProcessProducts,
	createProcessProduct,
	removeProcessProduct,
} from 'store/modules/processProduct/actions';
import api from 'services/api';
import mock from 'assets/mock.json';
import { maskValue } from 'services/mask';
import * as yup from 'yup';
import { useFormik } from 'formik';

const validationSchema = yup.object().shape({
	itemNumber: yup.number().required('Preencha o campo'),
	quantity: yup.number().required('Preencha o campo'),
	discountValue: yup.string().required('Preencha o campo'),
	initialValue: yup.string().required('Preencha o campo'),
	totalItem: yup.string().required('Preencha o campo'),
	winner: yup.string().required('Preencha o campo'),
	value: yup.string().required('Preencha o campo'),
});

export default function ScopeRegistration() {
	const dispatch = useDispatch();

	const processDefault = useSelector((state) => state.process.process);
	const processItems = useSelector((state) => state.processProduct.products);
	const [products, setProducts] = useState([]);
	const [newItem, setNewItem] = useState({});

	const [autocompleteItems, setAutocompleteItems] = useState([]);
	const [selectedProduct, setSelectedProduct] = useState({});

	const {
		values,
		errors,
		touched,
		handleBlur,
		handleChange,
		setFieldValue,
		handleSubmit,
		resetForm,
	} = useFormik({
		initialValues: {
			itemNumber: '',
			quantity: '',
			discountValue: '',
			initialValue: '',
			totalItem: '',
			winner: 'não',
			value: '',
		},
		validate: (values) => {
			const err = {};
			const messages = {
				empty: 'Preencha o campo',
				moreThan: (value) => `Não pode ser menor que ${value}`,
			};

			if (values.itemNumber < 1) err.itemNumber = messages.moreThan(1);
			if (values.quantity < 1) err.quantity = messages.moreThan(1);
			if (values.initialValue < 1) err.initialValue = messages.moreThan(0);

			return err;
		},
		validationSchema,
		onSubmit(fields) {
			const data = {
				...newItem,
				...values,
				discount: parseFloat(newItem.discountValue) > 0 ? true : false,
				discountValue: parseFloat(newItem.discountValue),
				processId: processDefault.id,
				productId: selectedProduct.id,
				discount_orders: [],
				specification: '',
				unity: 'KG',
				approve: true,
				markup: 0,
				justify: '',
				initialQuantity: values.quantity,
				initialValue: parseFloat(values.initialValue),
				finalValue: parseFloat(values.finalValue),
				winner: values.winner === 'sim' ? true : false,
				approve: true,
			};
			dispatch(createProcessProduct(data));
			setNewItem({});
		},
	});

	// get products
	useEffect(() => {
		async function getProducts() {
			try {
				const response = await api.get(`products`, {
					params: {
						$limit: -1,
					},
				});
				if (response.data) {
					setProducts(response.data);
					setAutocompleteItems(
						(response.data || []).map((product) => {
							return {
								title: product.description,
								value: product.id,
							};
						}),
					);
				}
			} catch (error) {
				return null;
			}
		}
		getProducts();
	}, []);
	// product total and quantity changes
	useEffect(() => {
		if (values.totalItem && values.quantity) {
			const maskedValue = maskValue(
				'money',
				// (() => {
				// 	if (String(values.totalItem).includes('.')) {
				// 		const splited = String(values.totalItem).split(',');
				// 		const integers = splited[0].replace('.', '');
				// 		return parseFloat(`${parseInt(integers)}.${splited[1]}`);
				// 	}
				// 	else return parseFloat(values.totalItem);
				// })
				parseFloat(values.totalItem) * parseInt(values.quantity),
			);
			handleChangeNewItem({
				target: {
					name: 'finalValue',
					value: maskedValue,
				},
			});
		}
	}, [values.totalItem, values.quantity]);
	// updating process items
	useEffect(() => {
		dispatch(getProcessProducts({ processId: processDefault.id }));
	}, [dispatch, processDefault.id]);

	const handleChangeNewItem = (evt) => {
		setNewItem((newItem) => {
			return {
				...newItem,
				[evt.target.name]: evt.target.value,
			};
		});
	};

	const removeItem = (processProduct) => {
		dispatch(removeProcessProduct(processProduct));
	};

	const eventGoBack = () => {
		const data = {
			id: processDefault.id,
			step: 'notice_analysis',
		};

		updateProcess(data);
	};

	const eventNext = () => {
		const data = {
			id: processDefault.id,
			process_products: processItems,
			step: 'attachments',
		};
		updateProcess(data);
	};

	const updateProcess = async (process) => {
		dispatch({ type: '@process/UPDATE', payload: process });
	};

	return (
		<Grid container direction="column">
			<StyledGrid container>
				<Title>Informações do processo</Title>
				<Box pl={3} pr={3} pb={3} pt={1} mb={2} style={{ width: '100%' }}>
					<form
						onSubmit={(e) => {
							handleSubmit(e);
							e.preventDefault();
						}}
					>
						<Grid container spacing={3}>
							<Grid item xs={12} md={4}>
								<UIInput
									label="Número do item"
									type="number"
									value={values.itemNumber}
									onChange={handleChange('itemNumber')}
									onBlur={handleBlur('itemNumber')}
									error={touched.itemNumber && errors.itemNumber}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<UIAutocomplete
									label="Produto"
									value={
										newItem.description
											? { title: newItem.description, value: newItem.id }
											: ''
									}
									onChange={(evt, newValue) => {
										setNewItem((newItem) => {
											return {
												...newItem,
												description: (newValue || {}).title,
											};
										});
										const index = autocompleteItems.indexOf(newValue);
										if (newValue) {
											setSelectedProduct(products[index]);
										} else {
											setSelectedProduct({});
										}
									}}
									filter
									options={autocompleteItems}
									renderInput={(params) => (
										<StyledTextField
											{...params}
											fullWidth
											InputLabelProps={{ shrink: true }}
											label="Produto"
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<UIInput
									label="Gramatura"
									value={(selectedProduct || {}).grammage || 0}
									disabled
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<UIInput
									label="SKU"
									value={selectedProduct.sku || ''}
									disabled
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<UIInput
									label="(UNI)Total do item"
									type="number"
									value={values.quantity}
									onChange={handleChange('quantity')}
									onBlur={handleBlur('quantity')}
									error={touched.quantity && errors.quantity}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<UIInput
									label="(KG)Total do item"
									value={
										selectedProduct.grammage && values.quantity
											? selectedProduct.grammage * values.quantity
											: 0
									}
									disabled
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<UIInput
									label="(%)Desconto máximo"
									value={values.discountValue}
									onChange={(evt) => {
										const maskedValue = maskValue('percent', evt.target.value);
										setFieldValue('discountValue', maskedValue, true);
									}}
									onBlur={handleBlur('discountValue')}
									error={touched.discountValue && errors.discountValue}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<UIInput
									label="(R$)Preço mínimo da tabela"
									value="34,33"
									disabled
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<UIInput
									label="(R$) (UNI sem desconto) Preço lista"
									value="34,33"
									disabled
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<UIInput
									label="(UNI)Preço Inicial"
									value={values.initialValue}
									onChange={(evt) => {
										const maskedValue = maskValue('money', evt.target.value);
										setFieldValue('initialValue', maskedValue, true);
									}}
									onBlur={handleBlur('initialValue')}
									error={touched.initialValue && errors.initialValue}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<UIInput
									label="Preço total do item"
									value={values.totalItem}
									onChange={(evt) => {
										const maskedValue = maskValue('money', evt.target.value);
										setFieldValue('totalItem', maskedValue, true);
									}}
									onBlur={handleBlur('totalItem')}
									error={touched.totalItem && errors.totalItem}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<UIInput
									label="(UNI)Preço final"
									value={newItem.finalValue || ''}
									disabled
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<UIInput
									label="(UNI)Preço venda NF-Nestlé"
									value={values.value}
									onChange={(evt) => {
										const maskedValue = maskValue('money', evt.target.value);
										setFieldValue('value', maskedValue, true);
									}}
									onBlur={handleBlur('value')}
									error={touched.value && errors.value}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<UIInput label="GPR investido" value="3423,00" disabled />
							</Grid>
							<Grid item xs={12} md={4}>
								<UIRadio
									label="Item ganho"
									items={mock.yesNo}
									value={values.winner}
									onChange={handleChange('winner')}
									onBlur={handleBlur('winner')}
									error={touched.winner && errors.winner}
								/>
							</Grid>
						</Grid>
						<Grid container justify="flex-end" alignItems="center">
							<UIButton text="Adicionar" type="submit" />
						</Grid>
					</form>
				</Box>
			</StyledGrid>
			{(processItems || []).map((product) => (
				<StyledGrid container key={product.itemNumber}>
					<Box pl={3} pr={3} pb={3} pt={1} mb={2} style={{ width: '100%' }}>
						<form>
							<Grid container spacing={3}>
								<Grid item xs={12} md={4}>
									<UIInput
										label="Número do item"
										value={product.itemNumber || ''}
										name="itemNumber"
										onChange={handleChangeNewItem}
										type="text"
										disabled
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<UIInput
										label="Produto"
										value={product.itemNumber}
										name="itemNumber"
										onChange={handleChangeNewItem}
										type="text"
										disabled
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<UIInput
										label="Gramatura"
										value={selectedProduct.grammage || ''}
										disabled
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<UIInput
										label="SKU"
										value={selectedProduct.sku || ''}
										disabled
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<UIInput
										label="(UNI)Total do item"
										value={product.quantity || ''}
										name="quantity"
										onChange={handleChangeNewItem}
										disabled
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<UIInput label="(KG)Total do item" value="0" disabled />
								</Grid>
								<Grid item xs={12} md={4}>
									<UIInput
										label="(%)Desconto máximo"
										value={product.discountValue || ''}
										name="discountValue"
										onChange={handleChangeNewItem}
										disabled
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<UIInput
										label="(R$)Preço mínimo da tabela"
										value="34,33"
										disabled
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<UIInput
										label="(R$) (UNI sem desconto) Preço lista"
										value="34,33"
										disabled
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<UIInput
										label="(UNI)Preço Inicial"
										value={product.initialValue}
										name="initialValue"
										onChange={handleChangeNewItem}
										disabled
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<UIInput
										label="Preço total do item"
										value={product.totalItem || ''}
										name="totalItem"
										onChange={handleChangeNewItem}
										disabled
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<UIInput
										label="(UNI)Preço final"
										value={product.finalValue || ''}
										disabled
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<UIInput
										label="(UNI)Preço venda NF-Nestlé"
										value="0,00"
										disabled
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<UIInput label="GPR investido" value="3423,00" disabled />
								</Grid>
							</Grid>
							<Grid container justify="flex-end" alignItems="center">
								<UIButton
									text="Apagar item"
									onClick={() => removeItem(product)}
									variant="outlined"
								/>
							</Grid>
						</form>
					</Box>
				</StyledGrid>
			))}
			<Box pl={3} pr={3} pb={3} pt={3} mb={2} style={{ width: '100%' }}>
				<Grid container justify="flex-end" alignItems="center" spacing={3}>
					<Grid item xs={12} md={2}>
						<UIButton text="Voltar" onClick={eventGoBack} variant="outlined" />
					</Grid>
					<Grid item xs={12} md={2}>
						<UIButton text="Avançar" onClick={eventNext} />
					</Grid>
				</Grid>
			</Box>
		</Grid>
	);
}
