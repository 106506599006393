import { Grid, withStyles } from '@material-ui/core';

export const CardContainer = withStyles({
	root: {
		borderLeft: '5px solid #000',
		borderColor: (props) => props.color || '',
		borderRadius: '4px',
		background: '#fff',
		minHeight: '100%',
		padding: '.5rem .8rem',
		fontSize: '12px',
		lineHeight: '14,06px',
	},
})(Grid);
