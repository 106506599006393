import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

import UIDate from 'components/UI/Date/Date';
import UIInput from 'components/UI/Input/Input';
import UIAutocomplete from 'components/UI/Autocomplete';
import UISelect from 'components/UI/Select/Select';
import UIRadio from 'components/UI/Radio/Radio';
import UIButton from 'components/UI/Button/Button';

import { StyledGrid, StyledTextField, Title } from './style';

import api from 'services/api';
import { useDispatch, useSelector } from 'react-redux';
import mock from './mock.json';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { LeftForm } from 'screens/Process/components/DistributorCreating/FormSection/style';

const validationSchema = yup.object().shape({
	distributorId: yup.string().optional('Preencha o campo'),
	socialName: '',
	programName: yup.string().required('Escolha uma opção'),
	comercialPolitcs: yup.string().required('Escolha uma opção'),
	noticeModality: yup.string().required('Escolha uma opção'),
	tradingCode: yup.string().required('Preencha o campo'),
	processDirect: yup.string().required('Escolha uma opção'),
	period: yup.string().required('Escolha uma opção'),
	canExtend: yup.string().required('Escolha uma opção'),
	extendMonths: yup.string().optional('Escolha uma opção'),
	createdAt: yup.date().required('Selecione uma data'),
});

function NoticeAnalysis() {
	const dispatch = useDispatch();

	const processDefault = useSelector((state) => state.process.process);

	const [processForm, setProcessForm] = useState(processDefault);
	const [allCities, setAllCities] = useState([]);
	const [cities, setCities] = useState([]);
	const [ufs, setUfs] = useState([]);
	const [dataDistributor, setDataDistributor] = useState({
		distributors: [],
		selectedDistributor: {},
	});
	const [dataInstitutions, setDataInstitutions] = useState({
		institutions: [],
		autocomplete: [],
	});
	// get data
	useEffect(() => {
		async function getCities() {
			try {
				const response = await api.get(`cities`, {
					params: {
						$limit: -1,
					},
				});
				setAllCities(
					(response.data || []).map((city) => {
						return {
							name: city.city,
							uf: city.uf,
						};
					}),
				);
			} catch (error) {
				return null;
			}
		}

		async function getUfs() {
			try {
				const response = await api.get(`cities`, {
					params: {
						$limit: -1,
						$distinct: 'uf',
					},
				});
				setUfs(
					(response.data || [])
						.map((uf) => String(uf.uf).toUpperCase())
						.sort()
						.map((item) => {
							return {
								title: item,
								value: item,
							};
						}),
				);
			} catch (error) {
				return null;
			}
		}

		async function getDistributors() {
			try {
				const response = await api.get(`distributor`, {
					params: {
						$limit: -1,
					},
				});
				setDataDistributor((dataDistributor) => {
					return {
						...dataDistributor,
						distributors: response.data,
					};
				});
			} catch (error) {
				return null;
			}
		}

		async function getInstitutions() {
			try {
				const response = await api.get(`institution`, {
					params: {
						$limit: -1,
					},
				});
				setDataInstitutions((dataInstitutions) => {
					return {
						...dataInstitutions,
						institutions: response.data,
						autocomplete: response.data.sort().map((item, index) => {
							return {
								title: item.name,
								value: index,
							};
						}),
					};
				});
			} catch (error) {
				return null;
			}
		}

		getCities();
		getUfs();
		getDistributors();
		getInstitutions();
	}, []);
	// filter cities
	useEffect(() => {
		if (processForm.uf) {
			const parsedCities = allCities.filter(
				(city) => city.uf === processForm.uf,
			);
			setCities(
				(parsedCities || [])
					.map((city) => String(city.name).toUpperCase())
					.sort()
					.map((item) => {
						return {
							title: item,
							value: item,
						};
					}),
			);
		} else if (processForm.uf === undefined) {
			setCities([]);
			setProcessForm((processForm) => {
				return {
					...processForm,
					city: '',
				};
			});
		}
	}, [allCities, processForm.uf, ufs]);
	// on code cliente changes
	useEffect(() => {
		if (dataDistributor.distributors && processForm.distributorId) {
			const foundDistributor = dataDistributor.distributors.filter(
				(distributor) => distributor.id === parseInt(processForm.distributorId),
			);
			handleChangeForm({
				target: {
					name: 'distributor',
					value: foundDistributor[0] || {},
				},
			});
		}
	}, [processForm.distributorId, dataDistributor.distributors]);

	const {
		values,
		errors,
		touched,
		handleBlur,
		handleChange,
		setFieldValue,
		handleSubmit,
		resetForm,
	} = useFormik({
		initialValues: {
			programName: processForm.programName || '',
			comercialPolitcs: processForm.comercialPolitcs || '',
			noticeModality: processForm.noticeModality || '',
			tradingCode: processForm.tradingCode || '',
			processDirect:
				(processForm.processDirect ? 'Direta' : 'Indireta') || 'Direta',
			period: processForm.period || '',
			canExtend: (processForm.canExtend ? 'sim' : 'não') || 'não',
			extendMonths: processForm.tradingCode || '',
			createdAt: processForm.createdAt || '',
		},
		validate: (values) => {
			const err = {};
			const messages = {
				moreThan: (value) => `Não pode ser menor que ${value}`,
			};

			return err;
		},
		validationSchema,
		onSubmit(fields) {
			const data = {
				...processForm,
				...values,
				step: 'scope_registration',
				processDirect: values.processDirect === 'sim' ? true : false,
				canExtend: values.canExtend === 'sim' ? true : false,
				extendMonths: values.extendMonths || 0,
			};
			updateProcess(data);
		},
		onReset() {
			const data = {
				id: processForm.id,
				step: 'proposal_summary',
			};
			updateProcess(data);
		},
	});

	// on date changes
	useEffect(() => {
		const sumDates = (oldDate, addMonths) => {
			const date = new Date(oldDate);
			const auxDate = new Date(date);
			const newDate = new Date(
				auxDate.setMonth(auxDate.getMonth() + addMonths),
			);
			return newDate;
		};
		if (values.period && processForm.startPeriod) {
			let endDate = sumDates(processForm.startPeriod, values.period);

			if (values.canExtend === 'sim' && values.extendMonths) {
				endDate = sumDates(endDate, values.extendMonths);
			}
			handleChangeForm({
				target: {
					name: 'endPeriod',
					value: endDate,
				},
			});
		} else if (!processForm.startPeriod)
			handleChangeForm({
				target: {
					name: 'startPeriod',
					value: new Date(),
				},
			});
	}, [
		values.canExtend,
		values.extendMonths,
		values.period,
		processForm.startPeriod,
	]);

	const handleChangeForm = (evt) => {
		setProcessForm((process) => {
			return {
				...process,
				[evt.target.name]: evt.target.value,
			};
		});
	};

	const updateProcess = async (process) => {
		await dispatch({ type: '@process/UPDATE', payload: process });
	};

	const filterOptions = createFilterOptions({
		matchFrom: 'start',
		stringify: (option) => option.name,
	});

	return (
		<StyledGrid container>
			<Title>Informações do processo</Title>
			<Box pl={3} pr={3} pb={3} pt={1} style={{ width: '100%' }}>
				<form
					onSubmit={(e) => {
						handleSubmit(e);
						e.preventDefault();
					}}
				>
					<Grid container spacing={3}>
						<Grid item xs={12} md={4}>
							<UIDate
								shrink={true}
								fullWidth={true}
								formatDate="dd/MM/yy"
								onChange={(date) =>
									handleChangeForm({
										target: {
											name: 'createdAt',
											valeu: new Date(date).toISOString(),
										},
									})
								}
								label="Abertura do processo"
								selectedDate={processForm.createdAt || new Date()}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UIAutocomplete
								label="Estado"
								value={
									processForm.uf
										? { title: processForm.uf, value: processForm.uf }
										: ''
								}
								onChange={(evt, newValue) =>
									handleChangeForm({
										target: {
											name: 'uf',
											value: (newValue || {}).title,
										},
									})
								}
								filter
								options={ufs}
								renderInput={(params) => (
									<StyledTextField
										{...params}
										fullWidth
										InputLabelProps={{ shrink: true }}
										label="Estado"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UIAutocomplete
								label="Cidade"
								value={
									processForm.city
										? { title: processForm.city, value: processForm.city }
										: ''
								}
								onChange={(evt, newValue) =>
									handleChangeForm({
										target: {
											name: 'city',
											value: (newValue || {}).title,
										},
									})
								}
								filter
								options={cities}
								renderInput={(params) => (
									<StyledTextField
										{...params}
										fullWidth
										InputLabelProps={{ shrink: true }}
										label="Cidade"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UIInput
								label="Código do cliente (opcional)"
								type="number"
								value={processForm.distributorId || ''}
								onChange={handleChangeForm}
								name="distributorId"
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UIInput
								label="Razão Social"
								value={(processForm.distributor || {}).socialName}
								disabled
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<Autocomplete
								options={dataInstitutions.institutions}
								getOptionLabel={(option) => option.name || ''}
								filterOptions={filterOptions}
								value={processForm.institution}
								onChange={(event, newValue) => {
									setProcessForm((process) => {
										return {
											...process,
											institution: {
												...newValue,
											},
										};
									});
								}}
								renderInput={(params) => (
									<StyledTextField
										{...params}
										fullWidth
										InputLabelProps={{ shrink: true }}
										label="Instituição"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UISelect
								label="Nome do programa"
								items={mock.programNames}
								value={values.programName}
								onChange={handleChange('programName')}
								onBlur={handleBlur('programName')}
								error={touched.programName && errors.programName}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UISelect
								label="Política Comercial"
								items={mock.comercialPolitcs}
								value={values.comercialPolitcs}
								onChange={handleChange('comercialPolitcs')}
								onBlur={handleBlur('comercialPolitcs')}
								error={touched.comercialPolitcs && errors.comercialPolitcs}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UISelect
								label="Modalidade do pregão"
								items={mock.noticeModality}
								value={values.noticeModality}
								onChange={handleChange('noticeModality')}
								onBlur={handleBlur('noticeModality')}
								error={touched.noticeModality && errors.noticeModality}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UIInput
								label="Número da cotação"
								value={values.tradingCode}
								onChange={handleChange('tradingCode')}
								onBlur={handleBlur('tradingCode')}
								error={touched.tradingCode && errors.tradingCode}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UISelect
								label="Forma de participação"
								items={mock.participation}
								value={values.processDirect}
								onChange={handleChange('processDirect')}
								onBlur={handleBlur('processDirect')}
								error={touched.processDirect && errors.processDirect}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UIDate
								shrink={true}
								fullWidth={true}
								formatDate="dd/MM/yy"
								onChange={(date) =>
									handleChangeForm({
										target: {
											name: 'startPeriod',
											value: new Date(date).toISOString(),
										},
									})
								}
								label="Ínicio fornecimento"
								selectedDate={processForm.startPeriod || new Date()}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UISelect
								label="Periodo de fornecimento (mês)"
								items={mock.optionsMonth}
								value={values.period}
								onChange={handleChange('period')}
								onBlur={handleBlur('period')}
								error={touched.period && errors.period}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UIDate
								shrink={true}
								fullWidth={true}
								formatDate="dd/MM/yy"
								onChange={(date) =>
									handleChangeForm({
										target: {
											name: 'endPeriod',
											endPeriod: new Date(date).toISOString(),
										},
									})
								}
								label="Término de fornecimento"
								selectedDate={processForm.endPeriod || new Date()}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UIRadio
								label="Prorrogar"
								items={mock.yesNo}
								value={values.canExtend}
								onChange={handleChange('canExtend')}
								onBlur={handleBlur('canExtend')}
								error={touched.canExtend && errors.canExtend}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UISelect
								label="Meses a ser prorrogado"
								items={mock.optionsMonth}
								disabled={values.canExtend === 'sim' ? false : true}
								value={
									values.canExtend === 'sim' ? values.extendMonths || '' : ''
								}
								onChange={handleChange('extendMonths')}
								onBlur={handleBlur('extendMonths')}
								error={touched.extendMonths && errors.extendMonths}
							/>
						</Grid>
					</Grid>
					<Grid container justify="flex-end" alignItems="center" spacing={3}>
						<Grid item xs={12} md={2}>
							<UIButton text="Voltar" variant="outlined" type="reset" />
						</Grid>
						<Grid item xs={12} md={2}>
							<UIButton text="Avançar" type="submit" />
						</Grid>
					</Grid>
				</form>
			</Box>
		</StyledGrid>
	);
}

export default NoticeAnalysis;
