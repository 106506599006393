import { FormControlLabel, withStyles } from '@material-ui/core';

export const StyledLabel = withStyles({
	root: {
		color: '#565656',
	},
	label: {
		fontWeight: (props) => props.fontWeight,
		fontSize: (props) => props.fontSize,
	},
})(FormControlLabel);
