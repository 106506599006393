import React from 'react';

import { TagChip } from './styles';
/**
 *
 * @param {String} label text
 * @param {Number} size string optional defualt prop
 * @param {String} variant string
 * @param {String} width string
 * @param {string} height string
 */

const variants = {
	public: {
		color: 'var(--primary)',
		background: 'rgba(80, 130, 201, 0.16)',
	},
	blue: {
		color: 'var(--primary)',
		background: 'rgba(80, 130, 201, 0.16)',
	},
	Publico: {
		color: 'var(--primary)',
		background: 'rgba(80, 130, 201, 0.16)',
	},
	private: {
		color: 'var(--success)',
		background: 'rgba(101, 172, 40, 0.12)',
	},
	Privado: {
		color: 'var(--success)',
		background: 'rgba(101, 172, 40, 0.12)',
	},
	gold: {
		color: 'var(--orange)',
		background: 'rgba(255, 189, 20, 0.16)',
	},
	silver: {
		color: 'var(--text-grey)',
		background: 'rgba(196, 196, 196, 0.1)',
	},
	bgGrey: {
		color: 'black',
		background: 'rgba(196, 196, 196, 0.1)',
	},
	success: {
		color: 'var(--success)',
		background: 'rgba(101, 172, 40, 0.25)',
	},
	pink: {
		color: 'var(--pink)',
		background: 'rgba(201, 80, 80, 0.16)',
	},
	error: {
		color: 'var(--error)',
		background: 'rgba(201, 80, 80, 0.16)',
	},
	denied: {
		color: 'var(--error)',
		background: 'rgba(201, 80, 80, 0.16)',
	},
	suspending: {
		color: 'var(--error)',
		background: 'rgba(201, 80, 80, 0.16)',
	},
	disabled: {
		color: 'var(--text-dark)',
		background: 'var(--text-grey2)',
	},
	orange: {
		color: '#EF851B',
		background: '#fff4d9',
	},
	requested: {
		color: '#EF851B',
		background: '#fff4d9',
	},
	approved: {
		color: 'var(--success)',
		background: 'rgba(101, 172, 40, 0.25)',
	},
	contested: {
		color: '#EF851B',
		background: '#fff4d9',
	},
	consultant: {
		color: 'var(--primary)',
		background: 'rgba(80, 130, 201, 0.16)',
	},
	distributor: {
		color: 'var(--success)',
		background: 'rgba(101, 172, 40, 0.25)',
	},
	financier: {
		color: '#EF851B',
		background: '#fff4d9',
	},
	supervisor: {
		color: '#EF851B',
		background: '#fff4d9',
	},
	manager: {
		color: '#FF73C3',
		background: '#f6e3e3',
	},
	admin: {
		color: '#FF73C3',
		background: '#f6e3e3',
	},
};

/**
 *
 * @param {*} text string
 * @param {} variant status color
 */
export default function UITag({
	text,
	variant,
	size,
	width,
	height,
	fontSize,
	fontWeight = '500',
}) {
	return (
		<TagChip
			label={text}
			size={size}
			background={
				variant
					? (variants[variant] || {}).background
					: variants.public.background
			}
			color={variant ? (variants[variant] || {}).color : variants.public.color}
			width={width ? width : 'min-content'}
			height={height ? height : 'min-content'}
			fontSize={fontSize}
			fontWeight={fontWeight}
		/>
	);
}
