import { IconButton, Input, MenuItem } from '@material-ui/core';
import React from 'react';

import { AiOutlineSearch } from 'react-icons/ai';
import {
	StyledPaper,
	StyledInputBase,
	StyledDivider,
	StyledSelect,
} from './style';

function UISearch({
	valueInput,
	valueSelect = 'GanhoParcial',
	onChangeInput,
	onChangeSelect,
	placeholder,
	selectOptions = [],
}) {

	return (
		<StyledPaper elevation={0}>
			<IconButton type="submit" aria-label="search">
				<AiOutlineSearch size={20} color="var(--text-grey)" />
			</IconButton>
			<StyledInputBase
				placeholder={placeholder}
				value={valueInput}
				onChange={onChangeInput}
				fullWidth
			/>
			<StyledDivider orientation="vertical" />
			<StyledSelect
				value={valueSelect}
				onChange={onChangeSelect}
				input={<Input disableUnderline />}
			>
				{selectOptions.map(({ text, value }) => (
					<MenuItem value={value} key={value}>
						{text}
					</MenuItem>
				))}
			</StyledSelect>
		</StyledPaper>
	);
}

export default UISearch;
