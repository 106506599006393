import React from 'react';

import { FormControl, FormHelperText, InputAdornment } from '@material-ui/core';
import { StyledInput, StyledInputLabel } from './style';

export default function UIInput({
	value,
	label,
	placeholder,
	type,
	startIcon,
	endIcon,
	onChange,
	disabled,
	shrink = true,
	name,
	color,
	fontSize,
	error,
	labelwidth,
	...rest
}) {
	const startAdornment = startIcon ? (
		<InputAdornment position="start">{startIcon}</InputAdornment>
	) : (
			''
		);

	const endAdornment = endIcon ? (
		<InputAdornment position="end">{endIcon}</InputAdornment>
	) : (
			''
		);

	return (
		<FormControl fullWidth disabled={disabled} error={error ? true : false}>
			<StyledInputLabel shrink={shrink} color={color} fontSize={fontSize} labelwidth={labelwidth}>
				{label}
			</StyledInputLabel>
			<StyledInput
				value={value}
				onChange={onChange}
				name={name}
				type={type}
				placeholder={placeholder}
				startAdornment={startAdornment}
				endAdornment={endAdornment}
				{...rest}
			/>
			<FormHelperText>{error}</FormHelperText>
		</FormControl>
	);
}
