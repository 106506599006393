import React, { useCallback } from 'react';

import { useDropzone } from 'react-dropzone';

import { StyledGrid } from './style';

import documentsImage from '../../../assets/images/documents.svg';
import UIButton from '../../UI/Button/Button';
import { Box, Grid } from '@material-ui/core';
import UIText from 'components/UI/Text/Text';

export default function UploadArea({
	onChange = () => { },
	buttonOnClick,
	buttonText = 'Adicionar',
	labelText,
	height,
	hideButton,
	direction = 'row',
	dropButtonText = 'Inserir aqui'
}) {
	const onDrop = useCallback(
		(acceptedFiles) => {
			onChange({ ...acceptedFiles, success: true });
		},
		[onChange],
	);
	const { getRootProps, getInputProps } = useDropzone({ onDrop });

	return (
		<>
			<StyledGrid
				container
				justify="center"
				alignItems="center"
				height={height}
				direction={direction}
				{...getRootProps()}
			>
				<Grid item xs={12} sm={direction === 'column' ? 12 : 4}>
					<img src={documentsImage} alt="Imagem de documentos" />
				</Grid>
				<Grid item xs={12} sm={direction === 'column' ? 12 : 4} md={direction === 'column' ? 12 : 8}>
					<Grid container spacing={2} alignItems="center" direction="column">
						<Grid item xs={direction === 'column' ? 12 : 6}>
							<input {...getInputProps()} />
							<UIText
								text="Arraste e solte o arquivo pdf aqui
ou"
								fontSize="14px"
								textalign="center"
							/>
						</Grid>
						<Grid item>
							<UIButton
								text={dropButtonText}
								borderwidth="1px"
								variant="outlined"
								fontSize="14px"
								width="150px"
							/>
						</Grid>
					</Grid>
				</Grid>
			</StyledGrid>
			<Box pt={2} pb={2}>
				<Grid container justify="space-between">
					<Grid item>
						{labelText !== 'Arquivo selecionado : ' ? labelText : null}
					</Grid>
					<Grid item>
						{!hideButton && (
							<UIButton
								variant="outlined"
								text={buttonText}
								onClick={buttonOnClick}
							/>
						)}
					</Grid>
				</Grid>
			</Box>
		</>
	);
}
