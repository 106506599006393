import React from 'react';
import ReactDOM from 'react-dom';

import 'config/yup'

import App from './components/App/App';

import { store, persistor } from 'store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

ReactDOM.render(
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<React.Fragment>
				<App />
			</React.Fragment>
		</PersistGate>
	</Provider>,
	document.getElementById('root'),
);
