import { Grid, Paper, Typography, withStyles } from '@material-ui/core';

export const StyledPaper = withStyles({
	root: {
		background: 'var(--primary)',
		padding: '6px',
		minHeight: '74px',
		color: '#FFF',
	},
})(Paper);

export const StyledGrid = withStyles({
	root: {
		backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='white' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e")`,
		borderRadius: '6px',
	},
})(Grid);

export const Message = withStyles({
	root: {
		fontSize: '14px',
		fontWeight: '400',
	},
})(Typography);
