import React from 'react';

import { FormControl, FormHelperText } from '@material-ui/core';
import { StyledInput, StyledInputLabel } from './style';

function UITextarea({
	value,
	colorLabel,
	label,
	placeholder,
	onChange,
	disabled,
	rows,
	name,
	error,
	helperText,
}) {
	return (
		<FormControl fullWidth disabled={disabled} error={error ? true : false}>
			<StyledInputLabel shrink color={colorLabel}>
				{label}
			</StyledInputLabel>
			<StyledInput
				disableUnderline
				value={value}
				onChange={(event) => onChange(event)}
				placeholder={placeholder}
				rows={rows}
				name={name}
				multiline
			/>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
}

export default UITextarea;
