import React from 'react';
import { Pagination } from './style';
import PropTypes from 'prop-types';

function TablePagination(props) {
	const { width, count, shape, onChange } = props;
	return (
		<Pagination width={width} count={count} shape={shape} onChange={onChange} />
	);
}

export default TablePagination;

TablePagination.propTypes = {
	width: PropTypes.string,
	count: PropTypes.number,
	shape: PropTypes.string,
};

TablePagination.defaultProps = {
	count: 0,
	shape: 'rounded',
	onChange: () => {},
};
