import { Grid, Typography, Box, withStyles } from '@material-ui/core';

export const CardContainer = withStyles({
	root: {
		height: '175px',
		width: '262px',
		borderRadius: '4px',
		background: '#fff',
		padding: '.9rem',
		weight: 500,
		cursor: 'pointer',
	},
})(Grid);

export const CardTitle = withStyles({
	root: {
		color: (props) => props.textcolor || 'var(--text-grey)',
		fontWeight: (props) => props.fontWeight || 500,
		fontSize: '12px',
		lineHeight: '14,06px',
		paddingBottom: (props) => (props.paddined ? '.3rem' : '0'),
		paddingRight: '.3rem',
		textTransform: (props) => props.texttransform,
	},
})(Typography);

export const CardBlock = withStyles({
	root: {
		background: 'var(--primary)',
		display: 'block',
	},
})(Box);
