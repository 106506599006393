import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';

import UISelect from 'components/UI/Select/Select';
import DocumentsSolicitation from '../Fields/DocumentSolicitation/DocumentSolicitation';
import UIDate from 'components/UI/Date/Date';
import UIInput from 'components/UI/Input/Input';
import UITextarea from 'components/UI/Textarea/Textarea';
import UIAutocomplete from 'components/UI/Autocomplete/index';
import { StyledTextField } from 'screens/Process/components/DistributorCreating/Fields/DocumentSolicitation/styles';
import UploadList from 'components/Upload/UploadList/UploadList';
import lang from 'lang/lang.json';

import {
	LeftForm,
	RightForm,
	Container,
	BannerHead,
	BannerRight,
} from './style';

import DocumentsForm from 'assets/images/DocumentsForm.svg';
import ConfirmProcess from 'assets/images/confirmProcess.png';

import api from 'services/api';
import mock from 'assets/mock.json';

export default function FormSection({
	disabled,
	handleChangeProcess,
	handleChangeSolicitation,
	processForm,
	uploadHidden,
}) {
	const { channels } = mock;

	const [attachments, setAttachments] = useState([]);
	const [cities, setCities] = useState([]);
	const [ufs, setUfs] = useState([]);

	//buscar dados no banco
	useEffect(() => {
		async function getAttachments() {
			try {
				const response = await api.get(`attachments`, {
					params: {
						table: 'process',
						tableId: processForm.id,
					},
				});

				const result = response.data.data.map((att) => {
					return {
						...att,
						url: att.key,
						success: true,
					};
				});

				setAttachments(result);
			} catch (error) {
				return null;
			}
		}

		async function getUfs() {
			const response = await api.get(`cities`, {
				params: {
					$limit: -1,
					$distinct: 'uf',
				},
			});
			if (response.status === 200) {
				const { data } = response;
				let states = [];
				data.map(({ uf }) => states.push(uf));
				setUfs(states);
			}
		}

		async function getCities() {
			const response = await api.get('cities', {
				params: {
					$limit: -1,
					uf: processForm.uf,
				},
			});

			if (response.status === 200) {
				const { data } = response;
				let cities = [];
				data.map(({ city }) => {
					cities.push(city);
				});
				setCities(cities);
			}
		}

		async function getEmployees() {
			const response = await api.get('cities', {
				params: {
					$limit: -1,
					uf: processForm.uf,
					city: processForm.city,
				},
			});

			if (response.status === 200) {
				const { data } = response;
				handleChangeProcess({
					target: {
						value:
							process.env.NODE_ENV === 'development' //salvar todos os processos para o consultor 1
								? 1
								: (data[0] || {}).employeeId,
						name: 'employeeId',
					},
				});
			}
		}
		if (disabled) getAttachments();
		if (ufs.length === 0) getUfs();
		if (processForm.uf) getCities();
		if (processForm.city) getEmployees();
	}, [disabled, processForm.uf, processForm.city]);

	return (
		<form>
			<BannerRight item xs={12}>
				<BannerHead className="header-form">
					<Grid container>
						<img src={disabled ? ConfirmProcess : DocumentsForm} alt="" />
					</Grid>
				</BannerHead>
			</BannerRight>
			<Container container direction="row">
				<LeftForm container direction="column" sm={6}>
					<UIAutocomplete
						label="Estado"
						value={processForm.uf ? processForm.uf : ''}
						disabled={disabled}
						options={ufs}
						onChange={(e, value) => {
							handleChangeProcess({
								target: {
									value: value,
									name: 'uf',
								},
							});
						}}
						renderInput={(params) => (
							<StyledTextField
								{...params}
								fullWidth
								InputLabelProps={{ shrink: true }}
								label="Estado"
							/>
						)}
					/>
					<UISelect
						label="Canal de atendimento"
						name="serviceChannel"
						items={channels}
						disabled={disabled}
						value={
							processForm && processForm.serviceChannel
								? processForm.serviceChannel
								: ''
						}
						onChange={handleChangeProcess}
					/>

					<UIInput
						label="Número do pregão"
						disabled={disabled}
						value={
							processForm && processForm.tradingCode
								? processForm.tradingCode
								: ''
						}
						onChange={handleChangeProcess}
						name="tradingCode"
					/>
				</LeftForm>
				<RightForm container direction="column" xs={6}>
					<UIAutocomplete
						label="Cidade"
						value={processForm.city ? processForm.city : ''}
						options={cities}
						disabled={disabled}
						onChange={(e, value) => {
							handleChangeProcess({
								target: {
									value: value,
									name: 'city',
								},
							});
						}}
						renderInput={(params) => (
							<StyledTextField
								{...params}
								fullWidth
								InputLabelProps={{ shrink: true }}
								label="Cidade"
							/>
						)}
					/>

					<UIDate
						label="Data do processo"
						selectedDate={
							processForm && processForm.tradingDate
								? processForm.tradingDate
								: Date.now()
						}
						onChange={(date) =>
							handleChangeProcess({
								target: {
									name: 'tradingDate',
									value: new Date(date).toISOString(),
								},
							})
						}
						disabled={disabled}
					/>
					<UIInput
						label="Segmento"
						disabled
						value={
							processForm && processForm.processDirect === true
								? 'Privado'
								: 'Publico'
						}
						name="segment"
					/>
				</RightForm>
				<Grid container direction="column">
					<DocumentsSolicitation
						placeholderInput="Solicitar documentos"
						disabled={disabled}
						name="requiredDocuments"
						onChangeSolicitation={handleChangeSolicitation}
						value={
							processForm && processForm.requiredDocuments
								? processForm.requiredDocuments
								: []
						}
					/>
					<div className="observação">
						<UITextarea
							disabled={disabled}
							label="Observação (opcional)"
							onChange={handleChangeProcess}
							rows={4}
							placeholder="Insira o texto..."
							value={
								processForm && processForm.observation
									? processForm.observation
									: ''
							}
							name="observation"
						/>
					</div>

					{!uploadHidden ? (
						<UploadList
							itemsList={attachments}
							titleList={
								disabled ? 'Etapas das licitações' : 'Anexos inseridos'
							}
							iconDelete={false}
						/>
					) : null}
				</Grid>
			</Container>
		</form>
	);
}
