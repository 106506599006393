import { withStyles } from '@material-ui/core';
import PaginationMaterial from '@material-ui/lab/Pagination';

export const Pagination = withStyles({
	root: {
		width: (props) => props.width,
	},
	ul: {
		width: (props) => props.width,
		'&& .MuiPaginationItem-root': {
			minWidth: '18px',
			height: '18px',
			margin: '0',
		},
		'&& .Mui-selected': {
			background: '#EAEEF7',
			color: 'var(--text-dark)',
		},
	},
})(PaginationMaterial);
