import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@material-ui/core';

import HeaderScreens from 'screens/Admin/components/HeaderScreens/index';
import NavTabs from 'components/Navigation/Tabs/Tabs';
import Card from 'components/Cards/index';

import { breadcrumbItems } from './selectItems';
import { useDispatch } from 'react-redux';
import { setLoading } from 'store/modules/app/actions';
import api from 'services/api';
import { useHistory } from 'react-router';
import Modal from 'components/Modals';
import UIPagination from 'components/UI/Pagination';

export default function Institution() {
	const history = useHistory();
	const dispatch = useDispatch();

	const [searchValue, setSearchValue] = useState('');
	const [activeTab, setActiveTab] = useState(0);
	const [paramsInstitution, setParamsInstitution] = useState({
		disabled: 0,
	});
	const [selectedInstitution, setSelectedInstitution] = useState({});
	const [institutions, setInstitutions] = useState([]);
	const [allInstitutions, setAllInstitutions] = useState([]);
	const [confirmModal, setConfirmModal] = useState(false);
	const [successModal, setSuccessModal] = useState(false);

	useEffect(() => {
		getInstitutions(paramsInstitution);
	}, [dispatch, paramsInstitution]);

	const stepsProcess = [
		{
			label: 'Todas',
			color: 'var(--primary)',
			component: () => '',
		},
		{
			label: 'Privadas',
			color: 'var(--primary)',
			component: () => '',
		},
		{
			label: 'Públicas',
			color: 'var(--primary)',
			component: () => '',
		},
		{
			label: 'Inativas',
			color: 'var(--primary)',
			component: () => '',
			marginLeft: 'auto',
		},
	];

	const getInstitutions = async () => {
		try {
			dispatch(setLoading(true));
			const response = await api.get(`institution`, {
				params: paramsInstitution,
			});

			setInstitutions(response.data);
			setAllInstitutions(response.data);
			dispatch(setLoading(false));
		} catch (error) {
			dispatch(setLoading(false));
			return error;
		}
	};

	const handleChangeTab = (e, newValue) => {
		switch (newValue) {
			case 0:
				setParamsInstitution({
					segment: null,
					disabled: 0,
				});
				break;
			case 1:
				setParamsInstitution({
					segment: 'Privado',
					disabled: 0,
				});
				break;
			case 2:
				setParamsInstitution({
					segment: 'Publico',
					disabled: 0,
				});
				break;
			case 3:
				setParamsInstitution({
					segment: null,
					disabled: 1,
				});
				break;
			default:
				break;
		}

		setActiveTab(newValue);
	};

	const updateInstitution = async () => {
		try {
			handleConfirmModal();

			await api.patch(`institution/${selectedInstitution.id}`, {
				disabled: !selectedInstitution.disabled,
			});

			if (selectedInstitution.disabled === 1) handleSuccessModal();
			getInstitutions();
		} catch (error) {
			return error;
		}
	};

	const handleConfirmModal = () => {
		setConfirmModal(!confirmModal);
	};

	const handleSuccessModal = () => {
		setSuccessModal(!successModal);
	};

	useEffect(() => {
		const objToArray = object => Object.keys(object || {}).map(key => object[key]);
		const recursive = data => {
			const response = [];
			const array = objToArray(data);
			const haveMore = array.filter(item => Object.keys(item || {}).length > 0 && typeof (item) != "string");
			if (haveMore.length > 0) {
				const arrayCopy = array;
				haveMore.map(item => {
					arrayCopy.splice(array.indexOf(item), 1);
					recursive(item).filter(item => item != null && item != undefined).map(result => response.push(result));
				});
				arrayCopy.filter(item => item != null && item != undefined).map(result => response.push(result));
			}
			else array.filter(item => item != null && item != undefined).map(item => response.push(item));
			return response;
		};

		if (searchValue) {
			const current = String(searchValue).toLocaleLowerCase();
			const newProcess = [...allInstitutions.data];
			const found = [];
			newProcess.map(process => {
				// all process
				const array = recursive(objToArray(process));
				const values = array.findIndex(item => String(item).toLowerCase().indexOf(current) > -1);
				if (values > -1) found.push(process);
			});
			if (found) setInstitutions({ data: found });
		} else setInstitutions({ data: allInstitutions.data });
	}, [searchValue]);

	return (
		<Box p={3}>
			<HeaderScreens
				titleScreen="Instituições"
				dtUpdated="Adicionado em: 24/04/19 - 19:12"
				buttonText="Adicionar Instituição"
				buttonOnClick={() => history.push('/instituicao/nova')}
				currentBreadItem={breadcrumbItems}
				searchPlaceholder="Pesquisar instituições..."
				searchValue={searchValue}
				searchOnChange={(e) => setSearchValue(e.target.value)}
			/>
			<NavTabs
				tabs={stepsProcess}
				activeTab={activeTab}
				onChange={handleChangeTab}
			/>
			<Box py={2}>
				<Grid container spacing={3}>
					{activeTab !== 3
						? (institutions.data || []).map((institution) => (
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Card
									variant="contact"
									informations={{
										borderColor:
											institution.segment === 'Privado'
												? 'var(--success)'
												: 'var(--primary)',
										tag: {
											variant: institution.segment,
											text: institution.segment,
										},
										...institution,
										onTrashClick: () => {
											setSelectedInstitution(institution);
											handleConfirmModal();
										},
										onPencilClick: () =>
											history.push(`instituicao/${institution.id}`),
										name:
											String(institution.name).length > 50
												? `${String(institution.name).slice(0, 50)}...`
												: String(institution.name),
									}}
								/>
							</Grid>
						))
						: institutions.data.map((institution) => (
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Card
									variant="contact-disabled"
									informations={{
										borderColor:
											institution.segment === 'Privado'
												? 'var(--success)'
												: 'var(--primary)',
										tag: {
											variant: institution.segment,
											text: institution.segment,
										},
										menuItems: [
											{
												text: 'Reativar instituição',
												onClick: handleConfirmModal,
											},
										],
										onMenuClick: () => {
											setSelectedInstitution(institution);
										},
										...institution,
										name:
											String(institution.name).length > 50
												? `${String(institution.name).slice(0, 50)}...`
												: String(institution.name),
									}}
								/>
							</Grid>
						))}
				</Grid>
				<Box display="flex" justifyContent="flex-end" py={2}>
					<UIPagination
						count={Math.ceil(institutions.total / 12)}
						size="medium"
						onChange={(e, value) =>
							setParamsInstitution((params) => {
								return {
									...params,
									$skip: (value - 1) * 12,
								};
							})
						}
					/>
				</Box>
			</Box>
			<Modal
				variant="error"
				title={
					selectedInstitution.disabled === 1
						? 'Deseja reativar a instituição ao sistema?'
						: 'Deseja suspender a instituição usuário?'
				}
				subtitle={
					selectedInstitution.disabled === 0 &&
					'Todos os processos da instituição serão suspensos'
				}
				buttonConfirmText={
					selectedInstitution.disabled === 1 ? 'Reativar' : 'Confirmar'
				}
				colorIcon={
					selectedInstitution.disabled === 1
						? 'var(--text-grey)'
						: 'var(--error)'
				}
				open={confirmModal}
				onClose={handleConfirmModal}
				buttonConfirmAction={() => updateInstitution()}
				buttonCancelAction={() => setConfirmModal(false)}
			/>
			<Modal
				variant="success"
				title="Instituição reativada
				com sucesso"
				buttonText="Voltar as instituições"
				open={successModal}
				onClose={handleSuccessModal}
				buttonAction={handleSuccessModal}
			/>
		</Box>
	);
}
