import {
  Dialog, makeStyles, Typography, withStyles,
} from '@material-ui/core';

export const StyledDialog = withStyles({
  paper: {
    padding: '30px 10px 40px 10px',
  },
})(Dialog);

export const Title = withStyles({
  root: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
})(Typography);

export const Subtitle = withStyles({
  root: {
    fontSize: '14px',
    textAlign: 'center',
  },
})(Typography);

export const useStyles = makeStyles({
  iconClose: {
    position: 'absolute',
    left: '90%',
    bottom: '80%',
    cursor: 'pointer',
  },
});
