import { Grid, withStyles } from '@material-ui/core';

export const StyledGrid = withStyles({
	root: {
		border: '2px dashed var(--text-grey)',
		background: 'rgba(80, 130, 201, 0.05)',
		padding: '20px 30px',
		height: (props) => props.height,
	},
})(Grid);
