import React from 'react';

import {
	FormControl,
	FormHelperText,
	MenuItem,
	Select,
} from '@material-ui/core';
import { StyledInput, StyledInputLabel } from './style';

function UISelect({
	label,
	value,
	name,
	onChange,
	items,
	disabled,
	error,
	msgError,
	color,
	keyName,
	keyValue,
}) {
	return (
		<FormControl error={error ? true : false} fullWidth disabled={disabled}>
			<StyledInputLabel shrink color={color}>
				{label}
			</StyledInputLabel>
			<Select
				value={value}
				name={name}
				onChange={onChange}
				input={<StyledInput />}
			>
				{items.map((item, key) => {
					return(
						keyName !== undefined && keyValue !== undefined ? (
							<MenuItem value={item[[keyValue]]} key={item[[keyValue]]}>
								{item[[keyName]]}
							</MenuItem>
						) : (
							<MenuItem value={item.value} key={key}>
								{item.text}
							</MenuItem>
						)
				)})}
			</Select>
			<FormHelperText>{msgError || error}</FormHelperText>
		</FormControl>
	);
}

export default UISelect;
