import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useFormik } from 'formik';

import MainModal from 'components/Modals/MainModal';
import UIAutocomplete from 'components/UI/Autocomplete';
import { Box } from '@material-ui/core';
import UIButton from 'components/UI/Button/Button';
import api from 'services/api';
import { createFilterOptions } from '@material-ui/lab';

const validationSchema = yup.object().shape({
	distributor: yup.mixed().required('Selecione o distribuidor'),
});

export default function SelectSubstituteDistributorModal({
	show,
	onClose,
	onConfirm,
	user,
}) {
	const [distributors, setDistributors] = useState([]);

	const {
		values,
		errors,
		touched,
		handleBlur,
		handleSubmit,
		setFieldValue,
		resetForm,
	} = useFormik({
		initialValues: {
			distributor: null,
		},
		validationSchema,
		onSubmit({ distributor }) {
			onConfirm({ accessId: distributor.value, oldAccessId: user.id });
		},
	});

	useEffect(() => {
		if (!show) {
			resetForm({ distributor: null });
		}
	}, [show, resetForm]);

	useEffect(() => {
		async function getDistributors() {
			try {
				const response = await api.get(
					`access?distributorId[$nin]=[null, ${user.id}]&distributorId[$nin]=[null, ${user.id}]`,
					{},
				);

				setDistributors(
					response.data.data.map((access) => {
						return {
							title: access.distributor.name,
							value: access.id,
						};
					}),
				);
			} catch (error) {
				throw error;
			}
		}
		getDistributors();
	}, []);

	return (
		<MainModal
			show={show}
			title="Definir usuário substituto"
			width="small"
			onClose={onClose}
		>
			<form
				onSubmit={(e) => {
					handleSubmit(e);
					e.preventDefault();
				}}
			>
				<Box marginTop="32px">
					<UIAutocomplete
						value={values.distributor}
						options={distributors}
						label="Distribuidor"
						onChange={(_, newValue) => setFieldValue('distributor', newValue)}
						error={touched.distributor && errors.distributor}
						onBlur={handleBlur('distributor')}
						filter
					/>
				</Box>
				<Box display="flex" justifyContent="flex-end" marginTop="46px">
					<UIButton text="Voltar" variant="outlined" onClick={onClose} />
					<Box width="8px" />
					<UIButton text="Confirmar" type="submit" />
				</Box>
			</form>
		</MainModal>
	);
}

SelectSubstituteDistributorModal.propTypes = {
	show: PropTypes.bool,
	onClose: PropTypes.func,
	onConfirm: PropTypes.func,
};
