import { withStyles, Avatar } from '@material-ui/core';

export const PrevItem = withStyles({
	root: {
		background: '#EAEEF7',
		border: '2px solid var(--primary)',
		color: 'var(--primary)',

		width: '34px',
		height: '34px',
	},
})(Avatar);

export const ActiveItem = withStyles({
	root: {
		background: 'var(--primary)',
		border: '2px solid var(--primary)',
		color: '#fff',

		width: '34px',
		height: '34px',
	},
})(Avatar);

export const NextItem = withStyles({
	root: {
		background: 'rgba(80, 130, 201, 0.05)',
		border: '2px solid rgba(80, 130, 201, 0.05)',
		color: 'var(--primary)',

		width: '34px',
		height: '34px',
	},
})(Avatar);
