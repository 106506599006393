import React, { useEffect, useState } from 'react';
import { Container, Paper } from '@material-ui/core';
import VerticalStageBar from 'components/StageBar/VerticalStageBar/VerticalStageBar';

import UIButton from 'components/UI/Button/Button';
import FormSection from 'screens/Process/components/DistributorCreating/FormSection';
import UploadSection from 'screens/Process/components/DistributorCreating/UploadSection';
import CreateProcess from './createProcess';
import { store } from 'store';

import { Grid } from '@material-ui/core';
import { Banner, BannerLeft, DateArea, ButtonArea } from './style';
import NavBreadcrumbs from 'components/Navigation/Breadcrumbs/Breadcrumbs';
import SuccessModal from 'components/Modals/SuccessModal/index';

import { useHistory, useParams } from 'react-router';
import api from 'services/api';
import mock from 'assets/mock.json';

export default function DistributorCreate() {
	const history = useHistory();
	const { processType } = useParams();

	const { breadcumbs, stageBarItems } = mock;
	const [step, setStep] = useState(0);
	const [form, setForm] = useState();
	const [button, setButton] = useState(<UIButton />);
	const [openModalSuccess, setOpenModalSuccess] = useState(false);

	const processDefault = store.getState().process.process;
	const [process, setProcess] = useState(processDefault);

	useEffect(() => {
		setProcess({
			...processDefault,
			processDirect: processType === 'direto' ? true : false,
			segment: processType === 'direto' ? 'private' : 'public',
		});
	}, [processDefault]);

	const onChangeHandler = (e) => {
		const { name, value } = e.target;
		setProcess((process) => {
			return { ...process, [name]: value };
		});
	};

	const onChangeHandlerSolicitation = (event, newValue) => {
		setProcess((process) => {
			return { ...process, requiredDocuments: newValue };
		});
	};

	const nextStep = async () => {
		//avan'ca e edita, usar para trazer erros tmb.
		const limit = 3;
		const next = step + 1 > limit ? limit : step + 1;
		if (step === 0) {
			setStep(next);
			return true;
		}
		const response = await api.patch(`/process/${process.id}`, process); //isso aqui tem que ir pro redux

		if (response.status !== 200)
			//alguem cria aqui tmb
			return false;

		setStep(next);
	};

	const submitProcess = async () => {
		// finaliza, aqui geraria o codigo PNE
		const response = await api.patch(`process/${process.id}`, {
			status: 'const recording',
		});
		if (response.status === 200) setStep(4);
	};

	useEffect(() => {
		if (step === 1) {
			setForm(
				<FormSection
					handleChangeProcess={onChangeHandler}
					handleChangeSolicitation={onChangeHandlerSolicitation}
					processForm={process}
					uploadHidden={true}
				/>,
			);
			setButton(
				<UIButton text="Avançar" width="100%" onClick={() => nextStep()} />,
			);
		} else if (step === 2) {
			setForm(<UploadSection height="100%" />);
			setButton(
				<>
					<UIButton
						variant="outlined"
						text="Voltar"
						width="48%"
						onClick={(e) => {
							if (step > 1) setStep(step - 1);
						}}
					/>
					<UIButton text="Avançar" width="48%" onClick={() => nextStep()} />
				</>,
			);
		} else if (step === 3) {
			setForm(
				<FormSection
					disabled
					processForm={process}
					handleChangeProcess={() => {}}
				/>,
			);
			setButton(
				<>
					<UIButton
						variant="outlined"
						text="Voltar"
						width="40%"
						onClick={(e) => {
							if (step > 1) setStep(step - 1);
						}}
					/>
					<UIButton
						text="Finalizar"
						width="40%"
						onClick={() => submitProcess()}
					/>
				</>,
			);
		} else if (step === 4) {
			setOpenModalSuccess(true);
		}
	}, [step, process]);

	if (step === 0) return <CreateProcess buttonAction={nextStep} />;

	return (
		<Grid container direction="row">
			<Banner>
				<Container maxWidth="lg">
					<Grid container direction="row">
						<BannerLeft item className="leftBanner" xs={12} sm={12} md={5}>
							<NavBreadcrumbs items={breadcumbs} color="#fff" />
							<div>
								<h3 className="banner-titulo">Cadastro do processo</h3>
							</div>
						</BannerLeft>
					</Grid>
				</Container>
			</Banner>
			<Container maxWidth="lg">
				<Grid container direction row className="content-form">
					<Grid item xs={12} sm={12} md={5} className="listStage">
						<VerticalStageBar items={stageBarItems} current={step} />
					</Grid>
					<DateArea item xs={12} sm={12} md={7} className="form">
						<Paper elevation={0}>{form}</Paper>
						<ButtonArea
							container
							direction="row"
							justify="space-between"
							alignItems="center"
						>
							{button}
						</ButtonArea>
					</DateArea>
				</Grid>
			</Container>
			<SuccessModal
				open={openModalSuccess}
				title="Processo criado com sucesso"
				buttonAction={() => history.push('/')}
				buttonText="Voltar para a home"
				onClose={() => history.push('/')}
			/>
		</Grid>
	);
}
