import { Box, Dialog, IconButton } from '@material-ui/core';
import { IoMdCloseCircle } from 'react-icons/io';
import { useHistory } from 'react-router';

import { Title, Text, Card } from './style';

import Persons from '../../../../assets/images/persons.svg';
import Building from '../../../../assets/images/building.svg';

export default function AddProductModal({ show, onClose, actionRival }) {
	const history = useHistory();

	return (
		<Dialog open={show} onClose={onClose}>
			<Box
				css={{ bgcolor: '#fff' }}
				boxShadow="0px 0px 8px rgba(0, 0, 0, 0.16)"
				textAlign="center"
				paddingTop="48px"
				paddingBottom="55px"
				paddingX="80px"
			>
				<Box position="absolute" right="24px" top="40px">
					<IconButton size="medium" onClick={onClose}>
						<IoMdCloseCircle color="var(--text-grey)" size={25} />
					</IconButton>
				</Box>
				<Title>Adicionar Produto</Title>
				<Text>Escolha entre adicionar produtos nestlé ou concorrentes</Text>
				<Box display="flex" justifyContent="space-between" marginTop="22px">
					<Card
						title="Nestlé"
						image={Persons}
						onClick={() => history.push('/produto/novo')}
					/>
					<Card title="Concorrentes" image={Building} onClick={actionRival} />
				</Box>
			</Box>
		</Dialog>
	);
}
