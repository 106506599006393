import { Grid } from '@material-ui/core';
import MainModal from 'components/Modals/MainModal';
import { UIAutocomplete } from 'components/UI/Autocomplete';
import UIInput from 'components/UI/Input/Input';
import UIRadio from 'components/UI/Radio/Radio';
import UITextarea from 'components/UI/Textarea/Textarea';
import { optionsYesNo } from './mock.json';
import { useEffect, useState } from 'react';
import api from 'services/api';
import { moneyMask, percentMask } from 'services/mask';
import { parseToNumber } from 'brazilian-values';
import { useParams } from 'react-router';
import Modal from 'components/Modals';
import { updateProcessProduct } from 'store/modules/processProduct/actions';
import { useDispatch } from 'react-redux';
import { createFilterOptions } from '@material-ui/lab';
import { StyledTextField } from './styles';
import { setRefresh } from 'store/modules/app/actions';

export default function EditItem({
	defaultItem,
	show,
	onClose,
	extraSuccessAction = () => {},
}) {
	const dispatch = useDispatch();
	const { idProcess } = useParams();
	const [item, setItem] = useState(defaultItem);
	const [productsList, setProductsList] = useState([]);
	const [successModal, setSuccessModal] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(defaultItem.product);

	useEffect(() => {
		setItem(defaultItem);
		const processItem = defaultItem.product || {};
		setSelectedProduct({
			title: processItem.sku,
			product: processItem.description,
			value: 0,
		});
	}, [defaultItem]);

	useEffect(() => {
		async function getProducts() {
			try {
				const response = await api.get(`products`);

				const products = response.data.data.map((item) => {
					return {
						title: item.sku,
						product: item.description,
						value: item.id,
					};
				});

				setProductsList(products);
			} catch (error) {
				return null;
			}
		}

		getProducts();
	}, []);

	const handleChangeItem = (event) => {
		setItem((item) => {
			return {
				...item,
				[event.target.name]: event.target.value,
			};
		});
	};

	const handleSuccessModal = () => {
		setSuccessModal(!successModal);
	};

	const updateProduct = async () => {
		const data = {
			...item,
			discountValue: parseToNumber(
				(`${item.discountValue}` || '').replace('%', ''),
			),
			markup: parseToNumber((`${item.markup}` || '').replace('%', '')),
			quantity: parseToNumber(`${item.quantity}` || ''),
			processId: idProcess,
			institutionValue: parseToNumber(`${item.institutionValue}` || ''),
			nfPrice: parseToNumber(`${item.nfPrice}` || ''),
			priceList: parseToNumber(`${item.priceList}` || ''),
		};

		dispatch(updateProcessProduct(data));

		setItem({ approve: true, discount: false });

		onClose();
		handleSuccessModal();
	};

	const filterOptions = createFilterOptions({
		matchFrom: 'start',
		stringify: (option) => option.title,
	});

	return (
		<>
			<MainModal
				title="Editar item"
				confirmButton="Confirmar"
				onConfirm={() => updateProduct()}
				onCancel={onClose}
				cancelButton="Cancelar"
				bottonButtonMd={6}
				confirmVariant="contained"
				width="small"
				show={show}
				onClose={onClose}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<UIInput
							label="Item"
							name="itemNumber"
							onChange={handleChangeItem}
							value={item.itemNumber || ''}
						/>
					</Grid>
					<Grid item xs={12}>
						<UITextarea
							label="Especificação"
							name="specification"
							rows={3}
							onChange={handleChangeItem}
							value={item.specification || ''}
						/>
					</Grid>
					<Grid item xs={12}>
						<UIInput
							label="Quantidade"
							name="quantity"
							onChange={handleChangeItem}
							value={item.quantity || ''}
							type="number"
						/>
					</Grid>
					<Grid item xs={12}>
						<UIInput
							label="Unidade"
							name="unity"
							onChange={() => {}}
							value="UNI"
							disabled
						/>
					</Grid>
					<Grid item xs={12}>
						<UIRadio
							label="Produto pode ser atendido?"
							items={optionsYesNo}
							name="approve"
							onChange={(e) =>
								setItem((item) => {
									return {
										...item,
										approve: e.target.value === 'sim' ? true : false,
									};
								})
							}
							value={item.approve ? 'sim' : 'não'}
						/>
					</Grid>
					<Grid item xs={12}>
						<UITextarea
							label="Justificativa"
							rows={3}
							name="justify"
							onChange={handleChangeItem}
							value={item.justify || ''}
							disabled={item.approve}
						/>
					</Grid>
					<Grid item xs={12}>
						<UIAutocomplete
							label="SKU"
							options={productsList}
							filter
							value={selectedProduct ? selectedProduct : ''}
							onChange={(event, newValue) => {
								setSelectedProduct(newValue);
								setItem((item) => {
									return {
										...item,
										productId: newValue.value,
										product: {
											...item.product,
											sku: newValue.title,
											description: newValue.product,
										},
									};
								});
							}}
							renderInput={(params) => (
								<StyledTextField
									{...params}
									fullWidth
									InputLabelProps={{ shrink: true }}
									label="SKU"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<UIInput
							label="Produto"
							value={selectedProduct ? selectedProduct.product : ''}
							disabled
						/>
					</Grid>
					<Grid item xs={12}>
						<UIInput
							label="(R$)Preço lista"
							name="value"
							disabled={true}
							value={item.priceList || ''}
						/>
					</Grid>
					<Grid item xs={12}>
						<UIRadio
							label="Solicitar desconto?"
							items={optionsYesNo}
							name="discount"
							onChange={(e) =>
								setItem((item) => {
									return {
										...item,
										discount: e.target.value === 'sim' ? true : false,
									};
								})
							}
							value={item.discount ? 'sim' : 'não'}
						/>
					</Grid>
					<Grid item xs={12}>
						<UIInput
							label="Desconto proposto"
							name="discountValue"
							onChange={(e) =>
								handleChangeItem({
									target: {
										name: 'discountValue',
										value: percentMask(e.target.value),
									},
								})
							}
							value={item.discount ? item.discountValue : ''}
							disabled={!item.discount}
						/>
					</Grid>
					<Grid item xs={12}>
						<UIInput
							label="(R$)Preço NF Nestlé"
							value={item.nfPrice || ''}
							disabled={true}
						/>
					</Grid>
					<Grid item xs={12}>
						<UIInput
							label="Markup"
							name="markup"
							onChange={(e) =>
								handleChangeItem({
									target: {
										name: 'markup',
										value: percentMask(e.target.value),
									},
								})
							}
							value={item.markup || ''}
						/>
					</Grid>
					<Grid item xs={12}>
						<UIInput
							label="(R$)Preço instituição"
							name="finalValue"
							value={item.institutionValue || ''}
							disabled={true}
						/>
					</Grid>
					{/* <Grid item xs={12}>
								<NegateDiscountCard />
							</Grid> */}
					<Grid item xs={12}>
						<UITextarea
							label="Justificativa"
							rows={3}
							onChange={handleChangeItem}
							value={item.justify || ''}
						/>
					</Grid>
				</Grid>
			</MainModal>
			<Modal
				open={successModal}
				variant="success"
				title="Item alterado com sucesso"
				buttonText="Voltar para o cadastro"
				buttonAction={() => {
					handleSuccessModal();
					extraSuccessAction();
				}}
			/>
		</>
	);
}
