import { withStyles } from '@material-ui/core';
import { PaginationItem } from '@material-ui/lab';

const widths = {
	small: {
		height: '18px',
		width: '18px',
		font: '12px',
	},
	medium: {
		height: '25px',
		width: '25px',
		font: '14px',
	},
};

export const StyledPaginationItem = withStyles({
	root: {
		fontSize: (props) => widths[props.size].font,
		minWidth: (props) => widths[props.size].width,
		height: (props) => widths[props.size].height,
		margin: '0px',
		padding: '0px',
	},
})(PaginationItem);
