import React from 'react';
import ConfirmModal from './ConfirmModal';
import ErrorModal from './ErrorModal';
import SuccessModal from './SuccessModal';

export default function Modal(props) {
	const { variant } = props;

	if (variant === 'success') {
		return <SuccessModal {...props} />;
	}

	if (variant === 'error') {
		return <ErrorModal {...props} />;
	}

	if (variant === 'confirm') {
		return <ConfirmModal {...props} />;
	}
}
