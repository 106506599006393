import React, { useEffect, useState } from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Grid,
	makeStyles,
} from '@material-ui/core';
import { BsChevronDown } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router';

import UIButton from 'components/UI/Button/Button';
import UIInput from 'components/UI/Input/Input';
import UIText from 'components/UI/Text/Text';
import HeaderScreens from 'screens/Admin/components/HeaderScreens';
import UploadArea from 'components/Upload/UploadArea/UploadArea';
import Modal from 'components/Modals';
import { states } from 'utils';
import UISelect from 'components/UI/Select/Select';
import UploadList from 'components/Upload/UploadList/UploadList';

import { Divider } from './style';

import * as yup from 'yup';
import { useFormik } from 'formik';
import api from 'services/api';
import mock from './mock.json';
import { formatToNumber, parseToNumber } from 'brazilian-values';

const PADDING = '16px';

const useStyles = makeStyles({
	container: {
		boxSizing: 'border-box',
		height: 'calc(100vh - 60px)',
		display: 'flex',
		flexDirection: 'column',
		'& .MuiFormControl-root': {
			marginBottom: '18px !important',
		},
	},
	accordion: {
		padding: 0,
	},
	accordionSumary: {
		padding: 0,
		minHeight: '10px',
		margin: '0px !important',
		'&.Mui-expanded': {
			minHeight: 'initial',
		},
		'& .MuiAccordionSummary-content': {
			justifyContent: 'space-between',
			margin: 0,

			'& svg': {
				transition: '200ms ease-in-out',
			},
		},
	},
	grid: {
		flex: 1,
		marginTop: '24px',
		overflow: 'hidden',
	},
	gridItem: {
		height: '100%',
	},
	scroll: {
		'&::-webkit-scrollbar-track': {
			backgroundColor: '#F4F4F4',
		},
		'&::-webkit-scrollbar': {
			height: '4px',
			width: '8px',
			background: '#E5E5E5',
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#C4C4C4',
			borderRadius: '4px',
			'&:hover': {
				background: '#B0B0B0',
			},
		},
		'&::-webkit-scrollbar-track-piece': {
			background: '#E5E5E5',
		},
	},
});

const validationSchema = yup.object().shape({
	sku: yup.string().required('Digite o SKU'),
	description: yup.string().required('Digite o nome'),
	unity: yup.string().required('Selecione a unidade'),
	category: yup.string().optional('Selecione a categoria'),
	grammage: yup.string().required('Digite a gramatura').nullable(),
	fiscalClassification: yup
		.string()
		.required('Selecione a clissificação fiscal'),
	CEST: yup.string().required('Digite o CEST').nullable(),
	EAN: yup.string().required('Digite o EAN'),
});

export default function CreateProduct() {
	const classes = useStyles();
	const [expanded, setExpanded] = useState(states[0].value);
	const [attachments, setAttachments] = useState([]);
	const [politicalPrices, setPoliticalPrices] = useState([]);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [labelFile, setLabelFile] = useState('');
	const [itemsConfigs, setItemsConfigs] = useState();
	const [selectedDocumentType, setSelectedDocumentType] = useState(null);
	const [msgErrorCombo, setMsgErrorCombo] = useState(null);

	const history = useHistory();
	const { productId } = useParams();

	const [product, setProduct] = useState();

	const {
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
		handleSubmit,
	} = useFormik({
		initialValues: product || {
			sku: '',
			description: '',
			unity: '',
			grammage: '',
			category: '',
			businessUnity: '',
			fiscalClassification: '',
			CEST: '',
			EAN: '',
		},
		enableReinitialize: true,
		validationSchema,
		async onSubmit(fields) {
			try {
				if (!productId) {
					//post product
					const response = await api.post(`products`, fields);

					//post political-prices
					await api.post(
						`political-prices`,
						politicalPrices.map((price) => {
							return {
								...price,
								productId: response.data.id,
							};
						}),
					);

					//update attachments with tableId
					attachments.map(async (att) => {
						await api.patch(`attachments/${att.id}`, {
							tableId: response.data.id,
						});
					});
					return setShowSuccessModal(true);
				}
				//update product and political prices
				await api.patch(`products/${productId}`, fields);

				await api.patch(`political-prices`, politicalPrices);

				return setShowSuccessModal(true);
			} catch (error) {
				return error;
			}
		},
	});

	useEffect(() => {
		async function getProduct() {
			try {
				const response = await api.get(`products/${productId}`);

				setProduct(response.data);
			} catch (error) {
				throw error;
			}
		}

		async function getPoliticalPrices() {
			try {
				const response = await api.get(`political-prices`, {
					params: {
						productId,
					},
				});
				setPoliticalPrices(response.data.data);
			} catch (error) {
				throw error;
			}
		}

		async function getAttachments() {
			try {
				const response = await api.get(`attachments`, {
					tableId: productId,
					table: 'products',
				});

				setAttachments(
					response.data.data.map((att) => {
						return {
							...att,
							showBars: false,
							success: true,
						};
					}),
				);
				setRefresh(!refresh);
			} catch (error) {
				throw error;
			}
		}

		if (productId) {
			getProduct();
			getPoliticalPrices();
			getAttachments();
		}
	}, [productId]);

	//render error message
	useEffect(() => {
		if (selectedDocumentType === '') {
			setMsgErrorCombo('Selecione um tipo de documento');
		} else if (selectedDocumentType === false) {
			setMsgErrorCombo('');
		} else {
			setMsgErrorCombo('');
		}
	}, [selectedDocumentType]);

	const uploadAttachment = async (data) => {
		try {
			let formData = new FormData();
			formData.append('file', data.file);
			formData.append('table', 'products');
			formData.append('tableId', productId);
			formData.append('category', selectedDocumentType);

			const response = await api.post(`attachments`, formData);
			if (response.data)
				return {
					...itemsConfigs,
					id: response.data.id,
					url: response.data.key,
					category: response.data.category,
				};
		} catch (error) {
			return null;
		}
	};

	const addAttachment = async () => {
		if (itemsConfigs) {
			if (
				!msgErrorCombo &&
				selectedDocumentType !== '' &&
				selectedDocumentType !== null
			) {
				const file = await uploadAttachment(itemsConfigs);
				const newItems = attachments;
				newItems.push(file);
				setAttachments(newItems);
				setSelectedDocumentType(null);
				setItemsConfigs(null);
				setLabelFile('');
			}
		}
	};

	const handleChangePrices = (event, uf) => {
		const idx = politicalPrices.findIndex((state) => state.uf === uf);

		const newPrices = politicalPrices;

		if (idx !== -1) newPrices[idx][event.target.name] = event.target.value;
		else newPrices.push({ uf, [event.target.name]: event.target.value });

		setPoliticalPrices(newPrices);
		setRefresh(!refresh);
	};

	return (
		<Box className={classes.container} p={3}>
			<HeaderScreens
				titleScreen={
					productId ? 'Atualizar produto Nestlé' : 'Adicionar produto Nestlé'
				}
				currentBreadItem={{ text: 'Produtos', href: '/produto' }}
			/>
			<Grid container spacing={3} className={classes.grid}>
				<Grid item xs={4} className={classes.gridItem}>
					<Box
						borderRadius="4px"
						bgcolor="#fff"
						maxHeight="100%"
						display="flex"
						flexDirection="column"
					>
						<Box padding={PADDING}>
							<UIText
								text="Informações do produto"
								color="#565656"
								fontWeight="500"
							/>
						</Box>
						<Divider />
						<Box padding={PADDING} overflow="auto" className={classes.scroll}>
							<UIInput
								label="SKU"
								value={values.sku || ''}
								error={touched.sku && errors.sku}
								onChange={handleChange('sku')}
								onBlur={handleBlur('sku')}
							/>
							<UIInput
								label="Nome do produto"
								value={values.description || ''}
								error={touched.description && errors.description}
								onChange={handleChange('description')}
								onBlur={handleBlur('description')}
							/>
							<UISelect
								label="Unidade"
								items={mock.unityItems}
								value={values.unity || ''}
								error={touched.unity && errors.unity}
								onChange={handleChange('unity')}
								onBlur={handleBlur('unity')}
							/>
							<UIInput
								label="Gramatura"
								type="number"
								value={values.grammage || ''}
								error={touched.grammage && errors.grammage}
								onChange={handleChange('grammage')}
								onBlur={handleBlur('grammage')}
							/>
							<UISelect
								label="Categoria"
								items={mock.businessUnityItems}
								value={values.businessUnity || ''}
								error={touched.businessUnity && errors.businessUnity}
								onChange={handleChange('businessUnity')}
								onBlur={handleBlur('businessUnity')}
							/>
							<UISelect
								label="Tipo de fórmula"
								items={mock.categoryItems}
								disabled={values.businessUnity !== 'Fórmulas infantis'}
								value={values.category || ''}
								error={
									values.businessUnity === 'Fórmulas infantis'
										? touched.category && errors.category
										: false
								}
								onChange={handleChange('category')}
								onBlur={handleBlur('category')}
							/>
							<UIInput
								label="Classificação Fiscal"
								type="number"
								value={values.fiscalClassification || ''}
								error={
									touched.fiscalClassification && errors.fiscalClassification
								}
								onChange={handleChange('fiscalClassification')}
								onBlur={handleBlur('fiscalClassification')}
							/>
							<UIInput
								label="CEST"
								type="number"
								value={values.CEST || ''}
								error={touched.CEST && errors.CEST}
								onChange={handleChange('CEST')}
								onBlur={handleBlur('CEST')}
							/>
							<UIInput
								label="EAN (Unidade)"
								type="number"
								value={values.EAN || ''}
								error={touched.EAN && errors.EAN}
								onChange={handleChange('EAN')}
								onBlur={handleBlur('EAN')}
							/>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={4} className={classes.gridItem}>
					<Box
						borderRadius="4px"
						bgcolor="#fff"
						maxHeight="100%"
						display="flex"
						flexDirection="column"
					>
						<Box padding={PADDING}>
							<UIText
								text="Política de preços"
								color="#565656"
								fontWeight="500"
							/>
						</Box>
						<Divider />
						<Box overflow="auto" className={classes.scroll}>
							{states.map((state) => (
								<Box key={state.value}>
									<Box padding={PADDING}>
										<Accordion
											expanded={expanded === state.value}
											elevation={0}
											onClick={() => setExpanded(state.value)}
										>
											<AccordionSummary
												square
												className={classes.accordionSumary}
											>
												<UIText
													text={state.text}
													color="#565656"
													fontWeight="500"
												/>
												<BsChevronDown
													color="#565656"
													size={20}
													style={{
														transform: `rotateZ(${
															expanded === state.value ? '-180deg' : '0deg'
														})`,
													}}
												/>
											</AccordionSummary>
											<AccordionDetails className={classes.accordion}>
												<Box marginTop="16px">
													<UIInput
														label="(R$)Preço lista"
														name="price"
														onChange={(e) => handleChangePrices(e, state.value)}
													/>
													<UIInput
														label="(%)Desconto pré aprovado"
														name="preDiscount"
														onChange={(e) => handleChangePrices(e, state.value)}
													/>
													<UIInput
														label="(R$)Preço dentro da política"
														disabled
														value={
															(parseToNumber(
																(
																	politicalPrices[
																		politicalPrices.findIndex(
																			(price) => price.uf === state.value,
																		)
																	] || {}
																).price,
															) *
																parseToNumber(
																	(
																		politicalPrices[
																			politicalPrices.findIndex(
																				(state) => state.uf,
																			)
																		] || {}
																	).preDiscount,
																)) /
																100 || 0
														}
													/>
												</Box>
											</AccordionDetails>
										</Accordion>
									</Box>
									<Divider />
								</Box>
							))}
						</Box>
					</Box>
				</Grid>
				<Grid item xs={4} className={classes.gridItem}>
					<Box
						borderRadius="4px"
						bgcolor="#fff"
						height="100%"
						display="flex"
						flexDirection="column"
					>
						<Box padding={PADDING}>
							<UIText
								text="Anexos do Produto"
								color="#565656"
								fontWeight="500"
							/>
						</Box>
						<Divider />
						<Box padding={PADDING} overflow="auto" className={classes.scroll}>
							<Box>
								<UISelect
									label="Unidade"
									items={mock.attachmentsItems}
									value={selectedDocumentType}
									onChange={(e) => setSelectedDocumentType(e.target.value)}
									msgError={msgErrorCombo}
									error={msgErrorCombo}
								/>

								<UploadArea
									direction="column"
									labelText={labelFile}
									onChange={(param) => {
										const file = param[0];
										setItemsConfigs({
											name: file.name,
											size: String(file.size),
											showBars: true,
											success: param.success,
											file,
										});
										setLabelFile(
											`Arquivo selecionado: ${String(file.name).substr(
												0,
												30,
											)}.../pdf`,
										);
									}}
									buttonOnClick={() => {
										if (selectedDocumentType == null) {
											return setSelectedDocumentType('');
										}

										return addAttachment();
									}}
								/>
								<UploadList
									itemsList={attachments}
									titleList="Documentos anexados"
								/>
							</Box>
						</Box>
					</Box>
				</Grid>
			</Grid>
			<Box display="flex" justifyContent="flex-end" marginTop="18px">
				<UIButton
					text={productId ? 'Atualizar produto' : 'Adicionar produto'}
					onClick={() => {
						handleSubmit();
					}}
				/>
			</Box>
			<Modal
				variant="success"
				open={showSuccessModal}
				title={
					productId
						? 'Produto atualizado com sucesso'
						: 'Produto adicionado com sucesso'
				}
				buttonText="Volta a tela inicial"
				buttonAction={() => history.push('/produto')}
				onClose={() => setShowSuccessModal(false)}
			/>
		</Box>
	);
}
