import React from 'react';

import { DialogTitle, Grid } from '@material-ui/core';

import UIButton from 'components/UI/Button/Button';

import { StyledDialog, useStyles, Title, Subtitle } from './styles';
import { IoMdCloseCircle } from 'react-icons/io';

export default function ConfirmModal({
	onClose,
	open = true,
	title,
	subtitle,
	buttonCancelAction,
	buttonAcceptAction,
}) {
	const classes = useStyles();
	return (
		<StyledDialog onClose={onClose} open={open} maxWidth="xs" fullWidth>
			<IoMdCloseCircle
				color="var(--text-grey)"
				className={classes.iconClose}
				size={20}
				onClick={onClose}
			/>
			<DialogTitle>
				<Title>{title}</Title>
				<Subtitle>{subtitle}</Subtitle>
			</DialogTitle>
			<Grid container spacing={3} justify="center">
				<Grid item xs={5}>
					<UIButton
						fullWidth
						variant="outlined"
						textcolor="var(--text-grey)"
						color="var(--text-grey)"
						text="Não"
						onClick={buttonCancelAction}
						texttransform="inherit"
					/>
				</Grid>
				<Grid item xs={5}>
					<UIButton
						fullWidth
						text="Sim"
						onClick={() => {
							buttonAcceptAction();
							onClose();
						}}
						texttransform="inherit"
					/>
				</Grid>
			</Grid>
		</StyledDialog>
	);
}
