import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { store } from 'store';

import { Box, Grid } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

import { StyledGrid, Title, StyledTextField } from './style';

import UIInput from 'components/UI/Input/Input';
import UIRadio from 'components/UI/Radio/Radio';
import UITextarea from 'components/UI/Textarea/Textarea';
import UIButton from 'components/UI/Button/Button';

import { yesNoOptions, formParticipationOptions } from './selectOptions';

import api from 'services/api';

function ProposalSummary() {
	const dispatch = useDispatch();

	const processDefault = store.getState().process.process;
	const [institutionsList, setInstitutionsList] = useState([]);
	const [processForm, setProcessForm] = useState(processDefault);
	const [comments, setComments] = useState(
		processDefault.comments || {
			0: {
				comment: '',
			},
		},
	);
	const [inputError, setInputError] = useState({
		institutionId: '',
	});
	// get institutions
	useEffect(() => {
		async function getInstitutions() {
			try {
				const response = await api.get(`institution`);

				const institutions = response.data.data.map((item) => {
					return {
						...item,
					};
				});
				setInstitutionsList(institutions);
			} catch (error) {
				return null;
			}
		}

		getInstitutions();
	}, []);

	const validateInputErrors = () => {
		const inputErrorKeys = Object.keys(inputError);
		return inputErrorKeys.forEach((inputKey) => {
			if (processForm[inputKey]) {
				setInputError((inputError) => {
					return {
						...inputError,
						[inputKey]: '',
					};
				});
			}
		});
	};

	const validateFields = () => {
		const inputErrorKeys = Object.keys(inputError);
		return inputErrorKeys.some((inputKey) => {
			if (!processForm[inputKey]) {
				setInputError((inputError) => {
					return {
						...inputError,
						[inputKey]: 'Preenchimento obrigatório',
					};
				});
				return false;
			}
			return true;
		});
	};

	const handleChangeProcess = (event) => {
		setProcessForm((process) => {
			return { ...process, [event.target.name]: event.target.value };
		});
		validateInputErrors();
	};

	const handleChangeComments = (event) => {
		const newComments = comments;
		const comment = {
			...newComments[0],
			comment: event.target.value,
		};
		setComments({
			...newComments,
			0: comment,
		});
	};

	const submitForm = () => {
		const data = {
			...processForm,
			step: 'notice_analysis',
		};

		if (validateFields()) updateProcess(data);
	};

	const updateProcess = async (process) => {
		dispatch({ type: '@process/UPDATE', payload: process });
	};

	const filterOptions = createFilterOptions({
		matchFrom: 'start',
		stringify: (option) => option.name,
	});

	return (
		<StyledGrid container>
			<Title>Informações do processo</Title>
			<Box pl={3} pr={3} pb={3} pt={1} style={{ width: '100%' }}>
				<form>
					<Grid container spacing={3}>
						<Grid item xs={12} md={4}>
							<UIInput
								label="Código do cliente"
								onChange={handleChangeProcess}
								value={processForm.distributorId ?? ''}
								name="distributorId"
								disabled
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UIInput
								label="Cliente"
								value={
									processForm.distributor && processForm.distributor.name
										? processForm.distributor.name
										: ''
								}
								disabled
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<Autocomplete
								options={institutionsList}
								getOptionLabel={(option) => option.name || ''}
								filterOptions={filterOptions}
								value={processForm.institution}
								onChange={(event, newValue) => {
									setProcessForm((process) => {
										return {
											...process,
											institutionId: (newValue || {}).id,
											institution: newValue,
										};
									});
								}}
								renderInput={(params) => (
									<StyledTextField
										{...params}
										fullWidth
										InputLabelProps={{ shrink: true }}
										label="Instituição"
										error={inputError.institutionId ? true : false}
										helperText={inputError.institutionId}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UIInput
								label="SAP do Consultor"
								value={
									processForm.employee && processForm.employee.sap
										? processForm.employee.sap
										: ''
								}
								disabled
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UIInput
								label="Nome do Consultor"
								value={
									processForm.employee && processForm.employee.name
										? processForm.employee.name
										: ''
								}
								disabled
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UIRadio
								label="Forma de participação"
								items={formParticipationOptions}
								value={processForm.processDirect ?? ''}
								disabled
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UIInput
								label="Canal de atendimento"
								value={
									processForm.institution && processForm.institution.type
										? processForm.institution.type
										: ''
								}
								disabled
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UIInput
								label="Classificação"
								value={
									processForm.institution && processForm.institution.class
										? processForm.institution.class
										: ''
								}
								disabled
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UIRadio
								label="Munícipio foco"
								items={yesNoOptions}
								value={processForm.focusUf ?? ''}
								disabled
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UIInput
								label="Classificação da secretária"
								value={processForm.secretaryClassification ?? ''}
								disabled
							/>
						</Grid>
						<Grid item xs={12}>
							<UITextarea
								label="Comentários"
								placeholder="Insira o texto..."
								value={
									comments[0] && comments[0].comment ? comments[0].comment : ''
								}
								name="comments"
								rows={3}
								onChange={handleChangeComments}
							/>
						</Grid>
					</Grid>
					<Grid container justify="flex-end">
						<Grid item xs={12} md={4} lg={2}>
							<Box pt={3}>
								<UIButton
									text="Avançar"
									fullWidth
									onClick={() => submitForm()}
								/>
							</Box>
						</Grid>
					</Grid>
				</form>
			</Box>
		</StyledGrid>
	);
}

export default ProposalSummary;
