import React, { useEffect, useState } from 'react';

import { Box, Container, Grid } from '@material-ui/core';

import NavBreadcrumbs from 'components/Navigation/Breadcrumbs/Breadcrumbs';
import UIText from 'components/UI/Text/Text';

import { breadcrumbItems } from 'screens/Admin/process/selectItems';
import UISearch from 'components/UI/Search/Search';
import UIButton from 'components/UI/Button/Button';
import TableOpenProcess from 'components/Process/TableOpenProcess';
import api from 'services/api';
import { store } from 'store';
import MainModal from 'components/Modals/MainModal';
import UIDate from 'components/UI/Date/Date';
import { useDispatch } from 'react-redux';
import { setLoading } from 'store/modules/app/actions';

import mock from './mock.json';

export default function AllProcess() {
	const dispatch = useDispatch();
	const [valueSearchInput, setSearchInput] = useState();
	const [valueSearchSelect, setSearchSelect] = useState();
	const [process, setProcess] = useState([]);
	const [allProcess, setAllProcess] = useState([]);
	const { type, id } = store.getState().user;
	const [modalReport, setModalReport] = useState(false);
	const [paramsReport, setParamsReport] = useState({
		fromDate: new Date(),
		toDate: new Date(),
	});
	const [paramsProcess, setParamsProcess] = useState({});
	const [urlReport, setUrlReport] = useState('');

	useEffect(() => {
		if (type === 'consultant') {
			setParamsProcess((params) => {
				return {
					...params,
					employeeId: id,
				};
			});
		}
		if (type === 'distributor') {
			setParamsProcess((params) => {
				return {
					...params,
					distributorId: id,
				};
			});
		}
		getProcess();
	}, [valueSearchSelect]);

	const [search, setSearch] = useState({
		input: '',
		select: '',
		current: '',
	});

	const getProcess = async () => {
		try {
			const query = {
				params: paramsProcess,
				// params: {
				// 	status: valueSearchSelect,
				// 	search: true,
				// 	$or: [
				// 		{ 'tradingCode[$like]': '%Ba%' },
				// 		{ 'regional[$like]': '%%' },
				// 		{ 'segment[$like]': '%%' },
				// 		{ 'city[$like]': '%%' },
				// 		{ 'competitionModality[$like]': '%%' },
				// 	],
				// },
			};
			const { status, data } = await api.get('/process', query);
			if (status === 200) {
				setProcess(data.data);
				setAllProcess(data.data);
			}
		} catch (e) {}
	};

	const exportReport = async () => {
		try {
			dispatch(setLoading(true));
			const response = await api.get(`report/basic`, {
				params: {
					...paramsProcess,
					'createdAt[$gte]': paramsReport.fromDate,
					'createdAt[$lte]': paramsReport.toDate,
				},
			});

			const link = document.createElement('a');
			link.href = response.data.url;
			link.target = '_blank';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (error) {
			throw error;
		} finally {
			dispatch(setLoading(false));
		}
	};

	const handleModalReport = () => {
		setModalReport(!modalReport);
	};
	const handleChangeSearch = (evt) => {
		setSearch((search) => {
			return {
				...search,
				[evt.name]: evt.value,
				current: evt.value,
			};
		});
	};

	useEffect(() => {
		const objToArray = (object) =>
			Object.keys(object || {}).map((key) => object[key]);
		const recursive = (data) => {
			const response = [];
			const array = objToArray(data);
			const haveMore = array.filter(
				(item) => Object.keys(item || {}).length > 0 && typeof item != 'string',
			);
			if (haveMore.length > 0) {
				const arrayCopy = array;
				haveMore.map((item) => {
					arrayCopy.splice(array.indexOf(item), 1);
					recursive(item)
						.filter((item) => item != null && item != undefined)
						.map((result) => response.push(result));
				});
				arrayCopy
					.filter((item) => item != null && item != undefined)
					.map((result) => response.push(result));
			} else
				array
					.filter((item) => item != null && item != undefined)
					.map((item) => response.push(item));
			return response;
		};

		if (search.current) {
			const current = String(search.current).toLocaleLowerCase();
			const newProcess = [...allProcess];
			const found = [];
			newProcess.map((process) => {
				// all process
				const array = recursive(objToArray(process));
				const values = array.findIndex(
					(item) => String(item).toLowerCase().indexOf(current) > -1,
				);
				if (values > -1) found.push(process);
			});
			if (found) setProcess(found);
		} else {
			setProcess(allProcess || []);
		}
	}, [search.current]);

	return (
		<>
			<Grid container justify="space-between" alignItems="center">
				<NavBreadcrumbs items={breadcrumbItems} />
				<UIText
					text="Adicionado em: 24/04/19 - 19:12"
					fontSize="12px"
					fontWeight="500"
					textcolor="var(--text-grey)"
				/>
			</Grid>
			<Grid container justify="space-between" alignItems="center">
				<UIText
					text="Processos"
					textcolor="var(--text-dark)"
					fontWeight="500"
					fontSize="24px"
				/>
				<UIButton
					text="Exportar relatório"
					onClick={() => handleModalReport()}
				/>
			</Grid>
			<Box pt={3} pb={3}>
				<Grid container>
					<Grid item xs={12}>
						<UISearch
							placeholder="Pesquisar licitações"
							selectOptions={mock.options}
							onChangeSelect={(evt) =>
								handleChangeSearch({ name: 'select', value: evt.target.value })
							}
							onChangeInput={(evt) =>
								handleChangeSearch({ name: 'input', value: evt.target.value })
							}
							valueInput={search.input}
							valueSelect={search.select || ''}
						/>
					</Grid>
				</Grid>
			</Box>
			<TableOpenProcess items={process} />
			<MainModal
				title="Definir período do relatório"
				width="small"
				confirmButton="Gerar relatório"
				confirmVariant="contained"
				onConfirm={() => exportReport()}
				cancelButton="Voltar"
				onCancel={() => handleModalReport()}
				onClose={() => handleModalReport()}
				show={modalReport}
			>
				<Box py={3}>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<UIDate
								label="Período inicial"
								selectedDate={paramsReport.fromDate}
								onChange={(value) =>
									setParamsReport((params) => {
										return {
											...params,
											fromDate: value,
										};
									})
								}
							/>
						</Grid>
						<Grid item xs={6}>
							<UIDate
								label="Período final"
								selectedDate={paramsReport.toDate}
								onChange={(value) =>
									setParamsReport((params) => {
										return {
											...params,
											toDate: value,
										};
									})
								}
							/>
						</Grid>
					</Grid>
				</Box>
			</MainModal>
		</>
	);
}
