import React from 'react';
import { Backdrop, CircularProgress, Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import Navbar from 'components/Navigation/Navbar/Navbar';
import { useSelector } from 'react-redux';
export default function LayoutDefault({ children, noContainer }) {
	const loading = useSelector((state) => state.app.loading);

	return (
		<>
			{noContainer ? (
				<>
					<Navbar />
					{children}
				</>
			) : (
				<>
					<Navbar />
					<Container maxWidth="lg" style={{minHeight: 'calc(100vh - 60px)'}}>{children}</Container>
				</>
			)}
			<Backdrop open={loading} style={{ zIndex: '9999' }}>
				<CircularProgress color="secondary" />
			</Backdrop>
		</>
	);
}

LayoutDefault.propTypes = {
	children: PropTypes.node.isRequired,
};
