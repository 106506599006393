import styled from 'styled-components';

const colors = {
	grey: '#C4C4C4',
	blue: '#5082C9',
	red: '#C95050',
	orange: '#EF851B',
	dark: '#565656',
};

export const StyledSpan = styled.span`
	color: ${(props) =>
		props.color && props.color ? colors[props.color] : colors.dark};
	font-size: ${(props) => props.fontSize ?? '14px'};
`;

export const StyledP = styled.p`
	font-size: ${(props) => props.fontSize ?? '14px'};
	margin: ${(props) => props.margin ?? '0'};
	color: ${(props) =>
		props.color && props.color ? colors[props.color] : colors.dark};
	text-overflow: ${(props) => props.textOverflow ?? 'ellipsis'};
	overflow: ${(props) => props.overflow ?? 'hidden'};
	white-space: ${(props) => props.whiteSpace ?? 'nowrap'};
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '')};
`;

export const Specification = styled.div`
	background-color: #F8F4F4;
	margin-top: 1em;
	padding-left: 2em;
	height : 130px;
	padding-top: 1em;
	padding-right: 2em;

`
