import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { Box, Container, Typography } from '@material-ui/core';

import NavBreadcrumbs from 'components/Navigation/Breadcrumbs/Breadcrumbs';
import NavTabs from 'components/Navigation/Tabs/Tabs';
/*indirect route components*/
import ProposalSummaryIndirect from './indirectDistributor/ProposalSummary';
import NoticeAnalysisIndirect from './indirectDistributor/NoticeAnalysis';
import ScopeRegistrationIndirect from './indirectDistributor/ScopeRegistration';
import AttachmentsIndirect from './indirectDistributor/Attachments';
/*direct route components*/
import ProposalSummaryDirect from './direct/ProposalSummary';
import NoticeAnalysisDirect from './direct/NoticeAnalysis';
import ScopeRegistrationDirect from './direct/ScopeRegistration';
import AttachmentsDirect from './direct/Attachments';

import { useDispatch, useSelector } from 'react-redux';
import { store } from 'store';

export default function ConsultantCreate() {
	const dispatch = useDispatch();

	const { idProcess } = useParams();
	const { processDirect } = store.getState().process.process;

	const [processDirection, setProcessDirection] = useState(false);

	const processDefault = useSelector((state) => state.process.process);

	const [activeTab, setActiveTab] = useState(0);

	useEffect(() => {
		if (idProcess) {
			dispatch({
				type: '@process/GET_PROCESS',
				payload: {
					id: idProcess,
				},
			});
			setProcessDirection(true);
		}
	}, [dispatch, idProcess, processDirect]);

	useEffect(() => {
		if (processDefault.step === 'proposal_summary') {
			setActiveTab(0);
		}
		if (processDefault.step === 'notice_analysis') {
			setActiveTab(1);
		}
		if (processDefault.step === 'scope_registration') {
			setActiveTab(2);
		}
		if (processDefault.step === 'attachments') {
			setActiveTab(3);
		}
	}, [setActiveTab, processDefault]);

	const stepsProcess = [
		{
			label: 'Resumo da proposta',
			color: 'var(--primary)',
			component: () => processDirection ? ProposalSummaryIndirect() : ProposalSummaryDirect(),
		},
		{
			label: 'Análise do edital',
			color: 'var(--pink)',
			component: () => processDirection ? NoticeAnalysisIndirect() : NoticeAnalysisDirect(),
		},
		{
			label: 'Cadastro do escopo',
			color: 'var(--orange)',
			component: () => processDirection ? ScopeRegistrationIndirect() : ScopeRegistrationDirect(),
		},
		{
			label: 'Anexos',
			color: 'var(--success)',
			component: () => processDirection ? AttachmentsIndirect() : AttachmentsDirect(),
		},
	];

	const breadcrumbsItems = [{ text: 'Dashboard', href: '/' }, { text: 'OCR' }];

	return (
		<Box pb={5}>
			<Container maxWidth="lg">
				<NavBreadcrumbs items={breadcrumbsItems} />
				<Typography variant="h1">Adicionar Processo</Typography>
				<NavTabs tabs={stepsProcess} activeTab={activeTab} />
			</Container>
		</Box>
	);
}
