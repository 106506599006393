import { Box, Typography, withStyles } from "@material-ui/core";
import styled from 'styled-components';
import PropTypes from 'prop-types';

import UIText from "components/UI/Text/Text";

export const Title = withStyles({
  root: {
    color: '#565656',
    fontWeight: '700',
    fontSize: '24px'
  }
})(Typography)

export const Text = withStyles({
  root: {
    color: '#565656',
    fontSize: '14px'
  }
})(Typography)

export function Card({title, image, onClick}) {
  return (
    <Box
      padding="16px"
      width="170px"
      boxSizing="border-box"
      border="2px solid #5082C9"
      backgroundColor="#fff"
      borderRadius="4px"
      textAlign="center"
      style={{cursor: 'pointer'}}
      onClick={onClick}
    >
      <Image src={image} />
      <UIText
        text={title}
        textcolor="#5082C9"
        fontWeight="500"
      />
    </Box>
  )
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
}

const Image = styled.img`
  height: 92px;
  margin-bottom: 8px;
  margin-top: 16px;
`;