import produce from 'immer';

const INITIAL_STATE = {
	id: null,
	name: null,
	lastName: null,
	sap: null,
	type: null,
	email: null,
	supervisor: null,
	cnpj: null,
	sold: null,
	telephone: null,
	pendencies: [{}],
	totalPendencies: null,
	profilePicture: null,
};

export default function theme(state = INITIAL_STATE, action) {
	return produce(state, (draft) => {
		switch (action.type) {
			case '@user/LOGIN_DISTRIBUTOR': {
				const name = action.payload.distributor.socialName;
				const type = 'distributor';
				const { cnpj, email, sold, telephone, id } = action.payload.distributor;
				draft.id = id;
				draft.name = name;
				draft.type = type;
				draft.email = email;
				draft.cnpj = cnpj;
				draft.sold = sold;
				draft.telephone = telephone;
				draft.profilePicture = action.payload.profilePicture
				break;
			}
			case '@user/LOGIN_EMPLOYEE': {
				const {
					id,
					name,
					lastName,
					accessLevel,
					sap,
					supervisor,
				} = action.payload.employee;
				const { email } = action.payload;
				draft.id = id;
				draft.accessId = action.payload.id;
				draft.name = name;
				draft.lastName = lastName;
				draft.type = accessLevel;
				draft.sap = sap;
				draft.supervisor = supervisor;
				draft.email = email;
				draft.profilePicture = action.payload.profilePicture
				break;
			}
			case '@user/LOGIN_ADMIN': {
				const {
					name,
					email,
					lastName,
					sap,
					accessLevel,
				} = action.payload.admin;
				draft.name = name;
				draft.email = email;
				draft.lastName = lastName;
				draft.type = accessLevel;
				draft.sap = sap;
				break;
			}
			case '@user/LOGOUT': {
				draft.name = null;
				draft.profile = null;
				draft.profilePicture = null;
				break;
			}
			case '@user/SET_PENDENCIES': {
				draft.pendencies = action.payload.data;
				draft.totalPendencies = action.payload.total;
				break;
			}
			case '@user/PUT_PENDENCIES': {
				//checa se a pendencia eh desse usuario
				if (
					state.type === 'distributor' &&
					action.payload.distributorId !== state.id
				)
					break;
				else if (action.payload.employeeId !== state.id) break;

				if (state.pendencies === undefined) {
					let pendencies = [action.payload];
					draft.pendencies = pendencies;
					break;
				}
				let pendenciesArray = Object.values(state.pendencies); // get values not reference this var
				pendenciesArray.push(action.payload);
				draft.pendencies = undefined;
				draft.pendencies = pendenciesArray;

				break;
			}
			case '@user/UPDATE_USER':
				const values = action.payload.data;
				Object.keys(values).map(key => {
					if (values[key]) draft[key] = values[key];
				})
				break;
			case '@user/UPDATE_PICTURE': {
				draft.profilePicture = action.payload?.key;
				break;
			}
			default:
				return state;
		}
	});
}
