import React from 'react';

import { Bullet } from 'components/UI/Avatar/styles';

export default function Avatar({ background, textcolor, width, height, fontSize, children }) {

	return (
		<Bullet
			background={background}
			textcolor={textcolor}
			width={width}
			height={height}
			fontSize={fontSize}
		>
			{children}
		</Bullet>
	);
}
