import { Chip, withStyles } from '@material-ui/core';

export const TagChip = withStyles({
	root: {
		color: (props) => props.color || 'primary',
		background: (props) => props.background || 'rgba(80, 130, 201, 0.16)',
		fontSize: (props) => props.fontSize || '10px',
		height: (props) => props.height,
		width: (props) => props.width,
		fontWeight: (props) => props.fontWeight,
		padding: '.3rem',
	},
})(Chip);
