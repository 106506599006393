import React from 'react';

import PropTypes from 'prop-types';

import { PrevItem, ActiveItem, NextItem } from './styles';

import './styles.css';

export default function BulletItem({
	number,
	state,
}) {
	return (
		<div className="BulletItem">
			<div className="item-number">
				{
					// eslint-disable-next-line no-nested-ternary
					state === 'active'
						? (
							<ActiveItem>
								{number}
							</ActiveItem>
						)
						: state === 'visited'
							? (
								<PrevItem>
									{number}
								</PrevItem>
							)
							: (
								<NextItem>
									{number}
								</NextItem>
							)
				}
			</div>
		</div>
	);
}

BulletItem.propTypes = {
	number: PropTypes.number.isRequired,
	state: PropTypes.string.isRequired,
};
