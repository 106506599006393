import { Dialog, makeStyles, Typography, withStyles } from '@material-ui/core';

export const StyledDialog = withStyles({
	paper: {
		padding: '25px 50px 50px',
	},
})(Dialog);

export const Title = withStyles({
	root: {
		fontSize: '24px',
		fontWeight: 'bold',
		color: 'var(--primary)',
		textAlign: 'center',
	},
})(Typography);

export const Subtitle = withStyles({
	root: {
		fontSize: '16px',
		textAlign: 'center',
	},
})(Typography);

export const useStyles = makeStyles({
	iconClose: {
		position: 'absolute',
		left: '90%',
		bottom: '80%',
		cursor: 'pointer',
	},
});
