import { Tab, Tabs, withStyles } from '@material-ui/core';

export const StyledTabs = withStyles({
	root: {
		minHeight: '30px',
		height: '30px',
		marginTop: '20px',
		borderBottom: '1px solid var(--text-grey)',
	},
	indicator: {
		background: 'none',
	},
})(Tabs);

export const StyledTab = withStyles({
	root: {
		minHeight: '30px',
		height: '30px',
		minWidth: 'auto',
		marginRight: (props) => props.marginRight || '20px',
		marginLeft: (props) => props.marginLeft,
		padding: '2px',
		textTransform: 'initial',
		color: 'var(--text-grey)',
	},
	selected: {
		color: 'var(--text-dark)!important',
		borderBottom: (props) => `4px solid ${props.color}`,
	},
})(Tab);
