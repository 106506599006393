import React from 'react';
import PropTypes from 'prop-types';

import { StyledTypograph } from './styles';

export default function UIText({
	text,
	textcolor,
	fontSize,
	fontWeight,
	textalign,
	textOverflow,
	overflow,
	whiteSpace,
	onClick = () => { },
	cursor = "default",
}) {
	return (
		<StyledTypograph
			textcolor={textcolor}
			fontSize={fontSize}
			fontWeight={fontWeight}
			textalign={textalign}
			textOverflow={textOverflow}
			overflow={overflow}
			whiteSpace={whiteSpace}
			onClick={onClick}
			cursor={cursor}
		>
			{text}
		</StyledTypograph>
	);
}

UIText.propTypes = {
	text: PropTypes.string.isRequired,
	textcolor: PropTypes.string,
	fontSize: PropTypes.string,
	fontWeight: PropTypes.string,
	textalign: PropTypes.string,
	onClick: PropTypes.func,
};
