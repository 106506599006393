import React from 'react';

import DistributorCreate from './Distributor/index';
import ConsultantCreate from './Consultant';

import { store } from 'store';

export default function CreateProcess() {
	const { type } = store.getState().user;

	if (type) {
		if (['consultant', 'supervisor'].includes(type)) {
			return ConsultantCreate();
		} else if (['distributor'].includes(type)) {
			return DistributorCreate(); // indirect
		}
	}
}
