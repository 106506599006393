import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { DialogTitle, DialogContent, Box } from '@material-ui/core';

import UIButton from 'components/UI/Button/Button';
import IndirectProcess from '../DirectProcess/index';

import imgDirectRoute from 'assets/images/directRoute.svg';
import imgIndirectRoute from 'assets/images/indirectRoute.svg';
import { IoMdCloseCircle } from 'react-icons/io';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setModalNewProcess } from 'store/modules/process/actions';
import {
	Title, Subtitle, StyledDialog, useStyles,
} from './style';
import { store } from 'store';

export default function ModalNewProcess({ onClose }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const { type } = store.getState().user;

	const open = useSelector((state) => state.process.modalNewProcess);

	const classes = useStyles();

	const [openIndirect, setOpenIndirect] = useState(false);

	const directRoute = (
		<Box display="flex" flexDirection="column" ml={-1} mr={-1} pb={2} pt={2}>
			<Box mb={1}>
				<img src={imgDirectRoute} alt="represents direct route" />
			</Box>
      Rota direta
		</Box>
	);

	const indirectRoute = (
		<Box display="flex" flexDirection="column" ml={-1} mr={-1} pb={2} pt={2}>
			<Box mb={1}>
				<img src={imgIndirectRoute} alt="represents indirect route" />
			</Box>
      Rota indireta
		</Box>
	);

	const newDirectProcess = () => {
		if (['distributor', 'consultant', 'supervisor'].includes(type)) {
			dispatch({
				type: '@process/SET_PROCESS', payload: {
					...process.data,
					processDirect: true,
				}
			});
			history.push("/processo/novo");
			close();
		}
	}

	const newIndirectProcess = () => {
		dispatch({
			type: '@process/SET_PROCESS', payload: {
				...process.data,
				processDirect: false,
			}
		});
		if (['distributor'].includes(type)) {
			history.push("/processo/novo/");
			close();
		}
		if (['consultant', 'supervisor'].includes(type)) {
			close();
			setOpenIndirect(true);
		}
	};

	const close = () => dispatch(setModalNewProcess(false));

	return (
		<>
			<StyledDialog onClose={onClose} open={open} maxWidth="xs" fullWidth>
				<IoMdCloseCircle
					color="var(--text-grey)"
					className={classes.iconClose}
					size={20}
					onClick={onClose}
				/>
				<DialogTitle>
					<Title>Rota direta ou indireta</Title>
					<Subtitle>
						Escolha rota direta ou indireta para criar um processo
        </Subtitle>
				</DialogTitle>
				<DialogContent>
					<Box display="flex" justifyContent="space-around">
						<UIButton variant="outlined" text={directRoute} onClick={newDirectProcess} />
						<UIButton variant="outlined" text={indirectRoute} onClick={newIndirectProcess} />
					</Box>
				</DialogContent>
			</StyledDialog>
			<IndirectProcess
				opened={openIndirect}
				close={() => setOpenIndirect(false)}
			/>
		</>
	);
}

ModalNewProcess.propTypes = {
	onClose: PropTypes.func.isRequired,
};
