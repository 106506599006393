import {
	Input,
	InputLabel,
	Card,
	Divider,
	makeStyles,
	withStyles,
	Typography,
} from '@material-ui/core';

import styled from 'styled-components';

export const StyledCard = withStyles({
	root: {
		boxShadow: 'inherit',
		borderRadius: '4px',
		padding: '15px',
		height: 'calc(100% - 30px)',
	},
})(Card);

export const SectionBody = styled.div`
	width: 100%;
	max-height: 400px;
	padding: 5px 10px;
	overflow: auto;
	overflow-x: hidden;

	::-webkit-scrollbar {
		width: 5px;
	}
	::-webkit-scrollbar-track {
		border-radius: 3px;
	}
	::-webkit-scrollbar-thumb {
		background: #c4c4c4;
		border-radius: 3px;
	}
`;

export const StyledDivider = withStyles({
	root: {
		margin: '15px -15px',
	},
})(Divider);

export const StyledInput = withStyles({
	root: {
		border: '1px dashed var(--text-grey)',
		borderRadius: '4px',
		padding: '0px 5px',
		fontSize: '12px',
		color: 'var(--text-dark)',
	},
})(Input);

export const StyledInputLabel = withStyles({
	root: {
		fontWeight: '400',
		fontSize: '14px',
		marginBottom: '7px',
		marginTop: '10px',
	},
})(InputLabel);

export const Confirm = withStyles({
	root: {
		fontSize: '12px',
		color: 'var(--primary)',
		marginLeft: 'auto',
		marginTop: '5px',
		marginBottom: '-5px',
		width: 'min-content',
	},
})(Typography);
