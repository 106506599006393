import React, { useEffect, useState } from 'react';

import {
	Box,
	Fade,
	FormControl,
	Grid,
	Paper,
	Popper,
	TextField,
	Typography,
} from '@material-ui/core';

import { MdContentCopy } from 'react-icons/md';

import Autocomplete from '@material-ui/lab/Autocomplete';

import lang from 'lang/lang.json';

import { StyledCard, SectionBody, StyledDivider } from './style';
import UISelect from 'components/UI/Select/Select';
import { useHistory } from 'react-router';
import Modal from 'components/Modals';

function CardOCRFields({ fields = [], ocr, products }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(false);
	const [placement, setPlacement] = useState();
	const [selectedProduct, setSelectedProduct] = useState(0);
	const [openModal, setOpenModal] = useState(false);
	const history = useHistory();

	useEffect(() => {
		console.log(ocr);
		if (ocr && ocr.status === false) {
			setOpenModal(true);
		} else {
			setOpenModal(false);
		}
	}, [ocr]);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
		setPlacement('bottom');
		setTimeout(() => {
			setOpen(false);
		}, 1000);
	};

	return (
		<StyledCard>
			<Modal
				open={openModal}
				onClose={() => setOpenModal(false)}
				variant="error"
				title="OCR em andamento ou falha no carregamento do arquivo"
				subtitle="OCR ainda em andamento ou houve uma falha na leitura do arquivo. Se é uma licitacão recente espere pelo menos 5 minutos"
				buttonCancelText="Voltar"
				buttonConfirmAction={() => setOpenModal(false)}
				buttonCancelAction={() => history.push('/')}
				buttonConfirmText="Continuar"
			/>
			<Popper open={open} anchorEl={anchorEl} placement={placement} transition>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps} timeout={350}>
						<Paper>
							<Typography>Copiado com sucesso</Typography>
						</Paper>
					</Fade>
				)}
			</Popper>
			<Grid container justify="space-between">
				<Grid item xs={12}>
					<Typography variant="h4">Informações selecionadas (OCR)</Typography>
					<StyledDivider />
				</Grid>
				<SectionBody>
					{ocr &&
						Object.keys(ocr).map((type) => (
							<>
								<Grid
									container
									display="flex"
									direction="row"
									justifyContent="center"
									xs={12}
								>
									{/* <StyledInputLabel> {lang[type]} </StyledInputLabel> */}
									<Grid item xs={10}>
										<FormControl fullWidth>
											<Autocomplete
												id={type}
												options={ocr[type]}
												getOptionLabel={(option) => option.Text}
												renderInput={(params) => (
													<TextField
														{...params}
														label={lang[type]}
														variant="outlined"
													/>
												)}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={2}>
										<Box m={2}>
											<MdContentCopy
												onClick={(e) => {
													handleClick(e);
													let { value } = document.querySelector(`#${type}`);
													navigator.clipboard.writeText(value);
												}}
												size="20"
											/>
										</Box>
									</Grid>
									<Box m={2} />
									<StyledDivider />
								</Grid>
							</>
						))}

					{products && products[0] !== undefined ? (
						<>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<UISelect
										onChange={(e) => setSelectedProduct(e.target.value)}
										items={products.map((product) => {
											return {
												text: product.item,
												value: product.item,
											};
										})}
										label="Selecione um item"
									/>
								</Grid>
								<Grid item xs={12}>
									<Box display="flex" style={{ width: '100%' }}>
										<TextField
											id="ocrQuantity"
											label="Quantidade"
											value={products[selectedProduct - 1]?.quantity ?? ''}
											InputLabelProps={{
												shrink: true,
											}}
											variant="outlined"
											fullWidth
										/>
										<Box m={2}>
											<MdContentCopy
												onClick={(e) => {
													handleClick(e);
													let { value } = document.querySelector(
														`#ocrQuantity`,
													);
													console.log(value);
													navigator.clipboard.writeText(value);
												}}
												size="20"
											/>
										</Box>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Box display="flex" style={{ width: '100%' }}>
										<TextField
											id="ocrDescription"
											label="Descricao"
											value={products[selectedProduct - 1]?.description ?? ''}
											InputLabelProps={{
												shrink: true,
											}}
											variant="outlined"
											fullWidth
										/>
										<Box m={2}>
											<MdContentCopy
												onClick={(e) => {
													handleClick(e);
													let { value } = document.querySelector(
														`#ocrDescription`,
													);
													console.log(value);
													navigator.clipboard.writeText(value);
												}}
												size="20"
											/>
										</Box>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Box display="flex" style={{ width: '100%' }}>
										<TextField
											id="ocrUnityPrice"
											label="Preco unitario"
											value={products[selectedProduct - 1]?.unityPrice ?? ''}
											InputLabelProps={{
												shrink: true,
											}}
											variant="outlined"
											fullWidth
										/>
										<Box m={2}>
											<MdContentCopy
												onClick={(e) => {
													handleClick(e);
													let { value } = document.querySelector(
														`#ocrUnityPrice`,
													);
													console.log(value);
													navigator.clipboard.writeText(value);
												}}
												size="20"
											/>
										</Box>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Box display="flex" style={{ width: '100%' }}>
										<TextField
											id="ocrTotalPrice"
											label="Preco total"
											value={products[selectedProduct - 1]?.totalPrice ?? ''}
											InputLabelProps={{
												shrink: true,
											}}
											variant="outlined"
											fullWidth
										/>
										<Box m={2}>
											<MdContentCopy
												onClick={(e) => {
													handleClick(e);
													let { value } = document.querySelector(
														`#ocrTotalPrice`,
													);
													console.log(value);
													navigator.clipboard.writeText(value);
												}}
												size="20"
											/>
										</Box>
									</Box>
								</Grid>
							</Grid>
						</>
					) : null}
				</SectionBody>
			</Grid>
		</StyledCard>
	);
}

export default CardOCRFields;
