import React, { useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { HiOutlineZoomIn } from 'react-icons/hi';
import Scrollbar from 'react-scrollbar';

import { Document, Page, pdfjs } from 'react-pdf';

import { StyledCard, StyledPaginationItem, useStyles } from './style';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function CardPreviewFile({
	urlFile,
	onZoomClick,
	setCurrentPage,
	currentPage,
}) {
	const classes = useStyles();

	const [numPages, setNumPages] = useState(null);
	const [fullWidth, setFullWidth] = useState();

	useEffect(() => {}, [setFullWidth]);

	function onDocumentLoadSuccess({ numPages }) {
		let width = window.innerWidth / 3.5;
		if (window.innerWidth < 600) width = window.innerWidth - 50;
		if (window.innerWidth < 1000 && window.innerWidth > 600)
			width = window.innerWidth / 2 - 50;
		if (window.innerWidth >= 1500) width = window.innerWidth / 5.3;
		if (window.innerWidth >= 2000) width = window.innerWidth / 5.5 - 50;
		setFullWidth(width);
		setNumPages(numPages);
	}

	return (
		<StyledCard>
			<Grid container justify="space-between">
				<Grid item>
					<Typography variant="h4">Pré-visualização</Typography>
				</Grid>
				<Grid item>
					<HiOutlineZoomIn color="var(--primary)" onClick={onZoomClick} />
				</Grid>
			</Grid>
			<Scrollbar className={classes.scrollbar} autoHide={false}>
				<Box border={1} borderColor="var(--text-grey2)" borderRadius={4} my={2}>
					<Document
						file={{ url: urlFile }}
						onLoadSuccess={onDocumentLoadSuccess}
					>
						<Page pageNumber={currentPage} width={fullWidth} />
					</Document>
				</Box>
			</Scrollbar>
			<Grid container justify="flex-end">
				<Grid item>
					<Pagination
						count={numPages}
						page={currentPage}
						shape="rounded"
						onChange={(e, value) => {
							setCurrentPage(value);
						}}
						renderItem={(item) => <StyledPaginationItem {...item} />}
					/>
				</Grid>
			</Grid>
		</StyledCard>
	);
}

export default CardPreviewFile;
