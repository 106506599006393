import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import UIDate from 'components/UI/Date/Date';
import UISelect from 'components/UI/Select/Select';
import UIRadio from 'components/UI/Radio/Radio';
import UIInput from 'components/UI/Input/Input';
import UIAutocomplete from 'components/UI/Autocomplete/index';
import { StyledTextField } from 'screens/Process/components/DistributorCreating/Fields/DocumentSolicitation/styles';
import { StyledCard, StyledDivider, SectionBody } from './style';
import {
	optionsCompetitionModality,
	optionsMonth,
	optionsYesNo,
	quotaOptions,
	regionalOptions,
} from './selectOptions';

import api from 'services/api';
import { useDispatch } from 'react-redux';

function CardNoticeFields({ process, submit }) {
	const dispatch = useDispatch();

	const [processForm, setProcessForm] = useState(process);
	const [allCities, setAllCities] = useState([]);
	const [cities, setCities] = useState([]);
	const [ufs, setUfs] = useState([]);

	useEffect(() => {
		function updateProcess(process) {
			dispatch({ type: '@process/UPDATE', payload: process });
		}

		function submitForm() {
			const data = {
				...processForm,
				step: 'scope_registration',
			};
			updateProcess(data);
		}

		if (submit) submitForm();
	}, [submit, processForm, dispatch]);

	// get locations
	useEffect(() => {
		async function getCities() {
			try {
				const response = await api.get(`cities`, {
					params: {
						$limit: -1,
					},
				});
				setAllCities(
					(response.data || []).map((city) => {
						return {
							name: city.city,
							uf: city.uf,
						};
					}),
				);
			} catch (error) {
				return null;
			}
		}

		async function getUfs() {
			try {
				const response = await api.get(`cities`, {
					params: {
						$limit: -1,
						$distinct: 'uf',
					},
				});
				setUfs(
					(response.data || [])
						.map((uf) => String(uf.uf).toUpperCase())
						.sort()
						.map((item) => {
							return {
								title: item,
								value: item,
							};
						}),
				);
			} catch (error) {
				return null;
			}
		}
		getCities();
		getUfs();
	}, []);
	// filter cities
	useEffect(() => {
		if (processForm.uf) {
			const parsedCities = allCities.filter(
				(city) => city.uf === processForm.uf,
			);
			setCities(
				(parsedCities || [])
					.map((city) => String(city.name).toUpperCase())
					.sort()
					.map((item) => {
						return {
							title: item,
							value: item,
						};
					}),
			);
		} else if (processForm.uf === undefined) {
			setCities([]);
			setProcessForm((processForm) => {
				return {
					...processForm,
					city: '',
				};
			});
		}
	}, [allCities, processForm.uf, ufs]);

	const handleChangeProcess = (event) => {
		if (['newProgram', 'isImprovement'].includes(event.target.name)) {
			return setProcessForm((process) => {
				return {
					...process,
					[event.target.name]: event.target.value === 'sim' ? true : false,
				};
			});
		}
		return setProcessForm((process) => {
			return {
				...process,
				[event.target.name]: event.target.value,
			};
		});
	};

	return (
		<StyledCard>
			<Grid container justify="space-between">
				<Grid item xs={12}>
					<Typography variant="h4">Validação oficial do processo</Typography>
					<StyledDivider />
				</Grid>
				<SectionBody>
					<Box pb={2} minWidth="max-content" maxWidth="100%">
						<UIDate
							shrink={true}
							fullWidth={true}
							label="Abertura do processo"
							selectedDate={processForm.tradingDate || new Date()}
							onChange={(date) =>
								handleChangeProcess({
									target: {
										name: 'tradingDate',
										value: new Date(date).toISOString(),
									},
								})
							}
						/>
					</Box>
					<Box pb={2}>
						<UISelect
							label="Cota"
							items={quotaOptions}
							value={processForm.quota || ''}
							name="quota"
							onChange={handleChangeProcess}
						/>
					</Box>
					<Box pb={2}>
						<UISelect
							label="Regional"
							name="regional"
							items={regionalOptions}
							value={processForm.regional || ''}
							onChange={handleChangeProcess}
						/>
					</Box>
					<Box pb={2}>
						<UIAutocomplete
							label="Estado"
							value={
								processForm.uf
									? { title: processForm.uf, value: processForm.uf }
									: ''
							}
							onChange={(evt, newValue) => {
								setProcessForm((processForm) => {
									return {
										...processForm,
										uf: (newValue || {}).title,
									};
								});
							}}
							getOptionLabel={(option) => option.title}
							options={ufs}
							renderInput={(params) => (
								<StyledTextField
									{...params}
									fullWidth
									InputLabelProps={{ shrink: true }}
									label="Estado"
								/>
							)}
							filter
						/>
					</Box>
					<Box pb={2}>
						<UIAutocomplete
							label="Cidade"
							value={
								processForm.city
									? { title: processForm.city, value: processForm.city }
									: ''
							}
							onChange={(evt, newValue) => {
								setProcessForm((processForm) => {
									return {
										...processForm,
										city: (newValue || {}).title,
									};
								});
							}}
							filter
							options={cities}
							renderInput={(params) => (
								<StyledTextField
									{...params}
									fullWidth
									InputLabelProps={{ shrink: true }}
									label="Cidade"
								/>
							)}
						/>
					</Box>
					<Box pb={2} minWidth="max-content" maxWidth="100%">
						<UIRadio
							label="É um novo programa governamental?"
							items={optionsYesNo}
							name="newProgram"
							value={processForm.newProgram ? 'sim' : 'não'}
							onChange={handleChangeProcess}
							fontSize="17px"
						/>
					</Box>
					<Box pb={2}>
						<UIRadio
							label="Melhoria no programa?"
							items={optionsYesNo}
							name="isImprovement"
							value={processForm.isImprovement ? 'sim' : 'não'}
							onChange={handleChangeProcess}
							fontSize="17px"
						/>
					</Box>
					<Box pb={2}>
						<UIInput
							name="programName"
							label="Nome do programa"
							value={processForm.programName || ''}
							onChange={handleChangeProcess}
						/>
					</Box>
					<Box pb={2}>
						{processForm.newProgram ? (
							<UIInput
								label="Modalidade da concorrência"
								name="competitionModality"
								value={processForm.competitionModality || ''}
								onChange={handleChangeProcess}
							/>
						) : (
							<UISelect
								label="Modalidade da concorrência"
								name="competitionModality"
								items={optionsCompetitionModality}
								value={processForm.competitionModality || ''}
								onChange={handleChangeProcess}
							/>
						)}
					</Box>
					<Box pb={2}>
						<UIInput
							label="Número do edital"
							name="tradingCode"
							value={processForm.tradingCode || ''}
							onChange={handleChangeProcess}
						/>
					</Box>
					<Box pb={2}>
						{processForm.newProgram ? (
							<UIInput
								label="Período de fornecimento (meses)"
								name="period"
								value={processForm.period || ''}
								onChange={handleChangeProcess}
							/>
						) : (
							<UISelect
								label="Período de fornecimento (meses)"
								name="period"
								items={optionsMonth}
								value={processForm.period || ''}
								onChange={handleChangeProcess}
							/>
						)}
					</Box>
				</SectionBody>
			</Grid>
		</StyledCard>
	);
}

export default CardNoticeFields;
