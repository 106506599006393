import React, { useEffect, useState } from 'react';

import { Grid, Box, IconButton, Paper } from '@material-ui/core';
import { useHistory } from 'react-router';

import UIText from 'components/UI/Text/Text';
import UIAvatar from 'components/UI/Avatar/index';

import {
	MenuHeader,
	TopIcon,
	IconBackground,
	StyledMenu,
	StyledMenuItem,
	VerticalDivider,
	ScrollContainer,
	Ball,
} from 'components/Navigation/Notifications/styles';

import { IoMdNotifications } from 'react-icons/io';
import { MdEmail } from 'react-icons/md';
import { useSelector } from 'react-redux';

const MenuItem = (
	{
		text,
		process,
		onClick = () => { }
	}) => {
	return (
		<StyledMenuItem
			container
			justify="flex-start"
			alignItems="center"
			onClick={onClick}
		>
			<Grid item xs={3}>
				<Grid container direction="column" justify="center" alignItems="center">
					<IconBackground p="0 .3rem">
						<MdEmail size={30} color="var(--primary)" />
					</IconBackground>
				</Grid>
			</Grid>
			<Grid item xs={1}>
				<Grid container justify="center" alignItems="center">
					<Box mt=".5rem">
						<VerticalDivider />
					</Box>
				</Grid>
			</Grid>
			<Grid
				item
				xs={8}
			>
				<Box p=".5rem">
					<Grid container justify="flex-start" alignItems="center">
						<UIText
							text={text}
							fontSize="12px"
							fontWeight="400"
							textcolor="var(text-dark)"
						/>
						<UIText
							text={process ? `${process.city || ""} - ${process.uf || ""}` : ''}
							fontSize="10px"
							fontWeight="500"
							textcolor="var(--text-grey)"
						/>
					</Grid>
				</Box>
			</Grid>
		</StyledMenuItem>
	);
};

export default function Notifications() {
	const history = useHistory();

	const [anchorEl, setAnchorEl] = useState(null);
	const [allReaded, setAllReaded] = useState(false);
	const [size, setSize] = useState(0);
	const open = Boolean(anchorEl);
	const pendencies = useSelector((state) => state.user.pendencies);
	useEffect(() => {
		// console.log('chegou notificacao ');
		if (pendencies.length > size) {
			setSize(pendencies.length);
			setAllReaded(true)
		}
	}, [pendencies])
	const handleClick = event => {
		setAnchorEl(event.currentTarget);
		setAllReaded(false);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<>
					<IoMdNotifications size={25} color="var(--primary)" />
					<Ball
						visible={allReaded}
					/>
				</>
			</IconButton>

			<StyledMenu
				elevation={0}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: 'auto',
						width: '240px',
					},
				}}
			>
				<Grid container>
					<Grid item xs={12}>
						<Grid container justify="center" alignItems="center">
							<TopIcon />
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<MenuHeader p=".3rem">
							<Box p=".2rem">
								<Grid container justify="space-between" alignItems="center">
									<Grid item xs={8}>
										<Grid container>
											<UIText text="Notificações" textcolor="#fff" />
											<Box p="0 .1rem">
												<UIAvatar
													fontSize="12px"
													textcolor="#fff"
													background="rgba(229,229,229,0.5)"
													width="25px"
													height="25px"
												>
													{(pendencies || []).length}
												</UIAvatar>
											</Box>
										</Grid>
									</Grid>
									<Grid item>
										<UIText
											text="Ver todas"
											fontSize="12px"
											textcolor="#fff"
											onClick={() => history.push('/pendencias')}
											cursor="pointer"
										/>
									</Grid>
								</Grid>
							</Box>
						</MenuHeader>
						<Paper elevation={1}>
							<ScrollContainer >
								{(pendencies || []).map((item) => (
									<MenuItem
										{...item}
										onClick={() => {
											handleClose();
											history.push(`/processo/${item.process.id}`);
										}}
									/>
								))}
							</ScrollContainer>
						</Paper>
					</Grid>
				</Grid>
			</StyledMenu>
		</>
	);
}
