import React from 'react';
import MainModal from 'components/Modals/MainModal';
import UICheckbox from 'components/UI/Checkbox/Checkbox';
import UIText from 'components/UI/Text/Text';
import UITag from 'components/UI/Tag/index';
import UITextArea from 'components/UI/Textarea/Textarea';
import { useEffect, useState } from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Grid,
	Typography,
	Divider,
} from '@material-ui/core';
import { moneyMask, percentMask } from 'services/mask';

import { ScrollContainer, StyledBox } from './styles';

import { IoIosArrowDown } from 'react-icons/io';
import UIButton from 'components/UI/Button/Button';
import UIInput from 'components/UI/Input/Input';
import UploadArea from 'components/Upload/UploadArea/UploadArea';
import api from 'services/api';
import lang from 'lang/lang.json';
import { formatToNumber, parseToNumber } from 'brazilian-values';
import { useParams } from 'react-router';
import Modal from 'components/Modals';
import { useFormik } from 'formik';

export function ListOrders({ action, show, onClose }) {
	const [config, setConfig] = useState({});

	useEffect(() => {
		if (action === 'e2o') {
			setConfig({
				title: 'Gerar E20',
				text: 'Selecione os pedidos para gerar o E2O',
			});
		} else {
			setConfig({
				title: 'Ver notas',
				text: 'Selecione as notas que deseja visualizar',
			});
		}
	}, [action]);

	return (
		<MainModal
			width="small"
			show={show}
			onClose={onClose}
			title={config.title}
			confirmButton="Confirmar"
			onConfirm={onClose}
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<UIText text={config.text} />
				</Grid>
				<Grid item xs={12}>
					<UICheckbox label="Pedido 1" fontWeight="500" fontSize="14px" />
					<Box mx={4}>
						<Grid container spacing={1} justify="space-between">
							<Grid item xs={6}>
								<Grid container spacing={1}>
									<Grid item>
										<Typography variant="h6" color="secondary">
											Valor:
										</Typography>
									</Grid>
									<Grid item>
										<Typography variant="h6" color="primary">
											R$ 12.000,00
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={6}>
								<Grid container spacing={1}>
									<Grid item>
										<Typography variant="h6" color="secondary">
											Data do pedido:
										</Typography>
									</Grid>
									<Grid item>
										<Typography variant="h6" color="primary">
											21/10/2020
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
			</Grid>
		</MainModal>
	);
}

export function DiscountItems({ open, onClose, items = [], process }) {
	const [discountItems, setDiscountItems] = useState([]);
	const [selectedDiscountItem, setSelectedDiscountItem] = useState({
		item: {},
		opened: false,
	});

	useEffect(() => {
		async function getProcessProducts() {
			try {
				const response = await api.get(`process-products`, {
					params: {
						processId: process.id,
					},
				});

				setDiscountItems(
					response.data.data.filter(
						({ discount_orders }) => discount_orders[0].status !== 'approved',
					),
				);
			} catch (error) {}
		}

		getProcessProducts();
	}, [open]);

	return (
		<MainModal
			width="bigger"
			show={open}
			onClose={onClose}
			title="Confirmar valores aprovados pelo financeiro"
			confirmButton="Confirmar"
			onConfirm={onClose}
		>
			<ScrollContainer
				container
				direction="row"
				justify="flex-start"
				alignItems="flex-start"
			>
				{discountItems.map((item) => (
					<Grid item xs={12}>
						<Accordion>
							<AccordionSummary expandIcon={<IoIosArrowDown />}>
								<Grid container>
									<Grid
										container
										direction="row"
										justify="space-between"
										alignItems="flex-start"
									>
										<Grid item xs={10}>
											<UIText
												text={item.product.description}
												textcolor="var(--text-dark)"
												fontSize="20px"
												fontWeight="500"
											/>
										</Grid>
										<Grid item xs={2}>
											{item.discount && (
												<UITag
													text={lang[item.discount_orders[0].status]}
													variant={item.discount_orders[0].status}
													size="small"
												/>
											)}
										</Grid>
									</Grid>
									<Grid item xs={10}>
										<Box pt={3}>
											<Grid
												container
												direction="row"
												justify="space-between"
												alignItems="flex-start"
											>
												<Grid item xs={2}>
													<Grid container>
														<UIText
															text="Item: "
															textcolor="var(--text-grey)"
															fontWeight="500"
															fontSize="14px"
														/>
														<UIText
															text={item.itemNumber}
															textcolor="var(--primary)"
															fontWeight="400"
															fontSize="14px"
														/>
													</Grid>
												</Grid>
												<Grid item xs={4}>
													<Grid container>
														<UIText
															text="SKU: "
															textcolor="var(--text-grey)"
															fontWeight="500"
															fontSize="14px"
														/>
														<UIText
															text={item.product.sku}
															textcolor="var(--primary)"
															fontWeight="400"
															fontSize="14px"
														/>
													</Grid>
												</Grid>
												<Grid item xs={6}>
													<Grid container>
														<UIText
															text="(R$)Valor do item: "
															textcolor="var(--text-grey)"
															fontWeight="500"
															fontSize="14px"
														/>
														<UIText
															text={item.nfPrice}
															textcolor="var(--primary)"
															fontWeight="400"
															fontSize="14px"
														/>
													</Grid>
												</Grid>
											</Grid>
										</Box>
									</Grid>
								</Grid>
							</AccordionSummary>
							<AccordionDetails>
								<Grid
									container
									direction="row"
									justify="space-between"
									alignItems="center"
								>
									<Grid item xs={9}>
										<Grid container>
											<Grid item xs={4}>
												<Grid
													container
													direction="column"
													justify="center"
													alignItems="flex-start"
												>
													<UIText
														text="(R$) Valor contrato "
														textcolor="var(--text-grey)"
														fontWeight="500"
														fontSize="12px"
													/>
													<Box pt={1}>
														<UITag
															text={item.institutionValue}
															variant="disabled"
															size="small"
														/>
													</Box>
												</Grid>
											</Grid>
											<Grid item xs={4}>
												<Grid
													container
													direction="column"
													justify="center"
													alignItems="flex-start"
												>
													<UIText
														text="(R$) Saldo faturado	"
														textcolor="var(--text-grey)"
														fontWeight="500"
														fontSize="12px"
													/>
													<Box pt={1}>
														<UITag
															text={item.finalValue}
															variant="orange"
															size="small"
														/>
													</Box>
												</Grid>
											</Grid>
											<Grid item xs={4}>
												<Grid
													container
													direction="column"
													justify="center"
													alignItems="flex-start"
												>
													<UIText
														text="(Kg) Saldo volume: "
														textcolor="var(--text-grey)"
														fontWeight="500"
														fontSize="12px"
													/>
													<Box pt={1}>
														<UIText
															text={item.initialValue}
															textcolor="var(--orange)"
															fontSize="12px"
														/>
													</Box>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={3}>
										<Box pr={3}>
											{item.discount && (
												<UITag
													text={lang[item.discount_orders[0].status]}
													variant={item.discount_orders[0].status}
													size="small"
												/>
											)}
										</Box>
									</Grid>
									<Grid container justify="flex-end" alignItems="flex-start">
										<Box p={3}>
											<UIButton
												text="Contestar valor"
												type="button"
												variant="outline"
												textcolor="var(--primary)"
												onClick={() =>
													setSelectedDiscountItem({ opened: true, item })
												}
											/>
										</Box>
									</Grid>
								</Grid>
							</AccordionDetails>
						</Accordion>
					</Grid>
				))}
				<Grid container>
					<Grid item xs={12}>
						<StyledBox color="#F8F4F4">
							<UITextArea
								label="Comentário do financeiro"
								value="Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt, esse hic quasi recusandae dignissimos explicabo aut dolorum dolore inventore error animi perspiciatis nulla. Blanditiis sapiente velit officiis hic autem eius?"
								disabled
							/>
						</StyledBox>
					</Grid>
				</Grid>
			</ScrollContainer>
			<AlterDiscount
				open={selectedDiscountItem.opened}
				defaultItem={selectedDiscountItem.item}
				onClose={() => {
					setSelectedDiscountItem({
						item: {},
						opened: false,
					});
				}}
			/>
		</MainModal>
	);
}

export function AlterDiscount({ open, onClose, defaultItem }) {
	const [successModal, setSuccessModal] = useState(false);
	const { idProcess } = useParams();

	const { values, handleSubmit, setFieldValue } = useFormik({
		initialValues: (defaultItem.discount_orders || {})[0] || {
			values: '',
		},
		enableReinitialize: true,
		async onSubmit(values) {
			try {
				await api.patch(`process/${idProcess}`, {
					status: 'awaiting financier',
					discount: true,
				});

				await api.patch(`process-products/${defaultItem.id}`, {
					discountValue: parseToNumber(
						(values.discountValue || '').replace('%', ''),
					),
					discount: true,
				});

				if (defaultItem.discount_orders.length > 0)
					await api.patch(
						`discount-order/${defaultItem.discount_orders[0].id}`,
						{
							status: 'contested',
						},
					);
				else
					await api.post(`discount-order`, {
						justify: values.justify,
						status: 'contested',
						processProductId: defaultItem.id,
						discountValue: parseToNumber(
							(values.discountValue || '').replace('%', ''),
						),
					});

				handleSuccessModal();
			} catch (error) {}
		},
	});

	const handleSuccessModal = () => {
		setSuccessModal(!successModal);
	};

	return (
		<MainModal
			width="medium"
			show={open}
			onClose={onClose}
			title="Solicitar alteração desconto"
			confirmButton="Confirmar"
			onConfirm={() => handleSubmit()}
		>
			<Grid container>
				<Grid item xs={12}>
					<Box pt={1} pb={1}>
						<UIInput
							label="Produto"
							value={(defaultItem.product || {}).description}
							type="text"
							color="#000"
							disabled
							name="description"
						/>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box pt={1} pb={1}>
						<UIInput
							label="Valor do desconto nescessário"
							value={values.discountValue}
							onChange={(e) =>
								setFieldValue(
									'discountValue',
									percentMask(
										parseToNumber((e.target.value || '').replace('%', '')),
									),
								)
							}
							type="text"
							name="discountValue"
						/>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box pt={1} pb={3}>
						<UITextArea
							label="Justificativa"
							onChange={(e) => setFieldValue('justify', e.target.value)}
							type="text"
							placeholder="Insira o texto..."
							rows={3}
							value={values.justify}
							name="justify"
						/>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box pb={3}>
						<UploadArea
							buttonOnClick={() => {}}
							buttonText="Adicionar"
							onChange={() => {}}
							direction="column"
						/>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
			</Grid>
			<Modal
				variant="success"
				title="Solicitacao enviada com sucesso"
				buttonText="Voltar para a licitação"
				onClose={handleSuccessModal}
				open={successModal}
				buttonAction={() => {
					handleSuccessModal();
					onClose();
				}}
			/>
		</MainModal>
	);
}
