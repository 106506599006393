import { Grid, TextField, Typography, withStyles } from '@material-ui/core';

export const StyledGrid = withStyles({
	container: {
		backgroundColor: '#fff',
		marginTop: '20px',
		padding: '10px 0px',
	},
})(Grid);

export const Title = withStyles({
	root: {
		width: '100%',
		padding: '10px 20px',
		marginBottom: '15px',
		borderBottom: '1px solid var(--text-grey2)',
		fontWeight: '500',
		fontSize: '16px',
	},
})(Typography);

export const StyledTextField = withStyles({
	root: {
		'& .MuiInput-underline:before': {
			borderBottom: '1px solid var(--text-grey)',
		},
		'& .MuiFormLabel-root': {
			color: 'var(--primary)',
			fontSize: '20px',
		},
	},
})(TextField);
