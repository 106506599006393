import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';

import UIInput from 'components/UI/Input/Input';
import UISelect from 'components/UI/Select/Select';

import { useFormik } from 'formik';
import * as yup from 'yup';

import mock from './mock.json';
import lang from 'lang/lang.json';

const validationSchema = yup.object().shape({
	name: yup.string().required('Digite o nome'),
	lastName: yup.string().required('Digite o sobrenome'),
	email: yup.string().required('Digite o email').email('Email inválido'),
	regional: yup.string().required('Selecione a regional'),
	sap: yup.string().required('Digite o SAP'),
	accessLevel: yup.string().required('Selecione o nivel de acesso'),
});

export default function Admin({ user, submit }) {
	const {
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
		handleSubmit,
	} = useFormik({
		validationSchema,
		initialValues: {
			...user.employee,
			email: user.email,
			regional: user.employee.regional || '',
		} || {
			email: '',
			name: '',
			lastName: '',
			regional: '',
			sap: '',
			accessLevel: ''
		},
		onSubmit(fields) {
		},
	});

	useEffect(() => {
		submit({
			values,
			errors,
		});
	}, [values]);

	return (
		<Box pt={3} pb={3}>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					handleSubmit();
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<UIInput
							value={values.name}
							name="name"
							label="Nome"
							error={touched.name && errors.name}
							onChange={handleChange('name')}
							onBlur={handleBlur('name')}
						/>
					</Grid>
					<Grid item xs={6}>
						<UIInput
							value={values.lastName}
							name="lastName"
							label="Sobrenome"
							error={touched.lastName && errors.lastName}
							onChange={handleChange('lastName')}
							onBlur={handleBlur('lastName')}
						/>
					</Grid>
					<Grid item xs={6}>
						<UIInput
							value={values.email}
							name="email"
							label="Email"
							error={touched.email && errors.email}
							onChange={handleChange('email')}
							onBlur={handleBlur('email')}
						/>
					</Grid>
					<Grid item xs={6}>
						<UISelect
							items={mock.regionals}
							value={values.regional}
							name="regional"
							label="Regional"
							error={touched.regional && errors.regional}
							onChange={handleChange('regional')}
							onBlur={handleBlur('regional')}
						/>
					</Grid>
					<Grid item xs={6}>
						<UIInput
							value={values.sap}
							name="SAP"
							label="SAP"
							error={touched.sap && errors.sap}
							onChange={handleChange('sap')}
							onBlur={handleBlur('sap')}
						/>
					</Grid>
					<Grid item xs={6}>
						<UISelect
							items={mock.accessLevels}
							value={values.accessLevel}
							name="accessLevel"
							label="Nível de acesso"
							error={touched.accessLevel && errors.accessLevel}
							onChange={handleChange('accessLevel')}
							onBlur={handleBlur('accessLevel')}
						/>
					</Grid>
				</Grid>
			</form>
		</Box>
	)
}
