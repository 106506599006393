import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import process from './process/reducer';
import processProduct from 'store/modules/processProduct/reducer';
import app from './app/reducer';

export default combineReducers({
	auth,
	user,
	process,
	processProduct,
	app,
});
