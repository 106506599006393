import React, { useState } from 'react';
import UIButton from 'components/UI/Button/Button';
import { Grid } from '@material-ui/core';
import lang from 'lang/lang.json';
import { SuspendProcess, ProcessResult, RequestSuspend } from './modals';
import { Container } from './style';
import { useHistory, useParams } from 'react-router';
import { store } from 'store';
import { DiscountItems } from '../Table/ProcessOrders/modals';

export default function BottomButtons({ process }) {
	const [resultModal, setResultModal] = useState(false);
	const [modalSuspendProcess, setModalSuspendProcess] = useState(false);
	const [financierModal, setFinancierModal] = useState(false);
	const handleResultModal = () => {
		setResultModal(!resultModal);
	};

	const history = useHistory();

	const { idProcess } = useParams();
	const { type } = store.getState().user;

	return (
		<Container container spacing={3} justify="flex-end">
			{lang.process_steps[process.status] !== 1 && (
				<>
					{lang.process_steps[process.status] === 2 && type === 'consultant' ? (
						<Grid item xs={12} md={4} lg={3}>
							<UIButton
								variant="outlined"
								text="Validação preço financeiro"
								onClick={() => setFinancierModal(true)}
								fullWidth
							/>
						</Grid>
					) : (
							''
						)}
					{[3, 4].includes(lang.process_steps[process.status]) ||
						(process.status === 'suspending' && type === 'consultant') ? (
							<Grid item xs={12} md={4} lg={3}>
								<UIButton
									variant="outlined"
									text="Suspender processo"
									onClick={() => setModalSuspendProcess(true)}
									fullWidth
								/>
							</Grid>
						) : null}
					{process.status === 'awaiting distributor result' &&
						lang.process_steps[process.status] === 3 &&
						type === 'distributor' ? (
							<Grid item xs={6} md={3}>
								<UIButton
									variant="contained"
									text="Informar resultado"
									onClick={() => handleResultModal()}
								/>
							</Grid>
						) : (
							''
						)}
					{process.status === 'awaiting consultant result' &&
						lang.process_steps[process.status] === 3 &&
						type === 'consultant' ? (
							<Grid item xs={6} md={3}>
								<UIButton
									variant="contained"
									text="Informar resultado"
									onClick={() => history.push(`/resultado/${idProcess}`)}
								/>
							</Grid>
						) : (
							''
						)}
					<ProcessResult
						title="Informar resultado"
						confirmButton="Confirmar"
						confirmVariant="contained"
						cancelButton="Cancelar pedido"
						show={resultModal}
						processId={idProcess}
						bottonButtonMd="6"
						onClose={() => setResultModal(false)}
					/>
					<DiscountItems
						open={financierModal}
						bottonButtonMd="6"
						onClose={() => setFinancierModal(false)}
						process={process}
					/>
					{type === 'distributor' || process.status !== 'suspending' ? (
						<SuspendProcess
							show={modalSuspendProcess}
							onClose={() => setModalSuspendProcess(false)}
							process={process}
						/>
					) : (
							<RequestSuspend
								show={modalSuspendProcess}
								onClose={() => setModalSuspendProcess(false)}
								process={process}
							/>
						)}
				</>
			)}
		</Container>
	);
}
