/* eslint-disable no-mixed-operators */
import React, { useState, useEffect, Fragment } from 'react';
import TablePaper from 'components/UI/Table/TablePaper';
import TableSkeleton from 'components/UI/Table/TableSkeleton';
import PropTypes from 'prop-types';
import TableRow from 'components/UI/Table/TableRow';
import TableCell from 'components/UI/Table/TableCell';
import TableCellMenu from 'components/UI/Table/TableCellMenu';
import TablePagination from 'components/UI/Table/TablePagination';
import { Grid, Select } from '@material-ui/core';
import UIButton from 'components/UI/Button/Button';
import { ListOrders, DiscountItems } from './modals';
import SuccessModal from 'components/Modals/SuccessModal';
import EditItem from '../../Modal/EditItem';
import UISelect from 'components/UI/Select/Select';

import { store } from 'store';
import { useHistory, useParams } from 'react-router';

function TableItemsProcess(props) {
	const { type } = store.getState().user;

	const { titles, items, totalPages } = props;
	const [newTitles, setNewTitles] = useState([]);
	const [newItems, setNewItems] = useState([]);
	const [listOrders, setListOrders] = useState(false);
	const [modalActionOrder, setModalActionOrder] = useState('');
	const [openSucessModal, setOpenSucessModal] = useState(false);
	const [selectedItem, setSelectedItem] = useState({});
	const [openEditItem, setOpenEditItem] = useState(false);
	const [openValidationDiscount, setOpenValidationDiscount] = useState(false);
	const [refresh, setRefresh] = useState(false);
	useEffect(() => {
		setNewTitles(titles);
	}, [titles]);

	const arrayOrdering = (inputArr) => {
		let len = inputArr.length;
		for (let i = 0; i < len; i++) {
			for (let j = 0; j < len; j++) {
				if (inputArr[j] > inputArr[j + 1]) {
					let tmp = inputArr[j];
					inputArr[j] = inputArr[j + 1];
					inputArr[j + 1] = tmp;
				}
			}
		}
		return inputArr;
	};

	useEffect(() => {
		// Esse trecho vai percorrer o array de itens e pegar o valor
		// do maior array de pedidos que tiver
		let maxArraySize = 0;
		let ordersId = [];
		items.forEach((product) => {
			if (
				maxArraySize < (product.order_items ? product.order_items.length : 5)
			) {
				maxArraySize =
					product.order_items && product.order_items.length
						? product.order_items.length
						: 0;
			}
			(product.order_items || []).map((order) => {
				if (ordersId.indexOf(order.orderId) !== -1) {
					return 0;
				}
				ordersId.push(order.orderId);
			});
			ordersId = arrayOrdering(ordersId);
		});

		// need refactor
		// This will order the product_itens if the orders who all the other 5 products have.
		const orderProduct = (product) => {
			const empty = {
				orderId: '-',
				quantity: '-',
				total: '-',
				createdAt: '-',
			};

			let temp = []; //temp array
			let i = 0;
			(product.order_items || []).map((order) => {
				// if is the first item and don't have the same ID of the orderID item will add an empty column
				// is necessary ? refactor
				if (
					product.order_item[i - 1] === undefined &&
					product.order_item[i] !== undefined &&
					order.orderId !== ordersId[i]
				) {
					temp.push(empty);
					i++;
				}
				// if this order ID don't match with the same position of the orders ID's will add an empty column
				if (order.orderId !== ordersId[i]) {
					do {
						temp.push(empty);
						i++;
					} while (order.orderId !== ordersId[i]);
				}
				temp.push(order); //add the order in the right position
				i++;
			});
			// If the 'temp' length don't match with the orders ID will add empty columns.
			if (temp.length < ordersId.length) {
				do {
					temp.push(empty);
				} while (temp.length < ordersId.length);
			}
			product.order_item = temp;
		};

		items.forEach((product) => {
			orderProduct(product);
		});

		// esse trecho vai inserir as colunas de pedidos de acordo
		// com a quantidade do maior array de pedidos que foi calculado
		let newArrayOfTitles = titles;
		for (let i = 0; i < ordersId.length; i++) {
			newArrayOfTitles.push(
				`Pedido ${ordersId[i]}`,
				'Volume',
				'(R$)Valor do pedido',
				'Data do Pedido',
			);
		}
		setNewTitles(newArrayOfTitles);

		// esse trecho vai inserir objetos sem valores nos itens que
		// tiverem a quantidade de pedidos menor que a maior quantidade
		// de pedidos calculado pelos itens nos trechos a cima.
		// Pode ser que quando vc altere algo no código o FastRefresh do react
		// crie novas colunas vazias, isso é um bug que acredito que não
		// vai ocorrer em produção, mas caso ocorra tem que ajustar o código.
		let newArrayOfItems = [];
		items.forEach((product) => {
			const length = (product.order_item || []).length;
			// for (let i = length; i < maxArraySize; i++) {
			// 	product.order_items.push({
			// 		orderId: '-',
			// 		quantity: '-',
			// 		total: '-',
			// 		createdAt: '-',
			// 	});
			// }
			newArrayOfItems.push(product);
		});

		setNewItems(newArrayOfItems);
	}, [items, titles, refresh]);

	const filter = (newFilter) => {
		let filtered;
		if (newFilter === 'Todos') setRefresh(!refresh);
		else if (newFilter === 'Itens ganhos')
			filtered = items.filter((a) => a.winner === true);
		else filtered = items.filter((a) => a.winner === false);

		setNewItems(filtered);
	};

	const onHandleListOrders = (action = '') => {
		setListOrders(!listOrders);
		setModalActionOrder(action);
	};

	const menuItems =
		type === 'distributor'
			? [
					// precisa ser alterado para de fato realizar a notificacao
					{
						onClick: () => {
							setOpenSucessModal(true);
						},
						text: 'Contestar as informações',
					},
			  ]
			: [
					{
						onClick: (evt) => {
							setSelectedItem(evt);
							setOpenEditItem(true);
						},
						text: 'Editar item',
					},
					[
						'waiting to confirm consultant values',
						'awaiting consultant result',
						'awaiting distributor result',
					].includes((props.process || {}).status)
						? {
								onClick: () => {
									setOpenValidationDiscount(true);
								},
								color: 'var(--error)',
								text: 'Solicitar alteração do desconto',
						  }
						: {},
			  ];

	return (
		<TablePaper
			title="Itens do processo"
			elementsTopStart={
				<Grid container>
					<UISelect
						items={[
							{ title: 'Todos' },
							{ title: 'Itens ganhos' },
							{ title: 'Itens Perdidos' },
						]}
						onChange={(e) => {
							filter(e.target.value);
						}}
						keyName="title"
						keyValue="title"
					/>
				</Grid>
			}
			elementsTopEnd={
				<Grid container alignItems="center" spacing={2}>
					<Grid item>
						<TablePagination count={totalPages} />
					</Grid>
					<Grid item>
						<UIButton
							variant="outlined"
							text="Ver notas"
							fontSize="14px"
							borderwidth="1px"
							onClick={() => onHandleListOrders('notes')}
						/>
					</Grid>
					<Grid item>
						<UIButton
							variant="outlined"
							text={
								type === 'distributor'
									? 'Exportar escopo dos itens'
									: 'Gerar E2O'
							}
							fontSize="14px"
							borderwidth="1px"
							onClick={() =>
								type === 'distributor'
									? alert('ainda não implementado')
									: onHandleListOrders('e2o')
							}
						/>
					</Grid>
					<ListOrders
						show={listOrders}
						onClose={onHandleListOrders}
						action={modalActionOrder}
					/>
				</Grid>
			}
		>
			<TableSkeleton
				hideScrollbars={false}
				horizontalScroll
				head={
					<TableRow>
						{(newTitles || []).map((title, index) => (
							<TableCell
								key={index}
								background="var(--primary)"
								color="#FFFFFF"
								borderright={
									(index === 0 && '1px solid  var(--grey-opacity)') ||
									(index === 8 && '1px solid  var(--grey-opacity)')
								}
							>
								{title}
							</TableCell>
						))}
					</TableRow>
				}
				body={(newItems || []).map((item, index) => (
					<TableRow key={index} borderbottom="1px solid  var(--grey-opacity)">
						<TableCellMenu menuItems={menuItems} row={item}>
							{item.itemNumber ?? ''}
						</TableCellMenu>
						<TableCell
							textdecorationline="underline"
							link
							borderleft="1px solid var(--grey-opacity)"
						>
							{item.specification ?? ''}
						</TableCell>
						<TableCell>{item.quantity ?? ''}</TableCell>
						<TableCell>{item.unity || ''}</TableCell>
						<TableCell
							color={item.approve ? 'var(--success)' : 'var(--error)'}
							textdecorationline={!item.approve ? 'underline' : ''}
							link={item.approve === false && true}
						>
							{item.approve ? 'Sim' : 'Não' || '-'}
						</TableCell>
						<TableCell>{item.product.sku || ''}</TableCell>
						<TableCell
							textdecorationline="underline"
							link
							borderleft="1px solid var(--grey-opacity)"
						>
							{item.product.description || ''}
						</TableCell>
						<TableCell>{item.quantity || ''}</TableCell>
						<TableCell borderright="1px solid  var(--grey-opacity)">
							{item.finalValue || ''}
						</TableCell>
						<TableCell background="rgba(255, 172, 20, 0.12)">
							{item.product && item.product.value ? item.product.value : ''}
						</TableCell>
						<TableCell
							background="rgba(255, 172, 20, 0.12)"
							textdecorationline={item.winner ? 'none' : 'underline'}
							link={item.winner ? false : true}
							chip
							chipStyle={item.winner ? 'green' : 'red'}
						>
							{item.winner ? 'Ganhou' : 'Perda'}
						</TableCell>
						<TableCell background="rgba(255, 172, 20, 0.12)">
							{item.finalValue || ''}
						</TableCell>
						<TableCell background="rgba(255, 172, 20, 0.12)">
							{item.discountValue || ''}
						</TableCell>
						<TableCell background="rgba(255, 172, 20, 0.12)">
							{item.product && item.product.markup ? item.product.markup : ''}
						</TableCell>
						<TableCell
							background="rgba(255, 172, 20, 0.12)"
							chip
							chipStyle="grey"
						>
							{item.value || ''}
						</TableCell>
						<TableCell
							background="rgba(255, 172, 20, 0.12)"
							chip
							chipStyle="orange"
						>
							{item.value || ''}
						</TableCell>
						<TableCell
							background="rgba(255, 172, 20, 0.12)"
							color="var(--orange)"
						>
							{item.quantity || ''}
						</TableCell>
						{(item.order_item || []).map((item, index) => (
							<Fragment key={index}>
								<TableCell
									borderleft="1px solid  var(--grey-opacity)"
									color="var(--primary)"
								>
									{item.orderId || ''}
								</TableCell>
								<TableCell>{item.quantity || ''}</TableCell>
								<TableCell>{item.total || ''}</TableCell>
								<TableCell>{item.createdAt || ''}</TableCell>
							</Fragment>
						))}
					</TableRow>
				))}
			/>
			<SuccessModal
				open={openSucessModal}
				buttonText="Voltar para o processo"
				buttonAction={() => {
					setOpenSucessModal(false);
				}}
				onClose={() => {
					setOpenSucessModal(false);
				}}
				title="Solicitação enviada com sucesso"
			/>
			<EditItem
				defaultItem={selectedItem}
				show={openEditItem}
				onClose={() => setOpenEditItem(false)}
				// extraSuccessAction={() => history.go(0)}
			/>
			<DiscountItems
				open={openValidationDiscount}
				onClose={() => setOpenValidationDiscount(false)}
				items={items}
				process={props.process}
			/>
		</TablePaper>
	);
}

export default TableItemsProcess;

TableItemsProcess.propTypes = {
	titles: PropTypes.array,
	items: PropTypes.array,
	totalPages: PropTypes.number,
};

TableItemsProcess.defaultProps = {
	totalPages: 1,
	titles: [
		'Item',
		'Especificação',
		'Quantidade',
		'Unidade',
		'Atende',
		'SKU',
		'Produto',
		'Quant./prod.',
		'(R$)Preço lista',
		'(R$)Valor da oportunidade',
		'Resultado',
		'(R$)Preço Final',
		'Desconto',
		'Markup',
		'(R$) Valor contrato',
		'(R$) Saldo do contrato',
		'(Kg) Volume restante',
	],
};
