import React from 'react';

import { StyledGrid } from 'components/Layout/PaddingGrid/styles';

export default function PaddingGrid(props) {
	return (
		<StyledGrid
			padding={props.padding ? props.padding : '0'}
			margin={props.margin ? props.margin : '0'}
			{...props}
		>
			{props.children}
		</StyledGrid>
	);
}
