import React, { useEffect, useState } from 'react';

import {
	Grid,
	Divider,
	Box,
	IconButton,
	Menu,
	MenuItem,
} from '@material-ui/core';

import {
	CardContainer,
	UserPhoto,
	MiddleColumn,
	PaddingGrid,
	EllipsisText,
} from 'components/Cards/UserCard/styles';

import UITag from 'components/UI/Tag/index';
import UIText from 'components/UI/Text/Text';

import { TiPencil } from 'react-icons/ti';
import { BiTrash } from 'react-icons/bi';
import { MdMoreVert } from 'react-icons/md';

import lang from 'lang/lang.json';

import api from 'services/api';
import { formatToBRL, formatToNumber } from 'brazilian-values';

export default function UserCard({ informations }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [cardUser, setCardUSer] = useState(informations);

	return (
		<CardContainer>
			<Box padding=".5rem" display="flex" justify="center" alignItems="center">
				<Box>
					<UserPhoto
						size={40}
						borderColor={
							cardUser.status !== 'inactive' ? 'var(--primary)' : '#787B7E'
						}
					/>
				</Box>
				<Box
					paddingLeft="8px"
					flex="1"
					boxSizing="border-box"
					overflow="hidden"
					flexWrap="wrap"
				>
					<Grid
						container
						direction="row"
						justify="space-between"
						alignItems="center"
					>
						<UITag
							text={
								lang[(cardUser.employee || {}).accessLevel || 'distributor']
							}
							variant={
								cardUser.status !== 'inactive'
									? (cardUser.employee || {}).accessLevel || 'distributor'
									: 'disabled'
							}
						/>
						<Box>
							{cardUser.status !== 'inactive' && (
								<>
									<IconButton size="small" onClick={cardUser.onPencilClick}>
										<TiPencil color="var(--text-grey)" size={18} />
									</IconButton>
									<Box width="4px" display="inline-block" />
									{cardUser.status === 'active' && (
										<IconButton size="small" onClick={cardUser.onTrashClick}>
											<BiTrash color="var(--text-grey)" size={18} />
										</IconButton>
									)}
								</>
							)}
							{(cardUser.employee || cardUser.status === 'inactive') && (
								<>
									<Box width="4px" display="inline-block" />
									<IconButton
										size="small"
										onClick={(e) => setAnchorEl(e.currentTarget)}
									>
										<MdMoreVert color="var(--text-grey)" size={18} />
									</IconButton>
									<Menu
										anchorEl={anchorEl}
										keepMounted
										open={Boolean(anchorEl)}
										onClose={() => setAnchorEl(null)}
										anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
									>
										{(cardUser.menuItems || []).map((item) => (
											<MenuItem
												onClick={() => {
													item.onClick && item.onClick();
													setAnchorEl(null);
												}}
											>
												{item.text}
											</MenuItem>
										))}
									</Menu>
								</>
							)}
						</Box>
					</Grid>
					<EllipsisText fontWeight="500" fontSize="16px">
						{(cardUser.employee || cardUser.distributor || {}).name}
					</EllipsisText>
					<EllipsisText fontSize="12px" fontWeight="400" title={cardUser.email}>
						{cardUser.email}
					</EllipsisText>
				</Box>
			</Box>
			<Grid container direction="row" justify="flex-start">
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid container direction="row" justify="center" alignItems="center">
					<PaddingGrid
						padding=".5rem 0"
						margin="0 .2rem"
						item
						container
						direction="column"
						justify="center"
						alignItems="center"
						xs={4}
					>
						<UIText
							text="Processos em aberto"
							fontWeight="400"
							fontSize="12px"
							textalign="center"
						/>
						<UIText
							text={cardUser.openProcesses}
							textcolor={
								cardUser.status !== 'inactive' ? 'var(--primary)' : '#787B7E'
							}
							fontWeight="500"
							fontSize="16px"
							textalign="center"
						/>
					</PaddingGrid>
					<MiddleColumn
						container
						direction="column"
						justify="center"
						alignItems="center"
						item
						xs={4}
					>
						<UIText
							text="Saldo em aberto(R$mil)"
							fontWeight="400"
							fontSize="12px"
							textalign="center"
						/>
						<UIText
							text={formatToNumber(cardUser.total)}
							textcolor={
								cardUser.status !== 'inactive' ? 'var(--primary)' : '#787B7E'
							}
							fontWeight="500"
							fontSize="16px"
							textalign="center"
						/>
					</MiddleColumn>
					<PaddingGrid
						item
						padding=".5rem 0"
						margin="0 .2rem"
						container
						direction="column"
						justify="center"
						alignItems="center"
						xs={3}
					>
						<UIText
							text="Total de ganhos"
							fontWeight="400"
							fontSize="12px"
							textalign="center"
						/>
						<UIText
							text={cardUser.totalWinner}
							textcolor={
								cardUser.status !== 'inactive' ? 'var(--primary)' : '#787B7E'
							}
							fontWeight="500"
							fontSize="16px"
							textalign="center"
						/>
					</PaddingGrid>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<PaddingGrid
					padding=".7rem"
					container
					direction="row"
					justify="center"
					alignItems="flex-start"
				>
					<UIText
						text="Última interação: &nbsp;"
						fontWeight="400"
						fontSize="12px"
						textcolor="var(--text-grey)"
						spacing={3}
					/>
					<UIText
						text={new Date(cardUser.lastSeen).toLocaleString()}
						fontWeight="500"
						fontSize="12px"
						textcolor="var(--text-dark)"
					/>
				</PaddingGrid>
			</Grid>
		</CardContainer>
	);
}
