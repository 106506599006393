import styled from 'styled-components';
import {
	Stepper,
	StepLabel,
	withStyles,
	StepConnector,
	makeStyles,
	Typography,
} from '@material-ui/core';

export const StyleStepper = styled(Stepper)`
	background-color: transparent;
	min-height: 83.65px;
	overflow-x: auto;
	overflow-y: hidden;

	.MuiStepConnector-root {
		top: 75px;
	}

	::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}
	::-webkit-scrollbar-track {
		border-radius: 3px;
	}
	::-webkit-scrollbar-thumb {
		background: #c4c4c4;
		border-radius: 3px;
	}
`;

export const StyledStepLabel = withStyles({
	root: {
		flexDirection: 'column-reverse!important',
	},
	labelContainer: {
		height: '47px',
		marginBottom: '1em',
	},
	label: {
		fontWeight: '500',
		color: 'var(--text-dark)',
	},
	completed: {
		color: 'var(--text-dark)!important',
	},
	active: {
		color: 'var(--primary)!important',
	},
})(StepLabel);

export const Text = styled(Typography)`
	font-size: ${(props) => props.fontSize};
	font-weight: ${(props) => props.fontWeight};
	color: ${(props) => props.textcolor || 'var(--text-dark)'};
	text-align: ${(props) => props.textalign};

	@media (max-width: 960px) {
		visibility: hidden;
	}
`;

export const QontoConnector = withStyles({
	alternativeLabel: {
		top: 10,
		left: 'calc(-50% + 12px)',
		right: 'calc(50% + 12px)',
	},
	active: {
		'& $line': {
			borderColor: 'var(--primary)',
		},
	},
	completed: {
		'& $line': {
			borderColor: 'var(--primary)',
		},
	},
	line: {
		borderColor: 'var(--text-grey)',
		borderTopWidth: 2,
		borderRadius: 1,
	},
})(StepConnector);

export const useQontoStepIconStyles = makeStyles({
	root: {
		color: 'var(--text-dark)',
		boxShadow: 'inset 0px 0px 0px 2px var(--text-grey)',
		borderRadius: '100%',
		height: '28px',
		width: '28px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '14px',
	},
	active: {
		background: 'var(--primary)',
		boxShadow: 'inset 0px 0px 0px 2px var(--primary)',
		borderRadius: '100%',
		height: '28px',
		width: '28px',
		color: '#fff',
		zIndex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	completed: {
		border: '2px solid var(--primary)',
		borderRadius: '100%',
		height: '24px',
		width: '24px',
		color: 'var(--primary)',
		zIndex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});
