import { Paper, withStyles } from '@material-ui/core';
import styled from 'styled-components';

export const SectionTableTop = styled.div`
	width: 100%;
	display: flex;

	h1 {
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: var(--text-dark);
	}
`;

export const SectionTopStart = styled.div`
	padding-left: 16px;
	display: flex;
	align-items: center;
`;

export const SectionTopEnd = styled.div`
	padding-right: 16px;
	display: flex;
	align-items: center;
`;

export const StyledPaper = withStyles({
	root: {
		width: '100%',
	},
})(Paper);
