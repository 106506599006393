function moneyMask(value) {
	let v = String(value).replace(/\D/g, '');
	v = `${(v / 100).toFixed(2)}`;
	return v
		.replace('.', ',')
		.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,')
		.replace(/(\d)(\d{3}),/g, '$1.$2,');
}

function percentMask(value) {
	let v = String(value).replace(/\D/g, '');
	v = `${(v / 100).toFixed(2)}`;
	return (
		v
			.replace('.', ',')
			.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,')
			.replace(/(\d)(\d{3}),/g, '$1.$2,') + '%'
	);
}

function telMask(value) {
	return value
		.replace(/\D/g, '')
		.replace(/(\d{2})(\d)/, '($1) $2')
		.replace(/(\d{4})(\d)/, '$1-$2')
		.replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
		.replace(/(-\d{4})\d+?$/, '$1');
}

function cnpjMask(value) {
	return value
		.replace(/\D/g, '')
		.replace(/(\d{2})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1/$2')
		.replace(/(\d{4})(\d)/, '$1-$2')
		.replace(/(-\d{2})\d+?$/, '$1');
}

function maskFields(fields, field) {
	return (fields.porcent || []).includes(field.name) ? percentMask(field.value) :
		(fields.money || []).includes(field.name) ? moneyMask(field.value) :
			(fields.tel || []).includes(field.name) ? telMask(field.value) :
				(fields.cnpj || []).includes(field.name) ? cnpjMask(field.value) :
					field.value
		;
}

function maskValue(type, value) {
	switch (type) {
		case 'money':
			return moneyMask(value);
		case 'percent':
			return percentMask(value);
		case 'tel':
			return telMask(value);
		case 'cnpj':
			return cnpjMask(value);
		default: return value;
	}
}

export {
	moneyMask,
	percentMask,
	telMask,
	cnpjMask,
	maskFields,
	maskValue,
};
