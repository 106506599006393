import { Typography, Grid, withStyles } from '@material-ui/core';

export const Title = withStyles({
	root: {
		width: '100%',
		padding: '10px 20px',
		marginBottom: '15px',
		borderBottom: '1px solid var(--text-grey2)',
	},
})(Typography);

export const StyledGrid = withStyles({
	container: {
		backgroundColor: '#fff',
		marginTop: '20px',
		padding: '10px 0px',
	},
})(Grid);
