import React from 'react';
import { Cell, Text, Link } from './style';
import PropTypes from 'prop-types';

function TableCell(props) {
	const {
		children,
		background,
		fontSize,
		fontWeight,
		lineheight,
		color,
		bordertop,
		borderbottom,
		borderleft,
		borderright,
		textdecorationline,
		cursor,
		onClick,
		link,
		height,
		padding,
		minwidth,
		chip,
		chipStyle,
		width
	} = props;

	function setChipStyle() {
		switch (chipStyle) {
			case 'red':
				return { chipcolor: 'rgba(201, 80, 80, 0.16)', color: 'var(--error)' };
			case 'green':
				return {
					chipcolor: 'rgba(101, 172, 40, 0.25)',
					color: 'var(--success)',
				};
			case 'blue':
				return {
					chipcolor: 'rgba(80, 130, 201, 0.12)',
					color: 'var(--primary)',
				};
			case 'orange':
				return {
					chipcolor: 'rgba(255, 189, 20, 0.16)',
					color: 'var(--orange)',
				};
			case 'SILVER':
				return {
					chipcolor: 'rgba(196, 196, 196, 0.2)',
					color: 'var(--text-grey)',
				};
			case 'GOLD':
				return {
					chipcolor: 'rgba(255, 189, 20, 0.16)',
					color: 'var(--orange)',
				};
			case 'grey':
				return {
					chipcolor: 'rgba(196, 196, 196, 0.2)',
					color: 'var(--text-dark)',
				};
			case 'none':
				return { chipcolor: '', color };
			default:
				return { chipcolor: '', color };
		}
	}

	return (
		<Cell
			background={background}
			fontSize={fontSize}
			fontWeight={fontWeight}
			lineheight={lineheight}
			color={setChipStyle().color}
			bordertop={bordertop}
			borderbottom={borderbottom}
			borderleft={borderleft}
			borderright={borderright}
			textdecorationline={textdecorationline}
			cursor={cursor}
			onClick={onClick}
			height={height}
			padding={padding}
			minwidth={minwidth}
			width={width}
		>
			{link ? (
				<Link href={link} chip={chip} chipcolor={setChipStyle().chipcolor}>
					{children}
				</Link>
			) : (
				<Text chip={chip} chipcolor={setChipStyle().chipcolor}>
					{children}
				</Text>
			)}
		</Cell>
	);
}

export default TableCell;

TableCell.propTypes = {
	children: PropTypes.any,
	background: PropTypes.string,
	fontSize: PropTypes.string,
	fontWeight: PropTypes.string,
	lineheight: PropTypes.string,
	color: PropTypes.string,
	bordertop: PropTypes.string,
	borderbottom: PropTypes.string,
	borderleft: PropTypes.string,
	cursor: PropTypes.string,
	onClick: PropTypes.func,
	link: PropTypes.bool,
	height: PropTypes.string,
	minwidth: PropTypes.string,
	chip: PropTypes.bool,
	chipcolor: PropTypes.string,
	chipStyle: PropTypes.oneOf([
		'red',
		'green',
		'blue',
		'orange',
		'silver',
		'gold',
		'none',
		'grey',
	]),
};

TableCell.defaultProps = {
	background: '#fff',
	fontSize: '12px',
	fontWeight: '500',
	lineheight: '14px',
	color: 'var(--text-dark)',
	bordertop: 'none',
	borderbottom: 'none',
	borderleft: 'none',
	borderright: 'none',
	textdecorationline: 'none',
	cursor: 'auto',
	onClick: () => {},
	link: false,
	height: '36px',
	chip: false,
	chipStyle: 'none',
};

// 1px solid #E5E5E5
