export const serviceChannelOptions = [
	{ text: 'Hospital', value: 'Hospital' },
	{ text: 'Hospital maternidade', value: 'Hospital maternidade' },
	{ text: 'Programa social', value: 'Programa social' },
	{ text: 'Creche', value: 'Creche' },
];

export const yesNoOptions = [
	{ label: 'Sim', value: 'sim' },
	{ label: 'Não', value: 'não' },
];

export const regionalOptions = [
	{ text: 'SSP', value: 'SSP' },
	{ text: 'CNN', value: 'CNN' },
];

export const classificationOptions = [
	{ text: 'Gold', value: 'Gold' },
	{ text: 'Silver', value: 'Silver' },
	{ text: 'Não se aplica', value: 'Não se aplica' },
];
