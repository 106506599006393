import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
	padding-top: 2em;
	margin-left: 24px;
	margin-right: 24px;
	height: 400px;
	overflow: auto;
	overflow-x: hidden;

	::-webkit-scrollbar {
		width: 5px;
	}
	::-webkit-scrollbar-track {
		border-radius: 3px;
	}
	::-webkit-scrollbar-thumb {
		background: #c4c4c4;
		border-radius: 3px;
	}
`;

export const BannerRight = styled(Grid)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	color: black;
	margin-top: -130px;

	span {
		padding-top: 1em;
		padding-left: 2em;
		padding-right: 2em;

		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 135%;
	}

	@media (max-width: 960px) {
		margin-top: 0px;
	}
`;

export const BannerHead = styled.div`
	height: 100%;
	padding: 1.5rem 1.5rem;

	background: #eaeef6 80%;
	border-radius: 4px 4px 0px 0px;

	display: flex;
	justify-content: space-around;
	align-items: flex-start;
`;
