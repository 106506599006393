export function setEmployee(authentication) {
	return {
		type: '@user/LOGIN_EMPLOYEE',
		payload: authentication,
	};
}
export function setDistributor(authentication) {
	return {
		type: '@user/LOGIN_DISTRIBUTOR',
		payload: authentication,
	};
}

export function setAdmin(authentication) {
	return {
		type: '@user/LOGIN_ADMIN',
		payload: authentication,
	};
}

export function setPendencies(pendencies) {
	return {
		type: '@user/SET_PENDENCIES',
		payload: pendencies,
	};
}

export function updateUser(user) {
	return {
		type: '@user/UPDATE_USER',
		payload: user,
	}
};

export function updatePicture(user) {
	return {
		type: '@user/UPDATE_PICTURE',
		payload: user,
	}
}
