import UISelect from 'components/UI/Select/Select';
import React, { useEffect, useState } from 'react';

import mock from 'assets/mock.json';
import UploadArea from 'components/Upload/UploadArea/UploadArea';
import UploadList from 'components/Upload/UploadList/UploadList';
import { BannerHead, BannerRight, Container } from './style';
import { store } from 'store';
import api from 'services/api';
import { Box } from '@material-ui/core';
import uploadDocument from 'assets/images/uploadDocument.svg';

export default function UploadSection(height) {
	const { documentTypes } = mock;
	const { process } = store.getState().process;

	const [attachmentsDefault, setAttachmentsDefault] = useState([]);
	const [attachments, setAttachments] = useState([]);
	const [labelFile, setLabelFile] = useState('');
	const [itemsConfigs, setItemsConfigs] = useState();
	const [selectedDocumentType, setSelectedDocumentType] = useState(null);
	const [msgErrorCombo, setMsgErrorCombo] = useState(null);
	const [refresh, setRefresh] = useState(false);

	//buscar informações do processo e anexos
	useEffect(() => {
		async function getAttachments() {
			try {
				const response = await api.get(`attachments`, {
					params: {
						table: 'process',
						tableId: process.id,
					},
				});

				const result = response.data.data.map((att) => {
					return {
						...att,
						url: att.key,
						success: true,
					};
				});

				setAttachmentsDefault(result);
			} catch (error) {
				return null;
			}
		}

		getAttachments();
	}, [process]);

	//adiciona os anexos do processo no novo array para upload
	useEffect(() => {
		setAttachments((att) => {
			return [...att, ...attachmentsDefault];
		});
	}, [attachmentsDefault]);

	//renderiza mensagem de erro no upload do arquivo
	useEffect(() => {
		if (selectedDocumentType === '') {
			setMsgErrorCombo('Selecione um tipo de documento');
		} else if (selectedDocumentType === false) {
			setMsgErrorCombo('');
		} else {
			setMsgErrorCombo('');
		}
	}, [selectedDocumentType]);

	const uploadAttachment = async (data) => {
		try {
			let formData = new FormData();
			formData.append('file', data.file);
			formData.append('table', 'process');
			formData.append('tableId', process.id);
			formData.append('category', selectedDocumentType);

			const response = await api.post(`attachments`, formData);

			return {
				...itemsConfigs,
				id: response.data.id,
				url: response.data.key,
				category: response.data.category,
			};
		} catch (error) {
			return null;
		}
	};

	const addAttachment = async () => {
		if (itemsConfigs) {
			if (
				!msgErrorCombo &&
				selectedDocumentType !== '' &&
				selectedDocumentType !== null
			) {
				const file = await uploadAttachment(itemsConfigs);
				const newItems = attachments;
				newItems.push(file);
				setAttachments(newItems);
				setSelectedDocumentType(null);
				setItemsConfigs(null);
				setLabelFile('');
			}
		}
	};

	const deleteAttachment = async (id) => {
		try {
			await api.delete(`attachments/${id}`);

			const files = attachments;

			files.splice(
				files.findIndex((file) => file.id === id),
				1,
			);

			setAttachments(files);
			setRefresh(!refresh);
		} catch (error) {
			return null;
		}
	};

	return (
		<>
			<BannerRight item xs={12}>
				<BannerHead className="header-form">
					<div className="img">
						<img src={uploadDocument} alt="" />
					</div>
					<span>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate,
						ab? Rem incidunt, sunt in iusto culpa, saepe enim commodi cum
						repellendus ut et sit quam itaque, beatae obcaecati at ea?
					</span>
				</BannerHead>
			</BannerRight>
			<Container>
				<UISelect
					items={documentTypes}
					label="Documento"
					value={selectedDocumentType}
					onChange={(e) => setSelectedDocumentType(e.target.value)}
					msgError={msgErrorCombo}
					error={msgErrorCombo}
				/>
				<Box mt={4}>
					<UploadArea
						height="265px"
						labelText={labelFile}
						onChange={(param) => {
							const file = param[0];
							setItemsConfigs({
								name: file.name,
								size: String(file.size),
								showBars: true,
								success: param.success,
								file,
							});
							setLabelFile(
								`Arquivo selecionado: ${String(file.name).substr(
									0,
									30,
								)}.../pdf`,
							);
						}}
						buttonOnClick={() => {
							if (selectedDocumentType == null) {
								return setSelectedDocumentType('');
							}

							return addAttachment();
						}}
					/>
				</Box>
				<UploadList
					itemsList={attachments}
					titleList="Anexos inseridos"
					deleteAttachment={deleteAttachment}
				/>
			</Container>
		</>
	);
}
