import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Box, Grid } from '@material-ui/core';

import HeaderScreens from 'screens/Admin/components/HeaderScreens';
import NavTabs from 'components/Navigation/Tabs/Tabs';
import Card from 'components/Cards';
import Modal from 'components/Modals';
import AddUserModal from '../components/AddUserModal';
import SelectSubstituteUserModal from '../components/SelectSubstituteUserModal';
import SelectSubstituteDistributorModal from '../components/SelectSubstituteDistributorModal';

import { breadcrumbItems } from './selectItems';

import { useDispatch } from 'react-redux';
import { setLoading } from 'store/modules/app/actions';
import api from 'services/api';
import UIPagination from 'components/UI/Pagination';

export default function User() {
	const history = useHistory();

	const dispatch = useDispatch();

	const [refresh, setRefresh] = useState(false);

	const [users, setUsers] = useState({});
	const [allUsers, setAllUsers] = useState({});
	const [selectedUser, setSelectedUser] = useState({});
	const [paramsUsers, setParamsUsers] = useState({
		'status[$ne]': 'inactive',
	});

	const [searchValue, setSearchValue] = useState('');
	const [activeTab, setActiveTab] = useState(0);
	const [showAddUserModal, setShowAddUserModal] = useState(false);

	const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
	const [
		showSelectSubstituteUserModal,
		setShowSelectSubstituteUserModal,
	] = useState(false);
	const [showDeleteUserSuccessModal, setShowDeleteUserSuccessModal] = useState(
		false,
	);

	const [showDeleteDistributorModal, setShowDeleteDistributorModal] = useState(
		false,
	);
	const [
		showSelectSubstituteDistributorModal,
		setShowSelectSubstituteDistributorModal,
	] = useState(false);

	const [
		showSelectSubstituteUserForVacationModal,
		setShowSelectSubstituteUserForVacationModal,
	] = useState(false);
	const [showVacationSuccessModal, setShowVacationSuccessModal] = useState(
		false,
	);

	const [showCancelVacationModal, setShowCancelVacationModal] = useState(false);
	const [
		showCancelVacationSuccessModal,
		setShowCancelVacationSuccessModal,
	] = useState(false);

	const [showReactivateUserModal, setShowReactivateUserModal] = useState(false);
	const [
		showReactivateUserSuccessModal,
		setShowReactivateUserSuccessModal,
	] = useState(false);

	useEffect(() => {
		getUsers();
	}, [dispatch, paramsUsers, history]);

	const handleChangeTab = (e, newValue) => {
		switch (newValue) {
			case 0:
				setParamsUsers({
					'status[$ne]': 'inactive',
				});
				break;
			case 1:
				setParamsUsers({
					'distributorId[$ne]': 'null',
					'status[$ne]': 'inactive',
				});
				break;
			case 2:
				setParamsUsers({
					'employee[accessLevel]': 'consultant',
					'status[$ne]': 'inactive',
				});
				break;
			case 3:
				setParamsUsers({
					'employee[accessLevel]': 'supervisor',
					'status[$ne]': 'inactive',
				});
				break;
			case 4:
				setParamsUsers({
					'employee[accessLevel]': 'manager',
					'status[$ne]': 'inactive',
				});
				break;
			case 5:
				setParamsUsers({
					'employee[accessLevel]': 'financier',
					'status[$ne]': 'inactive',
				});
				break;
			case 6:
				setParamsUsers({
					'employee[accessLevel]': 'admin',
					'status[$ne]': 'inactive',
				});
				break;
			case 8:
				setParamsUsers({ status: 'inactive' });
				break;
			default:
				break;
		}

		setActiveTab(newValue);
	};

	const getUsers = async () => {
		try {
			dispatch(setLoading(true));
			const response = await api.get(`access`, {
				params: {
					...paramsUsers,
					$limit: 12,
				},
			});

			setUsers({
				total: response.data.total,
				list: response.data.data.map((user) => {
					return {
						...user,
						onPencilClick: () => history.push(`/editar/${user.id}`),
						onTrashClick: () => {
							setSelectedUser(user);
							user.distributorId
								? setShowDeleteDistributorModal(true)
								: setShowDeleteUserModal(true);
						},
						menuItems: [
							{
								text:
									user.status === 'active'
										? 'Inserir período de férias'
										: user.status === 'vacancy'
											? 'Suspender período de férias'
											: 'Reativar usuário',
								onClick: () => {
									setSelectedUser(user);
									user.status === 'active'
										? setShowSelectSubstituteUserForVacationModal(true)
										: user.status === 'vacancy'
											? setShowCancelVacationModal(true)
											: setShowReactivateUserModal(true);
								},
							},
						],
					};
				}),
			});
			setAllUsers({
				total: response.data.total,
				list: response.data.data.map((user) => {
					return {
						...user,
						onPencilClick: () => history.push(`/editar/${user.id}`),
						onTrashClick: () => {
							setSelectedUser(user);
							user.distributorId
								? setShowDeleteDistributorModal(true)
								: setShowDeleteUserModal(true);
						},
						menuItems: [
							{
								text:
									user.status === 'active'
										? 'Inserir período de férias'
										: user.status === 'vacancy'
											? 'Suspender período de férias'
											: 'Reativar usuário',
								onClick: () => {
									setSelectedUser(user);
									user.status === 'active'
										? setShowSelectSubstituteUserForVacationModal(true)
										: user.status === 'vacancy'
											? setShowCancelVacationModal(true)
											: setShowReactivateUserModal(true);
								},
							},
						],
					};
				}),
			});
		} catch (error) {
			return error;
		} finally {
			dispatch(setLoading(false));
		}
	};

	const sendStatusUser = async (user, status) => {
		try {
			if (status === 'vacancy' || status === 'inactive')
				await api.post(`users-status`, {
					...user,
					status,
				});

			if (status === 'active') {
				const response = await api.get(`users-status`, {
					params: { 'status[$ne]': 'active', oldAccessId: user.id, $limit: 1 },
				});

				await api.patch(`users-status/${response.data.data[0].id}`, {
					status,
				});
			}

			setShowSelectSubstituteDistributorModal(false);
			setShowSelectSubstituteUserForVacationModal(false);
			setShowDeleteDistributorModal(false);
			setShowDeleteUserModal(false);
			setShowSelectSubstituteUserForVacationModal(false);
			setShowCancelVacationModal(false);
			setShowSelectSubstituteUserModal(false);
			setShowReactivateUserModal(false);
			getUsers();
		} catch (error) {
			throw error;
		}
	};

	useEffect(() => {
		const objToArray = object => Object.keys(object || {}).map(key => object[key]);
		const recursive = data => {
			const response = [];
			const array = objToArray(data);
			const haveMore = array.filter(item => Object.keys(item || {}).length > 0 && typeof (item) != "string");
			if (haveMore.length > 0) {
				const arrayCopy = array;
				haveMore.map(item => {
					arrayCopy.splice(array.indexOf(item), 1);
					recursive(item).filter(item => item != null && item != undefined).map(result => response.push(result));
				});
				arrayCopy.filter(item => item != null && item != undefined).map(result => response.push(result));
			}
			else array.filter(item => item != null && item != undefined).map(item => response.push(item));
			return response;
		};

		if (searchValue) {
			const current = String(searchValue).toLocaleLowerCase();
			const newProcess = [...allUsers.list];
			const found = [];
			newProcess.map(process => {
				// all process
				const array = recursive(objToArray(process));
				const values = array.findIndex(item => String(item).toLowerCase().indexOf(current) > -1);
				if (values > -1) found.push(process);
			});
			if (found) setUsers({ list: found });
		} else setUsers({ list: allUsers.list });
	}, [searchValue]);

	return (
		<Box p={3}>
			<HeaderScreens
				titleScreen="Usuários"
				dtUpdated="Adicionado em: 24/04/19 - 19:12"
				buttonText="Adicionar Usuários"
				buttonOnClick={() => setShowAddUserModal(true)}
				currentBreadItem={breadcrumbItems}
				searchPlaceholder="Pesquisar usuários..."
				searchValue={searchValue}
				searchOnChange={(e) => {
					setSearchValue(e.target.value);
				}}
			/>
			<NavTabs
				tabs={tabs}
				rightTabs={rightTabs}
				activeTab={activeTab}
				onChange={handleChangeTab}
			/>
			<Box py={2}>
				<Grid container spacing={2}>
					{/* repetir estrutura abaixo */}
					{(users.list || []).map((user) => (
						<Grid item xs={12} sm={4} lg={3} key={user.id}>
							<Card variant="user" informations={user} />
						</Grid>
					))}
				</Grid>
			</Box>
			<Box display="flex" justifyContent="flex-end" py={2}>
				<UIPagination
					count={Math.ceil(users.total / 12)}
					size="medium"
					onChange={(e, value) =>
						setParamsUsers((params) => {
							return {
								...params,
								$skip: (value - 1) * 12,
							};
						})
					}
				/>
			</Box>
			<AddUserModal
				show={showAddUserModal}
				onClose={() => setShowAddUserModal(false)}
			/>

			<Modal
				variant="error"
				open={showDeleteUserModal}
				onClose={() => setShowDeleteUserModal(false)}
				title="Deseja suspender o usuário?"
				subtitle="Necessário definir um usuário par passar os processos do usuário a ser suspendo"
				buttonCancelAction={() => setShowDeleteUserModal(false)}
				buttonConfirmAction={() => setShowSelectSubstituteUserModal(true)}
			/>

			<SelectSubstituteUserModal
				show={showSelectSubstituteUserModal}
				onClose={() => setShowSelectSubstituteUserModal(false)}
				onConfirm={(user) => {
					sendStatusUser(user, 'inactive');
					setShowDeleteUserSuccessModal(true);
				}}
				user={selectedUser}
			/>

			<Modal
				variant="success"
				open={showDeleteUserSuccessModal}
				onClose={() => setShowDeleteUserSuccessModal(false)}
				title="Suspensão efetuada com sucesso"
				buttonText="Voltar a tela inicial"
				buttonAction={() => setShowDeleteUserSuccessModal(false)}
			/>

			<Modal
				variant="error"
				open={showDeleteDistributorModal}
				onClose={() => setShowDeleteDistributorModal(false)}
				title="Deseja suspender o distribuidor?"
				subtitle="Necessário definir um outro distribuidor para passar os processos do distribuidor a ser suspenso"
				buttonCancelAction={() => setShowDeleteDistributorModal(false)}
				buttonConfirmAction={() =>
					setShowSelectSubstituteDistributorModal(true)
				}
			/>

			<SelectSubstituteDistributorModal
				show={showSelectSubstituteDistributorModal}
				onClose={() => setShowSelectSubstituteDistributorModal(false)}
				onConfirm={(user) => {
					sendStatusUser(user, 'inactive');
					setShowDeleteUserSuccessModal(true);
				}}
				user={selectedUser}
			/>

			<SelectSubstituteUserModal
				show={showSelectSubstituteUserForVacationModal}
				showPeriod
				onClose={() => setShowSelectSubstituteUserForVacationModal(false)}
				onConfirm={(user) => {
					sendStatusUser(user, 'vacancy');
					setShowVacationSuccessModal(true);
				}}
				user={selectedUser}
			/>

			<Modal
				variant="success"
				open={showVacationSuccessModal}
				onClose={() => {
					setShowVacationSuccessModal(false);
				}}
				title="Férias definidas com sucesso"
				buttonText="Voltar a tela inicial"
				buttonAction={() => setShowVacationSuccessModal(false)}
			/>

			<Modal
				variant="error"
				open={showCancelVacationModal}
				onClose={() => setShowCancelVacationModal(false)}
				title="Deseja suspender o período de férias do usuário?"
				buttonCancelAction={() => setShowCancelVacationModal(false)}
				buttonConfirmAction={() => {
					sendStatusUser(selectedUser, 'active');
					setShowCancelVacationSuccessModal(true);
				}}
			/>

			<Modal
				variant="success"
				open={showCancelVacationSuccessModal}
				onClose={() => {
					setShowCancelVacationModal(false);
					setShowCancelVacationSuccessModal(false);
				}}
				title="Férias suspensas com sucesso"
				buttonText="Voltar a tela inicial"
				buttonAction={() => setShowCancelVacationSuccessModal(false)}
			/>

			<Modal
				variant="error"
				open={showReactivateUserModal}
				onClose={() => setShowReactivateUserModal(false)}
				title="Deseja reativar o usuário ao sistema?"
				colorIcon="#C4C4C4"
				buttonCancelAction={() => setShowReactivateUserModal(false)}
				buttonConfirmAction={() => {
					sendStatusUser(selectedUser, 'active');
					setShowReactivateUserSuccessModal(true);
				}}
			/>

			<Modal
				variant="success"
				open={showReactivateUserSuccessModal}
				onClose={() => {
					setShowReactivateUserModal(false);
					setShowReactivateUserSuccessModal(false);
				}}
				title="Usuário reativado com sucesso"
				buttonText="Voltar aos usuários"
				buttonAction={() => setShowReactivateUserSuccessModal(false)}
			/>
		</Box>
	);
}

const tabs = [
	{
		label: 'Todos',
		color: 'var(--primary)',
	},
	{
		label: 'Distribuidor',
		color: 'var(--primary)',
	},
	{
		label: 'Consultor',
		color: 'var(--primary)',
	},
	{
		label: 'Supervisor',
		color: 'var(--primary)',
	},
	{
		label: 'Gerente',
		color: 'var(--primary)',
	},
	{
		label: 'Financeiro',
		color: 'var(--primary)',
	},
	{
		label: 'Administrador',
		color: 'var(--primary)',
	},
];

const rightTabs = [{ label: 'Inativos', color: 'var(--primary)' }];
