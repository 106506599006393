import { Dialog } from '@material-ui/core';
import styled from 'styled-components';

export const MContainer = styled(Dialog)`
	.MuiDialog-paperWidthSm {
		max-width: 100% !important;
		overflow-x: hidden;
		::-webkit-scrollbar {
			width: 5px;
		}
		::-webkit-scrollbar-track {
			border-radius: 3px;
		}
		::-webkit-scrollbar-thumb {
			background: #c4c4c4;
			border-radius: 3px;
		}
	}
`;

const widths = {
	small: '456px',
	medium: '583px',
	normal: '643px',
};

export const MBody = styled.div`
	width: ${(props) => widths[props.width] || widths.normal};
	background-color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	@media (max-width: 960px) {
		width: auto;
	}
`;

export const MHeader = styled.div`
	background-color: #fff;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0.5em 1em;
	button {
		margin-right: 1em;
		padding: 0px;
	}
`;

export const MFooter = styled.div`
	margin-bottom: 2em;
`;
