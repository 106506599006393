import React from 'react';

import SmallCard from 'components/Cards/SmallCard/index';
import ActionCard from 'components/Cards/ActionCard/index';
import ContactCard from 'components/Cards/ContactCard/index';
import UserCard from 'components/Cards/UserCard/index';
import DisabledContactCard from 'components/Cards/DisabledContactCard/index';
import ProductCards from 'components/Cards/ProductCard/index';
import DisabledProductCards from 'components/Cards/DisabledProductCard/index';

export default function Card({ variant, informations }) {
	if (variant === 'small') {
		return <SmallCard informations={informations} />;
	}

	if (variant === 'action') {
		return <ActionCard informations={informations} />;
	}

	if (variant === 'contact') {
		return <ContactCard informations={informations} />;
	}

	if (variant === 'contact-disabled') {
		return <DisabledContactCard informations={informations} />;
	}

	if (variant === 'user') {
		return <UserCard informations={informations} />;
	}

	if (variant === 'product') {
		return <ProductCards informations={informations} />;
	}

	if (variant === 'product-disabled') {
		return <DisabledProductCards informations={informations} />;
	}
}
