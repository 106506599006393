import { Checkbox } from '@material-ui/core';
import React from 'react';

import { StyledLabel } from './style';

function UICheckbox({
	checked,
	color = 'primary',
	label,
	fontWeight,
	fontSize,
	size = 'small',
}) {
	return (
		<StyledLabel
			control={<Checkbox checked={checked} color={color} size={size} />}
			label={label}
			fontWeight={fontWeight}
			fontSize={fontSize}
		/>
	);
}

export default UICheckbox;
