import React from 'react';

import { StyledButton } from './style';

function UIButton({
	variant = 'contained',
	color = 'primary',
	textcolor,
	text,
	type,
	onClick,
	width,
	height,
	fontSize,
	fullWidth,
	texttransform,
	borderwidth,
	...rest
}) {
	return (
		<StyledButton
			variant={variant}
			color={color}
			textcolor={textcolor}
			width={width}
			height={height}
			fontSize={fontSize}
			onClick={onClick}
			fullWidth={fullWidth}
			type={type}
			texttransform={texttransform}
			borderwidth={borderwidth}
			{...rest}
		>
			{text}
		</StyledButton>
	);
}

export default UIButton;
