import React, { useState } from 'react';

import { Document, Page, pdfjs } from 'react-pdf';
import { Modal, Backdrop, Grid, Box } from '@material-ui/core';
import Scrollbar from 'react-scrollbar';

import { StyledFade, useStyles } from 'components/File/ModalFile/styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ModalFile({ urlFile, isVisible, onClose, currentPage }) {
	const classes = useStyles();

	const [, setNumPages] = useState(null);
	const [fullWidthModal, setFullWidthModal] = useState();

	function onDocumentLoadSuccess({ numPages }) {
		let width = window.innerWidth / 1.5;
		if (width < 500) width = window.innerWidth - 50;
		setFullWidthModal(width);
		setNumPages(numPages);
	}

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={isVisible}
				onClose={onClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<StyledFade in={isVisible}>
					<Grid
						container
						direction="column"
						justify="center"
						alignItems="center"
						onClick={onClose}
					>
						<Grid item xs={12} sm={10} onClick={e => e.stopPropagation()}>
							<Box>
								<Scrollbar className={classes.scrollbar} autoHide={false}>
									<Box id="fileModal">
										<Document
											file={{
												url: urlFile,
											}}
											onLoadSuccess={onDocumentLoadSuccess}
										>
											<Page
												pageNumber={currentPage === 0 ? 1 : currentPage}
												width={fullWidthModal}
											/>
										</Document>
									</Box>
								</Scrollbar>
							</Box>
						</Grid>
					</Grid>
				</StyledFade>
			</Modal>
		</div>
	);
}
