import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';

import NavBreadcrumbs from 'components/Navigation/Breadcrumbs/Breadcrumbs';
import UIButton from 'components/UI/Button/Button';
import UIInput from 'components/UI/Input/Input';
import UIRadio from 'components/UI/Radio/Radio';
import UISelect from 'components/UI/Select/Select';
import UIAutocomplete from 'components/UI/Autocomplete/index';
import Modal from 'components/Modals';

import api from 'services/api';
import { useDispatch } from 'react-redux';
import { setLoading } from 'store/modules/app/actions';
import mock from './mock.json';
import { telMask, cnpjMask } from 'services/mask';
import { StyledTextField } from 'screens/Process/components/DistributorCreating/Fields/DocumentSolicitation/styles';
import { createFilterOptions } from '@material-ui/lab';

export default function CreateInstitution() {
	const history = useHistory();
	const dispatch = useDispatch();
	const { institutionId } = useParams();

	const [institution, setInstitution] = useState({});
	const [successModal, setSuccessModal] = useState(false);
	const [inputError, setInputError] = useState({
		name: '',
		email: '',
		telephone: '',
		regional: '',
		cnpj: '',
		social: '',
		code: '',
		segment: '',
		uf: '',
		city: '',
		serviceChannel: '',
		class: '',
	});

	const [allCities, setAllCities] = useState([]);
	const [cities, setCities] = useState([]);
	const [ufs, setUfs] = useState([]);

	const breadcrumbsItems = [
		{ text: 'Dashboard', href: '/instituicao' },
		{ text: 'Instituições' },
	];

	useEffect(() => {
		async function get() {
			try {
				const response = await api.get(`institution/${institutionId}`);

				setInstitution(response.data);
			} catch (error) {
				return error;
			}
		}
		if (institutionId) get();
	}, [institutionId]);

	// get locations
	useEffect(() => {
		async function getCities() {
			try {
				const response = await api.get(`cities`, {
					params: {
						$limit: -1,
					},
				});
				setAllCities(
					(response.data || []).map((city) => {
						return {
							name: city.city,
							uf: city.uf,
						};
					}),
				);
			} catch (error) {
				return null;
			}
		}

		async function getUfs() {
			try {
				const response = await api.get(`cities`, {
					params: {
						$limit: -1,
						$distinct: 'uf',
					},
				});
				setUfs(
					(response.data || [])
						.map((uf) => String(uf.uf).toUpperCase())
						.sort()
						.map((item) => {
							return {
								title: item,
								value: item,
							};
						}),
				);
			} catch (error) {
				return null;
			}
		}
		getCities();
		getUfs();
	}, []);
	// filter cities
	useEffect(() => {
		if (institution.uf) {
			const parsedCities = allCities.filter(
				(city) => city.uf === institution.uf,
			);
			setCities(
				(parsedCities || [])
					.map((city) => String(city.name).toUpperCase())
					.sort()
					.map((item) => {
						return {
							title: item,
							value: item,
						};
					}),
			);
		} else if (institution.uf === undefined) {
			setCities([]);
			setInstitution((institution) => {
				return {
					...institution,
					city: '',
				};
			});
		}
	}, [allCities, institution.uf, ufs]);

	const validateInputErrors = () => {
		const inputErrorKeys = Object.keys(inputError);
		return inputErrorKeys.forEach((inputKey) => {
			if (institution[inputKey]) {
				setInputError((inputError) => {
					return {
						...inputError,
						[inputKey]: '',
					};
				});
			}
		});
	};

	const validateFields = () => {
		const inputErrorKeys = Object.keys(inputError);
		return inputErrorKeys.some((inputKey) => {
			if (!institution[inputKey]) {
				setInputError((inputError) => {
					return {
						...inputError,
						[inputKey]: 'Preenchimento obrigatório',
					};
				});
				return true;
			}
			return false;
		});
	};

	const handleChange = (e) => {
		setInstitution((institution) => {
			return {
				...institution,
				[e.target.name]: e.target.value,
			};
		});
		validateInputErrors();
	};

	const submit = async () => {
		try {
			if (!validateFields()) {
				dispatch(setLoading(true));

				if (institutionId)
					await api.patch(`institution/${institutionId}`, institution);
				else await api.post(`institution`, institution);

				dispatch(setLoading(false));
				handleSuccessModal();
			}
		} catch (error) {
			dispatch(setLoading(false));
		}
	};

	const handleSuccessModal = () => {
		setSuccessModal(!successModal);
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<NavBreadcrumbs items={breadcrumbsItems} />
			</Grid>
			<Grid item xs={12}>
				<Typography variant="h1">
					{institutionId ? 'Editar' : 'Adicionar'} instituição
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Box pb={6}>
					<Paper elevation={0}>
						<Box p={2}>
							<Typography variant="h4">Informações da instituição</Typography>
						</Box>
						<Divider />
						<Box p={2}>
							<Grid container spacing={4}>
								<Grid item xs={12} sm={6} md={4}>
									<UIInput
										label="Nome"
										name="name"
										value={institution.name || ''}
										onChange={handleChange}
										error={inputError.name}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<UIInput
										label="Email"
										type="email"
										name="email"
										value={institution.email || ''}
										onChange={handleChange}
										error={inputError.email}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<UIInput
										label="Telefone"
										name="telephone"
										value={institution.telephone || ''}
										onChange={(e) =>
											handleChange({
												target: {
													name: 'telephone',
													value: telMask(e.target.value),
												},
											})
										}
										error={inputError.telephone}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<UISelect
										label="Regional"
										name="regional"
										value={institution.regional || ''}
										items={mock.regional}
										onChange={handleChange}
										error={inputError.regional}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<UIInput
										label="CNPJ"
										name="cnpj"
										value={institution.cnpj || ''}
										onChange={(e) =>
											handleChange({
												target: {
													name: 'cnpj',
													value: cnpjMask(e.target.value),
												},
											})
										}
										error={inputError.cnpj}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<UIInput
										label="Razão social"
										name="social"
										value={institution.social || ''}
										onChange={handleChange}
										error={inputError.social}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<UIInput
										label="Sold"
										type="number"
										name="code"
										value={institution.code || ''}
										onChange={handleChange}
										error={inputError.code}
										onKeyDown={(evt) => {
											if (evt.key === 'e') return evt.preventDefault();
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<UISelect
										label="Segmento"
										name="segment"
										items={mock.segment}
										value={institution.segment || ''}
										onChange={handleChange}
										error={inputError.segment}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<UIAutocomplete
										label="UF"
										value={
											institution.uf
												? { title: institution.uf, value: institution.uf }
												: ''
										}
										onChange={(evt, newValue) => {
											setInstitution((institution) => {
												return {
													...institution,
													uf: (newValue || {}).title,
												};
											});
										}}
										getOptionLabel={(option) => option.title}
										filter
										options={ufs}
										renderInput={(params) => (
											<StyledTextField
												{...params}
												fullWidth
												InputLabelProps={{ shrink: true }}
												label="UF"
												error={inputError.uf}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<UIAutocomplete
										label="Munícipio"
										value={
											institution.city
												? { title: institution.city, value: institution.city }
												: ''
										}
										onChange={(evt, newValue) => {
											setInstitution((institution) => {
												return {
													...institution,
													city: (newValue || {}).title,
												};
											});
										}}
										getOptionLabel={(option) => option.title}
										filter
										options={cities}
										renderInput={(params) => (
											<StyledTextField
												{...params}
												fullWidth
												InputLabelProps={{ shrink: true }}
												label="Munícipio"
												error={inputError.city}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<UISelect
										label="Canal de atendimento"
										name="serviceChannel"
										items={mock.serviceChannel}
										value={institution.serviceChannel || ''}
										onChange={handleChange}
										error={inputError.serviceChannel}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<UISelect
										label="Classificação"
										name="class"
										items={mock.class}
										onChange={handleChange}
										value={institution.class || ''}
										error={inputError.class}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<UISelect
										label="Tipo hospital"
										items={mock.type}
										name="type"
										disabled={institution.serviceChannel !== 'Hospitalar'}
										value={
											institution.type &&
											institution.serviceChannel === 'Hospitalar'
												? institution.type
												: ''
										}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<UISelect
										label="Padronização"
										items={mock.standardization}
										value={
											institution.standardization &&
											institution.serviceChannel === 'Hospitalar'
												? institution.standardization
												: ''
										}
										name="standardization"
										onChange={handleChange}
										disabled={institution.serviceChannel !== 'Hospitalar'}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<UIRadio
										label="Munícipio foco"
										items={mock.yesNo}
										name="focus"
										disabled={
											![
												'Cesta básica',
												'Programa governamental',
												'Prefeitura',
											].includes(institution.serviceChannel)
										}
										onChange={(e) =>
											handleChange({
												target: {
													name: 'focus',
													value: e.target.value === 'sim' ? true : false,
												},
											})
										}
										value={institution.focus ? 'sim' : 'não'}
									/>
								</Grid>
							</Grid>
						</Box>
						<Box pt={3} pb={5} px={2} display="flex" justifyContent="flex-end">
							<UIButton
								text={
									institutionId ? 'Salvar alterações' : 'Adicionar instituição'
								}
								onClick={submit}
							/>
						</Box>
					</Paper>
				</Box>
			</Grid>
			<Modal
				variant="success"
				title={
					institutionId
						? 'Alteração feita com sucesso'
						: 'Instituição adicionada com sucesso'
				}
				buttonText="Voltar a tela inicial"
				buttonAction={() => history.push('/instituicao')}
				onClose={handleSuccessModal}
				open={successModal}
			/>
		</Grid>
	);
}
