import { Input, InputLabel, withStyles } from '@material-ui/core';

export const StyledInput = withStyles({
	root: {
		width: (props) => props.width,
		border: '1px solid var(--text-grey)',
	},
	formControl: {
		marginTop: '25px!important',
		padding: '10px',
	},
	underline: {
		'&:before': {
			border: 'none',
		},
	},
})(Input);

export const StyledInputLabel = withStyles({
	root: {
		color: (props) => props.color || 'var(--primary)',
		fontWeight: '400',
		fontSize: '20px',
	},
})(InputLabel);
