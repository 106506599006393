import { all } from 'redux-saga/effects';

import auth from './auth/saga';
import user from './user/saga';
import process from './process/saga';
import processProduct from './processProduct/saga';

export default function* rootSaga() {
	return yield all([auth, user, process, processProduct]);
}
