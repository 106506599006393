import styled from 'styled-components';

import { Grid } from '@material-ui/core';

export const PanelLeft = styled(Grid)`
	background-color: white;
	padding: 1.5em;
	height: 540px;
	margin-right: 2em;

	h1 {
		margin-top: 1em;
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		display: flex;
		align-items: center;

		color: #5082c9;
	}
	span {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 135%;
		padding-right: 1em;
	}
	.TextItem-descripition {
		padding-right: 2em;
	}
	.MuiDivider-root {
		margin-top: 1em;
		width: 112%;
		margin-left: -24px;
	}
`;

export const PanelRight = styled(Grid)`
	height: 588px;
	background-color: white;
	padding: 1.5em;

	@media (max-width: 960px) {
		margin-left: 0em;
		margin-top: 2em;
	}
`;
