import { withStyles, TextField, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const StyledTags = withStyles({
  root: {
    color: 'var(--primary) !important',
  }
})(Autocomplete);

export const StyledTextField = withStyles({
  root: {
    color: "var(--primary)",
    borderColor: "var(--primary)",
  }
})(TextField);

export const StyledChip = withStyles({
  root: {
    color: "#5082C9",
    fontSize: "10px",
    height: "20px",
    fontWeight: "500",
    background: "rgba(80, 130, 201, 0.16)",
  }
})(Chip);
