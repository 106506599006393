import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import LayoutDefault from '../screens/layouts/default';

import { store } from 'store';
export default function RouteWrapper({
	component: Component,
	isPrivate,
	noLayout,
	noContainer,
	...rest
}) {
	const Layout = LayoutDefault;
	const { access_token } = store.getState().auth;

	if (!access_token && isPrivate) return <Redirect to="/login" />;

	if (noLayout)
		return <Route {...rest} render={(props) => <Component {...props} />} />;

	if (noContainer)
		return (
			<Route
				{...rest}
				render={(props) => (
					<Layout noContainer>
						<Component {...props} />
					</Layout>
				)}
			/>
		);

	return (
		<Route
			{...rest}
			render={(props) => (
				<Layout>
					<Component {...props} />
				</Layout>
			)}
		/>
	);
}

RouteWrapper.propTypes = {
	isPrivate: PropTypes.bool,
	component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
		.isRequired,
};

RouteWrapper.defaultProps = {
	isPrivate: false,
};
