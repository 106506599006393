import { Grid, Paper } from '@material-ui/core';
import styled from 'styled-components';

const colors = {
	grey: '#C4C4C4',
	blue: '#5082C9',
	red: '#C95050',
};

export const Container = styled(Paper)`
	margin-left: 2em;
	margin-top: 24px;
	padding-top: 1em;
	padding-bottom: 1em;
	@media (max-width: 960px) {
		margin-left: 0px;
	}

	.MuiGrid-item {
		padding-left: 16px;
		padding-right: 16px;
	}
`;

export const OrderHeader = styled(Grid)`
	padding-bottom: 1em;
`;


export const StyledSpan = styled.span`
	color: ${(props) => (props.color && props.color ? colors[props.color] : '')};
	font-size: ${(props) => props.fontSize ?? '14px'};
`;

export const StyledP = styled.p`
	font-size: ${(props) => props.fontSize ?? '14px'};
	margin: ${(props) => props.margin ?? '0'};
	color: ${(props) => (props.color && props.color ? colors[props.color] : '')};
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

export const Specification = styled.div`
	background-color: #f8f4f4;
	margin-top: 1em;
	padding-left: 2em;
	height: 130px;
	padding-top: 1em;
	padding-right: 2em;
`;