import React, { useState, useEffect } from 'react';
import TableSkeleton from 'components/UI/Table/TableSkeleton';
import TableRow from 'components/UI/Table/TableRow';
import TableCell from 'components/UI/Table/TableCell';
import TableCellMenu from 'components/UI/Table/TableCellMenu';
import TablePaper from 'components/UI/Table/TablePaper';
import TablePagination from 'components/UI/Table/TablePagination';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getProcessProducts } from 'store/modules/processProduct/actions';

function TableItemsProcess(props) {
	const { limit, titles, menuItems } = props;
	const [itemsOfPage, setItemsOfPage] = useState([]);
	const [totalPages, setTotalPages] = useState(0);
	const [pageSelected, setPageSelected] = useState(0);

	const { idProcess } = useParams();
	const items = useSelector((state) => state.processProduct.products);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getProcessProducts({ processId: idProcess }));
	}, [idProcess, dispatch]);

	useEffect(() => {
		const ItemsOfSelectedPage = items.slice(
			pageSelected * limit,
			pageSelected * limit + limit,
		);
		setItemsOfPage(ItemsOfSelectedPage);

		setTotalPages(Math.ceil(items.length / limit));
	}, [pageSelected, items.length, items, limit]);

	const handleChangePage = (event, value) => {
		setPageSelected(value - 1);
	};

	return (
		<>
			<TablePaper
				title="Itens do processo"
				elementsTopEnd={
					<TablePagination count={totalPages} onChange={handleChangePage} />
				}
			>
				<TableSkeleton
					horizontalScroll={true}
					hideScrollbars={false}
					verticalScroll={false}
					head={
						<TableRow
							bordertop="1px solid #E5E5E5"
							borderbottom="1px solid #E5E5E5"
						>
							{(titles || []).map((title, index) => (
								<TableCell
									key={index}
									background="var(--primary)"
									color="#FFFFFF"
									borderright={index === 0 ? '1px solid #E5E5E5' : ''}
								>
									{title}
								</TableCell>
							))}
						</TableRow>
					}
					body={(itemsOfPage || []).map((item, index) => (
						<TableRow key={index} borderbottom="1px solid  var(--grey-opacity)">
							<TableCellMenu menuItems={menuItems} row={item}>
								{item.itemNumber || '-'}
							</TableCellMenu>
							<TableCell
								textdecorationline="underline"
								link
								borderleft="1px solid var(--grey-opacity)"
							>
								{item.specification || '-'}
							</TableCell>
							<TableCell fontWeight="400">
								{item.initialQuantity || '-'}
							</TableCell>
							<TableCell>{item.unity || '-'}</TableCell>
							<TableCell
								color={item.approve ? 'var(--success)' : 'var(--error)'}
								textdecorationline={item.approve === false && 'underline'}
								link={item.approve === false && true}
							>
								{item.approve ? 'Sim' : 'Não' || '-'}
							</TableCell>
							<TableCell fontWeight="400">
								{(item.product || {}).sku || '-'}
							</TableCell>
							<TableCell textdecorationline="underline">
								{(item.product || {}).description || '-'}
							</TableCell>
							<TableCell fontWeight="400">
								{item.markup ? `${item.markup}%` : '-'}
							</TableCell>
							<TableCell fontWeight="400">
								{item.discountValue ? `${item.discountValue}%` : '-'}
							</TableCell>
							<TableCell fontWeight="400">{item.priceList || '-'}</TableCell>
							<TableCell fontWeight="400">{item.initialValue || '-'}</TableCell>
						</TableRow>
					))}
				/>
			</TablePaper>
		</>
	);
}

export default TableItemsProcess;

TableItemsProcess.propTypes = {
	limit: PropTypes.number,
	titles: PropTypes.arrayOf(PropTypes.string),
	items: PropTypes.arrayOf(PropTypes.object),
	menuItems: PropTypes.arrayOf(PropTypes.object),
};

TableItemsProcess.defaultProps = {
	limit: 5,
	titles: [
		'item',
		'Especificação',
		'Quantidade',
		'Unidade',
		'Atende',
		'SKU',
		'Produto',
		'Markup',
		'Desconto',
		'(R$) Preço Lista',
		'(R$) Preço Inicial',
	],
};
