import { Grid, withStyles } from '@material-ui/core';

export const CardContainer = withStyles({
	root: {
		minWidth: '262px',
		height: '161px',
		background: '#fff',
		borderLeft: '5px solid #000',
		borderColor: (props) => props.borderColor || '',
		borderRadius: '3px',
	}
})(Grid);

export const PaddingGrid = withStyles({
	root: {
		padding: (props) => props.padding,
		margin: (props) => props.margin,
	}
})(Grid);
