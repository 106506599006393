import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@material-ui/core';
import { useHistory } from 'react-router';

import HeaderScreens from 'screens/Admin/components/HeaderScreens';
import Tabs from 'components/Navigation/Tabs/Tabs';

import CardPendencie from 'components/Pendencies/CardPendencie';

import api from 'services/api';
import { useSelector } from 'react-redux';
import { maskValue } from 'services/mask';
import lang from 'lang/lang.json';
import mock from './mock.json';

export default function Pendencies() {
	return (
		<Grid
			container
		>
			<Grid item xs={12}>
				<HeaderScreens
					titleScreen="Pendências"
					currentBreadItem={mock.breadcrumbItems}
					dtUpdated="Última atualização: 24/04/19 - 19:12"
				/>
			</Grid>
			<Grid item xs={12}>
				<Box mb={3}>
					<Tabs
						tabs={mock.tabs.map(item => {
							return {
								...item,
								component: All(),
							};
						})}
						activeTab={0}
					/>
				</Box>
			</Grid>
		</Grid>
	)
}

export function All() {
	const pendencies = useSelector(state => state.user.pendencies);

	const [items, setItems] = useState([]);

	useEffect(() => {
		const getNumberArray = (array, key) => {
			return array.map(item => parseFloat(item[key]));
		}

		const getProcesses = async () => {
			try {
				const response = await api.get('process', {
					params: {
						$limit: -1,
					}
				});
				if (response) {
					const parsedPendencies = pendencies.map(pendence => {
						const pendenceProcess = response.data.find(item => item.id === pendence.processId);
						const arrayContract = getNumberArray(pendenceProcess.process_products, 'initialValue');
						const arrayInvoice = getNumberArray(pendenceProcess.process_products, 'finalValue');
						const contractValue = arrayContract && arrayContract.length > 0 ? arrayContract.reduce((acumulator, value) => acumulator + value) * 100 : 0;
						const invoiceValue = arrayInvoice && arrayInvoice.length > 0 ? arrayInvoice.reduce((acumulator, value) => acumulator + value) * 100 : 0;
						return {
							...pendence,
							process: pendenceProcess || pendence.process,
							contractValue: maskValue('money', contractValue),
							invoiceValue: maskValue('money', invoiceValue),
						};
					});
					setItems(parsedPendencies);
				}
			} catch (error) {
				return null;
			}
		};

		getProcesses();
	}, []);

	return (
		<Box mt={3} mb={3}>
			<Grid
				container
			>
				{items.map(item => ConcatData(item))}
			</Grid>
		</Box>
	);
};

export function ConcatData(item = {}) {
	const history = useHistory();
	return (
		<CardPendencie
			contractValue={item.contractValue}
			fatureValue={item.invoiceValue}
			nPreg={item.process.tradingCode}
			onClickMenuItems={() => history.push(`${item.process.id}`)}
			onCardClick={() => history.push(`processo/${item.process.id}`)}
			openEdict={new Date(item.process.startPeriod).toLocaleDateString()}
			resultDate={new Date(item.process.createdAt).toLocaleDateString()}
			status={lang.process_status[item.process.status]}
			subtitle=""
			title={item.text}
			variantBorder={parseInt(Math.random() * 3)}
		/>
	);
}

/* save it for later
 {
		"label": "Falta de anexos",
		"color": "var(--primary)"
 },
 {
		"label": "Processos pendentes",
		"color": "var(--primary)"
 },
 {
		"label": "Confirmações atrasadas",
		"color": "var(--primary)"
 }
*/
