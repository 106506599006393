import { Box, Divider, Grid, Typography } from '@material-ui/core';
import MainModal from 'components/Modals/MainModal';
import Modal from 'components/Modals';
import UIInput from 'components/UI/Input/Input';
import UploadArea from 'components/Upload/UploadArea/UploadArea';
import { useEffect, useState } from 'react';
import Accordion from 'components/Process/Accordion';
import { StyledP, Specification } from './style';
import UITextarea from 'components/UI/Textarea/Textarea';
import UIRadio from 'components/UI/Radio/Radio';
import UISelect from 'components/UI/Select/Select';
import documentImageColor from 'assets/images/documentImageColor.svg';
import UIText from 'components/UI/Text/Text';
import UIButton from 'components/UI/Button/Button';

import { optionsYesNo, optionsJustify } from './mock.json';
import { UIAutocomplete } from 'components/UI/Autocomplete';
import api from 'services/api';
import { moneyMask } from 'services/mask';
import { parseToNumber, formatToNumber } from 'brazilian-values';

import mock from './mock.json';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { setLoading } from 'store/modules/app/actions';
import UploadList from 'components/Upload/UploadList/UploadList';

const bonusStatus = ['approved', 'contested'];
const fakeProcessProducts = (discount) => {
	var generateProcessProduct = [];
	for (var i = 0; i < 10; i++) {
		generateProcessProduct.push({
			itemNumber: i,
			processProduct: {
				name: `Leite ninho ${i}mg`,
				sku: i,
				value: (Math.random(1, 10) * 1000).toFixed(),
			},
			totalItem: (Math.random(1, 10) * 1000).toFixed(),
			description: `asdfjkhasdhfjasdhfjk;asdhkjfhkjasdkhfasdhjkfasdfjkhasdhfjasdhfjk;asdhkjfhkjasdkhfasdhjkf${i}`,
			value: (Math.random(1, 10) * 1000).toFixed(),
			error: i % 3 === 0 ? true : false, //need create the function to change this value, we need know when we have the errors messages
		});
	}
	if (discount) {
		for (var j = 0; j < 10; j++) {
			generateProcessProduct[j] = {
				...generateProcessProduct[j],
				discountOrder: {
					status: bonusStatus[(Math.random() * 3).toFixed()],
				},
			};
		}
	}

	return generateProcessProduct;
};

//modal of suspension of the process by the consultant
export function SuspendProcess({ onClose, show, process }) {
	const [modalSuccess, setModalSuccess] = useState(false);

	const [selectedFile, setSelectedFile] = useState({});

	const sendRequest = async () => {
		try {
			await api.patch(`process/${process.id}`, {
				status: 'suspending',
			});

			let formData = new FormData();
			formData.append('file', selectedFile);
			formData.append('table', 'process');
			formData.append('tableId', process.id);
			formData.append('category', 'requestSuspend');

			await api.post(`attachments`, formData);

			setModalSuccess(true);
		} catch (error) { }
	};

	return (
		<>
			<MainModal title="Suspender processo" show={show} onClose={onClose}>
				{/* <Box pt={1} pb={3}>
					<UIInput label="Observação" placeholder="Insira o texto aqui..." />
				</Box> */}
				<Box pb={3}>
					<Typography variant="h2" color="primary">
						Insira comprovante da solicitação para continuar
					</Typography>
				</Box>
				<UploadArea
					buttonText="Suspender processo"
					buttonOnClick={() => sendRequest()}
					onChange={(file) => setSelectedFile(file[0])}
					labelText={(selectedFile || {}).name}
				/>
			</MainModal>
			<Modal
				variant="success"
				open={modalSuccess}
				onClose={() => setModalSuccess(false)}
				title="Suspensão efetuada com sucesso"
				buttonText="Voltar para o processo"
				buttonAction={() => {
					setModalSuccess(false);
					onClose();
				}}
			/>
		</>
	);
}

// modal of response of suspension request by the distributor
export function RequestSuspend({ onClose, show, process }) {
	const [modalSuccess, setModalSuccess] = useState(false);

	const [attachment, setAttachment] = useState({});

	useEffect(() => {
		async function getAttachment() {
			try {
				const response = await api.get(`attachments`, {
					params: {
						category: 'requestSuspend',
						table: 'process',
						tableId: process.id,
					},
				});
				setAttachment(response.data.data[0]);
			} catch (error) { }
		}
		getAttachment();
	}, [process]);

	const sendRequestSuspend = async () => {
		try {
			//por enquanto apenas é possivel suspender
			await api.patch(`process/${process.id}`, {
				status: 'suspended',
			});

			onClose();
			setModalSuccess(true);
		} catch (error) { }
	};

	return (
		<>
			<MainModal
				show={show}
				onClose={onClose}
				title="Suspender processo"
				buttonText="Ver processo"
				cancelButton="Manter processo"
				onCancel={onClose}
				confirmButton="Suspender processo"
				confirmVariant="contained"
				onConfirm={() => setModalSuccess(true)}
				bottomButtonMd={6}
			>
				<Box pt={1} pb={3}>
					<Grid
						container
						spacing={2}
						justify="space-between"
						alignItems="center"
					>
						<Grid item xs={12}>
							<Typography variant="h5">
								Faça download do documento para validar as informações
							</Typography>
						</Grid>
						<Grid item xs={1}>
							<img src={documentImageColor} alt="" />
						</Grid>
						<Grid item xs={6}>
							<UIText text="Solicitação de cancelamento" fontSize="20px" />
							<Grid container spacing={1}>
								<Grid item>
									<Typography variant="h5">{attachment.name} </Typography>
								</Grid>
								<Grid item>
									<Divider orientation="vertical" />
								</Grid>
								<Grid item>
									<Typography variant="h5" color="secondary">
										{attachment.size}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={3}>
							<UIButton
								text="Baixar"
								variant="outlined"
								fullWidth
								onClick={() => {
									const link = document.createElement('a');
									link.href = attachment.key;
									link.target = '_blank';
									link.click();
								}}
							/>
						</Grid>
					</Grid>
				</Box>
				<Box mx={-2}>
					<Divider />
				</Box>
				<Box my={1}>
					<UIInput label="Observação" placeholder="Insira o texto aqui..." />
				</Box>
			</MainModal>
			<Modal
				variant="success"
				open={modalSuccess}
				title="Suspensão efetuada com sucesso"
				buttonText="Voltar para o processo"
				buttonAction={() => sendRequestSuspend()}
			/>
		</>
	);
}

export function ProcessResult({ processId, onClose, ...rest }) {
	const { idProcess } = useParams();
	const dispatch = useDispatch();

	const [products, setProducts] = useState([]);
	const [rivalProducts, setRivalProducts] = useState([]);
	const [rivalCompanies, setRivalCompanies] = useState([]);
	const [successModal, setSuccesModal] = useState(false);
	const [selectedCompany, setSelectedCompany] = useState({});
	const [selectedRivalProduct, setSelectedRivalProduct] = useState({});
	const [refresh, setRefresh] = useState(false);
	const [allProducts, setAllProducts] = useState([]);

	const [selectedAtt, setSelectedAtt] = useState({});
	const [attachments, setAttachments] = useState([]);
	const [category, setCategory] = useState('');
	const [errorCategory, setErrorCategory] = useState(null);
	const [errorFile, setErrorFile] = useState(null);
	const [haveFile, setHaveFile] = useState(false);

	useEffect(() => {
		async function getProcessProducts() {
			const response = await api.get(`process-products`, {
				params: {
					processId,
					approve: 1,
				},
			});

			const processProducts = response.data.data.map((product) => {
				return {
					...product,
					initialValue: formatToNumber(product.institutionValue),
					processProduct: {
						...product.product,
						name: product.product.description,
						value: formatToNumber(product.institutionValue),
					},
					itemProduct: {
						title: product.product.description,
						productId: product.productId,
					},
					value: formatToNumber(
						product.initialQuantity * product.institutionValue,
					),
				};
			});

			setProducts(processProducts);
		}

		getProcessProducts();
	}, [processId]);

	useEffect(() => {
		async function getAllProducts() {
			const response = await api.get(`products`, {
				params: { $limit: -1 },
			});
			if (response.status === 200) setAllProducts(response.data);
		}

		const getRivalCompanies = async () => {
			const rivalsResponse = await api.get(`rival-products`, {
				params: {
					$distinct: 'company',
					$limit: -1,
				},
			});

			if (rivalsResponse.status === 200) setRivalCompanies(rivalsResponse.data);
		};

		getAllProducts();
		getRivalCompanies();
	}, []);
	useEffect(() => {
		getRivalProducts({ company: selectedCompany || {} });
	}, [selectedCompany]);

	const getRivalProducts = async ({ company }) => {
		const response = await api.get(`rival-products`, {
			params: {
				...company,
				$limit: -1,
			},
		});

		if (response.status === 200) setRivalProducts(response.data);
	};

	const handleChange = (e, productId) => {
		const newProducts = products;

		newProducts.forEach((product) => {
			if (product.id === productId) {
				if (e.target.name === 'winner') {
					return (product[e.target.name] =
						e.target.value === 'sim' ? true : false);
				}
				if (
					[
						'finalValue',
						'initialValue',
						'rivalInitialValue',
						'rivalFinalValue',
					].includes(e.target.name)
				) {
					return (product[e.target.name] = moneyMask(e.target.value));
				}
				return (product[e.target.name] = e.target.value);
			}
		});
		setProducts(newProducts);
		setRefresh(!refresh);
	};

	const handleSuccessModal = () => {
		setSuccesModal(!successModal);
	};

	const sendResult = async () => {
		try {
			const data = products.map((product) => {
				return {
					...product,
					rivalInitialValue: parseToNumber(
						`${product.rivalInitialValue}` || '',
					),
					rivalFinalValue: parseToNumber(`${product.rivalFinalValue}` || ''),
					initialValue: parseToNumber(`${product.initialValue}` || ''),
					finalValue: parseToNumber(`${product.finalValue}` || ''),
				};
			});

			await api.patch(`process-products`, data);
			await api.patch(`process/${processId}`, {
				status: 'awaiting consultant result',
			});
			onClose();
			handleSuccessModal();
		} catch (error) {
			return null;
		}
	};

	const addAtt = () => {
		const uploadAtt = async (data) => {
			dispatch(setLoading(true));
			try {
				let formData = new FormData();
				formData.append('file', selectedAtt.file);
				formData.append('table', 'process');
				formData.append('tableId', idProcess);
				formData.append('category', category);

				let config = {};
				if (category === 'Ata') config.headers = { ocr: true, category: 'ata' };

				const response = await api.post(`attachments`, formData, config);

				setCategory('');
				setErrorCategory(null);
				setSelectedAtt({});
				setErrorFile(null);
				setAttachments((att) => {
					return [...att, { ...response.data, success: true }];
				});
			} catch (error) {
				return null;
			} finally {
				dispatch(setLoading(false));
			}
		};

		if (category && selectedAtt.file) {
			uploadAtt();
		} else {
			if (!category) return setErrorCategory('Selecione o tipo do documento');
			if (!selectedAtt.file) return setErrorFile('Selecione o arquivo');
		}
	};

	return (
		<>
			<MainModal
				{...rest}
				onConfirm={() => sendResult()}
				onCancel={onClose}
				onClose={onClose}
			>
				{products.map((item) => {
					return (
						<Accordion result item={item} key={item.itemNumber}>
							<Box ml={-4} mr={-4}>
								<Specification>
									<Grid>
										<UITextarea
											value={item.specification}
											disabled
											label="Especificação"
											colorLabel="var(--text-grey)"
											rows={3}
										/>
									</Grid>
								</Specification>
							</Box>
							<Grid container>
								<Grid item xs={12}>
									<Box mt={2}>
										<StyledP fontSize="16px" fontWeight="500">
											Resultado do processo
										</StyledP>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Box pt={2}>
										<UIRadio
											label="O item foi ganho?"
											items={optionsYesNo}
											name="winner"
											value={item.winner ? 'sim' : 'não'}
											onChange={(e) => handleChange(e, item.id)}
										/>
									</Box>
									<Box mt={2} mb={2}>
										<Divider />
									</Box>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={12} md={6}>
									<UIAutocomplete
										label="Produto"
										options={allProducts}
										getOptionLabel={(option) => option.description}
										onChange={(e, value) => {
											handleChange(
												{
													target: {
														name: 'productId',
														value: (value || {}).productId,
													},
												},
												item.id,
											);
											handleChange(
												{
													target: {
														name: 'itemProduct',
														value: {
															productId: (value || {}).productId,
															title: (value || {}).title,
														},
													},
												},
												item.id,
											);
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<UIInput
										label="(R$) Valor inicial"
										value={item.initialValue || ''}
										name="initialValue"
										onChange={(e) => handleChange(e, item.id)}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<UIInput
										label="(R$) Valor final"
										name="finalValue"
										value={item.finalValue || ''}
										onChange={(e) => handleChange(e, item.id)}
									/>
								</Grid>
								<Grid item xs={12}>
									<Box pb={3}>
										<UIText
											text="Grade de preço"
											fontSize="16px"
											fontWeight="500"
											textcolor="var(--text-dark)"
										/>
									</Box>
								</Grid>
								<Grid item xs={6}>
									<UIAutocomplete
										label="Segundo colocado"
										options={rivalCompanies}
										value={selectedCompany}
										getOptionLabel={(option) => option.company || ''}
										onChange={(e, newValue) => {
											setSelectedCompany(newValue);
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<UIAutocomplete
										label="Produto"
										options={rivalProducts}
										value={selectedRivalProduct}
										name="rivalProductId"
										getOptionLabel={(option) => option.description || ''}
										onChange={(e, newValue) => {
											e.target.name = 'rivalProductId';
											e.target.value = newValue.id;
											setSelectedRivalProduct(newValue);
											handleChange(e, item.id);
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<UIInput
										label="(R$) Valor inicial"
										name="rivalInitialValue"
										value={item.rivalInitialValue || ''}
										onChange={(e) => handleChange(e, item.id)}
									/>
								</Grid>
								<Grid item xs={6}>
									<UIInput
										label="(R$) Valor final"
										name="rivalFinalValue"
										value={item.rivalFinalValue || ''}
										onChange={(e) => handleChange(e, item.id)}
									/>
								</Grid>
								<Grid item xs={6}>
									<UISelect
										items={optionsJustify}
										label="Justificativa"
										name="justify"
										disabled={item.winner}
										onChange={(e) => handleChange(e, item.id)}
									/>
								</Grid>
								<Grid item xs={12}>
									<UIInput
										label="Observação"
										placeholder="Insira o texto aqui..."
										onChange={(e) => handleChange(e, item.id)}
									/>
								</Grid>
							</Grid>
						</Accordion>
					);
				})}
				<Grid container spacing={3}>
					<Grid item>
						<Typography variant="h4"></Typography>
					</Grid>
					<Grid item xs={12}>
						<UISelect
							items={mock.documentTypes}
							value={category}
							label="Documento"
							onChange={(e) => setCategory(e.target.value)}
							error={!category && errorCategory ? errorCategory : null}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" color="error">
							{!selectedAtt.file && errorFile}
						</Typography>
						<UploadArea
							height="240px"
							labelText={(selectedAtt.file || {}).name}
							onChange={(param) => {
								setSelectedAtt({
									file: param[0],
									category,
								});
							}}
							buttonOnClick={() => addAtt()}
						/>
					</Grid>
					<Grid item xs={12}>
						<UploadList itemsList={attachments} />
					</Grid>
				</Grid>
			</MainModal>
			<Modal
				variant="success"
				title="Resultado do edital adicionado com sucesso"
				buttonText="Voltar para a licitação"
				buttonAction={handleSuccessModal}
				open={successModal}
			/>
		</>
	);
}
/*
{
				params: {
					ocr: true,
					category: 'ATA',
				}
			}
*/
