import { TextField, withStyles } from '@material-ui/core';
export const StyledTextField = withStyles({
	root: {
		'& .MuiInput-underline:before': {
			borderBottom: '1px solid var(--text-grey)',
		},
		'& .MuiFormLabel-root': {
			color: 'var(--primary)',
			fontSize: '20px',
		},
	},
})(TextField);
