import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import Routes from 'routes/index';

import './global.css';
import { Provider } from 'react-redux';
import { store } from '../../store';
import { Router } from 'react-router';
import history from 'services/history';
import { ptBR } from '@material-ui/core/locale';

export default function App() {
	const theme = createMuiTheme(
		{
			typography: {
				allVariants: {
					color: '#565656',
				},
				h1: {
					fontSize: '24px',
					fontWeight: 500,
				},
				h2: {
					fontSize: '20px',
					fontWeight: 500,
				},
				h4: {
					fontSize: '16px',
					fontWeight: 500,
				},
				h5: {
					fontSize: '14px',
					fontWeight: 500,
				},
				h6: {
					fontSize: '12px',
					fontWeight: '400',
				},
			},
			palette: {
				primary: {
					main: '#5082C9',
				},
				secondary: {
					main: '#c4c4c4',
				},
				success: {
					main: '#65AC28',
				},
				error: {
					main: '#C95050',
				},
			},
		},
		ptBR,
	);


	return (
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<Router history={history}>
					<Routes />
				</Router>
			</Provider>
		</ThemeProvider>
	);
}
