/* eslint-disable no-mixed-operators */
import React from 'react';
import TablePaper from 'components/UI/Table/TablePaper';
import TablePagination from 'components/UI/Table/TablePagination';
import TableSkeleton from 'components/UI/Table/TableSkeleton';
import TableRow from 'components/UI/Table/TableRow';
import TableCell from 'components/UI/Table/TableCell';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import lang from 'lang/lang.json';

function TableOpenProcess(props) {
	const { titles, items, totalPages, onChangePaginate } = props;
	const history = useHistory();
	function setStyleStatus(status) {
		switch (status.toLowerCase()) {
			case 'lost':
				return 'red';
			case 'suspending':
				return 'red';
			case 'awaiting consultant result':
				return 'orange';
			case 'waiting to confirm consultant values':
				return 'orange';
			case 'const recording':
				return 'orange';
			case 'total win':
				return 'green';
			case 'parcial win':
				return 'blue';
			case 'dist recording':
				return 'orange';
			case 'awaiting distributor result':
				return 'orange';
			case 'awaiting financier':
				return 'orange';
			case 'awaiting order':
				return 'orange';
			default:
				return 'none';
		}
	}

	function setStyleClassification(classification) {
		switch (classification.toUpperCase()) {
			case 'GOLD':
				return 'gold';
			case 'SILVER':
				return 'silver';
			default:
				return 'none';
		}
	}

	return (
		<TablePaper
			title="Processos em aberto"
			elementsTopEnd={
				<TablePagination count={totalPages} onChange={onChangePaginate} />
			}
		>
			<TableSkeleton
				hideScrollbars={false}
				horizontalScroll
				head={
					<TableRow>
						{(titles || []).map((title, index) => (
							<TableCell
								key={index}
								background="var(--primary)"
								color="#FFFFFF"
								minwidth={
									(title === 'Início fornecimento' && '110px') ||
									(title === 'Término fornecimento' && '130px') ||
									(title === 'Cliente' && '110px') ||
									(title === 'Nome do Consultor' && '110px') ||
									'100px'
								}
							>
								{title}
							</TableCell>
						))}
					</TableRow>
				}
				body={(items || []).map((item, index) => (
					<TableRow
						key={index}
						borderbottom="1px solid  var(--grey-opacity)"
						onClick={() => history.push(`/processo/${item.id}`)}
					>
						<TableCell
							chip
							chipStyle={setStyleStatus(item.status)}
							fontSize="10px"
						>
							{lang.process_status[item.status] || item.status}
						</TableCell>

						<TableCell color="var(--primary)">
							{item.tradingCode || ''}
						</TableCell>
						<TableCell fontWeight="400">{item.pne || ''}</TableCell>
						<TableCell>{item.city || ''}</TableCell>
						<TableCell>
							{item.institution ? item.institution.name : ''}
						</TableCell>
						<TableCell>{item.regional || ''}</TableCell>
						<TableCell chip chipStyle={item.processDirect ? 'green' : 'blue'}>
							{item.processDirect ? 'Privado' : 'Publico'}
						</TableCell>
						<TableCell fontWeight="400">
							{(item.distributor || {}).name || ''}
						</TableCell>
						<TableCell
							chip
							chipStyle={setStyleClassification(
								item.institution ? item.institution.class : '',
							)}
						>
							{item.institution ? item.institution.class : ''}
						</TableCell>
						<TableCell>{item.valor || ''}</TableCell>
						<TableCell>{item.quantidade || ''}</TableCell>
						<TableCell fontWeight="400">{item.tradingDate || ''}</TableCell>
						<TableCell fontWeight="400">{item.startPeriod || ''}</TableCell>
						<TableCell fontWeight="400">{item.endPeriod || ''}</TableCell>
						<TableCell fontWeight="400">
							{(item.distributor || {}).id || ''}
						</TableCell>
						<TableCell fontWeight="400">
							{(item.distributor || {}).name || ''}
						</TableCell>
						<TableCell fontWeight="400">
							{(item.employee || {}).sap || ''}
						</TableCell>
						<TableCell fontWeight="400">
							{(item.employee || {}).name || ''}
						</TableCell>
						<TableCell fontWeight="400">{item.extendMonths || ''}</TableCell>
					</TableRow>
				))}
			></TableSkeleton>
		</TablePaper>
	);
}

export default TableOpenProcess;

TableOpenProcess.propTypes = {
	titles: PropTypes.arrayOf(PropTypes.string),
	items: PropTypes.arrayOf(PropTypes.object),
	totalPages: PropTypes.number,
};

TableOpenProcess.defaultProps = {
	titles: [
		'Status',
		'Nº do pregão',
		'PNE',
		'Local',
		'Instituição',
		'Regional',
		'Segmento',
		'Cliente',
		'Classificação',
		'(R$) Valor',
		'(Kg) Quantidade',
		'Data Abertura',
		'Início fornecimento',
		'Término fornecimento',
		'Código do Cliente',
		'Cliente',
		'SAP do Consultor',
		'Nome do Consultor',
		'Prorrogado',
	],
	totalPages: 1,
};
