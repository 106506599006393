import React, { useState, useEffect } from 'react'
import { Box, Grid } from '@material-ui/core';
import { useHistory } from 'react-router';

import MainModal from 'components/Modals/MainModal/index';
import UploadArea from 'components/Upload/UploadArea/UploadArea';
import UIText from 'components/UI/Text/Text';
import UploadList from 'components/Upload/UploadList/UploadList';
import Modal from 'components/Modals';

import { ImFolderOpen } from 'react-icons/im';

import api from 'services/api';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'store/modules/app/actions';

export default function IndirectProcess({
	opened,
	close,
}) {
	const dispatch = useDispatch();
	const history = useHistory();

	const processDefault = useSelector((state) => state.process.process);
	const user = useSelector(state => state.user);
	const [idProcess, setIdProcess] = useState(processDefault.id);

	const [attachmentsDefault, setAttachmentsDefault] = useState([]);
	const [attachments, setAttachments] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [labelFile, setLabelFile] = useState('');
	const [itemsConfigs, setItemsConfigs] = useState();
	const [openModalSuccess, setOpenModalSuccess] = useState(false);
	const [submit, setSubmit] = useState(false);

	//create process
	useEffect(() => {
		const initProcess = async () => {
			try {
				const data = {
					institutionId: 1,
					employeeId: user.id,
					distributorId: 1,
					tradingCode: null,
					segment: 'private',
					observation: null,
					requiredDocuments: null,
					processDirect: false,
					comments: null,
					focusUf: null,
					secretaryClassification: null,
					quota: null,
					regional: null,
					uf: null,
					city: null,
					newProgram: null,
					programName: null,
					comercialPolitics: null,
					competitionModality: null,
					noticeNumber: null,
					period: null,
					canExtend: null,
					extendMonths: null,
					justify: null,
					note: null,
				}
				const process = await api.post(`process`, { ...data });
				dispatch({
					type: '@process/SET_PROCESS', payload: {
						...data,
						...process.data,
					}
				});
				setIdProcess(process.data.id);
			} catch (error) {
				return null;
			}
		}
		if (opened) initProcess();
	}, [dispatch, opened, user.id]);
	//buscar informações do processo e anexos
	useEffect(() => {
		async function getAttachments() {
			try {
				const response = await api.get(`attachments`, {
					params: {
						table: 'process',
						tableId: idProcess,
					},
				});

				const result = response.data.data.map((att) => {
					return {
						...att,
						url: att.key,
						success: true,
					};
				});

				setAttachmentsDefault(result);
			} catch (error) {
				return null;
			}
		}

		if (idProcess) getAttachments();
	}, [idProcess]);

	//adiciona os anexos do processo no novo array para upload
	useEffect(() => {
		setAttachments((att) => {
			return [...att, ...attachmentsDefault];
		});
	}, [attachmentsDefault]);

	const deleteAttachment = async (id) => {
		try {
			await api.delete(`attachments/${id}`);

			const files = attachments;

			files.splice(
				files.findIndex((file) => file.id === id),
				1,
			);

			setAttachments(files);
			setRefresh(!refresh);
		} catch (error) {
			return null;
		}
	};

	//fazer o upload qnd atualizar a lbl
	useEffect(() => {
		const submitForm = async () => {
			const data = {
				id: idProcess,
				step: '',
				status: 'const recording',
			};

			try {
				await api.patch(`process/${idProcess}`, data);
				setOpenModalSuccess(true);
			} catch (error) {
				return null;
			}
		};
		const uploadAttachment = async (data) => {
			try {
				let formData = new FormData();
				formData.append('file', data.file);
				formData.append('table', 'process');
				formData.append('tableId', idProcess);
				formData.append('category', 'Edital');

				const response = await api.post(`attachments`, formData);

				return {
					...itemsConfigs,
					id: response.data.id,
					url: response.data.key,
					category: 'Edital',
				};
			} catch (error) {
				return null;
			}
		};
		const addAttachment = async () => {
			dispatch(setLoading(true));
			if (itemsConfigs) {
				const file = await uploadAttachment(itemsConfigs);
				const newItems = attachments;
				newItems.push(file);
				setAttachments(newItems);
				setItemsConfigs(null);
				setLabelFile('');
				await submitForm();
			}
			dispatch(setLoading(false));
		};
		addAttachment();
	}, [labelFile]);

	return (
		<MainModal
			title="Adicionar edital"
			confirmVariant="outlined"
			show={opened}
			onClose={close}
			confirmButton="Adicionar"
			width="medium"
			onConfirm={() => setOpenModalSuccess(true)}
		>
			<Grid container>
				<Grid item xs={12}>
					<Box pb={3}>
						<UIText
							fontSize="20px"
							fontWeight={500}
							textcolor="var(--primary)"
							text="Adicione o documento para continuar"
						/>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<UploadArea
						height="265px"
						hideButton
						dropButtonText="Inserir Arquivo"
						labelText={labelFile}
						onChange={(param) => {
							const file = param[0];
							setItemsConfigs({
								name: file.name,
								size: String(file.size),
								showBars: true,
								success: param.success,
								category: 'Edital',
								file,
							});
							setLabelFile(
								`Arquivo selecionado: ${String(file.name).substr(
									0,
									30,
								)}.../pdf`,
							);
							return;
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<UploadList
						itemsList={attachments}
						iconTitle={
							<ImFolderOpen
								color="var(--primary)"
								size={30}
								style={{
									background: '#F6F9FC',
									padding: '10px',
									borderRadius: '100%',
									cursor: 'pointer',
								}}
								cursor="pointer"
							/>
						}
						titleList="Documentos"
						deleteAttachment={deleteAttachment}
					/>
				</Grid>
			</Grid>
			<Modal
				variant="success"
				title="Edital adicionado com sucesso"
				subtitle={(
					<UIText
						text={"Assim que for carregado estará disponível em  Ações a serem executadas"}
						fontSize="16px"
					/>)}
				buttonText="Voltar a tela inicial"
				open={openModalSuccess}
				onClose={() => setOpenModalSuccess(false)}
				buttonAction={() => {
					close();
					setOpenModalSuccess(false);
				}}
			/>
		</MainModal>
	)
}
