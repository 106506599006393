import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { StyledTab, StyledTabs } from './style';

function NavTabs({ tabs, activeTab, rightTabs = [], onChange = () => '' }) {
	function TabPanel(props) {
		const { children, index } = props;

		return (
			<div
				role="tabpanel"
				hidden={activeTab !== index}
				aria-labelledby={`nav-tab-${index}`}
			>
				<Box>{children}</Box>
			</div>
		);
	}

	return (
		<>
			<StyledTabs value={activeTab} onChange={onChange}>
				{tabs.map((tab, i) => (
					<StyledTab
						label={tab.label}
						color={tab.color}
						marginLeft={tab.marginLeft}
						key={`tab-${i}`}
					/>
					))}
				<Box flex="1" />
				{rightTabs.map((tab, i) => (
					<StyledTab
						label={tab.label}
						color={tab.color}
						marginRight={rightTabs.length - 1 === i ? '0' : '20px'}
						key={`tab-${i}`}
					/>
				))}
			</StyledTabs>
			{tabs.map(({ component }, i) => (
				<TabPanel index={i} key={`key-tab-panel-${i}`}>
					{component}
				</TabPanel>
			))}
		</>
	);
}

export default NavTabs;

NavTabs.propTypes = {
	tabs: PropTypes.array.isRequired,
	activeTab: PropTypes.number.isRequired,
};
