import React from 'react';

import { StyledTags, StyledTextField, StyledChip } from './styles';

export default function DocumentSolicitation({
  placeholderInput,
  disabled,
  onChangeSolicitation,
  value
}) {
  return (
    <StyledTags
      multiple
      freeSolo
      id="tag-standard"
      onChange={onChangeSolicitation}
      options={[
				"Carta de credenciamento",
				"Ficha técnica",
				"Registro do produto",
				"Licença de funcionamento",
				"Contrato nestlé",
				"CNPJ",
				"Atestado de responsabilidade técnica",
				"Certidões",
				"Outros documentos",
				"Boas práticas de fabricação"
			]}
      value={value}
      renderTags={(value, getTagProps) =>
      {
        return value.map((option, index) =>
          <StyledChip
            label={option}
            {...getTagProps({ index })}
          />
        )
      }
      }
      renderInput={(params) => (
        <StyledTextField
          disabled={disabled}
          {...params}
          label={placeholderInput}
          placeholder=""
        />
      )}
      disabled={disabled}
    />
  );
}
