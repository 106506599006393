import { withStyles, Typography } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

export const StyledProgressBar = withStyles({
  root: {
    height: 6,
    display: (props) => props.display,
  },
  colorPrimary: {
    backgroundColor: '#E5E5E5',
  },
  bar: {
    backgroundColor: '#5082C9',
  },
})(LinearProgress);

export const StyledError = withStyles({
  root: {
    color: 'var(--error)',
    fontSize: '12px',
  },
})(Typography);
