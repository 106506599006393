import { Pagination } from '@material-ui/lab';
import { StyledPaginationItem } from './style';
import React from 'react';

export default function UIPagination({
	count,
	pageNumber,
	shape = 'rounded',
	onChange,
	size = 'medium',
}) {
	return (
		<Pagination
			size={size}
			count={count}
			page={pageNumber}
			shape={shape}
			onChange={onChange}
			renderItem={(item) => <StyledPaginationItem {...item} />}
		/>
	);
}
