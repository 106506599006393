import React from 'react';
import PropTypes from 'prop-types';
import {
	SectionTableTop,
	SectionTopStart,
	SectionTopEnd,
	StyledPaper,
} from './style';
import { Box, Grid } from '@material-ui/core';

function TablePaper(props) {
	const { children, elementsTopStart, elementsTopEnd, title } = props;
	return (
		<StyledPaper>
			<SectionTableTop>
				<Grid container justify="space-between">
					<Grid item xs={4}>
						<SectionTopStart>
							<Grid item xs={4}>
								{title && <h1>{title}</h1>}
							</Grid>
							<Grid item xs={5}>
								<Box pl={2}>
									{elementsTopStart}
								</Box>
							</Grid>
						</SectionTopStart>
					</Grid>
					<SectionTopEnd>{elementsTopEnd}</SectionTopEnd>
				</Grid>
			</SectionTableTop>
			{children}
		</StyledPaper>
	);
}

export default TablePaper;

TablePaper.propTypes = {
	children: PropTypes.element,
	title: PropTypes.string,
	elementsTopStart: PropTypes.element,
	elementsTopEnd: PropTypes.element,
};
