import { Typography, withStyles } from '@material-ui/core';

export const StyledTypograph = withStyles({
	root: {
		fontSize: (props) => props.fontSize,
		fontWeight: (props) => props.fontWeight,
		color: (props) => props.textcolor || 'var(--text-dark)',
		textAlign: (props) => props.textalign,
		textOverflow: (props) => props.textOverflow,
		overflow: (props) => props.overflow,
		whiteSpace: (props) => props.whiteSpace,
		cursor: (props) => props.cursor,
	},
})(Typography);
