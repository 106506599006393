import React from 'react';

import { Box, Grid, IconButton } from '@material-ui/core';

import NavBreadcrumbs from 'components/Navigation/Breadcrumbs/Breadcrumbs';
import UIText from 'components/UI/Text/Text';
import UIButton from 'components/UI/Button/Button';

import { AiOutlineSearch } from 'react-icons/ai';
import {
	StyledPaper,
	StyledInputBase,
} from 'components/UI/Search/style';

const breadcrumbItems = [
	{ text: 'Dashboard', href: '/' },
];

export default function HeaderScreens({
	currentBreadItem = breadcrumbItems,
	titleScreen,
	dtUpdated,
	buttonText,
	buttonOnClick,
	searchValue,
	searchOnChange,
	searchPlaceholder,
}) {

	return (
		<Box>
			<Grid
				container
				justify="space-between"
				alignItems="center"
			>
				<NavBreadcrumbs items={breadcrumbItems.concat(currentBreadItem)} />
				<UIText
					text={dtUpdated}
					fontSize="12px"
					fontWeight="500"
					textcolor="var(--text-grey)"
				/>
			</Grid>
			<Grid
				container
				justify="space-between"
				alignItems="center"
			>
				<UIText
					text={titleScreen}
					textcolor="var(--text-dark)"
					fontWeight="500"
					fontSize="24px"
				/>
				{typeof searchValue === 'string' && (<Box>
					<Grid
						container
						justify="space-between"
						alignItems="center"
					>
						<Box marginRight="8px">
							<StyledPaper elevation={0}>
								<IconButton type="submit" aria-label="search">
									<AiOutlineSearch size={20} color="var(--text-grey)" />
								</IconButton>
								<StyledInputBase
									placeholder={searchPlaceholder}
									value={searchValue}
									onChange={searchOnChange}
									fullWidth
								/>
							</StyledPaper>
						</Box>
						<UIButton
							text={buttonText}
							onClick={buttonOnClick}
							type="button"
						/>
					</Grid>
				</Box>)}
			</Grid>
		</Box>
	);
};
