import React from 'react';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { StyledTextField } from './style';

export function UIAutocomplete({
	error,
	label,
	inputProps = {},
	filter,
	...rest
}) {
	const filterOptions = createFilterOptions({
		matchFrom: 'start',
		stringify: (option) => option.title,
	});
	if (filter) {
		const { options, onChange, value } = rest;
		return (
			<Autocomplete
				{...rest}
				options={options}
				getOptionLabel={(option) => option.title || ''}
				filterOptions={filterOptions}
				value={value}
				onChange={onChange}
				filterOptions={filterOptions}
				renderInput={(params) => (
					<StyledTextField
						{...params}
						fullWidth
						InputLabelProps={{ shrink: true }}
						label={label}
						helperText={error}
						error={error ? true : false}
						{...inputProps}
					/>
				)}
			/>
		);
	} else {
		return (
			<Autocomplete
				{...rest}
				renderInput={(params) => (
					<StyledTextField
						{...params}
						fullWidth
						InputLabelProps={{ shrink: true }}
						label={label}
						helperText={error}
						error={error ? true : false}
						{...inputProps}
					/>
				)}
			/>
		);
	}
}

export default UIAutocomplete;
