export function setLoading(loading) {
	return {
		type: '@app/LOADING',
		payload: loading,
	};
}

export function setRefresh(refresh) {
	return {
		type: '@app/REFRESH',
		payload: refresh,
	};
}
