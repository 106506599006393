import React, { useState } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import UIButton from 'components/UI/Button/Button';
import lang from 'lang/lang.json';
import { RenewProcess, E2O, InformDiscount } from './modals';
import { Order } from 'screens/Process/components/Modal';

import { useSelector } from 'react-redux';
/**
 * @param {Boolean} generate gerar relatorio
 * @param {Boolean} renewConsultant renovar processo
 * @param {Boolean} renewDistributor solicitar renovacao
 * @param {Boolean} invoice faturar pedido
 * @param {Boolean} order fazer pedido
 * @param {String} status status do processo
 * @return {Component} The title and buttons for the process page
 */
export default function ProcessTitle({
	generate,
	renewConsultant,
	renewDistributor,
	invoice,
	order,
	discount,
	status,
}) {
	const { process } = useSelector((state) => state.process);
	const [newOrder, setNewOrder] = useState(false);
	const [renewProcess, setRenewProcess] = useState(false);
	const [informDiscount, setInformDiscount] = useState(false);
	const onHandleOrder = (consultant) => {
		setNewOrder(!newOrder);
	};

	const onHandleRenewProcess = () => {
		setRenewProcess(!renewProcess);
	};

	const handleInformDiscount = () => {
		setInformDiscount(!informDiscount);
	};

	return (
		<Box mt={1}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={4} lg={5}>
					<Typography variant="h1">Historico do processo</Typography>
				</Grid>
				{/* Modals */}
				<Order
					title="Solicitar pedido"
					confirmButton={renewConsultant ? 'Gerar E2O' : 'Fazer pedido'}
					confirmVariant="contained"
					cancelButton={renewConsultant ? 'voltar' : 'Cancelar pedido'}
					show={newOrder}
					consultant={renewConsultant}
					bottonButtonMd="6"
					onClose={() => onHandleOrder()}
				/>
				{/* endModals */}
				<Grid item xs={12} md={8} lg={7}>
					<Grid container spacing={2} justify="flex-end">
						{lang.process_steps[status] !== 1 || status !== 'suspending' ? (
							<>
								{invoice && lang.process_steps[status] === 4 ? (
									<Grid item lg={4}>
										<UIButton
											fullWidth
											variant="outlined"
											color="primary"
											text="Faturar pedido"
											onClick={() => onHandleOrder(true)}
										/>
									</Grid>
								) : null}
								{generate && lang.process_steps[status] === 4 ? (
									<Grid item>
										<UIButton
											variant="outlined"
											color="primary"
											text="Gerar relatorio"
										/>
									</Grid>
								) : null}
								{renewConsultant && lang.process_steps[status] === 4 ? (
									<Grid item>
										<UIButton
											variant="contained"
											color="primary"
											text="Renovar processo"
											onClick={() => onHandleRenewProcess()}
										/>
									</Grid>
								) : null}
								{renewDistributor && lang.process_steps[status] === 4 ? (
									<Grid item xs={12} sm={6} md={5} lg={4}>
										<UIButton
											fullWidth
											variant="outlined"
											color="primary"
											text="Solicitar renovacao"
											onClick={() => onHandleRenewProcess()}
										/>
										<RenewProcess
											show={renewProcess}
											onClose={() => onHandleRenewProcess()}
										/>
									</Grid>
								) : null}
								{order && lang.process_steps[status] === 4 ? (
									<Grid item xs={12} sm={6} md={5} lg={4}>
										<UIButton
											fullWidth
											variant="contained"
											color="primary"
											text="Solicitar pedido"
											onClick={() => onHandleOrder()}
										/>
									</Grid>
								) : null}
								{discount && lang.process_steps[status] === 2 ? (
									<Grid item xs={12} sm={6} md={5} lg={4}>
										<UIButton
											fullWidth
											text="Informar desconto"
											onClick={() => handleInformDiscount()}
										/>
										<InformDiscount
											show={informDiscount}
											process={process}
											onClose={() => handleInformDiscount()}
										/>
									</Grid>
								) : null}
							</>
						) : null}
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}
