import React, { useEffect, useState } from 'react';
import { Box, Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import UIText from 'components/UI/Text/Text';

import { StyledPaginationItem, StyledPaper } from './style';
import { FiMoreVertical } from 'react-icons/fi';
import Modal from 'components/Modals';
import MainModal from 'components/Modals/MainModal';
import SucessModal from 'components/Modals/SuccessModal';
import UITextarea from 'components/UI/Textarea/Textarea';
import { useParams } from 'react-router';

import { store } from 'store';
import api from 'services/api';

export default function ObservationCard({ processId }) {
	const [pageNumber, setPageNumber] = useState(1);
	const [anchorEl, setAnchorEl] = useState(null);
	const [openErrorModal, setOpenErrorModal] = useState(false);
	const [openObservationModal, setOpenObservationModal] = useState(false);
	const [openSuccessModal, setOpenSuccessModal] = useState(false);

	const [list, setList] = useState([]);

	const [selectedObservation, setSelectedObservation] = useState({});
	const [comment, setComment] = useState();
	const { idProcess } = useParams();
	const user = store.getState().user;

	useEffect(() => {
		getComments();
	}, []);

	const createComment = async () => {
		try {
			if (selectedObservation) {
				const create = await api.patch(
					`/comments/${selectedObservation.id}`,
					comment,
				);
			} else {
				const create = await api.post('/comments', comment);
			}
			setOpenSuccessModal(true);
			getComments();
		} catch (e) {}
	};

	const changeComment = async () => {
		try {
			await api.patch(`/comments/${selectedObservation.id}`, {
				comment: selectedObservation.comment,
			});
			setOpenObservationModal(false);
			getComments();
		} catch (e) {}
	};

	const deleteComment = async () => {
		try {
			await api.patch(`/comments/${selectedObservation.id}`, { active: false });
			setOpenErrorModal(false);
			getComments();
		} catch (e) {}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		let tempComment = { processId: idProcess };
		if (!selectedObservation) {
			if (user.type === 'distributor') tempComment.distributorId = user.id;
			else tempComment.employeeId = user.id;
		} else {
			setSelectedObservation({ ...selectedObservation, [name]: value });
		}
		setComment({ ...tempComment, [name]: value });
	};

	const getComments = async () => {
		try {
			const comments = await api.get('/comments', {
				params: {
					processId: idProcess,
					active: 1,
				},
			});
			if (comments.status === 200 && comments.total !== 0)
				setList(comments.data.data);
		} catch (e) {}
	};

	return (
		<>
			<StyledPaper elevation={0}>
				<Grid container alignItems="center" justify="flex-end">
					<Grid item>
						<Pagination
							count={list.length}
							page={pageNumber}
							shape="rounded"
							onChange={(e, value) => setPageNumber(value)}
							renderItem={(item) => <StyledPaginationItem {...item} />}
						/>
					</Grid>
					<Grid item>
						<FiMoreVertical
							onClick={(e) => setAnchorEl(e.currentTarget)}
							color="var(--text-grey)"
							size={22}
							cursor="pointer"
						/>
						<Menu
							id="simple-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={() => setAnchorEl(null)}
							onClick={() => setAnchorEl(false)}
						>
							<MenuItem
								onClick={() => {
									setOpenObservationModal(true);
									setSelectedObservation(false);
								}}
							>
								<Typography variant="h5">Criar comentário</Typography>
							</MenuItem>
							{list.length > 0 && (
								<MenuItem
									onClick={() => {
										setOpenObservationModal(true);
										setSelectedObservation(list[pageNumber - 1]);
									}}
								>
									<Typography variant="h5">Editar comentário</Typography>
								</MenuItem>
							)}
							{list.length > 0 && (
								<MenuItem
									onClick={() => {
										setOpenErrorModal(true);
										setSelectedObservation(list[pageNumber - 1]);
									}}
								>
									<Typography variant="h5" color="error">
										Apagar comentário
									</Typography>
								</MenuItem>
							)}
						</Menu>
					</Grid>
				</Grid>
				<Grid container alignItems="center" spacing={1}>
					<Grid item>
						<UIText text="Observações |" fontWeight="500" fontSize="16px" />
					</Grid>
					<Grid item>
						<UIText
							text={
								list[pageNumber - 1] !== undefined &&
								list[pageNumber - 1].employee !== null
									? list[pageNumber - 1]?.employee?.name
									: list[pageNumber - 1]?.distributor?.name
							}
							fontSize="14px"
							fontWeight="400"
							textcolor="var(--primary)"
						/>
					</Grid>
					<Grid item xs={12}>
						<UIText
							fontSize="14px"
							fontWeight="400"
							text={list[pageNumber - 1]?.comment}
						/>
					</Grid>
				</Grid>
			</StyledPaper>
			{/* Modal of confirm delete comment */}
			<Modal
				variant="error"
				title="Deseja apagar o comentário?"
				subtitle="Não será possível voltar essa acao"
				buttonConfirmText="Apagar"
				colorIcon="var(--text-grey)"
				open={openErrorModal}
				onClose={() => setOpenErrorModal(false)}
				buttonConfirmAction={() => deleteComment()}
			/>
			{/* Modal of edit and creat comment */}
			<MainModal
				show={openObservationModal}
				onClose={() => {
					setOpenObservationModal(false);
					setSelectedObservation({});
				}}
				width="small"
				title={
					(selectedObservation && selectedObservation.comment
						? 'Editar'
						: 'Criar') + ` comentário`
				}
				confirmButton="Confirmar"
				cancelButton="voltar"
				confirmVariant="contained"
				onCancel={() => {
					setOpenObservationModal(false);
					setSelectedObservation({});
				}}
				onConfirm={() =>
					selectedObservation ? changeComment() : createComment()
				}
			>
				<UIText
					text={
						(selectedObservation ? 'Edite' : 'Insira') +
						` o comentário no campo abaixo`
					}
				/>
				<Box pt={1} pb={1}>
					<UITextarea
						label="Comentário"
						name="comment"
						rows={5}
						value={(selectedObservation || {}).comment}
						onChange={handleChange}
					/>
				</Box>
			</MainModal>
			<SucessModal
				buttonText="Voltar para o processo"
				buttonAction={() => {
					setOpenSuccessModal(false);
					setOpenErrorModal(false);
					setOpenObservationModal(false);
				}}
				onClose={() => setOpenSuccessModal(false)}
				title={`Comentário ${
					openErrorModal
						? 'apagado'
						: selectedObservation.observation
						? 'editado'
						: 'adicionado'
				} com sucesso`}
				open={openSuccessModal}
			/>
		</>
	);
}
