import React from 'react';

import TextItem from '../TextItem/TextItem';
import BulletItem from '../BulletItem/BulletItem';
import VerticalLine from './VerticalLine/VerticalLine';

import './styles.css';

export default function VerticalStageBar({ items, current }) {
	return (
		<div className="VerticalStageBar">
			<div>
				{items.map((item, index) => (
					<div className="item" key={index}>
						<div className="item-icon">
							{index !== 2 ? (
								<VerticalLine

									color={
										item.index <= current - 1
											? 'var(--primary)'
											: 'rgba(80, 130, 201, 0.05)'
									}
									isVisible={item.index > items.length ? 'hidden' : ''}
								/>
							) : null}

							<BulletItem
								number={item.index}
								state={
									item.index > current
										? 'pending'
										: item.index < current
											? 'visited'
											: 'active'
								}
							/>
						</div>
						<div className="item-text">
							<TextItem
								title={item.title}
								description={item.description}
								variant={item.index === current ? 'current' : ''}
							/>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
