import { Grid, Box, withStyles } from '@material-ui/core';

export const CardContainer = withStyles({
	root: {
		borderRadius: '4px',
		background: '#fff',
		borderLeft: '5px solid #000',
		borderColor: (props) => props.borderColor,
		padding: (props) => props.padding,
		margin: '.3rem 0',
		cursor: 'pointer',
		marginBottom: '1rem',
	}
})(Grid);

export const IconContainer = withStyles({
	root: {
		background: 'rgba(80, 130, 201, 0.16)',
		padding: '.3rem',
		// margin: '0 .5rem',
		borderRadius: '4px',
	}
})(Box);
