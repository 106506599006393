export function validateCpf(cpf = '') {
  // CPFs inválidos conhecidos
  const invalids = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
  ];

  if (!cpf) {
    return false;
  }
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf === '') {
    return false;
  }

  if (cpf.length !== 11 || invalids.includes(cpf)) {
    return false;
  }

  let add = 0;
  for (let i = 0; i < 9; i++) {
    add += parseInt(cpf.charAt(i), 10) * (10 - i);
  }
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }
  if (rev !== parseInt(cpf.charAt(9), 10)) {
    return false;
  }
  add = 0;
  for (let i = 0; i < 10; i++) {
    add += parseInt(cpf.charAt(i), 10) * (11 - i);
  }
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }
  if (rev !== parseInt(cpf.charAt(10), 10)) {
    return false;
  }
  return true;
}

export function validateCnpj (cnpj = '') {
  const invalids = [
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999'
  ]

  cnpj = cnpj?.replace(/[^\d]+/g, '') || ''

  if (cnpj === '') { return false }

  if (cnpj.length !== 14) { return false }

  if (invalids.includes(cnpj)) {
    return false
  }

  let size = cnpj.length - 2
  let numbers = cnpj.substring(0, size)
  const digits = cnpj.substring(size)
  let sum = 0
  let pos = size - 7
  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i)) * pos--
    if (pos < 2) {
      pos = 9
    }
  }
  let result = sum % 11 < 2 ? 0 : 11 - sum % 11
  if (result !== parseInt(digits.charAt(0))) {
    return false
  }

  size = size + 1
  numbers = cnpj.substring(0, size)
  sum = 0
  pos = size - 7
  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i)) * pos--
    if (pos < 2) {
      pos = 9
    }
  }
  result = sum % 11 < 2 ? 0 : 11 - sum % 11
  if (result !== parseInt(digits.charAt(1))) {
    return false
  }

  return true
}

export const states = [
  {
    text: "Acre",
    value: "AC"
  },
  {
    text: "Alagoas",
    value: "AL"
  },
  {
    text: "Amapá",
    value: "AP"
  },
  {
    text: "Amazonas",
    value: "AM"
  },
  {
    text: "Bahia",
    value: "BA"
  },
  {
    text: "Ceará",
    value: "CE"
  },
  {
    text: "Distrito Federal",
    value: "DF"
  },
  {
    text: "Espírito Santo",
    value: "ES"
  },
  {
    text: "Goiás",
    value: "GO"
  },
  {
    text: "Maranhão",
    value: "MA"
  },
  {
    text: "Mato Grosso",
    value: "MT"
  },
  {
    text: "Mato Grosso do Sul",
    value: "MS"
  },
  {
    text: "Minas Gerais",
    value: "MG"
  },
  {
    text: "Pará",
    value: "PA"
  },
  {
    text: "Paraíba",
    value: "PB"
  },
  {
    text: "Paraná",
    value: "PR"
  },
  {
    text: "Pernambuco",
    value: "PE"
  },
  {
    text: "Piauí",
    value: "PI"
  },
  {
    text: "Rio de Janeiro",
    value: "RJ"
  },
  {
    text: "Rio Grande do Norte",
    value: "RN"
  },
  {
    text: "Rio Grande do Sul",
    value: "RS"
  },
  {
    text: "Rondônia",
    value: "RO"
  },
  {
    text: "Roraima",
    value: "RR"
  },
  {
    text: "Santa Catarina",
    value: "SC"
  },
  {
    text: "São Paulo",
    value: "SP"
  },
  {
    text: "Sergipe",
    value: "SE"
  },
  {
    text: "Tocantins",
    value: "TO"
  }
]