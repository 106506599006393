import React from 'react';

import Grid from '@material-ui/core/Grid';

import { CardContainer, CardTitle, CardBlock } from './styles';
import UITag from 'components/UI/Tag/index';
import UIText from 'components/UI/Text/Text';

export default function ActionCard({ informations }) {
	/*
	template de informations = {
			title,
			bolderTitle,
			city,
			instituition,
			openProcess,
			nPreg,
			chips: [
				tag: {
					text,
					variant,
					width,
					height,
					size
				},
			],
		}
	*/

	return (
		<CardContainer container alignContent="space-between">
			<Grid>
				<UIText
					text={informations.title}
					fontSize="12px"
					fontWeight="500"
					textcolor="var(--text-grey)"
				/>
			</Grid>
			<Grid item xs={12}>
				<UIText
					text={informations.bolderTitle}
					fontSize="14px"
					fontWeight="500"
				/>
			</Grid>
			<Grid container direction="row" justify="flex-start">
				<CardTitle
					textcolor="var(--primary)"
					fontWeight="400"
					paddined={'true'}
				>
					{informations.city}
				</CardTitle>
				<CardTitle
					textcolor="var(--text-dark)"
					fontWeight="400"
					paddined={'true'}
					texttransform="capitalize"
				>
					| {informations.typeInstitution}
				</CardTitle>
			</Grid>
			<Grid container direction="row" justify="flex-start">
				<CardBlock />
			</Grid>
			<Grid
				container
				direction="row"
				justify="space-around"
				alignItems="flex-start"
			>
				<Grid
					container
					direction="column"
					justify="flex-start"
					alignItems="flex-start"
				>
					<CardTitle>Abertura do processo</CardTitle>
					<CardTitle textcolor="var(--text-dark)" fontWeight="400">
						{informations.openProcess}
					</CardTitle>
				</Grid>
				<Grid item xs={4}>
					<CardTitle>N° do pregão</CardTitle>
					<CardTitle textcolor="var(--text-dark)" fontWeight="400">
						{informations.tradingCode}
					</CardTitle>
				</Grid>
			</Grid>

			<Grid container direction="row" justify="flex-end" alignItems="center">
				{(informations.chips || []).map((chip) => (
					<UITag {...chip} />
				))}
			</Grid>
		</CardContainer>
	);
}
