import { withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import styled from 'styled-components';

export const Cell = withStyles({
	root: {
		background: (props) => props.background,
		fontSize: (props) => props.fontSize,
		fontWeight: (props) => props.fontWeight,
		lineHeight: (props) => props.lineheight,
		color: (props) => props.color,
		borderTop: (props) => props.bordertop,
		borderBottom: (props) => props.borderbottom,
		borderLeft: (props) => props.borderleft,
		borderRight: (props) => props.borderright,
		textDecorationLine: (props) => props.textdecorationline,
		cursor: (props) => props.cursor,
		height: (props) => props.height,
		padding: (props) => props.padding || '0px 16px',
		minWidth: (props) => props.minwidth,
		width: (props) => props.width,
		whiteSpace: 'nowrap',
	},
})(TableCell);

export const Text = styled.div`
	background-color: ${(props) => props.chipcolor};
	border-radius: ${(props) => props.chip && '10px'};
	height: ${(props) => props.chip && '20px'};
	line-height: ${(props) => props.chip && '20px'};
	padding-left: ${(props) => props.chip && '12px'};
	padding-right: ${(props) => props.chip && '12px'};
	width: ${(props) => props.chip && 'fit-content'};
`;

export const Link = styled.div`
	background-color: ${(props) => props.chipcolor};
	border-radius: ${(props) => props.chip && '10px'};
	height: ${(props) => props.chip && '20px'};
	line-height: ${(props) => props.chip && '20px'};
	padding-left: ${(props) => props.chip && '12px'};
	padding-right: ${(props) => props.chip && '12px'};
	padding-top: ${(props) => props.chip && '4px'};
	padding-bottom: ${(props) => props.chip && '4px'};
	width: ${(props) => props.chip && 'fit-content'};
	cursor: pointer;
`;
