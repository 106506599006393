import React from 'react';

import { Grid, Divider } from '@material-ui/core';

import { CardContainer } from './styles';
import UITag from 'components/UI/Tag';
import UIText from 'components/UI/Text/Text';

import { TiPencil } from 'react-icons/ti';
import { BiTrash } from 'react-icons/bi';

export default function ContactCard({ informations }) {
	/*
		template de informations = {
			borderColor,
			tag: {
				text,
				variant,
				width,
				height,
				size
			},
			instituition,
			name,
			email,
			local,
			onPencilClick,
			onTrashClick,
		}
	*/

	return (
		<CardContainer
			borderColor={informations.borderColor}
			container
			direction="row"
			justify="flex-start"
			alignItems="space-between"
		>
			<Grid container spacig={3}>
				<Grid item xs={12}>
					<Grid container justify="space-between" alignItems="flex-start">
						<UITag {...informations.tag} />
						<Grid item>
							<TiPencil
								color="var(--text-grey)"
								size={18}
								onClick={informations.onPencilClick}
							/>
							<BiTrash
								color="var(--text-grey)"
								size={18}
								onClick={informations.onTrashClick}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<UIText text={informations.name} fontSize="16px" fontWeight="500" />
				</Grid>
				<Grid item xs={12}>
					<UIText text={informations.email} fontSize="12px" fontWeight="400" />
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid container justify="flex-start" alignItems="center">
				<UIText
					text="Local: "
					fontSize="12px"
					fontWeight="400"
					textcolor="var(--text-grey)"
				/>
				<UIText
					text={`${informations.city || ''}, ${informations.uf || ''}`}
					fontSize="12px"
					fontWeight="500"
					textcolor="black"
				/>
			</Grid>
		</CardContainer>
	);
}

/*







*/
