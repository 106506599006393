import { Avatar, withStyles } from '@material-ui/core';

export const Bullet = withStyles({
	root: {
		background: (props) => props.background,
		color: (props) => props.textcolor,
		width: (props) => props.width,
		height: (props) => props.height,
		fontSize: (props) => props.fontSize,
	}
})(Avatar);
