import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputMask from 'react-input-mask';

import UIText from 'components/UI/Text/Text';
import UIInput from 'components/UI/Input/Input';
import UIButton from 'components/UI/Button/Button';
import HeaderScreens from 'screens/Admin/components/HeaderScreens';
import Modal from 'components/Modals';

import { Divider } from './style';
import { useHistory, useParams } from 'react-router';
import UISelect from 'components/UI/Select/Select';
import api from 'services/api';
import UIAutocomplete from 'components/UI/Autocomplete';

const PADDING = '16px';

const validationSchema = yup.object().shape({
	name: yup.string().required('Digite o nome'),
	email: yup.string().required('Digite o email').email('Email inválido'),
	telephone: yup.string().required('Digite o telefone'),
	contact: yup.string().required('Digite o nome do contato'),
	regional: yup.string().required('Selecione a regional'),
	companyName: yup.string().required('Digite a razão social'),
	cnpj: yup.string().required('Digite o CNPJ').cnpj('CNPJ inválido'),
	sold: yup.string().required('Digite o campo'),
	uf: yup.string().required('Selecione a UF'),
	city: yup.string().required('Selecione o município'),
});

export default function CreateDistributor() {
	const history = useHistory();
	const { distributorId } = useParams();
	const [ufs, setUfs ] = useState([]);;
	const [showSuccessModal, setShowSuccessModal] = useState(false);

	const {
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
		handleSubmit,
	} = useFormik({
		initialValues: {
			name: '',
			email: '',
			telephone: '',
			contact: '',
			regional: '',
			companyName: '',
			cnpj: '',
			sold: '',
			uf: '',
			city: '',
		},
		validationSchema,
		async onSubmit(fields) {
			try {
				let data = {
					...fields,
					password: `${fields.name}-${fields.sap}`,
					distributor: fields,
				};

				await api.post(`access`, data);
				setShowSuccessModal(true);
			} catch (error) {
				return error;
			}
		},
	});
	const getStates = async () => {
		const response = await api.get(`cities`, {
			params: {
				$limit: -1,
				$distinct: 'uf',
			},
		});
		if (response.status === 200)
			setUfs(response.data);
	}

	useEffect(() => {
		getStates();
	}, [])

	useEffect(() => {
		if (distributorId) {
			// TODO: fetch distributor data
		}
	}, [distributorId]);

	return (
		<Box p={3}>
			<HeaderScreens
				titleScreen="Usuários"
				currentBreadItem={{ text: 'Usuários', href: '/usuario' }}
			/>
			<Box css={{ bgcolor: '#fff' }} borderRadius="4px" marginTop="20px">
				<Box padding={PADDING}>
					<UIText
						text="Informações do distribuidor"
						color="#565656"
						fontWeight="500"
					/>
				</Box>
				<Divider />
				<Box padding={PADDING}>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						<Grid container spacing={3}>
							<Grid item md={4}>
								<UIInput
									value={values.name}
									label="Nome"
									error={touched.name && errors.name}
									onChange={handleChange('name')}
									onBlur={handleBlur('name')}
								/>
							</Grid>
							<Grid item md={4}>
								<UIInput
									value={values.email}
									label="Email"
									error={touched.email && errors.email}
									onChange={handleChange('email')}
									onBlur={handleBlur('email')}
								/>
							</Grid>
							<Grid item md={4}>
								<InputMask
									value={values.telephone}
									onChange={handleChange('telephone')}
									onBlur={handleBlur('telephone')}
									mask={
										values.telephone.trim().length === 15
											? '(99) 99999-9999'
											: '(99) 9999-99999'
									}
									maskChar=" "
								>
									{() => (
										<UIInput
											error={touched.telephone && errors.telephone}
											label="Telefone"
										/>
									)}
								</InputMask>
							</Grid>
							<Grid item md={4}>
								<UIInput
									value={values.contact}
									label="Contato"
									error={touched.contact && errors.contact}
									onChange={handleChange('contact')}
									onBlur={handleBlur('contact')}
								/>
							</Grid>
							<Grid item md={4}>
								<UISelect
									value={values.regional}
									label="Regional"
									items={regions}
									error={touched.regional && errors.regional}
									onBlur={handleBlur('regional')}
									onChange={handleChange('regional')}
								/>
							</Grid>
							<Grid item md={4}>
								<UIInput
									value={values.companyName}
									label="Razão Social"
									error={touched.companyName && errors.companyName}
									onChange={handleChange('companyName')}
									onBlur={handleBlur('companyName')}
								/>
							</Grid>
							<Grid item md={4}>
								<InputMask
									value={values.cnpj}
									onBlur={handleBlur('cnpj')}
									onChange={handleChange('cnpj')}
									mask="99.999.999/9999.99"
									maskChar="0"
								>
									{() => (
										<UIInput label="CNPJ" error={touched.cnpj && errors.cnpj} />
									)}
								</InputMask>
							</Grid>
							<Grid item md={4}>
								<UIInput
									value={values.sold}
									label="Sold"
									error={touched.sold && errors.sold}
									onChange={handleChange('sold')}
									onBlur={handleBlur('sold')}
								/>
							</Grid>
							<Grid item md={4}>
								<UISelect
									value={values.uf}
									label="UF"
									items={ufs}
									keyName="uf"
									keyValue="uf"
									error={touched.uf && errors.uf}
									onBlur={handleBlur('uf')}
									onChange={handleChange('uf')}
								/>
							</Grid>
							<Grid item md={4}>
								<UIInput
									value={values.city}
									label="Munícipio"
									error={touched.city && errors.city}
									onBlur={handleBlur('city')}
									onChange={handleChange('city')}
								/>
							</Grid>
						</Grid>
					</form>
					<Box display="flex" justifyContent="flex-end" marginTop="8px">
						<UIButton
							text={
								distributorId ? 'Atualizar distribuidor' : 'Criar distribuidor'
							}
							onClick={() => handleSubmit()}
						/>
					</Box>
				</Box>
			</Box>
			<Modal
				variant="success"
				open={showSuccessModal}
				title={
					distributorId
						? 'Distribuidor atualizado com sucesso'
						: 'Distribuidor adicionado com sucesso'
				}
				buttonText="Volta a tela inicial"
				buttonAction={() => history.push('/usuario')}
				widthTitle="280px"
				onClose={() => setShowSuccessModal(false)}
			/>
		</Box>
	);
}


const cities = [{ text: 'São Paulo', value: 'São Paulo' }];

const regions = [
	{ text: 'SSP', value: 'SSP' },
	{ text: 'CNN', value: 'CNN' },
];
