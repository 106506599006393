import { Fade, makeStyles, withStyles } from '@material-ui/core';

export const StyledFade = withStyles({
	root: {
		border: 0,
		outline: 'none',
		padding: '1rem',
		width: '100%',
	}
})(Fade);

export const useStyles = makeStyles({
	scrollbar: {
		maxHeight: 'calc(100vh - 2rem)',
	},
});
