export const quotaOptions = [
	{ text: 'Ampla', value: 'ampla' },
	{ text: 'Reservada', value: 'reservada' },
];

export const regionalOptions = [
	{ text: 'SSP', value: 'ssp' },
	{ text: 'CNN', value: 'cnn' },
];

export const stateOptions = [{ text: 'São Paulo', value: 'SP' }];
export const cityOptions = [{ text: 'São Paulo', value: 'Sao paulo' }];

export const optionsYesNo = [
	{ label: 'Sim', value: 'sim' },
	{ label: 'Não', value: 'não' },
];

export const optionsCompetitionModality = [
	{ text: 'Dispensa de licitação', value: 'Dispensa de licitação' },
	{ text: 'Pregão presencial', value: 'Pregão presencial' },
	{ text: 'Pregão eletrônico', value: 'Pregão eletrônico' },
	{ text: 'Carta convite', value: 'Carta convite' },
	{ text: 'Cotação', value: 'Cotação' },
];

export const optionsMonth = [
	{ text: '1', value: '1' },
	{ text: '2', value: '2' },
	{ text: '3', value: '3' },
	{ text: '4', value: '4' },
	{ text: '5', value: '5' },
	{ text: '6', value: '6' },
	{ text: '7', value: '7' },
	{ text: '8', value: '8' },
	{ text: '9', value: '9' },
	{ text: '10', value: '10' },
	{ text: '11', value: '11' },
	{ text: '12', value: '12' },
	{ text: '13', value: '13' },
	{ text: '14', value: '14' },
	{ text: '15', value: '15' },
	{ text: '16', value: '16' },
	{ text: '17', value: '17' },
	{ text: '18', value: '18' },
	{ text: '19', value: '19' },
	{ text: '20', value: '20' },
	{ text: '21', value: '21' },
	{ text: '22', value: '22' },
	{ text: '23', value: '23' },
	{ text: '24', value: '24' },
];
