import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineRight } from 'react-icons/ai';
import { StyledBreadcrumbs, StyledLink } from './style';

function NavBreadcrumbs({ items, color }) {

	return (
		<StyledBreadcrumbs
			separator={<AiOutlineRight size={10} />}
			aria-label="breadcrumb"
			color={color ? color : 'inherit'}
		>
			{items.map((item, i) => (
				color ? <StyledLink
					key={`key-${item.href}`}
					color={i === items.length - 1 ? color : color}
					to={item.href}
					underline="none"
				>
					{item.text}
				</StyledLink>
				:
				<StyledLink
				key={`key-${item.href}`}
				color={i === items.length - 1 ? '#5082C9' : 'inherit'}
				to={item.href}
				underline="none"
			>
				{item.text}
			</StyledLink>
			))}
		</StyledBreadcrumbs>
	);
}

export default NavBreadcrumbs;

NavBreadcrumbs.propTypes = {
	items: PropTypes.array.isRequired,
};
