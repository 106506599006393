import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import CardPreviewFile from 'components/File/PreviewFile';
import ModalFile from 'components/File/ModalFile';
import CardOCRFields from 'screens/Process/components/Card/OCRFields';
import CardScopeFields from 'screens/Process/components/Card/ScopeFields';
import UIButton from 'components/UI/Button/Button';
import TableItemsProcess from 'screens/Process/components/Table/ProcessItems';
import EditItem from '../../../../components/Modal/EditItem';

import api from 'services/api';
import { removeProcessProduct } from 'store/modules/processProduct/actions';

function ScopeRegistration() {
	const dispatch = useDispatch();

	const processDefault = useSelector((state) => state.process.process);
	const [scopeItems, setScopeItems] = useState([]);
	const [noticeURL, setNoticeURL] = useState('');
	const [selectedItem, setSelectedItem] = useState({});
	const [openEditItem, setOpenEditItem] = useState(false);
	const [products, setProducts] = useState({});

	useEffect(() => {
		const getNoticeURL = async () => {
			try {
				const response = await api.get(`attachments`, {
					params: {
						category: 'Edital',
						tableId: processDefault.id,
						table: 'process',
					},
				});
				if (response.status === 200) {
					const { key, id } = response.data.data[0];
					setNoticeURL(key);
					getOcr(id);
				}
			} catch (error) {
				return null;
			}
		};

		getNoticeURL();
	}, []);

	const getOcr = async (attachmentId) => {
		const response = await api.get(`ocr/${attachmentId}/products`);
		if (response.status === 200) setProducts(response.data);
	};

	const eventGoBack = () => {
		const data = {
			...processDefault,
			step: 'notice_analysis',
		};

		updateProcess(data);
	};

	const submitForm = () => {
		const data = {
			...processDefault,
			step: 'attachments',
		};

		updateProcess(data);
	};

	const updateProcess = async (process) => {
		dispatch({ type: '@process/UPDATE', payload: process });
	};

	const menuItems = [
		{
			onClick: (item) => {
				setSelectedItem(item);
				setOpenEditItem(true);
			},
			text: 'Editar item',
		},
		{
			onClick: (item) => dispatch(removeProcessProduct(item)),
			color: 'var(--error)',
			text: 'Excluir item',
		},
	];

	const [modalFileController, setModalFileController] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);

	return (
		<Box pt={3}>
			<Grid container spacing={3}>
				<Grid item xs={12} md={4}>
					<CardPreviewFile
						urlFile={noticeURL}
						onZoomClick={() => setModalFileController(!modalFileController)}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<CardOCRFields products={products} />
				</Grid>
				<Grid item xs={12} md={4}>
					<CardScopeFields
						addItem={(item) => {
							setScopeItems((items) => {
								return [...items, item];
							});
						}}
					/>
				</Grid>
			</Grid>
			<Box pt={3}>
				<Grid container>
					<TableItemsProcess
						items={scopeItems}
						menuItems={menuItems}
						process={process}
					/>
				</Grid>
			</Box>
			<Grid container justify="flex-end" spacing={3}>
				<Grid item xs={12} md={4} lg={2}>
					<Box pt={3}>
						<UIButton
							text="Voltar"
							fullWidth
							variant="outlined"
							color="secondary"
							textcolor="var(--text-grey)"
							onClick={() => eventGoBack()}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={4} lg={2}>
					<Box pt={3}>
						<UIButton text="Avançar" fullWidth onClick={() => submitForm()} />
					</Box>
				</Grid>
			</Grid>
			<EditItem
				defaultItem={selectedItem}
				show={openEditItem}
				onClose={() => setOpenEditItem(false)}
			/>
			<ModalFile
				urlFile={noticeURL}
				isVisible={modalFileController}
				onClose={() => setModalFileController(false)}
				currentPage={currentPage}
			/>
		</Box>
	);
}

export default ScopeRegistration;
