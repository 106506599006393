import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router';

import PaddingGrid from 'components/Layout/PaddingGrid/index';

import Card from 'components/Cards';
import NotificationCard from 'components/Dashboard/NotificationCard/index';
import Navbar from 'components/Navigation/Navbar/Navbar';
import Notifications from 'components/Navigation/Notifications/Notifications';
import Breadcrumbs from 'components/Navigation/Breadcrumbs/Breadcrumbs';
import Tabs from 'components/Navigation/Tabs/Tabs';
import VerticalStageBar from 'components/StageBar/VerticalStageBar/VerticalStageBar';
import UITag from 'components/UI/Tag/index';
import UIInput from 'components/UI/Input/Input';
import UISelect from 'components/UI/Select/Select';
import UIButton from 'components/UI/Button/Button';
import UIDate from 'components/UI/Date/Date';
import UICheckbox from 'components/UI/Checkbox/Checkbox';
import UIText from 'components/UI/Text/Text';
import UITextarea from 'components/UI/Textarea/Textarea';
import UIRadio from 'components/UI/Radio/Radio';
import UISearch from 'components/UI/Search/Search';
import CardPreviewFile from 'components/File/PreviewFile';
import UploadArea from 'components/Upload/UploadArea/UploadArea';
import UploadFile from 'components/Upload/UploadFile/UploadFile';
import Modal from 'components/Modals/index';
import CardPendencie from 'components/Pendencies/CardPendencie';

export default function ViewComponents() {
	const [openModalSuccess, setOpenModalSuccess] = useState(false);
	const [openModalConfirm, setOpenModalConfirm] = useState(false);

	const breadItems = [
		{ text: 'Essa página', href: '/' },
		{ text: 'Outra página', href: '/' },
	];

	const stepsProcess = [
		{
			label: 'Resumo da proposta',
			color: 'var(--primary)',
			component: <div />,
		},
		{
			label: 'Análise do edital',
			color: 'var(--pink)',
			component: <div />,
		},
		{
			label: 'Cadastro do escopo',
			color: 'var(--orange)',
			component: <div />,
		},
		{
			label: 'Anexos',
			color: 'var(--success)',
			component: <div />,
		},
	];

	const stageBarItems = [
		{
			title: 'Cadastro das informações iniciais do processo',
			description:
				'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem consequuntur ipsam culpa, vel iusto quae corporis?',
			index: 1,
		},
		{
			title: 'Cadastro das informações iniciais do processo',
			description:
				'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem consequuntur ipsam culpa, vel iusto quae corporis?',
			index: 2,
		},
		{
			title: 'Cadastro das informações iniciais do processo',
			description:
				'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem consequuntur ipsam culpa, vel iusto quae corporis?',
			index: 3,
		},
	];

	const selectItems = [
		{ text: 'option 1', value: 'option 1' },
		{ text: 'option 2', value: 'option 2' },
		{ text: 'option 3', value: 'option 3' },
	];

	const radioItems = [
		{ label: 'Teste 1', value: 'Teste 1' },
		{ label: 'Teste 2', value: 'Teste 2' },
		{ label: 'Teste 3', value: 'Teste 3' },
	];

	const fakePendencies = () => {
		var generatedNotifications = [];
		for (var i = 0; i < 3; i++) {
			generatedNotifications.push({
				title: 'A Ata do processo não foi entregue',
				subtitle:
					'A ata do processo de São José dos Campos que expirou a ' +
					'20' +
					' dias ainda não foi anexada',
				expireData: '20',
				fatureValue: 'R$31.127.459,00',
				id: '6',
				uf: 'SP',
				city: 'Sao Paulo',
				updatedAt: '2020-12-08T21:45:26.000Z',
				starPeriod: '03 de janeiro de 2021',
				endPeriod: '27 de janeiro de 2021',
				tradingCode: '023/2020',
				status: 'Aguard. Distribuidor',
				value: 123123213,
				onClick: () => alert('ola'),
				onCardClick: () => alert('redirecting...'),
			});
		}
		return generatedNotifications;
	};

	const statusTemplates = [
		'Perda',
		'Ganho total',
		'Ganho parcial',
		'Dist. Cadastrando',
		'Aguard. Distribuidor',
		'Aguard. Consultor',
		'Aguard. financeiro',
	];

	const getRandom = () => {
		return parseInt(Math.random() * 3);
	};

	const randomStatus = () => {
		return statusTemplates[parseInt(Math.random() * statusTemplates.length)];
	};

	const history = useHistory();

	return (
		<PaddingGrid
			container
			direction="column"
			justify="flex-start"
			alignItems="flex-start"
		>
			<PaddingGrid
				container
				direction="row"
				justify="flex-start"
				alignItems="flex-start"
			>
				<PaddingGrid padding="10px" item>
					<Card
						variant="small"
						informations={{
							borderColor: 'var(--primary)',
							title: 'Testando',
							number: '001',
							negative: true,
							percentage: '1000',
						}}
					/>
				</PaddingGrid>
				<PaddingGrid padding="10px" item>
					<Card
						variant="action"
						informations={{
							title: 'Testando',
							bolderTitle: 'EU TO TESTANDO!',
							city: 'São Paulo',
							instituition: 'Prefeitura',
							openProcess: '08/12/2020',
							nPreg: '001/0001',
							chips: [
								{
									text: 'Teste 1',
									variant: 'success',
								},
								{
									text: 'Teste 2',
									variant: 'error',
								},
							],
						}}
					/>
				</PaddingGrid>
				<PaddingGrid padding="10px" item>
					<Card
						variant="contact"
						informations={{
							borderColor: 'var(--primary)',
							tag: {
								text: 'Teste',
								variant: 'public',
							},
							instituition: 'Prefeitura',
							email: 'dev@dev.dev',
							local: 'São Paulo',
							onPencilClick: () => { },
							onTrashClick: () => { },
						}}
					/>
				</PaddingGrid>
				<PaddingGrid padding="10px" item xs={3}>
					<Card
						variant="user"
						informations={{
							tag: {
								text: 'Teste',
								variant: 'public',
							},
							picture: '',
							isEditable: false,
							name: 'Dev',
							email: 'dev@dev.dev',
							openProcess: '08/12/2020',
							sale: '59,99',
							gains: '56',
							lastInteraction: '08/12/2020',
							onPencilClick: () => { },
							onTrashClick: () => { },
							onMenuClick: () => { },
						}}
					/>
				</PaddingGrid>
			</PaddingGrid>
			<PaddingGrid
				container
				direction="row"
				justify="flex-start"
				alignItems="flex-start"
			>
				<PaddingGrid padding="10px" item xs={3}>
					<Card
						variant="product"
						informations={{
							borderColor: 'var(--orange)',
							tag: {
								text: 'Teste',
								variant: 'error',
							},
							productName: 'Produto',
							sku: '123456',
							price: '16,70',
							gramar: '100ml',
							utilCount: '13',
							onPencilClick: () => { },
							onTrashClick: () => { },
						}}
					/>
				</PaddingGrid>
				<PaddingGrid padding="10px" item xs={3}>
					<Card
						variant="contact-disabled"
						informations={{
							borderColor: 'var(--primary)',
							tag: {
								text: 'Teste',
								variant: 'public',
							},
							instituition: 'Prefeitura',
							email: 'dev@dev.dev',
							local: 'São Paulo',
							onPencilClick: () => { },
							onTrashClick: () => { },
						}}
					/>
				</PaddingGrid>
				<PaddingGrid padding="10px" item xs={3}>
					<Card
						variant="user-disabled"
						informations={{
							tag: {
								text: 'Teste',
								variant: 'disabled',
							},
							picture: '',
							isEditable: false,
							name: 'Dev',
							email: 'dev@dev.dev',
							openProcess: '08/12/2020',
							sale: '59,99',
							gains: '17',
							lastInteraction: '08/12/2020',
							onMenuClick: () => { },
						}}
					/>
				</PaddingGrid>
				<PaddingGrid padding="10px" item xs={3}>
					<Card
						variant="product-disabled"
						informations={{
							borderColor: 'var(--orange)',
							tag: {
								text: 'Teste',
							},
							productName: 'Produto',
							sku: '123456',
							price: '16,70',
							gramar: '100ml',
							utilCount: '13',
							onMenuClick: () => { },
						}}
					/>
				</PaddingGrid>
			</PaddingGrid>

			<PaddingGrid
				padding=".3rem"
				container
				direction="row"
				justify="flex-start"
				alignItems="flex-start"
			>
				<Grid item xs={4}>
					<NotificationCard
						title="Teste"
						msg="Eu estou fazendo um teste para ver como fica"
						bold="teste"
					/>
				</Grid>
				<Grid item xs={8}>
					<Navbar />
				</Grid>
			</PaddingGrid>

			<PaddingGrid
				container
				direction="row"
				justify="flex-start"
				alignItems="flex-start"
			>
				<PaddingGrid padding="10px" item xs={2}>
					<Breadcrumbs items={breadItems} />
				</PaddingGrid>
				<Grid item xs={4}>
					<Tabs tabs={stepsProcess} activeTab={1} />
				</Grid>
			</PaddingGrid>
			<PaddingGrid
				container
				direction="row"
				justify="flex-start"
				alignItems="flex-start"
			>
				<Grid item xs={3}>
					<VerticalStageBar items={stageBarItems} current={2} />
				</Grid>

				<Grid item xs={9}>
					<PaddingGrid
						container
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						padding="1rem"
					>
						<Grid item>
							<UITag text="Teste" variant="success" />
						</Grid>

						<PaddingGrid item padding="10px">
							<UIInput label="Teste" placeholder="Ex: Teste" />
						</PaddingGrid>
						<PaddingGrid item padding="10px">
							<UISelect items={selectItems} label="Teste" />
						</PaddingGrid>
						<PaddingGrid item padding="10px">
							<UIButton text="Teste" onClick={() => { }} />
						</PaddingGrid>
						<PaddingGrid item padding="10px">
							<UIDate
								label="Teste"
								onChange={(e) => {
									alert(e);
								}}
							/>
						</PaddingGrid>
						<PaddingGrid item padding="10px">
							<UICheckbox label="Teste" />
						</PaddingGrid>
						<PaddingGrid item padding="10px">
							<UIText text="Typography 1" fontSize="20px" fontWeight="500" />
						</PaddingGrid>
						<PaddingGrid item padding="10px">
							<UIText text="Typography 2" fontSize="18px" fontWeight="400" />
						</PaddingGrid>
						<PaddingGrid item padding="10px">
							<UIText text="Typography 3" fontSize="14px" fontWeight="500" />
						</PaddingGrid>
						<PaddingGrid item padding="10px">
							<UITextarea label="Teste" rows="4" />
						</PaddingGrid>
						<PaddingGrid item padding="10px">
							<UITextarea label="Teste" rows="1" />
						</PaddingGrid>
						<PaddingGrid item padding="10px">
							<UIRadio label="Teste" items={radioItems} />
						</PaddingGrid>
						<PaddingGrid item padding="10px">
							<UISearch label="Teste" items={selectItems} />
						</PaddingGrid>
						<PaddingGrid item padding="10px">
							<CardPreviewFile />
						</PaddingGrid>
					</PaddingGrid>
				</Grid>
			</PaddingGrid>

			<PaddingGrid
				container
				direction="row"
				justify="flex-start"
				alignItems="flex-start"
			>
				<PaddingGrid padding="1rem" item xs={4}>
					<UploadArea />
				</PaddingGrid>
				<PaddingGrid padding="1rem" item xs={5}>
					<UploadFile
						name="Teste.pdf"
						showBars={true}
						category="Documento"
						size="10mb"
					/>
				</PaddingGrid>
				<PaddingGrid item={2} padding="1rem">
					<PaddingGrid padding=".5rem" xs={4}>
						<UIButton
							text="Modal success"
							onClick={() => {
								setOpenModalSuccess(true);
							}}
						/>
					</PaddingGrid>
					<PaddingGrid padding=".5rem" xs={4}>
						<UIButton
							text="Modal confirm"
							onClick={() => {
								setOpenModalConfirm(true);
							}}
						/>
					</PaddingGrid>
				</PaddingGrid>
			</PaddingGrid>
			<PaddingGrid padding="1rem" container>
				<Modal
					variant="success"
					title="Resultado adicionado com sucesso"
					buttonText="Voltar a tela inicial"
					open={openModalSuccess}
					buttonAction={() => {
						setOpenModalSuccess(false);
					}}
				/>

				<Modal
					variant="confirm"
					title="Resultado adicionado com sucesso"
					buttonText="Voltar a tela inicial"
					open={openModalConfirm}
					buttonAcceptAction={() => {
						setOpenModalConfirm(false);
					}}
					onClose={() => {
						setOpenModalConfirm(false);
					}}
				/>
			</PaddingGrid>

			<PaddingGrid
				padding="1rem"
				container
				direction="column"
				justify="center"
				alignItems="flex-start"
			>
				{fakePendencies().map((item) => (
					<Grid item xs={12}>
						<CardPendencie
							{...item}
							variantBorder={getRandom()}
							openEdict={item.starPeriod}
							resultDate={item.endPeriod}
							contractValue={item.value}
							nPreg={item.tradingCode}
							onClick={() =>
								history.push(`/processo/` + parseInt(Math.random() * 7))
							}
							onCardClick={() => { }}
							status={randomStatus()}
						/>
					</Grid>
				))}
			</PaddingGrid>

			<PaddingGrid padding="1rem" container justify="center" alignItems="center">
				<Grid item xs={6}>
					<Notifications />
				</Grid>
			</PaddingGrid>
			<PaddingGrid padding="10rem" container>
			</PaddingGrid>
		</PaddingGrid>
	);
}
