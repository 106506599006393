import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

import { StyledGrid, Title, StyledTextField } from './style';

import UIInput from 'components/UI/Input/Input';
import UIRadio from 'components/UI/Radio/Radio';
import UITextarea from 'components/UI/Textarea/Textarea';
import UIButton from 'components/UI/Button/Button';

import { yesNoOptions, regionalOptions, classificationOptions } from './selectOptions';

import api from 'services/api';
import { useDispatch } from 'react-redux';
import { store } from 'store';
import UISelect from 'components/UI/Select/Select';
import mock from './mock.json';
import * as yup from 'yup';
import { useFormik } from 'formik';

const validationSchema = yup.object().shape({
	regional: yup.string().required('Escolha uma opção'),
	sap: yup.string().required('Insira um valor'),
	serviceChannel: yup.string().required('Escolha uma opção'),
	class: yup.string().required('Escolha uma opção'),
	consultantName: '',
	focusUf: yup.string().required('Escolha uma opção'),
	justify: yup.string().optional(''),
	observation: yup.string().optional(''),
});

export default function ProposalSummary() {
	const dispatch = useDispatch();

	const [user, setUser] = useState({
		...store.getState().user,
		accessLevel: 'consultant',
	});

	const processDefault = store.getState().process.process;
	const [institutionsList, setInstitutionsList] = useState([]);
	const [processForm, setProcessForm] = useState({
		...processDefault,
		processDirect: true,
		institution: {},
		newProgram: false,
	});
	// get data
	useEffect(() => {
		async function getInstitutions() {
			try {
				const response = await api.get(`institution`);

				const institutions = response.data.data.map((item) => {
					return {
						...item,
					};
				});
				setInstitutionsList(institutions);
			} catch (error) {
				return null;
			}
		}

		getInstitutions();
	}, []);

	const { values, errors, touched, handleBlur, handleChange, setFieldValue, handleSubmit, resetForm } = useFormik({
		initialValues: {
			regional: processForm.regional || '',
			sap: processForm.sap || '',
			serviceChannel: processForm.serviceChannel || '',
			class: processForm.class || '',
			consultantName: processForm.consultantName || user.name,
			focusUf: (processForm.focusUf ? 'sim' : 'não') || 'não',
			justify: processForm.justify || '',
			observation: processForm.observation || '',
		},
		validationSchema,
		onSubmit(fields) {
			const submitForm = async () => {
				const data = {
					...processForm,
					...values,
					step: 'notice_analysis',
					focusUf: values.focusUf === 'sim' ? true : false,
				};

				try {
					const process = await api.post(`process`, {
						institutionId: (data.institution || {}).id,
						employeeId: user.id,
						distributorId: 1,
					});
					dispatch({
						type: '@process/SET_PROCESS', payload: {
							...process.data,
							...data,
						}
					});
					await dispatch({
						type: '@process/UPDATE', payload: {
							...process.data,
							...data,
						}
					});
				} catch (error) {
					return null;
				}
			};

			submitForm();
		}
	});

	const filterOptions = createFilterOptions({
		matchFrom: 'start',
		stringify: (option) => option.name,
	});

	return (
		<StyledGrid container>
			<Title>Informações do processo</Title>
			<Box pl={3} pr={3} pb={3} pt={1} style={{ width: '100%' }}>
				<form onSubmit={(e) => { handleSubmit(e); e.preventDefault() }}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={4}>
							<Autocomplete
								options={institutionsList}
								getOptionLabel={(option) => option.name || ''}
								filterOptions={filterOptions}
								value={processForm.institution}
								onChange={(event, newValue) => {
									setProcessForm((process) => {
										return {
											...process,
											institution: {
												...newValue,
											},
										};
									});
								}}
								renderInput={(params) => (
									<StyledTextField
										{...params}
										fullWidth
										InputLabelProps={{ shrink: true }}
										label="Instituição (opcional)"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UISelect
								label="Regional"
								name="regional"
								items={mock.regional}
								value={values.regional}
								onChange={handleChange('regional')}
								onBlur={handleBlur('regional')}
								error={touched.regional && errors.regional}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UISelect
								label="Canal de atendimento"
								items={mock.chanels}
								value={values.serviceChannel}
								onChange={handleChange('serviceChannel')}
								onBlur={handleBlur('serviceChannel')}
								error={touched.serviceChannel && errors.serviceChannel}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UISelect
								label="Classificação"
								value={values.class}
								items={classificationOptions}
								onChange={handleChange('class')}
								onBlur={handleBlur('class')}
								error={touched.class && errors.class}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							{/* mudar para auto complete */}
							<UIInput
								label="SAP do Consultor"
								type="number"
								value={values.sap}
								onChange={handleChange('sap')}
								onBlur={handleBlur('sap')}
								error={touched.sap && errors.sap}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UIInput
								label="Nome do Consultor"
								value={values.consultantName}
								disabled
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UISelect
								label="Tipo de conquista"
								value="3-Reversão"
								items={[{ text: '3-Reversão', value: '3-Reversão' }]}
								onChange={() => { }}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UIRadio
								label="Munícipio foco"
								value={values.focusUf}
								items={yesNoOptions}
								onChange={handleChange('focusUf')}
								onBlur={handleBlur('focusUf')}
								error={touched.focusUf && errors.focusUf}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<UIRadio
								label="Licitação"
								items={yesNoOptions}
								value={'não'}
								onChange={() => { }}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item md={6} xs={12}>
							<UITextarea
								label="Justificativa"
								value={values.justify}
								onChange={handleChange('justify')}
								onBlur={handleBlur('justify')}
								error={touched.justify && errors.justify}
								placeholder="Insira o texto..."
								rows={3}
								fullWidth
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<UITextarea
								label="Observação"
								value={values.observation}
								onChange={handleChange('observation')}
								onBlur={handleBlur('observation')}
								error={touched.observation && errors.observation}
								placeholder="Insira o texto..."
								rows={3}
								fullWidth
							/>
						</Grid>
					</Grid>
					<Grid container justify="flex-end">
						<Grid item xs={12} md={4} lg={2}>
							<Box pt={3}>
								<UIButton
									text="Avançar"
									fullWidth
									type="submit"
								/>
							</Box>
						</Grid>
					</Grid>
				</form>
			</Box>
		</StyledGrid>
	);
}
