/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';

import { StyledProgressBar, StyledError } from './styles';

export default function UploadProgressBar({ invisible, success }) {
	const hid = invisible ? 'block' : 'none';

	const [progress, setProgress] = useState(0);
	const [elementos, setElementos] = useState(null);
	useEffect(() => {
		if (progress <= 100)
			setElementos(
				<StyledProgressBar
					display={hid}
					variant="determinate"
					value={progress}
				/>,
			);
	}, [progress, hid]);

	useEffect(() => {
		const timer = setInterval(() => {
			setProgress((oldProgress) => {
				if (oldProgress < 100) {
					const diff = Math.random() * 20;
					return Math.min(oldProgress + diff, 100);
				}
				if (!success) setElementos(<StyledError>Falha no upload</StyledError>);
				clearInterval(timer);
			});
		}, 500);
	}, [success]);

	return <div>{elementos}</div>;
}
