import { Grid, Avatar, Box, withStyles } from '@material-ui/core';
import { StyledTypograph } from 'components/UI/Text/styles';

export const CardContainer = withStyles({
	root: {
		background: '#FFFFFF',
		borderRadius: '3px',
	}
})(Box);

export const HeaderCard = withStyles({
	root: {
		padding: '.5rem',
	}
})(Grid);

export const UserPhoto = withStyles({
	root: {
		width: '50px',
		height: '50px',
		background: '#F8F4F4',
		borderStyle: 'solid',
		borderWidth: '1px',
		borderColor: (props) => props.borderColor || 'var(--primary)'
	}
})(Avatar);

export const MiddleColumn = withStyles({
	root: {
		borderLeft: '2px solid var(--grey-opacity)',
		borderRight: '2px solid var(--grey-opacity)',
		padding: '0 0.1rem',
		margin: '0 0.1rem',
	}
})(Grid);

export const ItemColumn = withStyles({
	root: {
		padding: '0 .5rem',
		marginTop: '.2rem',
	}
})(Grid);

export const FooterCard = withStyles({
	root: {
		padding: '.7rem',
	}
})(Grid);

export const PaddingGrid = withStyles({
	root: {
		padding: (props) => props.padding,
		margin: (props) => props.margin,
	}
})(Grid);


export const EllipsisText = withStyles({
	root: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	}
})(StyledTypograph)