import React, { useState } from 'react';

import { Grid, Menu, MenuItem, Typography } from '@material-ui/core';

import { CardContainer, IconContainer } from './styles';

import UIText from 'components/UI/Text/Text';
import PaddingGrid from 'components/Layout/PaddingGrid/index';
import UIButton from 'components/UI/Button/Button';

import { MdMail } from 'react-icons/md';
import UITag from 'components/UI/Tag/index';

export default function CardPendencie({
	variantBorder,
	title,
	subtitle,
	status,
	contractValue,
	fatureValue,
	nPreg,
	openEdict,
	resultDate,
	onCardClick,
	onClickMenuItems,
}) {
	const borderTemplates = ['var(--success)', 'var(--orange)', 'var(--pink)'];

	const statusTemplates = [
		'Perda',
		'Ganho total',
		'Ganho parcial',
		'Dist. Cadastrando',
		'Aguard. Distribuidor',
		'Aguard. Consultor',
		'Aguard. financeiro',
	];

	const tagVariant = [
		'error',
		'success',
		'public',
		'bgGrey',
		'gold',
		'disabled',
		'orange',
	];

	const [anchorEl, setAnchorEl] = useState(false);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(!anchorEl);
	};

	return (
		<CardContainer
			borderColor={borderTemplates[variantBorder]}
			padding=".5rem"
			container
			onClick={onCardClick}
		>
			<Grid container>
				<Grid item sm={12} md={7}>
					<Grid container justify="flex-start">
						<IconContainer item>
							<MdMail color="var(--primary)" size={50} />
						</IconContainer>
						<PaddingGrid padding=".3rem" item sm={10}>
							<UIText text={title} fontWeight="500" fontSize="16px" />
							<UIText
								text={subtitle}
								textcolor="var(--text-dark)"
								fontWeight="500"
								fontSize="14px"
							/>
						</PaddingGrid>
					</Grid>
				</Grid>
				<Grid item sm={12} md={4}>
					<PaddingGrid
						padding=".3rem"
						container
						justify="space-between"
						direction="row"
					>
						<UITag
							text={status}
							variant={tagVariant[statusTemplates.indexOf(status)]}
						/>
						<UIButton
							text="Atualizar Processo"
							variant="outline"
							textcolor="var(--primary)"
							fontSize="14px"
							type="button"
							onClick={(e) => {
								handleClick(e);
								e.stopPropagation();
							}}
						/>
						<Menu
							id="simple-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							<MenuItem
								onClick={onClickMenuItems}
							>
								<Typography variant="h5">Cancelamento de participação</Typography>
							</MenuItem>
							<MenuItem
								onClick={onClickMenuItems}
							>
								<Typography variant="h5">Adicionar documentos</Typography>
							</MenuItem>
							<MenuItem
								onClick={onClickMenuItems}
							>
								<Typography variant="h5">Verificar status do processo</Typography>
							</MenuItem>
							<MenuItem
								onClick={onClickMenuItems}
							>
								<Typography variant="h5">Fazer pedido</Typography>
							</MenuItem>
							<MenuItem
								onClick={onClickMenuItems}
							>
								<Typography variant="h5">Informar desconto</Typography>
							</MenuItem>
							<MenuItem
								onClick={onClickMenuItems}
							>
								<Typography variant="h5">Confirmação das etapas</Typography>
							</MenuItem>
						</Menu>
					</PaddingGrid>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item sm={12} md={4}>
					<PaddingGrid padding=".3rem" container justify="flex-start">
						<Grid item sm={6}>
							<UIText
								text="Valor contratado: "
								fontSize="12px"
								fontWeight="500"
								textcolor="var(--text-grey)"
							/>
							<UITag text={contractValue} fontSize="15px" variant="public" />
						</Grid>
						<Grid item sm={6}>
							<UIText
								text="Valor a faturar: "
								fontSize="12px"
								fontWeight="500"
								textcolor="var(--text-grey)"
							/>
							<UITag text={fatureValue} fontSize="15px" variant="silver" />
						</Grid>
					</PaddingGrid>
				</Grid>
				<Grid item sm={12} md={8}>
					<Grid container justify="space-around">
						<Grid item sm={4}>
							<PaddingGrid padding=".3rem" container direction="column">
								<UIText
									text="Número do pregão"
									fontWeight="400"
									size="12px"
									textcolor="var(--text-dark)"
								/>
								<UIText
									text={nPreg}
									fontWeight="500"
									size="14px"
									textcolor="var(--text-grey)"
								/>
							</PaddingGrid>
						</Grid>
						<Grid item sm={4}>
							<PaddingGrid padding=".3rem" container direction="column">
								<UIText
									text="Ínicio do edital"
									fontWeight="400"
									size="12px"
									textcolor="var(--text-dark)"
								/>
								<UIText
									text={openEdict}
									fontWeight="500"
									size="14px"
									textcolor="var(--text-grey)"
								/>
							</PaddingGrid>
						</Grid>
						<Grid item sm={4}>
							<PaddingGrid padding=".3rem" container direction="column">
								<UIText
									text="Data do resultado"
									fontWeight="400"
									size="12px"
									textcolor="var(--text-dark)"
								/>
								<UIText
									text={resultDate}
									fontWeight="500"
									size="14px"
									textcolor="var(--text-grey)"
								/>
							</PaddingGrid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</CardContainer>
	);
}
